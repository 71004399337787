  import React, {useState, useEffect, useRef} from 'react'
  import payment from '../assets/payment_methods.png'
  import furnitureImg from '../assets/furniture.jpg'
  import { IoLocation } from "react-icons/io5";
  import { MdModeEdit } from "react-icons/md";
  import { FaTrashCan } from "react-icons/fa6";
  import cards from '../assets/cards.png';
  import applePay from '../assets/applePay.png'
  import { CiCreditCard1 } from "react-icons/ci";
  import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
  import { collection, onSnapshot, query, where, doc, updateDoc} from 'firebase/firestore';
  import { db, auth, storage } from '../config/firebase'
  import { Divider } from '@mui/material';
  import { Oval } from 'react-loader-spinner'
  import { IoMdAddCircle } from "react-icons/io";
  import { IoIosCloseCircle } from "react-icons/io"; 
  import axios from 'axios';
  import LoadingScreen from './LoadingScreen';
  import { MdInfo } from "react-icons/md";
  import { GoHomeFill, GoReport } from "react-icons/go";
  import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
  import { getFunctions, httpsCallable } from "firebase/functions";
  import * as XLSX from 'xlsx';
  import excelFile from '../assets/excel/ServedCities.xlsx'; // Import the Excel file
  import canNavigateForward from '../hooks/useCanNavigateForward'
  function Checkout ({myId}) {

  // In your Checkout component
  const location = useLocation();
  const mode = location.state?.mode;
  const offerAmount = location.state?.offerAmount;
  const offer = location.state?.offer;



  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');



  useEffect(() => {
    const fetchCities = async () => {
      try {
        // Fetch the Excel file from the imported URL
        const response = await fetch(excelFile);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();
  
        // Parse the ArrayBuffer using XLSX
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
  
        // Read sheet data as an array of arrays
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        // Assuming the first column is English and the second is Arabic
        const cityList = data.map(row => ({
          english: row[0], // English city name (value)
          arabic: row[1],  // Arabic city name (label)
        })).filter(city => city.english && city.arabic); // Ensure both columns exist
  
        setCities(cityList);
      } catch (error) {
        console.error('Error fetching or parsing the Excel file:', error);
      }
    };
  
    fetchCities();
  }, []);
  

  const handleChange = (e) => {
    setCityHasError(false);
    setCity(e.target.value);
  };

          const [selectedOption, setSelectedOption] = useState(null);
          const [isSafari, setIsSafari] = useState(false);
          const [isLoading, setIsLoading] = useState(true);
          const [cardNumber, setCardNumber] = useState('');

          const [paymentStatus, setPaymentStatus] = useState('');

          const cardNumberRef = useRef();
          const monthRef = useRef();
          const yearRef = useRef();
          const cvcRef = useRef();

          const {id} = useParams();

          const q = query(collection(db, "Products"), where("id", "==", parseFloat(id)));



          const [windowSize, setWindowSize] = useState({
            width: window.innerWidth,
            height: window.innerHeight
          });
      
          useEffect(() => {
            const handleResize = () => {
              setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
              });
            };
        
            window.addEventListener('resize', handleResize);
        
            // Initial call to set the state with current window dimensions
            handleResize();
        
            return () => {
              window.removeEventListener('resize', handleResize);
            };
          }, []);

          const [showAddAddress, setShowAddAddress] = useState(false);
          
          const [addressShouldRender, setAddressShouldRender] = useState(false);

  useEffect(() => {
    if (showAddAddress) {
      setAddressShouldRender(true);
    }
  }, [showAddAddress]);

  useEffect(() => {
    if (!showAddAddress) {
      setTimeout(() => setAddressShouldRender(false), 500); // Duration matches the animation time
    }
  }, [showAddAddress]);



          const [productName, setProductName] = useState("");
          const [productCategory, setProductCategory] = useState("");
          const [productDescription, setProductDescription] = useState("");
          const [productPrice, setProductPrice] = useState("");
          const [entryFee, setEntryFee] = useState("");
          const [isSold, setIsSold] = useState("");
          const [isDeleted, setIsDeleted] = useState("");
          const [isAuction, setIsAuction] = useState(false);
          const [currentBid, setCurrentBid] = useState(0);
          const [productImg, setProductImg] = useState("");
          const [expiryDate, setExpiryDate] = useState("");
          const [myAddress, setMyAddress] = useState();
          const [myCity, setMyCity] = useState();
          const [myNeighbourhood, setMyNeighbourhood] = useState();
          const [myStreet, setMyStreet] = useState();
          const [myNotes, setMyNotes] = useState();
          const [hasExpired, setHasExpired] = useState(false);
          const [startingPrice, setStartingPrice] = useState(false);
          const [bidders, setBidders] = useState("");
          const [bidIncrements, setBidIncrements] = useState(0);
          const [highestBidder, setHeighestBidder] = useState(0);
          const [numBidders, setNumBidders] = useState(0);
          const [sellerId, setSellerId] = useState("");
          const [productWeight, setProductWeight] = useState("");
          const [postType, setPostType] = useState([]);

          const [generatedString, setGeneratedString] = useState('');

          const [shippingMethods, setShippingMethods] = useState([])


          const [saveMyAddress, setSaveMyAddress] = useState(true);
          const [myUID, setMyUID] = useState(null);
          const [requiresShipping, setRequiresShipping] = useState(false);
          const [deliveryOption, setDeliveryOption] = useState("");


          let price;
          let bidFee;
          let shippingFee;

          if(mode === "Bid"){
              // price = entryFee
              price = currentBid
              bidFee = 0
              shippingFee = 0
            // if(Object.keys(bidders).map(key => parseFloat(key)).includes(myId)){
            // } 
            // else{
            //   bidFee = 0;
            // }
          }


          else if(mode === "Purchase"){
            if(postType.includes("مزاد") && Object.keys(bidders).map(key => parseFloat(key)).includes(myId)){
              bidFee = entryFee;
              if(hasExpired){
                price = currentBid;
              }
              else{
                price = productPrice
              }
            } else {
              price = productPrice
              bidFee = 0
            }
            shippingFee = deliveryOption === "شحن" ? calculateShippingCost(productWeight) : 0;
          }

          else if(mode === "Offer"){
            price = parseFloat(offer) || offerAmount;
            shippingFee = deliveryOption === "شحن" ? calculateShippingCost(productWeight) : 0;
            bidFee = 0
          }
          else{
            shippingFee = 0;
          }

          const amount = price - bidFee + shippingFee


          // const price = mode !== "Bid" ? !isAuction ? productPrice : hasExpired ? highestBidder ? currentBid : null : productPrice : entryFee ;
          // const bidFee = !isAuction ? 0 : Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? entryFee : 0;
          // const shippingFee = requiresShipping && mode === "Purchase" ? calculateShippingCost(productWeight) : 0;
          // const price = mode === "Bid" ? startingPrice * 0.05 : (isAuction && productPrice > 0 ? hasExpired ? currentBid - (startingPrice * 0.05) : Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? productPrice - (startingPrice * 0.05) :  productPrice : isAuction && highestBidder === myId ? currentBid - (startingPrice * 0.05) : productPrice);
          // const amount = mode === "Purchase" && isAuction ? Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? parseFloat(price + shippingFee) -  (startingPrice * 0.05) : parseFloat(price + shippingFee) : productPrice  ;

          const [orderNumber, setOrderNumber] = useState(null)

          const navigate = useNavigate();

          const ordersCollection = collection(db, 'Orders');


          
          
          const [paymentLoading, setPaymentLoading] = useState(false);


          const [myReceiverName, setMyRceiverName] = useState("");
          const [receiverName, setReceiverName] = useState("");
          const [city, setCity] = useState("");

          const [neighbourhood, setNeighbourhood] = useState("");
          const [street, setStreet] = useState("");
          const [notes, setNotes] = useState("");
          const [receiverNameHasError, setReceiverNameHasError] = useState("");

          const receiverNameRef = useRef();
          const cityRef = useRef();
          const neighbourhoodRef = useRef();
          const streetRef = useRef();
          const notesRef = useRef();
          const myPhoneNumberRef = useRef();

          const [cityHasError, setCityHasError] = useState(false);
          const [neighbourhoodHasError, setNeighbourhoodHasError] = useState(false);
          const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);

          const [saveAddressIsLoading, setSaveAddressIsLoading] = useState(false);
          const [deleteAddressLoading, setDeleteAddressLoading] = useState(false);
          
          const [addressMode, setAddressMode] = useState("Add");
          const [myPhoneNumber, setMyPhoneNumber] = useState("");
          const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");

          const [buyerId, setBuyerId] = useState("");

          const [addressNotEntered, setAddressNotEntered] = useState(false);

          const [tranRef, setTranRef] = useState("")
          const [auctionStartDate, setAuctionStartDate] = useState("")


          useEffect(() => {
            window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }, []);

    const updateAddress = async () => {

      const functions = getFunctions();
      const updateUserAddress = httpsCallable(functions, 'updateUserAddress');

      try {
        const result = await updateUserAddress({
          userId: auth.currentUser.uid,
          city: city ? city : myAddress.city,
          neighborhood: neighbourhood ? neighbourhood : myAddress.neighborhood,
          street: street ? street : myAddress.street,
          notes: notes ? notes : myAddress.notes,
          receiverName: receiverName,
          receiverPhoneNumber
        });
    
        if (result.data.success) {
          console.log('Address updated successfully:', result.data.message);
        }
      } catch (error) {
        console.error('Error updating address:', error);
      }
    };


    const clearAddress = async () => {
      const functions = getFunctions();
      const clearUserAddress = httpsCallable(functions, 'clearUserAddress');
    
      try {
        setDeleteAddressLoading(true)
        const result = await clearUserAddress({
          userId: auth.currentUser.uid,
        });
        
        if (result.data.success) {
          console.log('Address cleared successfully:', result.data.message);
          setCity("")
          setReceiverName("")
        }
      } catch (error) {
        console.error('Error clearing address:', error);
      } finally{
        setDeleteAddressLoading(false)
      }
    };
    

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Reference the specific document by its ID
          const docRef = doc(db, "Products", id);
    
          // Use onSnapshot to listen to real-time updates on this document
          const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
              const data = doc.data();
    
              if (!data.isDeleted && !data.isSold) {
                const {
                  productName,
                  productPrice,
                  isSold,
                  productDescription,
                  isAuction,
                  currentBid,
                  imgs,
                  category,
                  expiryDate,
                  startingPrice,
                  bidders,
                  bidIncrements,
                  highestBidder,
                  isDeleted,
                  sellerId,
                  productWeight,
                  buyerId,
                  numBidders,
                  entryFee,
                  shippingMethods,
                  postType,
                  auctionStartDate
                } = data;
    
                setProductName(productName);
                setProductPrice(productPrice);
                setIsSold(isSold);
                setPostType(postType);
                setProductCategory(category);
                setShippingMethods(shippingMethods);
                setProductDescription(productDescription);
                setIsAuction(isAuction);
                setCurrentBid(currentBid);
                setProductImg(imgs[0]);
                setExpiryDate(expiryDate);
                setStartingPrice(startingPrice);
                setBidders(bidders);
                setBidIncrements(bidIncrements);
                setHeighestBidder(highestBidder);
                setIsDeleted(isDeleted);
                setSellerId(sellerId);
                setProductWeight(parseFloat(productWeight));
                setBuyerId(buyerId);
                setNumBidders(numBidders);
                setEntryFee(entryFee);
                setAuctionStartDate(auctionStartDate)
    
                if (expiryDate && expiryDate.seconds) {
                  setHasExpired(Date.now() / 1000 > expiryDate.seconds);
                }
              } else {
                setIsSold(!!data.isSold);
              }
            } else {
              console.error("No such document!");
            }
    
            // Set loading to false once data is fetched
            setIsLoading(false);
          });
    
          return unsubscribe;
        } catch (error) {
          console.error("Error fetching data:", error);
          // Set loading to false if there is an error
          setIsLoading  (false);
        }
      };
    
      fetchData(); // Call fetchData
    
    }, [id, isSold, hasExpired, myId]);











    useEffect(() => {
      const handlePageShow = async () => {
        const fromPaymentGateway = sessionStorage.getItem('fromPaymentGateway');
        
        if (fromPaymentGateway) {
          // console.log("User navigated back from the payment gateway");
          
          // Update Firestore
          const productRef = doc(db, 'Products', id.toString());
  
          try {
            await updateDoc(productRef, { isProcessing: false });
            console.log('Product updated successfully');
            sessionStorage.removeItem('fromPaymentGateway');
          } catch (error) {
            console.error('Error updating product:', error);
          }
        } else {
          // console.log("User did not come from the payment gateway");
        }
      };
  
      window.addEventListener('pageshow', handlePageShow);
  
      return () => {
        window.removeEventListener('pageshow', handlePageShow);
      };
    }, [id, location]);
    

  







    
    useEffect(() => {
      if (auth.currentUser && !auth.currentUser.isAnonymous) {
        const userDocRef = doc(db, "Users", auth.currentUser.uid);
  
        const unsubscribeUser = onSnapshot(userDocRef, (userDoc) => {
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setMyAddress(userData.address);
            setMyPhoneNumber(userData.phoneNumber)
            setReceiverPhoneNumber(userData.receiverPhoneNumber)
            setReceiverName(userData.receiverName)
            // Set other user-related states as needed
          } else {
            console.error("No such user document!");
          }
        });
  
        // Cleanup the user data listener when component unmounts
        return () => unsubscribeUser();
      }
    }, [auth.currentUser, db]);


            // Use useEffect for the timeout
            // useEffect(() => {
            //   if (sellerId === myId) {
            //     const timeoutId = setTimeout(() => {
            //       navigate(-1); // Navigate back after 3 seconds
            //     }, 3000);
            
            //     // Clear the timeout if the component unmounts before the timeout finishes
            //     return () => clearTimeout(timeoutId);
            //   }
            // }, [sellerId, myId, navigate]);

            // Use useEffect for the timeout
            useEffect(() => {
              if (!mode) {
                const timeoutId = setTimeout(() => {
                  navigate(-1); // Navigate back after 3 seconds
                }, 3000);
            
                // Clear the timeout if the component unmounts before the timeout finishes
                return () => clearTimeout(timeoutId);
              }
            }, [sellerId, myId, navigate]);

            // Use useEffect for the timeout
            useEffect(() => {
              if (postType.includes("مزاد") && auctionStartDate && auctionStartDate.toDate() > Date.now()) {
                const timeoutId = setTimeout(() => {
                  navigate(-1); // Navigate back after 3 seconds
                }, 3000);
            
                // Clear the timeout if the component unmounts before the timeout finishes
                return () => clearTimeout(timeoutId);
              }
            }, [sellerId, myId, navigate]);







            const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Set the div to visible after a slight delay to ensure the transition happens
  if(!isLoading){
    setTimeout(() => setIsVisible(true), 100);
  }
}, [isLoading]);


useEffect(() => {
  document.title = `بيع - الدفع`; // Dynamically set the page title
}, []); // Empty dependency array ensures this runs once on mount


    
          function calculateShippingCost(weight) {
            const basePrice = 23; // تكلفة الشحن لأول 15 كيلوجرام
            const additionalPricePerKilo = 2; // تكلفة الشحن لكل كيلوجرام إضافي
            const baseWeight = 15; // الوزن الأساسي
        
            if (weight <= baseWeight) {
                return basePrice;
            } else {
                const additionalWeight = weight - baseWeight;
                const additionalCost = additionalWeight * additionalPricePerKilo;
                return basePrice + additionalCost;
            }
        }

        if(isLoading){
          return <LoadingScreen/>
        }

        if(isAuction && hasExpired && highestBidder !== myId ){
          return <h1 style={{marginTop:"60px"}}>this post has expired</h1>
        }

        if(isDeleted){
          return <h1 style={{marginTop: "60px"}}>the post was deleted</h1>
        }

        if(isSold && buyerId !== myId){
          return <h1 style={{marginTop: "60px"}}>the product was sold</h1>
        }

        // if (sellerId === myId) {
        //   // Display the message
        //   return (
        //     <div>
        //       <h1>Can't buy your own product</h1>
        //     </div>
        //   );
        // }

        if(postType.includes("مزاد") && auctionStartDate && auctionStartDate.toDate() > Date.now()){
          return <h1>المزاد لم يبدأ بعد</h1>
        }

        if(!mode){
            return (
              <div>
                <h1>حدث خطأ</h1>
              </div>
            );
        }
        
        // if(isSold && buyerId === myId){
        //   navigate(`/product/${id}`)
        // }

        // if (mode !== "Purchase" && !hasExpired && Object.keys(bidders).map(parseFloat).includes(parseFloat(myId))) {
        //   navigate(`/product/${id}`);
        // } 
      

  //       if(auth.currentUser == null){
  //         return <SignInOrSignUpScreen />
  //       }


  //       async function createOrder(){
  //         const coll = collection(db, "Orders");
  //         const snapshot = await getCountFromServer(coll);

  //         var orderNumber = ++snapshot.data().count;

  //         await setDoc(doc(db, "Orders", `order${orderNumber}`), {
  //                 id: orderNumber,
  //                 buyer: auth.currentUser.uid,
  //                 amount: amount,
  //                 isPaid: false
  //         })
  //       }
        
          const isApplePaySupported = () => {
            return typeof ApplePaySession === 'function';
          };

          // const [supportsApplePay, setSupportsApplePay] = useState(isApplePaySupported());

          const options = [
                  { label : "Cards" , value: 'cards', image: cards },
                  { label: 'Apple Pay', value: 'applePay', image: applePay, size: '80px' },
                ];

          const handleOptionChange = (value) => {
                  setSelectedOption(value);
                };


                const handleInputChange = (e) => {
                  // Remove non-numeric characters
                  const newDigit = e.target.value.replace(/\D/g, '');
              
                  // Limit the input to 16 digits
                  if (newDigit.length > 16) {
                    return;
                  }
              
                  // Create a new card number with space after every 4 digits
                  const newCardNumber = newDigit
                    .match(/.{1,4}/g)
                    ?.join(' ')
                    .trim();
              
                  setCardNumber(newCardNumber || '');
                          if(e.target.value.length === 19){
                                  monthRef.current.focus();
                                }
                };


                function formatAccountingArabic(number) {
                  // Check if the input is a valid number
                  if (typeof number !== 'number' || isNaN(number)) {
                    return 'Invalid input';
                  }
                
                  // Use toLocaleString to format the number with commas and two decimal places
                  const formattedNumber = number.toLocaleString('en', {
                    minimumFractionDigits:2,
                    maximumFractionDigits: 2
                  });
                
                  // Add the Arabic currency symbol manually
                  const arabicFormattedNumber = `${formattedNumber} ر.س`;
                
                  return arabicFormattedNumber;
              }


            //   const handlePayment = async () => {
            //     try {
            //         if (auth.currentUser) {
            //             setPaymentLoading(true);
            
            //             const response = await fetch('https://us-central1-men-ela-testing.cloudfunctions.net/processPayment', {
            //                 method: 'POST',
            //                 headers: {
            //                     'Content-Type': 'application/json',
            //                 },
            //                 body: JSON.stringify({
            //                     postId: id,
            //                     userId: myId,
            //                     cart_amount: amount,
            //                     cart_currency: "SAR",
            //                     description: "Payment for XYZ",
            //                     profile_id: 106859,
            //                     tran_type: mode === "Bid" ? "auth" : "sale",
            //                     tran_class: "ecom",
            //                     cart_id: uuidv4(),
            //                     cart_description: `Order number: ${orderNumber}, Product number: ${id}, Buyer ID: ${myId}, Seller ID: ${sellerId}. `,
            //                     // return: `http://localhost:3000/test-screen`,
            //                     callback: `https://us-central1-men-ela-testing.cloudfunctions.net/handlePaymentCallback?a=${myId}&b=${id}&c=${mode}&d=${deliveryOption === "شحن" ? "Shipping" : deliveryOption === "تسليم" ? "Delivery": ""}&e=${orderNumber}&f=${sellerId}`,
            //                     hide_shipping: true,
            //                 }),
            //             });
            
            //             const responseData = await response.json();
            
            //             console.log('Response Data:', responseData);
            
            //             if (responseData.redirect_url) {
            //                 window.open(responseData.redirect_url, '_blank', 'noopener,noreferrer');
            //             } else {
            //                 console.error('Error or unsuccessful response from processPayment Cloud Function:', responseData);
            //                 setPaymentLoading(false);
            //                 // Handle error or unsuccessful payment
            //             }
            //         }
            //     } catch (error) {
            //         console.error('Error calling processPayment Cloud Function:', error);
            //         setPaymentLoading(false);
            //         // Handle error or unsuccessful payment
            //     }
            // };
              
            const handlePayment = async () => {
              console.log(id)
              try {
                setPaymentLoading(true)
                  const response = await fetch('https://us-central1-sell-production.cloudfunctions.net/processPaymentHelper', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                          postId: id,
                          userId: myId,
                          amount: amount,
                          tran_type: mode === "Bid" ? "auth" : "sale",
                          mode: mode,
                          deliveryOption: deliveryOption === "شحن" ? "Shipping" : "Delivery",
                          sellerId: sellerId
                      })
                  });
          
                  const data = await response.json();

                  if (data.success) {
                    sessionStorage.setItem('fromPaymentGateway', 'true');
                      window.location.href = data.redirect_url;
                  } else {
                    if(data.error.includes("Product is already sold or in process.")){
                      alert("توجد عملية شراء قيد التنفيذ من قبل عميل آخر")
                    }
                    setPaymentLoading(false)
                      console.error('Payment processing failed:', data.error);
                  }
              } catch (error) {
                  console.error('Error calling payment function:', error);
              }
              setTimeout(() => {
                setPaymentLoading(false)
              }, 3000); // 2 seconds delay
          };
          
          return (

            <div
            style={{
              backgroundColor: "white",
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(20px)",
              transition: "opacity 0.3s ease-in-out, transform 0.2s ease-in-out", // Adjusted to 0.3s
            }}
          >
            {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
          style={{marginBottom:"0px", 
          maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
          overflow: "hidden",  /* Hide any overflow content */
          whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
          textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
        fontSize:"14px",
color:"#01635c",
cursor:"pointer",
      fontWeight:"bold"

    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    onClick={()=>{navigate(-1)}}
          >{productName}</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px",
          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>الدفع</h6>
        </div>
      </div>
                  <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-between" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
  
  
  
                  {addressShouldRender && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          opacity: showAddAddress ? 1 : 0,
          transition: "opacity 0.5s ease-out",

            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: window.innerWidth > window.innerHeight ? "35%" : "90%",
              height: "fit-content",
              backgroundColor: "white",
              position: "relative",
              margin: "auto",
              opacity: 1,
              zIndex: 2,
              borderRadius: "16px",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              
            }}
            className={showAddAddress ? "pop-up" : "pop-down"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <IoIosCloseCircle
                size={27}
                style={{ cursor: "pointer" }}
                color="#ccc"
                onClick={() => {
                  if(saveAddressIsLoading) return;
                  setReceiverName("")
                  setCity("")
                  cityRef.current.value = "";
                  neighbourhoodRef.current.value = "";
                  streetRef.current.value = "";
                  notesRef.current.value = "";
                  setCityHasError(false);
                  setNeighbourhoodHasError(false);
                  setShowAddAddress(false);
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h5 style={{ marginTop: "16px" }}>
                  {addressMode === "Add" ? "أضف عنوانك" : "تحديث العنوان"}
                </h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{ width: "100%", padding: "0px 16px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ color: "red" }}>*</p>
                <input
                  autoFocus
                  id={receiverNameHasError ? "input-field-error" : "input-field"}
                  defaultValue={receiverName}
                  ref={receiverNameRef}
                  placeholder="اسم المستلم"
                  autoComplete='name'
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { setReceiverNameHasError(false); setReceiverName(e.target.value); }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "red" }}>*</p>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>

                <input
                  id={phoneNumberHasError ? "input-field-error" : "input-field"}
                  defaultValue={receiverPhoneNumber !== "" ? receiverPhoneNumber : myPhoneNumber}
                  ref={myPhoneNumberRef}
                  type='tel'
                  autoComplete='phone'
                  placeholder="رقم المستلم"
                  style={{ paddingBottom: "8px", direction:"ltr" }}
                  onChange={(e) => {
                    setPhoneNumberHasError(false)
                    const inputValue = e.target.value;
                  
                    // Check if input contains non-numeric characters
                    if (/[^0-9]/.test(inputValue)) {
                      alert("يرجى ادخال أرقام فقط");
                      myPhoneNumberRef.current.value = "";
                      return;
                    }
                  
                    // Filter out non-numeric characters and update the state
                    // const numericValue = inputValue.replace(/\D/g, '');
                    setReceiverPhoneNumber(inputValue);
                  }}
                  
                />
                  </div>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "red" }}>*</p>
                {/* <input
                  id={cityHasError ? "input-field-error" : "input-field"}
                  defaultValue=""
                  ref={cityRef}
                  autoComplete='address-level2'
                  placeholder="المدينة"
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { setCityHasError(false); }}
                /> */}
<select 
  ref={cityRef} 
  defaultValue={myAddress.city}
  id={cityHasError ? "input-field-error" : "input-field"}
  style={{ marginTop: "8px" }} 
  onChange={handleChange}
>
  <option value="">المدينة</option>
  {cities.map((city, index) => (
    <option 
      style={{ backgroundColor: "white" }} 
      key={index} 
      value={city.english} // Set the English city as the value
    >
      {city.arabic}
    </option>
  ))}
</select>

              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "16px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginBottom: "0px", color: "red" }}>*</p>
                    <input
                      defaultValue={myAddress.neighborhood}
                      id={neighbourhoodHasError ? "input-field-error" : "input-field"}
                      ref={neighbourhoodRef}
                      autoComplete='none'
                      placeholder="الحي"
                      style={{ paddingBottom: "8px" }}
                      onChange={(e) => {setNeighbourhood(e.target.value); setNeighbourhoodHasError(false); }}
                    />
                  </div>
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    defaultValue={myAddress.street}
                    onChange={(e)=>{
                      setStreet(e.target.value);
                    }}
                    id="input-field"
                    autoComplete='address'
                    placeholder="الشارع (اختياري)"
                    ref={streetRef}
                    style={{ paddingBottom: "8px" }}
                  />
                </div>
              </div>
              <input
                defaultValue={myAddress.notes}
                onChange={(e)=>{
                  setNotes(e.target.value)
                }}
                id="input-field"
                autoComplete='none'
                placeholder="ملاحظات (اختياري)"
                ref={notesRef}
                style={{ paddingBottom: "8px", marginTop: "16px" }}
              />
            </div>
            <button
              id="my-button"
              disabled={saveAddressIsLoading}
              style={{
                height: "40px",
                marginTop: "8px",
                marginBottom: "16px",
                width: window.innerWidth > window.innerHeight ? "50%" : "70%",
              }}
              onClick={async () => {

                if(receiverNameRef.current.value === ""){
                  setReceiverNameHasError(true);
                }

                if(myPhoneNumberRef.current.value === ""){
                  setPhoneNumberHasError(true)
                }

                if (cityRef.current.value === "") {
                  setCityHasError(true);
                }
                if (neighbourhoodRef.current.value === "") {
                  setNeighbourhoodHasError(true);
                }

                if (
                  saveMyAddress &&
                  receiverNameRef.current.value !== "" &&
                  myPhoneNumberRef.current.value !== "" &&
                  cityRef.current.value !== "" &&
                  neighbourhoodRef.current.value !== ""
                ) {
                  setSaveAddressIsLoading(true);
                  await updateAddress();
                  // try {
                  //   await updateDoc(userDocRef, {
                  //     "address.city": cityRef.current.value,
                  //     "address.neighbourhood": neighbourhoodRef.current.value,
                  //     "address.street": streetRef.current.value,
                  //     "address.notes": notesRef.current.value,
                  //     receiverName: receiverName,
                  //   });
                  //   console.log("Address updated successfully.");
                  // } catch (error) {
                  //   console.error("Error updating address:", error);
                  // }
                  setShowAddAddress(false);
                }
                if (!saveMyAddress) {
                  if (cityRef.current.value === "" || neighbourhoodRef.current.value === "")
                    return;
                  setCity(cityRef.current.value);
                  setNeighbourhood(neighbourhoodRef.current.value);
                  setStreet(streetRef.current.value);
                  setNotes(notesRef.current.value);

                  cityRef.current.value = "";
                  neighbourhoodRef.current.value = "";
                  streetRef.current.value = "";
                  notesRef.current.value = "";

                  setShowAddAddress(false);
                }
                setSaveAddressIsLoading(false);
              }}
            >
              {saveAddressIsLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display:"flex"
                  }}
                >
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{ display: "block" }}
                    ariaLabel="oval-loading"
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  />
                </div>
              ) : (
                <p style={{ marginBottom: "0px" }}>
                  {addressMode === "Add" ? "إضافة العنوان" : "تحديث العنوان"}
                </p>
              )}
            </button>
          </div>
        </div>
      )}






                          <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                  <div>
                          <h5 style={{marginTop:"16px"}}>ملخص الطلب</h5>
                          <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                  <h6 style={{marginRight: "8px"}}>{mode == "Bid" ? `عربون دخول مزاد لمنتج رقم#   ${id}` : mode === "Purchase" ? `طلب شراء لمنتج رقم#   ${id}` : `تقديم عرض لمنتج رقم#   ${id}`}</h6>

                                  <div style={{width: "95%", display: "flex"}}>
    <img src={productImg} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productCategory === "لوح سيارات" && "ارقام جوالات" ? "scale-down" : "cover", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
    <div style={{marginRight: "8px", width: "70%"}}>
      <p style={{
        marginBottom: "4px",
        fontWeight: "500",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 8,
        overflow: "hidden"
      }}>
        {productName}
      </p>
      <p style={{
        marginBottom: "0px",
        fontSize: "14px",
        color: "gray",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 10,
        overflow: "hidden"
      }}>
        {productDescription}
      </p>
      <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
        <p style={{marginBottom: "0px"}}>{formatAccountingArabic(price)}</p>
      </div>
    </div>
  </div>

                          </div>

  {
  mode !== "Bid" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    <>
                          <h5 style={{display:"flex", marginTop: "42px"}}>الشحن و التوصيل</h5>
                          
              <select 
            id='input-field' 
            defaultValue=""
            onChange={(event) => {
              const selectedValue = event.target.value;
              if(selectedValue === "شحن"){
                
                shippingFee = calculateShippingCost(productWeight)
              } 
              else{
                shippingFee = 0
              }
              setDeliveryOption(selectedValue)
            }}
            style={{ color: "black", borderRadius: "4px"}}
          >
            <option value=""></option>
            {shippingMethods.includes("شحن") && (
              <option defaultValue style={{ backgroundColor: "white", textAlign: "right" }} value="شحن">
                الشحن السريع
              </option>
            )}
            {shippingMethods.includes("تسليم") && (
              <option style={{ backgroundColor: "white", textAlign: "right" }} value="تسليم">
                سأستلم المنتج من البائع وجهاً لوجه
              </option>
            )}
          </select>

{
  deliveryOption === "تسليم" && (
    <div style={{padding:"8px", display: "flex", backgroundColor: "#fff0f2" ,marginBottom: "16px", marginTop: "8px", borderRadius:"8px"}}>
        <div>
          <MdInfo size={17} color='#ff7385' style={{minHeight:"17px", minWidth: "17px"}} />
        </div>

        <div>
        <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>نوصي بالتقاء البائع في مكان عام لاستلام المنتج و توخي الحذر لأي ظرف كان, و فحص المنتج في حينه, و تجنب استخدام اي وسيلة دفع خارج المنصة.</p>
        <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>منصة (بيع) تخلي مسؤوليتها في حال عدم اتباع اياً من المذكور أعلاه.</p>
        </div>
    </div>
  )
}

                            </>
                          )}
                          {  
                            mode !== "Bid" && deliveryOption === "شحن" &&  (
                              <>
                              <div style={{display:"flex", marginTop: "24px"}}>

                              <h5 style={{fontWeight: "bold", color: "#444" }}>العنوان</h5>
                              </div>
                              {
    myAddress?.city ?
    <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px"}}>
        <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverName}</p>    
        <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverPhoneNumber !== "" ? receiverPhoneNumber : myPhoneNumber}</p>    
        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{myAddress?.city}</p>    
        <p style={{ marginBottom: "4px", color: "gray" }}>
          {`حي ${myAddress.neighborhood} ${myAddress.street ? `, شارع ${myAddress.street}` : ""}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{myAddress.notes}</p>
      </div>
      <div style={{display: "flex", position:"absolute", left: 0 , top: 0, width: "100%", padding:"12px", justifyContent:"flex-end"}}>
        {
          deleteAddressLoading ? (
            <Oval
            height={"20px"}
            width={"20px"}
            color={"#00C8B8"}
            wrapperClass=""
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
          /> 
          ) : (
            <>
                    <MdModeEdit size={17} color='gray' style={{cursor:"pointer", minHeight:"17px", minWidth:"17px", marginLeft:"8px"}} onClick={()=>{setShowAddAddress(true); setAddressMode("Edit")}} />
        <FaTrashCan size={17} color='gray'  style={{cursor:"pointer", minHeight:"17px", minWidth:"17px"}} onClick={async ()=>{
          if (window.confirm("حذف العنوان الحالي؟")) {
            if(city && neighbourhood){
              setCity("");
              setNeighbourhood("");
              setStreet("");
              setNotes("");
            } else {
              // const userDocRef = doc(db, "Users", auth.currentUser.uid);
            //   try {
            //     await updateDoc(userDocRef, {
            //     'address.city': "",
            //     'address.neighbourhood': "",
            //     'address.street': "",
            //     'address.notes': "" 
            //   });
            //   console.log('Address updated successfully.');
            // } catch (error) {
            //   console.error('Error updating address:', error);
            // }
            clearAddress();
            }
        } else {
            console.log("Item deletion cancelled.");
        }
        }}/>
            </>
          )
        }
      </div>
    </div>
    : 
    <button onClick={()=>{setShowAddAddress(true); setAddressMode("Add"); setSaveMyAddress(true)}} id='my-button' style={{height:"35px", width:"100%", marginTop:"16px"}}>أضف عنوان</button>
  }

                              </>
                            )
                          }
                          
                          </div>
                          
                          </div>
                          <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                  <h5  style={{fontWeight: "bold", color: "#444", marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", }}>ملخص المبلغ</h5>

                                  {/* {
                                    mode === "Bid" && (
                                      <div style={{padding:"8px", display: "flex", backgroundColor: "#fff0f2" ,marginBottom: "16px", marginTop: "8px", borderRadius:"8px"}}>
                                      <div>
                                        <MdInfo size={17} color='#ff7385' style={{minHeight:"17px", minWidth: "17px"}} />
                                      </div>
                              
                                      <div>

                                      <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>
                                        Apple Pay غير مدعومة حالياً في عملية دفع عربون دخول المزاد, يرجى استخدام بطاقة مدى أو البطاقة الإئتمانية.
                                        </p>
                                      </div>
                                  </div>
                                    )
                                  } */}
                                  <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
                                  {/* {options.map((option) => (
          <div key={option.value} style={{ marginBottom: '10px', backgroundColor: 'white', borderRadius: '5px', width: '95%', marginRight: 'auto', marginLeft: 'auto' }}>
            {(isApplePaySupported() || option.label !== 'Apple Pay') && (
              <label
                htmlFor={option.value}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <input
                  type="radio"
                  id={option.value}
                  name="radioOptions"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={() => handleOptionChange(option.value)}
                  style={{ marginRight: '20px', paddingRight:"8px" ,height: '24px', width: '24px', accentColor: "#F00057" }}
                />
                <span
                  style={{
                    backgroundColor: option.color,
                    padding: '5px 10px',
                    height: '100%',
                    borderRadius: '5px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '50%', height: '50px' }}>
                    <img
                      src={option.image}
                      alt={option.label}
                      style={{ width: option.size, height: '100%', objectFit: 'cover', borderRadius:"8px" }}
                    />
                  </div>
                </span>
              </label>
            )}
          </div>
        ))} */}
        {/* {selectedOption === "cards" && (
    <>
      <div style={{ position: 'relative', width: '85%', margin: 'auto', marginTop: '24px' }}>
        <input
          type="tel"
          autoFocus
          maxLength="19" // Allowing 19 characters to account for spaces
          placeholder='رقم البطاقة'
          value={cardNumber}
          onChange={handleInputChange}
          ref={cardNumberRef}
          style={{
            width: '100%',
            border: 'none',
            outline: 'none',
            height: '40px',
            borderRadius: '4px',
            direction: 'rtl',
            padding: '0px 8px',
            color: '#555',
            paddingRight: "48px",
            fontSize: "14px",
            fontFeatureSettings: 'tnum',
          }}
        />
        <CiCreditCard1
          size={30}
          color="#555"
          style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
        />
      </div>
      <div style={{display: "flex", marginTop: "8px", width: "85%", marginRight: "auto", marginLeft: "auto", alignItems : "center"}}>
        <input ref={monthRef} onChange={(e)=>{
          if(e.target.value.length === 2){
                  yearRef.current.focus();
                }
        }} maxLength={2} type='tel' style={{width: "25%", height: "35px", direction: "rtl", textAlign: "center", borderLeft: "none", marginLeft: "-8px", borderRadius: "0px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px", border: "none", outline: "none"}} placeholder='الشهر'/>
        <p style={{marginBottom: "0px", color: "#555"}}>/</p>
        <input onChange={(e)=>{
          if(e.target.value.length === 2){
                  cvcRef.current.focus();
          }
        }} ref={yearRef} maxLength={2} type='tel' style={{width: "25%", height: "35px", borderRight: "none", borderRadius: "0px", textAlign: "center", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", border: "none", outline: "none"}} placeholder='السنة'/>
        <input maxLength={4} ref={cvcRef} type='tel' style={{width: "47%", height: "35px", marginRight: "auto", textAlign: "center", borderRadius: "4px", border: "none", outline: "none"}} placeholder='CVC' onChange={(e)=>{
          if(e.target.value.length === 4){
            cvcRef.current.blur();
          }
        }}/>
      </div>
    </>
  )} */}

  {/* <Divider sx={{height: "2px", width: "80%", marginRight: "auto", marginLeft: "auto", bgcolor: "white", borderColor: "white" ,marginTop: "16px"}}/> */}
  <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>المبلغ</h6>
  <h6>{formatAccountingArabic(price)}</h6>  
  </div>

  { deliveryOption == "شحن" &&   mode !== "Bid" &&
    <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
    <h6>الشحن</h6>
    <h6>{formatAccountingArabic(shippingFee)}</h6>
    </div>
  }

  {
    postType.includes("مزاد") && mode === "Purchase" && Object.keys(bidders).map(key => parseFloat(key)).includes(myId) &&

    <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>العربون المدفوع</h6>
  <h6> - {formatAccountingArabic(entryFee)}</h6>
  </div>
  }

  <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>الإجمالي</h6>
  <h6>{formatAccountingArabic(amount)}</h6>
  </div>

  <p style={{marginBottom:"0px", marginTop:"8px", textAlign:"center", color:"red", display: addressNotEntered && requiresShipping  ? "block" : "none"}}>يرجى ادخال عنوان صحيح لإكمال عملية الدفع</p>
  <div style={{display:"flex", marginTop: "8px"}}>


          <button style={{marginRight: "auto", marginLeft: "auto", backgroundColor: "#F00057", color:"white", width: "75%", height:"35px"}} id='my-button'
  disabled={paymentLoading || deleteAddressLoading || saveAddressIsLoading || isLoading}
  onClick={
    async ()=>{
      if(productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && deliveryOption === "" && mode !== "Bid"){
        alert("يرجى اختيار طريقة الشحن و التوصيل")
        return;
      }

      if(deliveryOption === "شحن" && mode !== "Bid"){
        if(!myAddress.city){
          alert("يرجى ادخال عنوان")
          return;
        }
      }

      if(deliveryOption === "شحن" && receiverName === "")
      {
        alert("يرجى ادخال اسم المستلم");
        return;
      }


      if(!isSold){
        handlePayment()
      }
  }}
  >{
    paymentLoading ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex"}}>
    <Oval
                height={"20px"}
                width={"20px"}
                color={"white"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 
    </div> : <p style={{marginBottom:"0px"}}>الانتقال لصفحة الدفع</p>
  }</button>

  </div>


                                  </div>
                          </div>
                  </div>
          
                  </div>
        
        );
  }
  
  export default Checkout;