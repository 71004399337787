import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  collection,
  onSnapshot,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
  getDocs,
  QueryConstraint,
} from "firebase/firestore";
import Product from "../components/Product";
import { query } from "firebase/database";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { db } from "../config/firebase";
import { FiFilter } from "react-icons/fi";
import LoadingScreen from "./LoadingScreen";
import Slider from "@mui/material/Slider";
import { FiChevronLeft } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { LuCircleDashed } from "react-icons/lu";
import { MdSell } from "react-icons/md";
import { TbGavel } from "react-icons/tb";
import { FaTransgender } from "react-icons/fa";
import { MdFactory } from "react-icons/md";
import { HiMiniPaintBrush } from "react-icons/hi2";
import { FaShapes } from "react-icons/fa6";
import { IoResize } from "react-icons/io5";
import { IoLanguage } from "react-icons/io5";
import { IoIosWatch } from "react-icons/io";
import { BiSolidPackage } from "react-icons/bi";
import { IoIosWater } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa6";
import SebhahIcon from "../assets/sebhah00C8B8.PNG";
import { GoNumber } from "react-icons/go";
import { GiSewingMachine } from "react-icons/gi";
import carPlateIcon from "../assets/car-plate-icon00C8B8.png";
import { FaTowerCell } from "react-icons/fa6";
import CustomCheckbox from "../components/CustomCheckbox";
import { FaShippingFast } from "react-icons/fa";
import NoProductsFoundAnim from "../assets/animations/ProductNotFoundAnim.json";
import Lottie from "lottie-react";
import { LiaSlidersHSolid } from "react-icons/lia";
import { FaSortAmountDown } from "react-icons/fa";
import { GoSortDesc } from "react-icons/go";
import useIsSafari from "../hooks/useIsSafari";
import { Oval } from "react-loader-spinner";
import useFetchPriceRange from "../hooks/useFetchPriceRange";
import Drawer from "@mui/material/Drawer";
import styled from "styled-components";
import { GoHomeFill, GoReport } from "react-icons/go";
import { debounce } from "lodash";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaCircle } from "react-icons/fa6";
import slugify from "slugify";
import { Helmet } from 'react-helmet';
import logo from '../assets/logo.png'
function ProductsScreen() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const isSafari = useIsSafari();

  const res = searchParams.get("search");

  const categorySearch = searchParams.get("category");
  const conditionFilter = searchParams.get("condition");
  const postTypeFilter = searchParams.get("postType");
  const companyFilter = searchParams.get("company");
  const watchDepartmentFilter = searchParams.get("watchDepartment");
  const watchOutsideColorFilter = searchParams.get("watchOutsideColor");
  const watchInsideColorFilter = searchParams.get("watchInsideColor");
  const watchDialShapeFilter = searchParams.get("watchDialShape");
  const smallestDialSizeFilter = parseFloat(
    searchParams.get("smallestWatchDialSize")
  );
  const biggestDialSizeFilter = parseFloat(
    searchParams.get("biggestWatchDialSize")
  );
  const watchHandlesColorFilter = searchParams.get("watchHandlesColor");
  const watchNumbersColorFilter = searchParams.get("watchNumbersColor");
  const watchNumbersLanguageFilter = searchParams.get("watchNumbersLanguage");
  const watchBandMaterialFilter = searchParams.get("watchBandMaterial");
  const watchBandColorFilter = searchParams.get("watchBandColor");
  const watchHasOriginalPackagingFilter = searchParams.get(
    "watchHasOriginalPackaging"
  );
  const watchIsWaterResistantFilter = searchParams.get("watchIsWaterResistant");
  const watchOldestYearMadeFilter = parseFloat(
    searchParams.get("oldestWatchYearMade")
  );
  const watchNewestYearMadeFilter = parseFloat(
    searchParams.get("newestWatchYearMade")
  );

  const rosaryKindFilter = searchParams.get("rosaryKind");
  const rosaryColorFilter = searchParams.get("rosaryColor");
  const leastRosaryCountFilter = parseFloat(
    searchParams.get("leastRosaryCount")
  );
  const mostRosaryCountFilter = parseFloat(searchParams.get("mostRosaryCount"));
  const smallestRosarySizeFilter = parseFloat(
    searchParams.get("smallestRosarySize")
  );
  const biggestRosarySizeFilter = parseFloat(
    searchParams.get("biggestRosarySize")
  );

  const walletDepartmentFilter = searchParams.get("walletDepartment");
  const walletOutsideColorFilter = searchParams.get("walletOutsideColor");

  const purseMaterialFilter = searchParams.get("purseMaterial");
  const purseOutsideColorFilter = searchParams.get("purseOutsideColor");
  const purseInsideColorFilter = searchParams.get("purseInsideColor");

  const carPlateKindFilter = searchParams.get("carPlateKind");
  const carPlateTransferFeeOnFilter = searchParams.get("carPlateTransferFeeOn");
  const carPlateTransferTimeFilter = searchParams.get("carPlateTransferTime");

  const phoneNumberCourierFilter = searchParams.get("phoneNumberCourier");

  const lowestPriceFilter = parseFloat(searchParams.get("lowestPrice"));
  const heighestPriceFilter = parseFloat(searchParams.get("heighestPrice"));

  const lowestEntryFeeFilter = parseFloat(searchParams.get("lowestEntryFee"));
  const heighestEntryFeeFilter = parseFloat(
    searchParams.get("heighestEntryFee")
  );

  const lowestStartingPriceFilter = parseFloat(
    searchParams.get("lowestStartingPrice")
  );
  const heighestStartingPriceFilter = parseFloat(
    searchParams.get("heighestStartingPrice")
  );

  const lowestBidIncrementFilter = parseFloat(
    searchParams.get("lowestBidIncrement")
  );
  const heighestBidIncrementFilter = parseFloat(
    searchParams.get("heighestBidIncrement")
  );

  const shippingMethodsFilter = searchParams.get("shippingMethods");

  const urlSortOption = searchParams.get("sort");

  const [selectedSortOption, setSelectedSortOption] = useState(
    urlSortOption || "من الأقدم إلى الأحدث"
  );

  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const [scrollPosition, setScrollPosition] = useState(0);

  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const subCategorySearch = searchParams.get("subCategory");
  const numBidders = searchParams.get("bidders");
  const views = searchParams.get("views");

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterDivRef = useRef();

  const [conditionSelected, setConditionSelected] = useState("");
  const [orderSelected, setOrderSelected] = useState("");
  const [orderSelectedName, setOrderSelectedName] = useState("");
  const [orderSelectedFun, setOrderSelectedFun] = useState("");
  const [filterSelected, setFilterSelected] = useState("");
  const [productKindSelected, setProductKindSelected] = useState("");
  const [postKind, setPostKind] = useState("");

  // const [mostExpensiveItemPrice, setMostExpensiveItemPrice] = useState(0)
  // const [leastExpensiveItemPrice, setLeastExpensiveItemPrice] = useState(0)
  const [leastPriceSelected, setLeastPriceSelected] = useState("");
  const [mostPriceSelected, setMostPriceSelected] = useState("");

  const [leastEntryFeeSelected, setLeastExpensiveItemEntryFeeSelected] =
    useState("");
  const [mostEntryFeeSelected, setMostExpensiveItemEntryFeeSelected] =
    useState("");

  const [
    leastStartingPriceSelected,
    setLeastExpensiveItemStartingPriceSelected,
  ] = useState(lowestStartingPriceFilter || "");
  const [mostStartingPriceSelected, setMostExpensiveItemStartingPriceSelected] =
    useState(heighestStartingPriceFilter || "");

  const [mostBidIncrementSelected, setMostExpensiveBidIncrementSelected] =
    useState(heighestBidIncrementFilter || "");

  const [oldestWatchYearSelected, setOldestWatchYearSelected] = useState("");
  const [newestWatchYearSelected, setNewestWatchYearSelected] = useState("");
  const [leastBidIncrementSelected, setLeastExpensiveBidIncrementSelected] =
    useState(lowestBidIncrementFilter || "");

  const [smallestDialSizeSelected, setSmallestWatchDialSizeSelected] =
    useState("");
  const [biggestDialSizeSelected, setBiggestWatchDialSizeSelected] =
    useState("");

  const [leastRosaryCountSelected, setLeastRosaryCountSelected] = useState("");

  const [mostRosaryCountSelected, setMostRosaryCountSelected] = useState("");

  const [smallestRosarySizeSelected, setSmallestRosarySizeSelected] =
    useState("");

  const [biggestRosarySizeSelected, setBiggestRosarySizeSelected] =
    useState("");

  const [priceFiltered, setPriceFiltered] = useState(
    lowestPriceFilter || heighestPriceFilter || false
  );
  const [watchYearMadeFiltered, setWatchYearMadeFiltered] = useState(false);
  const [watchDialSizeFiltered, setWatchDialSizeFiltered] = useState(false);
  const [entryFeeFiltered, setEntryFeeFiltered] = useState(false);
  const [startingPriceFiltered, setStartingPriceFiltered] = useState(false);
  const [BidIncrementFiltered, setBidIncrementFiltered] = useState(false);

  const [productConditionChecked_all, setProductConditionChecked_all] =
    useState(!conditionFilter);
  const [productConditionChecked_new, setProductConditionChecked_new] =
    useState(conditionFilter?.includes("جديد") || false);
  const [productConditionChecked_used, setProductConditionChecked_used] =
    useState(conditionFilter?.includes("مستعمل") || false);
  const [
    productConditionChecked_excellent,
    setProductConditionChecked_excellent,
  ] = useState(conditionFilter?.includes("ممتازة") || false);
  const [
    productConditionChecked_veryGood,
    setProductConditionChecked_veryGood,
  ] = useState(conditionFilter?.includes("جيدة جدا") || false);
  const [productConditionChecked_good, setProductConditionChecked_good] =
    useState(conditionFilter?.includes("جيدة") || false);
  const [
    productConditionChecked_acceptable,
    setProductConditionChecked_acceptable,
  ] = useState(conditionFilter?.includes("مقبولة") || false);

  const [productConditionFilters, setProductConditionFilters] = useState(
    conditionFilter ? conditionFilter.split(",") : ["الكل"]
  );

  const [productPostTypeChecked_all, setProductPostTypeChecked_all] = useState(
    !postTypeFilter
  );
  const [productPostTypeChecked_buyNow, setProductPostTypeChecked_buyNow] =
    useState(postTypeFilter?.includes("شراء فوري") || false);
  const [productPostTypeChecked_auction, setProductPostTypeChecked_auction] =
    useState(postTypeFilter?.includes("مزاد") || false);
  const [
    productPostTypeChecked_negotiable,
    setProductPostTypeChecked_negotiable,
  ] = useState(postTypeFilter?.includes("قابل للتفاوض") || false);

  const [productPostTypeFilters, setProductPostTypeFilters] = useState(
    postTypeFilter ? postTypeFilter.split(",") : ["الكل"]
  );

  const [companyChecked_all, setCompanyChecked_all] = useState(!companyFilter);
  const [companyChecked_rolex, setCompanyChecked_rolex] = useState(
    companyFilter?.includes("Rolex") || false
  );
  const [companyChecked_canon, setCompanyChecked_canon] = useState(
    companyFilter?.includes("Canon") || false
  );
  const [companyChecked_sony, setCompanyChecked_sony] = useState(
    companyFilter?.includes("Sony") || false
  );
  const [companyChecked_nikon, setCompanyChecked_nikon] = useState(
    companyFilter?.includes("Nikon") || false
  );
  const [companyChecked_omega, setCompanyChecked_omega] = useState(
    companyFilter?.includes("Omega") || false
  );
  const [companyChecked_cartier, setCompanyChecked_cartier] = useState(
    companyFilter?.includes("Cartier") || false
  );
  const [companyChecked_versace, setCompanyChecked_versace] = useState(
    companyFilter?.includes("Versace") || false
  );
  const [companyChecked_montBlanc, setCompanyChecked_montBlanc] = useState(
    companyFilter?.includes("Montblanc") || false
  );
  const [companyChecked_aigner, setCompanyChecked_aigner] = useState(
    companyFilter?.includes("Aigner") || false
  );
  const [companyChecked_rado, setCompanyChecked_rado] = useState(
    companyFilter?.includes("Rado") || false
  );
  const [companyChecked_givenchy, setCompanyChecked_givenchy] = useState(
    companyFilter?.includes("Givenchy") || false
  );
  const [companyChecked_chopard, setCompanyChecked_chopard] = useState(
    companyFilter?.includes("Chopard") || false
  );
  const [companyChecked_tissot, setCompanyChecked_tissot] = useState(
    companyFilter?.includes("Tissot") || false
  );
  const [companyChecked_patikPhillipe, setCompanyChecked_patikPhillipe] =
    useState(companyFilter?.includes("Patek Philippe") || false);
  const [companyChecked_armaniExchange, setCompanyChecked_armaniExchange] =
    useState(companyFilter?.includes("Armani Exchange") || false);
  const [companyChecked_gShock, setCompanyChecked_gShock] = useState(
    companyFilter?.includes("G-Shock") || false
  );
  const [companyChecked_apple, setCompanyChecked_apple] = useState(
    companyFilter?.includes("Apple") || false
  );
  const [companyChecked_huawei, setCompanyChecked_huawei] = useState(
    companyFilter?.includes("Huawei") || false
  );
  const [companyChecked_samsung, setCompanyChecked_samsung] = useState(
    companyFilter?.includes("Samsung") || false
  );
  const [companyChecked_xiaomi, setCompanyChecked_xiaomi] = useState(
    companyFilter?.includes("Xiaomi") || false
  );

  // wallets and purses
  const [companyChecked_chanel, setCompanyChecked_chanel] = useState(
    companyFilter?.includes("Chanel") || false
  );
  const [companyChecked_dior, setCompanyChecked_dior] = useState(
    companyFilter?.includes("Dior") || false
  );
  const [companyChecked_louisVuitton, setCompanyChecked_louisVuitton] =
    useState(companyFilter?.includes("Louis Vuitton") || false);
  const [companyChecked_gucci, setCompanyChecked_gucci] = useState(
    companyFilter?.includes("غوتشي") || false
  );
  const [companyChecked_dolceGabbana, setCompanyChecked_dolceGabbana] =
    useState(companyFilter?.includes("Dolce & Gabbana") || false);
  const [companyChecked_tomFord, setCompanyChecked_tomFord] = useState(
    companyFilter?.includes("Tom Ford") || false
  );
  const [companyChecked_fredPerry, setCompanyChecked_fredPerry] = useState(
    companyFilter?.includes("Fred Perry") || false
  );
  const [companyChecked_prada, setCompanyChecked_prada] = useState(
    companyFilter?.includes("Prada") || false
  );
  const [companyChecked_balenciaga, setCompanyChecked_balenciaga] = useState(
    companyFilter?.includes("Balenciaga") || false
  );
  const [companyChecked_saintLaurent, setCompanyChecked_saintLaurent] =
    useState(companyFilter?.includes("Saint Laurent") || false);
  const [companyChecked_toryBurch, setCompanyChecked_toryBurch] = useState(
    companyFilter?.includes("Tory Burch") || false
  );
  const [companyChecked_coach, setCompanyChecked_coach] = useState(
    companyFilter?.includes("Coach") || false
  );
  const [companyChecked_tedBaker, setCompanyChecked_tedBaker] = useState(
    companyFilter?.includes("Ted Baker") || false
  );
  const [companyChecked_charlesKeith, setCompanyChecked_charlesKeith] =
    useState(companyFilter?.includes("Charles & Keith") || false);
  const [companyChecked_guess, setCompanyChecked_guess] = useState(
    companyFilter?.includes("Guess") || false
  );
  const [companyChecked_offWhite, setCompanyChecked_offWhite] = useState(
    companyFilter?.includes("أوف وايت") || false
  );
  const [companyChecked_michaelKors, setCompanyChecked_michaelKors] = useState(
    companyFilter?.includes("Michael Kors") || false
  );
  // const [companyChecked_fendi, setCompanyChecked_fendi] = useState(companyFilter?.includes("فندي") || false);
  // const [companyChecked_hugoBoss, setCompanyChecked_hugoBoss] = useState(companyFilter?.includes("هوجو بوس") || false);
  // const [companyChecked_burberry, setCompanyChecked_burberry] = useState(companyFilter?.includes("بربري") || false);
  // const [companyChecked_valentino, setCompanyChecked_valentino] = useState(companyFilter?.includes("فالنتينو") || false);
  // const [companyChecked_hermes, setCompanyChecked_hermes] = useState(companyFilter?.includes("هيرميس") || false);
  // const [companyChecked_rolex, setCompanyChecked_rolex] = useState(companyFilter?.includes("Rolex") || false);
  // const [companyChecked_cartier, setCompanyChecked_cartier] = useState(companyFilter?.includes("Cartier") || false);
  // const [companyChecked_vacheronConstantin, setCompanyChecked_vacheronConstantin] = useState(companyFilter?.includes("فاشرون كونستانتين") || false);
  // const [companyChecked_tissot, setCompanyChecked_tissot] = useState(companyFilter?.includes("Tissot") || false);
  // const [companyChecked_tagHeuer, setCompanyChecked_tagHeuer] = useState(companyFilter?.includes("تاغ هوير") || false);
  // const [companyChecked_panasonic, setCompanyChecked_panasonic] = useState(companyFilter?.includes("باناسونيك") || false);
  // const [companyChecked_sony, setCompanyChecked_sony] = useState(companyFilter?.includes("سوني") || false);
  // const [companyChecked_samsung, setCompanyChecked_samsung] = useState(companyFilter?.includes("Samsung") || false);
  // const [companyChecked_apple, setCompanyChecked_apple] = useState(companyFilter?.includes("أبل") || false);
  // const [companyChecked_huawei, setCompanyChecked_huawei] = useState(companyFilter?.includes("Huawei") || false);
  // const [companyChecked_dell, setCompanyChecked_dell] = useState(companyFilter?.includes("ديل") || false);
  // const [companyChecked_hp, setCompanyChecked_hp] = useState(companyFilter?.includes("إتش بي") || false);
  // const [companyChecked_asus, setCompanyChecked_asus] = useState(companyFilter?.includes("أسوس") || false);
  // const [companyChecked_lenovo, setCompanyChecked_lenovo] = useState(companyFilter?.includes("لينوفو") || false);
  // const [companyChecked_acer, setCompanyChecked_acer] = useState(companyFilter?.includes("آيسر") || false);
  // const [companyChecked_msi, setCompanyChecked_msi] = useState(companyFilter?.includes("إم إس آي") || false);
  // const [companyChecked_razer, setCompanyChecked_razer] = useState(companyFilter?.includes("ريزر") || false);
  // const [companyChecked_alienware, setCompanyChecked_alienware] = useState(companyFilter?.includes("ألين وير") || false);

  const [companyChecked_other, setCompanyChecked_other] = useState(
    (companyFilter?.includes("أخرى") || companyFilter?.includes("آخر")) || false
  );

  const [productCompanyFilters, setProductCompanyFilters] = useState(["الكل"]);

  const [watchDepartmentChecked_all, setWatchDepartmentChecked_all] = useState(
    !watchDepartmentFilter
  );
  const [watchDepartmentChecked_men, setWatchDepartmentChecked_men] = useState(
    watchDepartmentFilter?.includes("رجالي") || false
  );
  const [watchDepartmentChecked_women, setWatchDepartmentChecked_women] =
    useState(watchDepartmentFilter?.includes("نسائي") || false);
  const [watchDepartmentChecked_unisex, setWatchDepartmentChecked_unisex] =
    useState(watchDepartmentFilter?.includes("للجنسين") || false);

  const [watchDepartmentFilters, setWatchDepartmentFilters] = useState([
    "الكل",
  ]);

  // State variables for watch outside color filters
  const [watchOutsideColorChecked_all, setWatchOutsideColorChecked_all] =
    useState(!watchOutsideColorFilter);
  const [
    watchOutsideColorChecked_stainlessSteel,
    setWatchOutsideColorChecked_stainlessSteel,
  ] = useState(watchOutsideColorFilter?.includes("ستانلس ستيل") || false);
  const [watchOutsideColorChecked_black, setWatchOutsideColorChecked_black] =
    useState(watchOutsideColorFilter?.includes("أسود") || false);
  const [watchOutsideColorChecked_white, setWatchOutsideColorChecked_white] =
    useState(watchOutsideColorFilter?.includes("أبيض") || false);
  const [watchOutsideColorChecked_red, setWatchOutsideColorChecked_red] =
    useState(watchOutsideColorFilter?.includes("أحمر") || false);
  const [watchOutsideColorChecked_blue, setWatchOutsideColorChecked_blue] =
    useState(watchOutsideColorFilter?.includes("أزرق") || false);
  const [watchOutsideColorChecked_green, setWatchOutsideColorChecked_green] =
    useState(watchOutsideColorFilter?.includes("أخضر") || false);
  const [watchOutsideColorChecked_yellow, setWatchOutsideColorChecked_yellow] =
    useState(watchOutsideColorFilter?.includes("أصفر") || false);
  const [watchOutsideColorChecked_orange, setWatchOutsideColorChecked_orange] =
    useState(watchOutsideColorFilter?.includes("برتقالي") || false);
  const [watchOutsideColorChecked_purple, setWatchOutsideColorChecked_purple] =
    useState(watchOutsideColorFilter?.includes("بنفسجي") || false);
  const [watchOutsideColorChecked_pink, setWatchOutsideColorChecked_pink] =
    useState(watchOutsideColorFilter?.includes("وردي") || false);
  const [watchOutsideColorChecked_brown, setWatchOutsideColorChecked_brown] =
    useState(watchOutsideColorFilter?.includes("بني") || false);
  const [watchOutsideColorChecked_gray, setWatchOutsideColorChecked_gray] =
    useState(watchOutsideColorFilter?.includes("رمادي") || false);
  const [watchOutsideColorChecked_olive, setWatchOutsideColorChecked_olive] =
    useState(watchOutsideColorFilter?.includes("زيتي") || false);
  const [watchOutsideColorChecked_cyan, setWatchOutsideColorChecked_cyan] =
    useState(watchOutsideColorFilter?.includes("سماوي") || false);
  const [watchOutsideColorChecked_navy, setWatchOutsideColorChecked_navy] =
    useState(watchOutsideColorFilter?.includes("كحلي") || false);
  const [watchOutsideColorChecked_beige, setWatchOutsideColorChecked_beige] =
    useState(watchOutsideColorFilter?.includes("بيج") || false);
  const [watchOutsideColorChecked_other, setWatchOutsideColorChecked_other] =
    useState(watchOutsideColorFilter?.includes("أخرى") || false);

  const [watchOutsideColorFilters, setWatchOutsideColorFilters] = useState([
    "الكل",
  ]);

  // State variables for watch inside color filters
  const [watchInsideColorChecked_all, setWatchInsideColorChecked_all] =
    useState(!watchInsideColorFilter);
  const [
    watchInsideColorChecked_stainlessSteel,
    setWatchInsideColorChecked_stainlessSteel,
  ] = useState(watchInsideColorFilter?.includes("ستانلس ستيل") || false);
  const [watchInsideColorChecked_black, setWatchInsideColorChecked_black] =
    useState(watchInsideColorFilter?.includes("أسود") || false);
  const [watchInsideColorChecked_white, setWatchInsideColorChecked_white] =
    useState(watchInsideColorFilter?.includes("أبيض") || false);
  const [watchInsideColorChecked_red, setWatchInsideColorChecked_red] =
    useState(watchInsideColorFilter?.includes("أحمر") || false);
  const [watchInsideColorChecked_blue, setWatchInsideColorChecked_blue] =
    useState(watchInsideColorFilter?.includes("أزرق") || false);
  const [watchInsideColorChecked_green, setWatchInsideColorChecked_green] =
    useState(watchInsideColorFilter?.includes("أخضر") || false);
  const [watchInsideColorChecked_yellow, setWatchInsideColorChecked_yellow] =
    useState(watchInsideColorFilter?.includes("أصفر") || false);
  const [watchInsideColorChecked_orange, setWatchInsideColorChecked_orange] =
    useState(watchInsideColorFilter?.includes("برتقالي") || false);
  const [watchInsideColorChecked_purple, setWatchInsideColorChecked_purple] =
    useState(watchInsideColorFilter?.includes("أرجواني") || false);
  const [watchInsideColorChecked_pink, setWatchInsideColorChecked_pink] =
    useState(watchInsideColorFilter?.includes("وردي") || false);
  const [watchInsideColorChecked_brown, setWatchInsideColorChecked_brown] =
    useState(watchInsideColorFilter?.includes("بني") || false);
  const [watchInsideColorChecked_gray, setWatchInsideColorChecked_gray] =
    useState(watchInsideColorFilter?.includes("رمادي") || false);
  const [watchInsideColorChecked_olive, setWatchInsideColorChecked_olive] =
    useState(watchInsideColorFilter?.includes("زيتي") || false);
  const [watchInsideColorChecked_cyan, setWatchInsideColorChecked_cyan] =
    useState(watchInsideColorFilter?.includes("سماوي") || false);
  const [watchInsideColorChecked_navy, setWatchInsideColorChecked_navy] =
    useState(watchInsideColorFilter?.includes("كحلي") || false);
  const [watchInsideColorChecked_beige, setWatchInsideColorChecked_beige] =
    useState(watchInsideColorFilter?.includes("بيج") || false);
  const [watchInsideColorChecked_other, setWatchInsideColorChecked_other] =
    useState(watchInsideColorFilter?.includes("أخرى") || false);

  const [watchInsideColorFilters, setWatchInsideColorFilters] = useState([
    "الكل",
  ]);

  // State variables for watch dial shape filters
  const [watchDialShapeChecked_all, setWatchDialShapeChecked_all] = useState(
    !watchDialShapeFilter
  );
  const [watchDialShapeChecked_round, setWatchDialShapeChecked_round] =
    useState(watchDialShapeFilter?.includes("دائري") || false);
  const [watchDialShapeChecked_square, setWatchDialShapeChecked_square] =
    useState(watchDialShapeFilter?.includes("مربع") || false);
  const [watchDialShapeChecked_rectangle, setWatchDialShapeChecked_rectangle] =
    useState(watchDialShapeFilter?.includes("مستطيل") || false);
  const [watchDialShapeChecked_oval, setWatchDialShapeChecked_oval] = useState(
    watchDialShapeFilter?.includes("بيضاوي") || false
  );
  const [watchDialShapeChecked_pentagon, setWatchDialShapeChecked_pentagon] =
    useState(watchDialShapeFilter?.includes("خماسي") || false);
  const [watchDialShapeChecked_hexagon, setWatchDialShapeChecked_hexagon] =
    useState(watchDialShapeFilter?.includes("سداسي") || false);

  const [watchDialShapeFilters, setWatchDialShapeFilters] = useState(["الكل"]);

  // Constants for watch handles color filters
  const [watchHandlesColorChecked_all, setWatchHandlesColorChecked_all] =
    useState(!watchHandlesColorFilter);
  const [
    watchHandlesColorChecked_stainlessSteel,
    setWatchHandlesColorChecked_stainlessSteel,
  ] = useState(watchHandlesColorFilter?.includes("ستانلس ستيل") || false);
  const [watchHandlesColorChecked_black, setWatchHandlesColorChecked_black] =
    useState(watchHandlesColorFilter?.includes("أسود") || false);
  const [watchHandlesColorChecked_white, setWatchHandlesColorChecked_white] =
    useState(watchHandlesColorFilter?.includes("أبيض") || false);
  const [watchHandlesColorChecked_red, setWatchHandlesColorChecked_red] =
    useState(watchHandlesColorFilter?.includes("أحمر") || false);
  const [watchHandlesColorChecked_blue, setWatchHandlesColorChecked_blue] =
    useState(watchHandlesColorFilter?.includes("أزرق") || false);
  const [watchHandlesColorChecked_green, setWatchHandlesColorChecked_green] =
    useState(watchHandlesColorFilter?.includes("أخضر") || false);
  const [watchHandlesColorChecked_yellow, setWatchHandlesColorChecked_yellow] =
    useState(watchHandlesColorFilter?.includes("أصفر") || false);
  const [watchHandlesColorChecked_orange, setWatchHandlesColorChecked_orange] =
    useState(watchHandlesColorFilter?.includes("برتقالي") || false);
  const [watchHandlesColorChecked_purple, setWatchHandlesColorChecked_purple] =
    useState(watchHandlesColorFilter?.includes("بنفسجي") || false);
  const [watchHandlesColorChecked_pink, setWatchHandlesColorChecked_pink] =
    useState(watchHandlesColorFilter?.includes("وردي") || false);
  const [watchHandlesColorChecked_brown, setWatchHandlesColorChecked_brown] =
    useState(watchHandlesColorFilter?.includes("بني") || false);
  const [watchHandlesColorChecked_gray, setWatchHandlesColorChecked_gray] =
    useState(watchHandlesColorFilter?.includes("رمادي") || false);
  const [watchHandlesColorChecked_olive, setWatchHandlesColorChecked_olive] =
    useState(watchHandlesColorFilter?.includes("زيتي") || false);
  const [watchHandlesColorChecked_cyan, setWatchHandlesColorChecked_cyan] =
    useState(watchHandlesColorFilter?.includes("سماوي") || false);
  const [watchHandlesColorChecked_navy, setWatchHandlesColorChecked_navy] =
    useState(watchHandlesColorFilter?.includes("كحلي") || false);
  const [watchHandlesColorChecked_beige, setWatchHandlesColorChecked_beige] =
    useState(watchHandlesColorFilter?.includes("بيج") || false);
  const [watchHandlesColorChecked_other, setWatchHandlesColorChecked_other] =
    useState(watchHandlesColorFilter?.includes("آخر") || false);

  const [watchHandlesColorFilters, setWatchHandlesColorFilters] = useState([
    "الكل",
  ]);

  const [watchNumbersColorChecked_all, setWatchNumbersColorChecked_all] =
    useState(!watchNumbersColorFilter);
  const [
    watchNumbersColorChecked_stainlessSteel,
    setWatchNumbersColorChecked_stainlessSteel,
  ] = useState(watchNumbersColorFilter?.includes("ستانلس ستيل") || false);
  const [watchNumbersColorChecked_black, setWatchNumbersColorChecked_black] =
    useState(watchNumbersColorFilter?.includes("أسود") || false);
  const [watchNumbersColorChecked_white, setWatchNumbersColorChecked_white] =
    useState(watchNumbersColorFilter?.includes("أبيض") || false);
  const [watchNumbersColorChecked_red, setWatchNumbersColorChecked_red] =
    useState(watchNumbersColorFilter?.includes("أحمر") || false);
  const [watchNumbersColorChecked_blue, setWatchNumbersColorChecked_blue] =
    useState(watchNumbersColorFilter?.includes("أزرق") || false);
  const [watchNumbersColorChecked_green, setWatchNumbersColorChecked_green] =
    useState(watchNumbersColorFilter?.includes("أخضر") || false);
  const [watchNumbersColorChecked_yellow, setWatchNumbersColorChecked_yellow] =
    useState(watchNumbersColorFilter?.includes("أصفر") || false);
  const [watchNumbersColorChecked_orange, setWatchNumbersColorChecked_orange] =
    useState(watchNumbersColorFilter?.includes("برتقالي") || false);
  const [watchNumbersColorChecked_purple, setWatchNumbersColorChecked_purple] =
    useState(watchNumbersColorFilter?.includes("بنفسجي") || false);
  const [watchNumbersColorChecked_pink, setWatchNumbersColorChecked_pink] =
    useState(watchNumbersColorFilter?.includes("وردي") || false);
  const [watchNumbersColorChecked_brown, setWatchNumbersColorChecked_brown] =
    useState(watchNumbersColorFilter?.includes("بني") || false);
  const [watchNumbersColorChecked_gray, setWatchNumbersColorChecked_gray] =
    useState(watchNumbersColorFilter?.includes("رمادي") || false);
  const [watchNumbersColorChecked_olive, setWatchNumbersColorChecked_olive] =
    useState(watchNumbersColorFilter?.includes("زيتي") || false);
  const [watchNumbersColorChecked_cyan, setWatchNumbersColorChecked_cyan] =
    useState(watchNumbersColorFilter?.includes("سماوي") || false);
  const [watchNumbersColorChecked_navy, setWatchNumbersColorChecked_navy] =
    useState(watchNumbersColorFilter?.includes("كحلي") || false);
  const [watchNumbersColorChecked_beige, setWatchNumbersColorChecked_beige] =
    useState(watchNumbersColorFilter?.includes("بيج") || false);
  const [watchNumbersColorChecked_other, setWatchNumbersColorChecked_other] =
    useState(watchNumbersColorFilter?.includes("آخر") || false);

  const [watchNumbersColorFilters, setWatchNumbersColorFilters] = useState([
    "الكل",
  ]);

  const [watchNumbersLanguageChecked_all, setWatchNumbersLanguageChecked_all] =
    useState(!watchNumbersLanguageFilter);
  const [
    watchNumbersLanguageChecked_arabic,
    setWatchNumbersLanguageChecked_arabic,
  ] = useState(watchNumbersLanguageFilter?.includes("العربية"));
  const [
    watchNumbersLanguageChecked_english,
    setWatchNumbersLanguageChecked_english,
  ] = useState(watchNumbersLanguageFilter?.includes("الإنجليزية"));
  const [
    watchNumbersLanguageChecked_roman,
    setWatchNumbersLanguageChecked_roman,
  ] = useState(watchNumbersLanguageFilter?.includes("الرومانية"));
  const [
    watchNumbersLanguageChecked_none,
    setWatchNumbersLanguageChecked_none,
  ] = useState(watchNumbersLanguageFilter?.includes("بدون أرقام"));

  const [watchNumbersLanguageFilters, setWatchNumbersLanguageFilters] =
    useState(["الكل"]);

  const [watchBandMaterialChecked_all, setWatchBandMaterialChecked_all] =
    useState(!watchBandMaterialFilter);
  const [
    watchBandMaterialChecked_stainlessSteel,
    setWatchBandMaterialChecked_stainlessSteel,
  ] = useState(watchBandMaterialFilter?.includes("ستانلس ستيل"));
  const [
    watchBandMaterialChecked_leather,
    setWatchBandMaterialChecked_leather,
  ] = useState(watchBandMaterialFilter?.includes("جلد"));
  const [watchBandMaterialChecked_rubber, setWatchBandMaterialChecked_rubber] =
    useState(watchBandMaterialFilter?.includes("ربل"));

  const [watchBandMaterialFilters, setWatchBandMaterialFilters] = useState([
    "الكل",
  ]); // Assuming "الكل" represents "all"

  const [watchBandColorChecked_all, setWatchBandColorChecked_all] = useState(
    !watchBandColorFilter
  );
  const [
    watchBandColorChecked_stainlessSteel,
    setWatchBandColorChecked_stainlessSteel,
  ] = useState(watchBandColorFilter?.includes("ستانلس ستيل") || false);
  const [watchBandColorChecked_black, setWatchBandColorChecked_black] =
    useState(watchBandColorFilter?.includes("أسود") || false);
  const [watchBandColorChecked_white, setWatchBandColorChecked_white] =
    useState(watchBandColorFilter?.includes("أبيض") || false);
  const [watchBandColorChecked_red, setWatchBandColorChecked_red] = useState(
    watchBandColorFilter?.includes("أحمر") || false
  );
  const [watchBandColorChecked_blue, setWatchBandColorChecked_blue] = useState(
    watchBandColorFilter?.includes("أزرق") || false
  );
  const [watchBandColorChecked_green, setWatchBandColorChecked_green] =
    useState(watchBandColorFilter?.includes("أخضر") || false);
  const [watchBandColorChecked_yellow, setWatchBandColorChecked_yellow] =
    useState(watchBandColorFilter?.includes("أصفر") || false);
  const [watchBandColorChecked_orange, setWatchBandColorChecked_orange] =
    useState(watchBandColorFilter?.includes("برتقالي") || false);
  const [watchBandColorChecked_purple, setWatchBandColorChecked_purple] =
    useState(watchBandColorFilter?.includes("بنفسجي") || false);
  const [watchBandColorChecked_pink, setWatchBandColorChecked_pink] = useState(
    watchBandColorFilter?.includes("وردي") || false
  );
  const [watchBandColorChecked_brown, setWatchBandColorChecked_brown] =
    useState(watchBandColorFilter?.includes("بني") || false);
  const [watchBandColorChecked_gray, setWatchBandColorChecked_gray] = useState(
    watchBandColorFilter?.includes("رمادي") || false
  );
  const [watchBandColorChecked_olive, setWatchBandColorChecked_olive] =
    useState(watchBandColorFilter?.includes("زيتي") || false);
  const [watchBandColorChecked_cyan, setWatchBandColorChecked_cyan] = useState(
    watchBandColorFilter?.includes("سماوي") || false
  );
  const [watchBandColorChecked_navy, setWatchBandColorChecked_navy] = useState(
    watchBandColorFilter?.includes("كحلي") || false
  );
  const [watchBandColorChecked_beige, setWatchBandColorChecked_beige] =
    useState(watchBandColorFilter?.includes("بيج") || false);
  const [watchBandColorChecked_other, setWatchBandColorChecked_other] =
    useState(watchBandColorFilter?.includes("آخر") || false);

  const [watchBandColorFilters, setWatchBandColorFilters] = useState(["الكل"]);

  const [
    watchHasOriginalPackagingChecked_all,
    setWatchHasOriginalPackagingChecked_all,
  ] = useState(!watchHasOriginalPackagingFilter);
  const [
    watchHasOriginalPackagingChecked_yes,
    setWatchHasOriginalPackagingChecked_yes,
  ] = useState(watchHasOriginalPackagingFilter?.includes(true) || false);
  const [
    watchHasOriginalPackagingChecked_no,
    setWatchHasOriginalPackagingChecked_no,
  ] = useState(watchHasOriginalPackagingFilter?.includes(false) || false);

  const [
    watchHasOriginalPackagingFilters,
    setWatchHasOriginalPackagingFilters,
  ] = useState(["الكل"]);

  const [
    watchIsWaterResistantChecked_all,
    setWatchIsWaterResistantChecked_all,
  ] = useState(!watchIsWaterResistantFilter);
  const [
    watchIsWaterResistantChecked_yes,
    setWatchIsWaterResistantChecked_yes,
  ] = useState(watchIsWaterResistantFilter?.includes(true) || false);
  const [watchIsWaterResistantChecked_no, setWatchIsWaterResistantChecked_no] =
    useState(watchIsWaterResistantFilter?.includes(false) || false);

  const [watchIsWaterResistantFilters, setWatchIsWaterResistantFilters] =
    useState(["الكل"]);

  const [rosaryKindChecked_all, setRosaryKindChecked_all] = useState(
    !rosaryKindFilter
  );
  const [rosaryKindChecked_backlight, setRosaryKindChecked_backlight] =
    useState(rosaryKindFilter?.includes("بكلايت") || false);
  const [rosaryKindChecked_kahraman, setRosaryKindChecked_kahraman] = useState(
    rosaryKindFilter?.includes("كهرمان") || false
  );
  const [rosaryKindChecked_fatoran, setRosaryKindChecked_fatoran] = useState(
    rosaryKindFilter?.includes("فاتوران") || false
  );
  const [rosaryKindChecked_ivory, setRosaryKindChecked_ivory] = useState(
    rosaryKindFilter?.includes("عاج") || false
  );
  const [rosaryKindChecked_wood, setRosaryKindChecked_wood] = useState(
    rosaryKindFilter?.includes("خشب") || false
  );
  const [rosaryKindChecked_stone, setRosaryKindChecked_stone] = useState(
    rosaryKindFilter?.includes("حجر") || false
  );

  const [rosaryKindFilters, setRosaryKindFilters] = useState(["الكل"]);

  const [rosaryColorChecked_all, setRosaryColorChecked_all] = useState(
    !rosaryColorFilter
  );
  const [rosaryColorChecked_black, setRosaryColorChecked_black] = useState(
    rosaryColorFilter?.includes("أسود") || false
  );
  const [rosaryColorChecked_white, setRosaryColorChecked_white] = useState(
    rosaryColorFilter?.includes("أبيض") || false
  );
  const [rosaryColorChecked_red, setRosaryColorChecked_red] = useState(
    rosaryColorFilter?.includes("أحمر") || false
  );
  const [rosaryColorChecked_blue, setRosaryColorChecked_blue] = useState(
    rosaryColorFilter?.includes("أزرق") || false
  );
  const [rosaryColorChecked_green, setRosaryColorChecked_green] = useState(
    rosaryColorFilter?.includes("أخضر") || false
  );
  const [rosaryColorChecked_yellow, setRosaryColorChecked_yellow] = useState(
    rosaryColorFilter?.includes("أصفر") || false
  );
  const [rosaryColorChecked_orange, setRosaryColorChecked_orange] = useState(
    rosaryColorFilter?.includes("برتقالي") || false
  );
  const [rosaryColorChecked_purple, setRosaryColorChecked_purple] = useState(
    rosaryColorFilter?.includes("بنفسجي") || false
  );
  const [rosaryColorChecked_pink, setRosaryColorChecked_pink] = useState(
    rosaryColorFilter?.includes("وردي") || false
  );
  const [rosaryColorChecked_brown, setRosaryColorChecked_brown] = useState(
    rosaryColorFilter?.includes("بني") || false
  );
  const [rosaryColorChecked_gray, setRosaryColorChecked_gray] = useState(
    rosaryColorFilter?.includes("رمادي") || false
  );
  const [rosaryColorChecked_olive, setRosaryColorChecked_olive] = useState(
    rosaryColorFilter?.includes("زيتي") || false
  );
  const [rosaryColorChecked_cyan, setRosaryColorChecked_cyan] = useState(
    rosaryColorFilter?.includes("سماوي") || false
  );
  const [rosaryColorChecked_navy, setRosaryColorChecked_navy] = useState(
    rosaryColorFilter?.includes("كحلي") || false
  );
  const [rosaryColorChecked_beige, setRosaryColorChecked_beige] = useState(
    rosaryColorFilter?.includes("بيج") || false
  );
  const [rosaryColorChecked_other, setRosaryColorChecked_other] = useState(
    rosaryColorFilter?.includes("آخر") || false
  );

  const [rosaryColorFilters, setRosaryColorFilters] = useState(["الكل"]);

  const [walletDepartmentChecked_all, setWalletDepartmentChecked_all] =
    useState(!walletDepartmentFilter);
  const [walletDepartmentChecked_men, setWalletDepartmentChecked_men] =
    useState(walletDepartmentFilter?.includes("رجالي") || false);
  const [walletDepartmentChecked_women, setWalletDepartmentChecked_women] =
    useState(walletDepartmentFilter?.includes("نسائي") || false);
  const [walletDepartmentChecked_unisex, setWalletDepartmentChecked_unisex] =
    useState(walletDepartmentFilter?.includes("للجنسين") || false);

  const [walletDepartmentFilters, setWalletDepartmentFilters] = useState([
    "الكل",
  ]);

  const [walletOutsideColorChecked_all, setWalletOutsideColorChecked_all] =
    useState(!walletOutsideColorFilter);
  const [walletOutsideColorChecked_black, setWalletOutsideColorChecked_black] =
    useState(walletOutsideColorFilter?.includes("أسود") || false);
  const [walletOutsideColorChecked_white, setWalletOutsideColorChecked_white] =
    useState(walletOutsideColorFilter?.includes("أبيض") || false);
  const [walletOutsideColorChecked_red, setWalletOutsideColorChecked_red] =
    useState(walletOutsideColorFilter?.includes("أحمر") || false);
  const [walletOutsideColorChecked_blue, setWalletOutsideColorChecked_blue] =
    useState(walletOutsideColorFilter?.includes("أزرق") || false);
  const [walletOutsideColorChecked_green, setWalletOutsideColorChecked_green] =
    useState(walletOutsideColorFilter?.includes("أخضر") || false);
  const [
    walletOutsideColorChecked_yellow,
    setWalletOutsideColorChecked_yellow,
  ] = useState(walletOutsideColorFilter?.includes("أصفر") || false);
  const [
    walletOutsideColorChecked_orange,
    setWalletOutsideColorChecked_orange,
  ] = useState(walletOutsideColorFilter?.includes("برتقالي") || false);
  const [
    walletOutsideColorChecked_purple,
    setWalletOutsideColorChecked_purple,
  ] = useState(walletOutsideColorFilter?.includes("بنفسجي") || false);
  const [walletOutsideColorChecked_pink, setWalletOutsideColorChecked_pink] =
    useState(walletOutsideColorFilter?.includes("وردي") || false);
  const [walletOutsideColorChecked_brown, setWalletOutsideColorChecked_brown] =
    useState(walletOutsideColorFilter?.includes("بني") || false);
  const [walletOutsideColorChecked_gray, setWalletOutsideColorChecked_gray] =
    useState(walletOutsideColorFilter?.includes("رمادي") || false);
  const [walletOutsideColorChecked_olive, setWalletOutsideColorChecked_olive] =
    useState(walletOutsideColorFilter?.includes("زيتي") || false);
  const [walletOutsideColorChecked_cyan, setWalletOutsideColorChecked_cyan] =
    useState(walletOutsideColorFilter?.includes("سماوي") || false);
  const [walletOutsideColorChecked_navy, setWalletOutsideColorChecked_navy] =
    useState(walletOutsideColorFilter?.includes("كحلي") || false);
  const [walletOutsideColorChecked_beige, setWalletOutsideColorChecked_beige] =
    useState(walletOutsideColorFilter?.includes("بيج") || false);
  const [walletOutsideColorChecked_other, setWalletOutsideColorChecked_other] =
    useState(walletOutsideColorFilter?.includes("أخرى") || false);

  const [walletOutsideColorFilters, setWalletOutsideColorFilters] = useState([
    "الكل",
  ]);

  const [purseMaterialChecked_all, setPurseMaterialChecked_all] = useState(
    !purseMaterialFilter
  );
  const [purseMaterialChecked_leather, setPurseMaterialChecked_leather] =
    useState(purseMaterialFilter?.includes("جلد") || false);
  const [purseMaterialChecked_fabric, setPurseMaterialChecked_fabric] =
    useState(purseMaterialFilter?.includes("مخمل") || false);

  const [purseMaterialFilters, setPurseMaterialFilters] = useState(["الكل"]);

  // Outside color states
  const [purseOutsideColorChecked_all, setPurseOutsideColorChecked_all] =
    useState(!purseOutsideColorFilter);
  const [purseOutsideColorChecked_black, setPurseOutsideColorChecked_black] =
    useState(purseOutsideColorFilter?.includes("أسود") || false);
  const [purseOutsideColorChecked_white, setPurseOutsideColorChecked_white] =
    useState(purseOutsideColorFilter?.includes("أبيض") || false);
  const [purseOutsideColorChecked_red, setPurseOutsideColorChecked_red] =
    useState(purseOutsideColorFilter?.includes("أحمر") || false);
  const [purseOutsideColorChecked_blue, setPurseOutsideColorChecked_blue] =
    useState(purseOutsideColorFilter?.includes("أزرق") || false);
  const [purseOutsideColorChecked_green, setPurseOutsideColorChecked_green] =
    useState(purseOutsideColorFilter?.includes("أخضر") || false);
  const [purseOutsideColorChecked_yellow, setPurseOutsideColorChecked_yellow] =
    useState(purseOutsideColorFilter?.includes("أصفر") || false);
  const [purseOutsideColorChecked_orange, setPurseOutsideColorChecked_orange] =
    useState(purseOutsideColorFilter?.includes("برتقالي") || false);
  const [purseOutsideColorChecked_purple, setPurseOutsideColorChecked_purple] =
    useState(purseOutsideColorFilter?.includes("بنفسجي") || false);
  const [purseOutsideColorChecked_pink, setPurseOutsideColorChecked_pink] =
    useState(purseOutsideColorFilter?.includes("وردي") || false);
  const [purseOutsideColorChecked_brown, setPurseOutsideColorChecked_brown] =
    useState(purseOutsideColorFilter?.includes("بني") || false);
  const [purseOutsideColorChecked_gray, setPurseOutsideColorChecked_gray] =
    useState(purseOutsideColorFilter?.includes("رمادي") || false);
  const [purseOutsideColorChecked_olive, setPurseOutsideColorChecked_olive] =
    useState(purseOutsideColorFilter?.includes("زيتي") || false);
  const [purseOutsideColorChecked_cyan, setPurseOutsideColorChecked_cyan] =
    useState(purseOutsideColorFilter?.includes("سماوي") || false);
  const [purseOutsideColorChecked_navy, setPurseOutsideColorChecked_navy] =
    useState(purseOutsideColorFilter?.includes("كحلي") || false);
  const [purseOutsideColorChecked_beige, setPurseOutsideColorChecked_beige] =
    useState(purseOutsideColorFilter?.includes("بيج") || false);
  const [purseOutsideColorChecked_other, setPurseOutsideColorChecked_other] =
    useState(purseOutsideColorFilter?.includes("أخرى") || false);

  const [purseOutsideColorFilters, setPurseOutsideColorFilters] = useState([
    "الكل",
  ]);

  // Inside color states
  const [purseInsideColorChecked_all, setPurseInsideColorChecked_all] =
    useState(!purseInsideColorFilter);
  const [purseInsideColorChecked_black, setPurseInsideColorChecked_black] =
    useState(purseInsideColorFilter?.includes("أسود") || false);
  const [purseInsideColorChecked_white, setPurseInsideColorChecked_white] =
    useState(purseInsideColorFilter?.includes("أبيض") || false);
  const [purseInsideColorChecked_red, setPurseInsideColorChecked_red] =
    useState(purseInsideColorFilter?.includes("أحمر") || false);
  const [purseInsideColorChecked_blue, setPurseInsideColorChecked_blue] =
    useState(purseInsideColorFilter?.includes("أزرق") || false);
  const [purseInsideColorChecked_green, setPurseInsideColorChecked_green] =
    useState(purseInsideColorFilter?.includes("أخضر") || false);
  const [purseInsideColorChecked_yellow, setPurseInsideColorChecked_yellow] =
    useState(purseInsideColorFilter?.includes("أصفر") || false);
  const [purseInsideColorChecked_orange, setPurseInsideColorChecked_orange] =
    useState(purseInsideColorFilter?.includes("برتقالي") || false);
  const [purseInsideColorChecked_purple, setPurseInsideColorChecked_purple] =
    useState(purseInsideColorFilter?.includes("بنفسجي") || false);
  const [purseInsideColorChecked_pink, setPurseInsideColorChecked_pink] =
    useState(purseInsideColorFilter?.includes("وردي") || false);
  const [purseInsideColorChecked_brown, setPurseInsideColorChecked_brown] =
    useState(purseInsideColorFilter?.includes("بني") || false);
  const [purseInsideColorChecked_gray, setPurseInsideColorChecked_gray] =
    useState(purseInsideColorFilter?.includes("رمادي") || false);
  const [purseInsideColorChecked_olive, setPurseInsideColorChecked_olive] =
    useState(purseInsideColorFilter?.includes("زيتي") || false);
  const [purseInsideColorChecked_cyan, setPurseInsideColorChecked_cyan] =
    useState(purseInsideColorFilter?.includes("سماوي") || false);
  const [purseInsideColorChecked_navy, setPurseInsideColorChecked_navy] =
    useState(purseInsideColorFilter?.includes("كحلي") || false);
  const [purseInsideColorChecked_beige, setPurseInsideColorChecked_beige] =
    useState(purseInsideColorFilter?.includes("بيج") || false);
  const [purseInsideColorChecked_other, setPurseInsideColorChecked_other] =
    useState(purseInsideColorFilter?.includes("أخرى") || false);

  const [purseInsideColorFilters, setPurseInsideColorFilters] = useState([
    "الكل",
  ]);

  const [carPlateKindChecked_all, setCarPlateKindChecked_all] = useState(
    !carPlateKindFilter
  );
  const [carPlateKindChecked_privateCar, setCarPlateKindChecked_privateCar] =
    useState(carPlateKindFilter?.includes("لوحة سيارة خاصة") || false);
  const [carPlateKindChecked_motorCycle, setCarPlateKindChecked_motorCycle] =
    useState(carPlateKindFilter?.includes("لوحة دراجة نارية") || false);
  const [
    carPlateKindChecked_transportation,
    setCarPlateKindChecked_transportation,
  ] = useState(carPlateKindFilter?.includes("لوحة سيارة نقل عام") || false);
  const [carPlateKindChecked_commerce, setCarPlateKindChecked_commerce] =
    useState(carPlateKindFilter?.includes("لوحة سيارة تجارية") || false);

  const [carPlateKindFilters, setCarPlateKindFilters] = useState(["الكل"]);

  // Initialize states based on the carPlateTransferFeeOnFilter
  const [
    carPlateTransferFeeOnChecked_all,
    setCarPlateTransferFeeOnChecked_all,
  ] = useState(!carPlateTransferFeeOnFilter);
  const [
    carPlateTransferFeeOnChecked_seller,
    setCarPlateTransferFeeOnChecked_seller,
  ] = useState(carPlateTransferFeeOnFilter?.includes("البائع") || false);
  const [
    carPlateTransferFeeOnChecked_buyer,
    setCarPlateTransferFeeOnChecked_buyer,
  ] = useState(carPlateTransferFeeOnFilter?.includes("المشتري") || false);

  const [carPlateTransferFeeOnFilters, setCarPlateTransferFeeOnFilters] =
    useState(["الكل"]);

  // Initialize states based on the carPlateTransferTimeFilter
  const [carPlateTransferTimeChecked_all, setCarPlateTransferTimeChecked_all] =
    useState(!carPlateTransferTimeFilter);
  const [
    carPlateTransferTimeChecked_24to48Hours,
    setCarPlateTransferTimeChecked_24to48Hours,
  ] = useState(carPlateTransferTimeFilter?.includes("24 إلى 48 ساعة") || false);
  const [
    carPlateTransferTimeChecked_twoToFiveDays,
    setCarPlateTransferTimeChecked_twoToFiveDays,
  ] = useState(
    carPlateTransferTimeFilter?.includes("يومين إلى 5 أيام") || false
  );

  const [carPlateTransferTimeFilters, setCarPlateTransferTimeFilters] =
    useState(["الكل"]);

  // Initialize states based on the phoneNumberCourierFilter
  const [phoneNumberCourierChecked_all, setPhoneNumberCourierChecked_all] =
    useState(!phoneNumberCourierFilter);
  const [phoneNumberCourierChecked_stc, setPhoneNumberCourierChecked_stc] =
    useState(phoneNumberCourierFilter?.includes("STC") || false);
  const [
    phoneNumberCourierChecked_mobily,
    setPhoneNumberCourierChecked_mobily,
  ] = useState(phoneNumberCourierFilter?.includes("موبايلي") || false);
  const [phoneNumberCourierChecked_zain, setPhoneNumberCourierChecked_zain] =
    useState(phoneNumberCourierFilter?.includes("زين") || false);

  const [phoneNumberCourierFilters, setPhoneNumberCourierFilters] = useState([
    "الكل",
  ]);

  const [shippingMethodChecked_all, setShippingMethodChecked_all] = useState(
    !shippingMethodsFilter
  );
  const [shippingMethodChecked_shipping, setShippingMethodChecked_shipping] =
    useState(shippingMethodsFilter?.includes("شحن") || false);
  const [shippingMethodChecked_delivery, setShippingMethodChecked_delivery] =
    useState(shippingMethodsFilter?.includes("تسليم") || false);

  const [shippingMethodFilters, setShippingMethodFilters] = useState(
    shippingMethodsFilter ? shippingMethodsFilter.split(",") : ["الكل"]
  );

  const { mostExpensiveItemPrice, leastExpensiveItemPrice } =
    useFetchPriceRange(categorySearch);

  // start here

  // logic for infinite scrolling
  const [lastDoc, setLastDoc] = useState(null); // To track last document fetched
  // const [hasMore, setHasMore] = useState(true); // To track if more data is available
  const batchSize = 10; // Number of documents to fetch per batch

  // url params to check if any product filter is applied
  const conditionFilterArray = conditionFilter
    ? conditionFilter.split(",")
    : [];
  const postTypeFilterArray = postTypeFilter ? postTypeFilter.split(",") : [];
  const companyFilterArray = companyFilter ? companyFilter.split(",") : [];
  const watchDepartmentFilterArray = watchDepartmentFilter
    ? watchDepartmentFilter.split(",")
    : [];
  const watchOutsideColorFilterArray = watchOutsideColorFilter
    ? watchOutsideColorFilter.split(",")
    : [];
  const watchInsideColorFilterArray = watchInsideColorFilter
    ? watchInsideColorFilter.split(",")
    : [];
  const watchDialShapeFilterArray = watchDialShapeFilter
    ? watchDialShapeFilter.split(",")
    : [];
  const watchHandlesColorFilterArray = watchHandlesColorFilter
    ? watchHandlesColorFilter.split(",")
    : [];
  const watchNumbersColorFilterArray = watchNumbersColorFilter
    ? watchNumbersColorFilter.split(",")
    : [];
  const watchNumbersLanguageFilterArray = watchNumbersLanguageFilter
    ? watchNumbersLanguageFilter.split(",")
    : [];
  const watchBandMaterialFilterArray = watchBandMaterialFilter
    ? watchBandMaterialFilter.split(",")
    : [];
  const watchBandColorFilterArray = watchBandColorFilter
    ? watchBandColorFilter.split(",")
    : [];
  const watchHasOriginalPackagingFilterArray = watchHasOriginalPackagingFilter
    ? watchHasOriginalPackagingFilter.split(",")
    : [];
  const watchIsWaterResistantFilterArray = watchIsWaterResistantFilter
    ? watchIsWaterResistantFilter.split(",")
    : [];
  const rosaryKindFilterArray = rosaryKindFilter
    ? rosaryKindFilter.split(",")
    : [];
  const rosaryColorFilterArray = rosaryColorFilter
    ? rosaryColorFilter.split(",")
    : [];
  const walletDepartmentFilterArray = walletDepartmentFilter
    ? walletDepartmentFilter.split(",")
    : [];
  const walletOutsideColorFilterArray = walletOutsideColorFilter
    ? walletOutsideColorFilter.split(",")
    : [];
  const purseMaterialFilterArray = purseMaterialFilter
    ? purseMaterialFilter.split(",")
    : [];
  const purseOutsideColorFilterArray = purseOutsideColorFilter
    ? purseOutsideColorFilter.split(",")
    : [];
  const purseInsideColorFilterArray = purseInsideColorFilter
    ? purseInsideColorFilter.split(",")
    : [];
  const carPlateKindFilterArray = carPlateKindFilter
    ? carPlateKindFilter.split(",")
    : [];
  const carPlateTransferFeeOnFilterArray = carPlateTransferFeeOnFilter
    ? carPlateTransferFeeOnFilter.split(",")
    : [];
  const carPlateTransferTimeFilterArray = carPlateTransferTimeFilter
    ? carPlateTransferTimeFilter.split(",")
    : [];
  const phoneNumberCourierFilterArray = phoneNumberCourierFilter
    ? phoneNumberCourierFilter.split(",")
    : [];
  const shippingMethodsFilterArray = shippingMethodsFilter
    ? shippingMethodsFilter.split(",")
    : [];

  // filter by search term, if any
  const searchTermArray = res ? res.split(" ") : [];

  // infinite scrolling logic

  // half working use effect

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setIsLoading(true); // Ensure loading state is set initially

  //     const baseConditions = [
  //       where("isDeleted", "==", false),
  //       where("isSold", "==", false)
  //     ];

  //     const priceConditions = [];
  //     const entryFeeConditions = [where('postType', 'array-contains', 'مزاد')];
  //     const startingPriceConditions = [where('postType', 'array-contains', 'مزاد')];
  //     const bidIncrementConditions = [where('postType', 'array-contains', 'مزاد')];
  //     const dialSizeConditions = [];
  //     const yearMadeConditions = [];

  //     if (parseFloat(lowestPriceFilter) > 0) {
  //       priceConditions.push(where('productPrice', '>=', parseFloat(lowestPriceFilter)));
  //     }
  //     if (parseFloat(heighestPriceFilter) > 0) {
  //       priceConditions.push(where('productPrice', '<=', parseFloat(heighestPriceFilter)));
  //     }
  //     if (parseFloat(lowestPriceFilter) > 0 || parseFloat(heighestPriceFilter) > 0) {
  //       priceConditions.push(where('productPrice', '!=', ""));
  //     }

  //     if (parseFloat(lowestEntryFeeFilter) > 0) {
  //       entryFeeConditions.push(where('entryFee', '>=', parseFloat(lowestEntryFeeFilter)));
  //     }
  //     if (parseFloat(heighestEntryFeeFilter) > 0) {
  //       entryFeeConditions.push(where('entryFee', '<=', parseFloat(heighestEntryFeeFilter)));
  //     }

  //     if (parseFloat(lowestStartingPriceFilter) > 0) {
  //       startingPriceConditions.push(where('startingPrice', '>=', parseFloat(lowestStartingPriceFilter)));
  //     }
  //     if (parseFloat(heighestStartingPriceFilter) > 0) {
  //       startingPriceConditions.push(where('startingPrice', '<=', parseFloat(heighestStartingPriceFilter)));
  //     }

  //     if (parseFloat(lowestBidIncrementFilter) > 0) {
  //       bidIncrementConditions.push(where('bidIncrements', '>=', parseFloat(lowestBidIncrementFilter)));
  //     }
  //     if (parseFloat(heighestBidIncrementFilter) > 0) {
  //       bidIncrementConditions.push(where('bidIncrements', '<=', parseFloat(heighestBidIncrementFilter)));
  //     }

  //     if (parseFloat(smallestDialSizeFilter) > 0) {
  //       dialSizeConditions.push(where('watchDialSize', '>=', parseFloat(smallestDialSizeFilter)));
  //     }
  //     if (parseFloat(biggestDialSizeFilter) > 0) {
  //       dialSizeConditions.push(where('watchDialSize', '<=', parseFloat(biggestDialSizeFilter)));
  //     }

  //     if (parseFloat(watchOldestYearMadeFilter) > 0) {
  //       yearMadeConditions.push(where('watchYearMade', '>=', parseFloat(watchOldestYearMadeFilter)));
  //     }
  //     if (parseFloat(watchNewestYearMadeFilter) > 0) {
  //       yearMadeConditions.push(where('watchYearMade', '<=', parseFloat(watchNewestYearMadeFilter)));
  //     }

  //     const conditionConditions = conditionFilterArray.length > 0 ? [where('condition', 'in', conditionFilterArray)] : [];
  //     const postTypeConditions = postTypeFilterArray.length > 0 ? [where('postType', 'array-contains-any', postTypeFilterArray)] : [];
  //     const categoryConditions = categorySearch && categorySearch.length > 0 ? [where('category', '==', categorySearch)] : [];
  //     const shippingMethodsConditions = shippingMethodsFilterArray.length > 0 ? [where('shippingMethods', 'array-contains-any', shippingMethodsFilterArray)] : [];
  //     const companyConditions = companyFilterArray.length > 0 ? [where('productCompany', 'in', companyFilterArray)] : [];
  //     const watchDepartmentConditions = watchDepartmentFilterArray.length > 0 ? [where('watchDepartment', 'in', watchDepartmentFilterArray)] : [];
  //     const watchOutsideColorConditions = watchOutsideColorFilterArray.length > 0 ? [where('watchOutsideColor', 'in', watchOutsideColorFilterArray)] : [];
  //     const watchInsideColorConditions = watchInsideColorFilterArray.length > 0 ? [where('watchInsideColor', 'in', watchInsideColorFilterArray)] : [];
  //     const watchDialShapeConditions = watchDialShapeFilterArray.length > 0 ? [where('watchDialShape', 'in', watchDialShapeFilterArray)] : [];
  //     const watchHandlesColorConditions = watchHandlesColorFilterArray.length > 0 ? [where('watchHandlesColor', 'in', watchHandlesColorFilterArray)] : [];
  //     const watchNumbersColorConditions = watchNumbersColorFilterArray.length > 0 ? [where('watchNumbersColor', 'in', watchNumbersColorFilterArray)] : [];
  //     const watchNumbersLanguageConditions = watchNumbersLanguageFilterArray.length > 0 ? [where('watchNumbersLanguage', 'in', watchNumbersLanguageFilterArray)] : [];
  //     const watchBandMaterialConditions = watchBandMaterialFilterArray.length > 0 ? [where('watchBandMaterial', 'in', watchBandMaterialFilterArray)] : [];
  //     const watchBandColorConditions = watchBandColorFilterArray.length > 0 ? [where('watchBandColor', 'in', watchBandColorFilterArray)] : [];
  //     const watchHasOriginalPackagingConditions = watchHasOriginalPackagingFilterArray.length > 0 ? [where('watchHasOriginalPackaging', 'in', watchHasOriginalPackagingFilterArray.map(value => value === 'true'))] : [];
  //     const watchIsWaterResistantConditions = watchIsWaterResistantFilterArray.length > 0 ? [where('watchIsWaterResistant', 'in', watchIsWaterResistantFilterArray.map(value => value === 'true'))] : [];
  //     const rosaryKindConditions = rosaryKindFilterArray.length > 0 ? [where('rosaryKind', 'in', rosaryKindFilterArray)] : [];
  //     const rosaryColorConditions = rosaryColorFilterArray.length > 0 ? [where('rosaryColor', 'in', rosaryColorFilterArray)] : [];
  //     const walletDepartmentConditions = walletDepartmentFilterArray.length > 0 ? [where('walletDepartment', 'in', walletDepartmentFilterArray)] : [];
  //     const walletOutsideColorConditions = walletOutsideColorFilterArray.length > 0 ? [where('walletOutsideColor', 'in', walletOutsideColorFilterArray)] : [];
  //     const purseMaterialConditions = purseMaterialFilterArray.length > 0 ? [where('purseMaterial', 'in', purseMaterialFilterArray)] : [];
  //     const purseOutsideColorConditions = purseOutsideColorFilterArray.length > 0 ? [where('purseOutsideColor', 'in', purseOutsideColorFilterArray)] : [];
  //     const purseInsideColorConditions = purseInsideColorFilterArray.length > 0 ? [where('purseInsideColor', 'in', purseInsideColorFilterArray)] : [];
  //     const carPlateKindConditions = carPlateKindFilterArray.length > 0 ? [where('carPlateKind', 'in', carPlateKindFilterArray)] : [];
  //     const carPlateTransferFeeOnConditions = carPlateTransferFeeOnFilterArray.length > 0 ? [where('carPlateTransferFeeOn', 'in', carPlateTransferFeeOnFilterArray)] : [];
  //     const carPlateTransferTimeConditions = carPlateTransferTimeFilterArray.length > 0 ? [where('carPlateTransferTime', 'in', carPlateTransferTimeFilterArray)] : [];
  //     const phoneNumberCourierConditions = phoneNumberCourierFilterArray.length > 0 ? [where('phoneNumberCourier', 'in', phoneNumberCourierFilterArray)] : [];

  //     let sortDirection;
  //     let sortBy;

  //     switch (urlSortOption) {
  //       case "من الأقدم إلى الأحدث":
  //         sortBy = "createdAt";
  //         sortDirection = "asc";
  //         break;
  //       case "من الأحدث إلى الأقدم":
  //         sortBy = "createdAt";
  //         sortDirection = "desc";
  //         break;
  //       case "سعر الشراء تنازليا":
  //         sortBy = "productPrice";
  //         sortDirection = "desc";
  //         break;
  //       case "سعر الشراء تصاعديا":
  //         sortBy = "productPrice";
  //         sortDirection = "asc";
  //         break;
  //       case "سعر المزايدة الحالي تنازليا":
  //         sortBy = "startingPrice";
  //         sortDirection = "desc";
  //         break;
  //       case "سعر المزايدة الحالي تصاعديا":
  //         sortBy = "startingPrice";
  //         sortDirection = "asc";
  //         break;
  //       case "عدد المزايدين تصاعديا":
  //         sortBy = "bidCount";
  //         sortDirection = "asc";
  //         break;
  //       case "عدد المزايدين تنازليا":
  //         sortBy = "bidCount";
  //         sortDirection = "desc";
  //         break;
  //       default:
  //         sortBy = "createdAt";
  //         sortDirection = "asc";
  //     }

  //     const queryConstraintsForPrice = [
  //       ...categoryConditions,  // Ensure category filter is applied

  //       ...baseConditions,
  //       ...priceConditions,
  //       ...conditionConditions,
  //       ...postTypeConditions,
  //       ...categoryConditions,
  //       ...shippingMethodsConditions,
  //       ...companyConditions,
  //       ...watchDepartmentConditions,
  //       ...watchOutsideColorConditions,
  //       ...watchInsideColorConditions,
  //       ...watchDialShapeConditions,
  //       ...watchHandlesColorConditions,
  //       ...watchNumbersColorConditions,
  //       ...watchNumbersLanguageConditions,
  //       ...watchBandMaterialConditions,
  //       ...watchBandColorConditions,
  //       ...watchHasOriginalPackagingConditions,
  //       ...watchIsWaterResistantConditions,
  //       ...dialSizeConditions,
  //       ...yearMadeConditions,
  //       ...rosaryKindConditions,
  //       ...rosaryColorConditions,
  //       ...walletDepartmentConditions,
  //       ...walletOutsideColorConditions,
  //       ...purseMaterialConditions,
  //       ...purseOutsideColorConditions,
  //       ...purseInsideColorConditions,
  //       ...carPlateKindConditions,
  //       ...carPlateTransferFeeOnConditions,
  //       ...carPlateTransferTimeConditions,
  //       ...phoneNumberCourierConditions,
  //       orderBy('productPrice'),
  //       orderBy(sortBy, sortDirection),
  //       limit(batchSize)
  //     ];

  //     const queryConstraintsForEntryFee = [
  //       ...categoryConditions,  // Ensure category filter is applied

  //       ...baseConditions,
  //       ...entryFeeConditions,
  //       ...conditionConditions,
  //       ...postTypeConditions,
  //       ...categoryConditions,
  //       ...shippingMethodsConditions,
  //       ...companyConditions,
  //       ...watchDepartmentConditions,
  //       ...watchOutsideColorConditions,
  //       ...watchInsideColorConditions,
  //       ...watchDialShapeConditions,
  //       ...watchHandlesColorConditions,
  //       ...watchNumbersColorConditions,
  //       ...watchNumbersLanguageConditions,
  //       ...watchBandMaterialConditions,
  //       ...watchBandColorConditions,
  //       ...watchHasOriginalPackagingConditions,
  //       ...watchIsWaterResistantConditions,
  //       ...dialSizeConditions,
  //       ...yearMadeConditions,
  //       ...rosaryKindConditions,
  //       ...rosaryColorConditions,
  //       ...walletDepartmentConditions,
  //       ...walletOutsideColorConditions,
  //       ...purseMaterialConditions,
  //       ...purseOutsideColorConditions,
  //       ...purseInsideColorConditions,
  //       ...carPlateKindConditions,
  //       ...carPlateTransferFeeOnConditions,
  //       ...carPlateTransferTimeConditions,
  //       ...phoneNumberCourierConditions,
  //       orderBy('entryFee'),
  //       orderBy(sortBy, sortDirection),
  //       limit(batchSize)
  //     ];

  //     const queryConstraintsForStartingPrice = [
  //       ...categoryConditions,  // Ensure category filter is applied

  //       ...baseConditions,
  //       ...startingPriceConditions,
  //       ...conditionConditions,
  //       ...postTypeConditions,
  //       ...categoryConditions,
  //       ...shippingMethodsConditions,
  //       ...companyConditions,
  //       ...watchDepartmentConditions,
  //       ...watchOutsideColorConditions,
  //       ...watchInsideColorConditions,
  //       ...watchDialShapeConditions,
  //       ...watchHandlesColorConditions,
  //       ...watchNumbersColorConditions,
  //       ...watchNumbersLanguageConditions,
  //       ...watchBandMaterialConditions,
  //       ...watchBandColorConditions,
  //       ...watchHasOriginalPackagingConditions,
  //       ...watchIsWaterResistantConditions,
  //       ...dialSizeConditions,
  //       ...yearMadeConditions,
  //       ...rosaryKindConditions,
  //       ...rosaryColorConditions,
  //       ...walletDepartmentConditions,
  //       ...walletOutsideColorConditions,
  //       ...purseMaterialConditions,
  //       ...purseOutsideColorConditions,
  //       ...purseInsideColorConditions,
  //       ...carPlateKindConditions,
  //       ...carPlateTransferFeeOnConditions,
  //       ...carPlateTransferTimeConditions,
  //       ...phoneNumberCourierConditions,
  //       orderBy('startingPrice'),
  //       orderBy(sortBy, sortDirection),
  //       limit(batchSize)
  //     ];

  //     const queryConstraintsForBidIncrement = [
  //       ...categoryConditions,  // Ensure category filter is applied

  //       ...baseConditions,
  //       ...bidIncrementConditions,
  //       ...conditionConditions,
  //       ...postTypeConditions,
  //       ...categoryConditions,
  //       ...shippingMethodsConditions,
  //       ...companyConditions,
  //       ...watchDepartmentConditions,
  //       ...watchOutsideColorConditions,
  //       ...watchInsideColorConditions,
  //       ...watchDialShapeConditions,
  //       ...watchHandlesColorConditions,
  //       ...watchNumbersColorConditions,
  //       ...watchNumbersLanguageConditions,
  //       ...watchBandMaterialConditions,
  //       ...watchBandColorConditions,
  //       ...watchHasOriginalPackagingConditions,
  //       ...watchIsWaterResistantConditions,
  //       ...dialSizeConditions,
  //       ...yearMadeConditions,
  //       ...rosaryKindConditions,
  //       ...rosaryColorConditions,
  //       ...walletDepartmentConditions,
  //       ...walletOutsideColorConditions,
  //       ...purseMaterialConditions,
  //       ...purseOutsideColorConditions,
  //       ...purseInsideColorConditions,
  //       ...carPlateKindConditions,
  //       ...carPlateTransferFeeOnConditions,
  //       ...carPlateTransferTimeConditions,
  //       ...phoneNumberCourierConditions,
  //       orderBy('bidIncrements'),
  //       orderBy(sortBy, sortDirection),
  //       limit(batchSize)
  //     ];

  //     const queryConstraintsForCategoryOnly = [
  //       ...categoryConditions,  // Ensure category filter is applied
  //       ...baseConditions,
  //       ...conditionConditions,
  //       ...postTypeConditions,
  //       ...categoryConditions,
  //       ...shippingMethodsConditions,
  //       ...companyConditions,
  //       ...watchDepartmentConditions,
  //       ...watchOutsideColorConditions,
  //       ...watchInsideColorConditions,
  //       ...watchDialShapeConditions,
  //       ...watchHandlesColorConditions,
  //       ...watchNumbersColorConditions,
  //       ...watchNumbersLanguageConditions,
  //       ...watchBandMaterialConditions,
  //       ...watchBandColorConditions,
  //       ...watchHasOriginalPackagingConditions,
  //       ...watchIsWaterResistantConditions,
  //       ...dialSizeConditions,
  //       ...yearMadeConditions,
  //       ...rosaryKindConditions,
  //       ...rosaryColorConditions,
  //       ...walletDepartmentConditions,
  //       ...walletOutsideColorConditions,
  //       ...purseMaterialConditions,
  //       ...purseOutsideColorConditions,
  //       ...purseInsideColorConditions,
  //       ...carPlateKindConditions,
  //       ...carPlateTransferFeeOnConditions,
  //       ...carPlateTransferTimeConditions,
  //       ...phoneNumberCourierConditions,
  //       orderBy(sortBy, sortDirection),
  //       limit(batchSize)
  //     ];

  //     const priceQueryRef = priceConditions.length > 0 ? query(collection(db, "Posts"), ...queryConstraintsForPrice) : null;
  //     const entryFeeQueryRef = entryFeeConditions.length > 1 ? query(collection(db, "Posts"), ...queryConstraintsForEntryFee) : null;
  //     const startingPriceQueryRef = startingPriceConditions.length > 1 ? query(collection(db, "Posts"), ...queryConstraintsForStartingPrice) : null;
  //     const bidIncrementQueryRef = bidIncrementConditions.length > 1 ? query(collection(db, "Posts"), ...queryConstraintsForBidIncrement) : null;
  //     const categoryOnlyQueryRef = priceConditions.length === 0 && entryFeeConditions.length <= 1 && startingPriceConditions.length <= 1 && bidIncrementConditions.length <= 1 ? query(collection(db, "Posts"), ...queryConstraintsForCategoryOnly) : null;

  //     const fetchQuerySnapshot = (querySnapshot) => {
  //       const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       return products;
  //     };

  //     try {
  //       const fetchPromises = [];

  //       if (priceQueryRef) {
  //         fetchPromises.push(new Promise((resolve, reject) => {
  //           const unsubscribe = onSnapshot(priceQueryRef, (querySnapshot) => {
  //             resolve(fetchQuerySnapshot(querySnapshot));
  //           }, reject);

  //           return unsubscribe;
  //         }));
  //       }

  //       if (entryFeeQueryRef) {
  //         fetchPromises.push(new Promise((resolve, reject) => {
  //           const unsubscribe = onSnapshot(entryFeeQueryRef, (querySnapshot) => {
  //             resolve(fetchQuerySnapshot(querySnapshot));
  //           }, reject);

  //           return unsubscribe;
  //         }));
  //       }

  //       if (startingPriceQueryRef) {
  //         fetchPromises.push(new Promise((resolve, reject) => {
  //           const unsubscribe = onSnapshot(startingPriceQueryRef, (querySnapshot) => {
  //             resolve(fetchQuerySnapshot(querySnapshot));
  //           }, reject);

  //           return unsubscribe;
  //         }));
  //       }

  //       if (bidIncrementQueryRef) {
  //         fetchPromises.push(new Promise((resolve, reject) => {
  //           const unsubscribe = onSnapshot(bidIncrementQueryRef, (querySnapshot) => {
  //             resolve(fetchQuerySnapshot(querySnapshot));
  //           }, reject);

  //           return unsubscribe;
  //         }));
  //       }

  //       if (categoryOnlyQueryRef) {
  //         fetchPromises.push(new Promise((resolve, reject) => {
  //           const unsubscribe = onSnapshot(categoryOnlyQueryRef, (querySnapshot) => {
  //             resolve(fetchQuerySnapshot(querySnapshot));
  //           }, reject);

  //           return unsubscribe;
  //         }));
  //       }

  //       const results = await Promise.all(fetchPromises);

  //       const productMap = new Map();
  //       results.flat().forEach(product => productMap.set(product.id, product));

  // // Filter out items that do not meet the conditions client-side
  // const allProducts = Array.from(productMap.values()).filter(product => {
  //   // Check if any search term matches product keywords
  //   const matchesSearchTerms = searchTermArray.every(term => {

  //     // partial
  //     if (Array.isArray(product.keywords)) {
  //       return product.keywords.some(keyword => {
  //         if (typeof keyword === 'string') {
  //           return keyword.includes(term);
  //         }
  //         return false;
  //       });
  //     }
  //     return false; // Handle case where keywords is not an array
  //   });

  //   // If search terms don't match, filter out the product
  //   if (!matchesSearchTerms) {
  //     console.log('Product filtered out due to search terms:', product);
  //     return false;
  //   }

  //   // Apply other filters
  //   if (parseFloat(heighestPriceFilter) > 0 && (!product.productPrice || product.productPrice > parseFloat(heighestPriceFilter))) {
  //     return false;
  //   }
  //   if (parseFloat(lowestPriceFilter) > 0 && product.productPrice < parseFloat(lowestPriceFilter)) {
  //     return false;
  //   }
  //   if (parseFloat(lowestEntryFeeFilter) > 0 && (!product.entryFee || product.entryFee < parseFloat(lowestEntryFeeFilter))) {
  //     return false;
  //   }
  //   if (parseFloat(heighestEntryFeeFilter) > 0 && product.entryFee > parseFloat(heighestEntryFeeFilter)) {
  //     return false;
  //   }
  //   if (parseFloat(lowestStartingPriceFilter) > 0 && (!product.startingPrice || product.startingPrice < parseFloat(lowestStartingPriceFilter))) {
  //     return false;
  //   }
  //   if (parseFloat(heighestStartingPriceFilter) > 0 && (!product.startingPrice || product.startingPrice > parseFloat(heighestStartingPriceFilter))) {
  //     return false;
  //   }
  //   if (parseFloat(lowestBidIncrementFilter) > 0 && (!product.bidIncrements || product.bidIncrements < parseFloat(lowestBidIncrementFilter))) {
  //     return false;
  //   }
  //   if (parseFloat(heighestBidIncrementFilter) > 0 && (!product.bidIncrements || product.bidIncrements > parseFloat(heighestBidIncrementFilter))) {
  //     return false;
  //   }
  //   if (conditionFilterArray.length > 0 && !conditionFilterArray.includes(product.condition)) {
  //     return false;
  //   }
  //   if (postTypeFilterArray.length > 0) {
  //     if (!postTypeFilterArray.some(type => product.postType.includes(type))) {
  //       return false;
  //     }
  //     if (product.postType.includes('قابل للتفاوض') && !product.postType.includes('شراء فوري')) {
  //       return false;
  //     }
  //   }
  //   if (shippingMethodsFilterArray.length > 0 && !shippingMethodsFilterArray.some(method => product.shippingMethods.includes(method))) {
  //     return false;
  //   }
  //   if (companyFilterArray.length > 0 && !companyFilterArray.includes(product.productCompany)) {
  //     return false;
  //   }
  //   if (watchDepartmentFilterArray.length > 0 && !watchDepartmentFilterArray.includes(product.watchDepartment)) {
  //     return false;
  //   }
  //   if (watchOutsideColorFilterArray.length > 0 && !watchOutsideColorFilterArray.includes(product.watchOutsideColor)) {
  //     return false;
  //   }
  //   if (watchInsideColorFilterArray.length > 0 && !watchInsideColorFilterArray.includes(product.watchInsideColor)) {
  //     return false;
  //   }
  //   if (watchDialShapeFilterArray.length > 0 && !watchDialShapeFilterArray.includes(product.watchDialShape)) {
  //     return false;
  //   }
  //   if (watchHandlesColorFilterArray.length > 0 && !watchHandlesColorFilterArray.includes(product.watchHandlesColor)) {
  //     return false;
  //   }
  //   if (watchNumbersColorFilterArray.length > 0 && !watchNumbersColorFilterArray.includes(product.watchNumbersColor)) {
  //     return false;
  //   }
  //   if (watchNumbersLanguageFilterArray.length > 0 && !watchNumbersLanguageFilterArray.includes(product.watchNumbersLanguage)) {
  //     return false;
  //   }
  //   if (watchBandMaterialFilterArray.length > 0 && !watchBandMaterialFilterArray.includes(product.watchBandMaterial)) {
  //     return false;
  //   }
  //   if (watchBandColorFilterArray.length > 0 && !watchBandColorFilterArray.includes(product.watchBandColor)) {
  //     return false;
  //   }
  //   if (watchHasOriginalPackagingFilterArray.length > 0 && !watchHasOriginalPackagingFilterArray.includes(product.watchHasOriginalPackaging)) {
  //     return false;
  //   }
  //   if (watchIsWaterResistantFilterArray.length > 0 && !watchIsWaterResistantFilterArray.includes(product.watchIsWaterResistant)) {
  //     console.log('Product filtered out due to watch water resistance filter:', product);
  //     return false;
  //   }
  //   if (parseFloat(smallestDialSizeFilter) > 0 && (!product.watchDialSize || product.watchDialSize < parseFloat(smallestDialSizeFilter))) {
  //     console.log('Product filtered out due to smallest dial size filter:', product);
  //     return false;
  //   }
  //   if (parseFloat(biggestDialSizeFilter) > 0 && (!product.watchDialSize || product.watchDialSize > parseFloat(biggestDialSizeFilter))) {
  //     console.log('Product filtered out due to biggest dial size filter:', product);
  //     return false;
  //   }
  //   if (parseFloat(watchOldestYearMadeFilter) > 0 && (!product.watchYearMade || product.watchYearMade < parseFloat(watchOldestYearMadeFilter))) {
  //     console.log('Product filtered out due to oldest year made filter:', product);
  //     return false;
  //   }
  //   if (parseFloat(watchNewestYearMadeFilter) > 0 && (!product.watchYearMade || product.watchYearMade > parseFloat(watchNewestYearMadeFilter))) {
  //     console.log('Product filtered out due to newest year made filter:', product);
  //     return false;
  //   }

  //   // Check for auction products and filter based on expiry date
  //   if (product.postType.includes('مزاد') && product.expiryDate && product.expiryDate.toDate() < new Date()) {
  //     console.log('Product filtered out due to auction expired:', product);
  //     return false;
  //   }

  //   // If all filters pass, include the product in the filtered list
  //   return true;
  // });

  //       setListing(allProducts);
  //       setIsLoading(false);
  //       setHasMore(allProducts.length === batchSize);
  //       setLastDoc(allProducts[allProducts.length - 1]);
  //     } catch (err) {
  //       console.error("Error executing queries: ", err);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchProducts();
  // }, [
  //   categorySearch,
  //   JSON.stringify(conditionFilterArray),
  //   JSON.stringify(postTypeFilterArray),
  //   lowestPriceFilter,
  //   heighestPriceFilter,
  //   lowestEntryFeeFilter,
  //   heighestEntryFeeFilter,
  //   lowestStartingPriceFilter,
  //   heighestStartingPriceFilter,
  //   lowestBidIncrementFilter,
  //   heighestBidIncrementFilter,
  //   JSON.stringify(shippingMethodsFilterArray),
  //   JSON.stringify(companyFilterArray),
  //   JSON.stringify(watchDepartmentFilterArray),
  //   JSON.stringify(watchOutsideColorFilterArray),
  //   JSON.stringify(watchInsideColorFilterArray),
  //   JSON.stringify(watchDialShapeFilterArray),
  //   JSON.stringify(watchHandlesColorFilterArray),
  //   JSON.stringify(watchNumbersColorFilterArray),
  //   JSON.stringify(watchNumbersLanguageFilterArray),
  //   JSON.stringify(watchBandMaterialFilterArray),
  //   JSON.stringify(watchBandColorFilterArray),
  //   JSON.stringify(watchHasOriginalPackagingFilterArray),
  //   JSON.stringify(watchIsWaterResistantFilterArray),
  //   parseFloat(smallestDialSizeFilter),
  //   parseFloat(biggestDialSizeFilter),
  //   parseFloat(watchOldestYearMadeFilter),
  //   parseFloat(watchNewestYearMadeFilter),
  //   JSON.stringify(searchTermArray)
  // ]);

  // useEffect(()=>{
  // setIsLoading(true)
  // }, [categorySearch])

  // const [qu, setQu] = useState(
  //   query(collection(db, "Posts"), where("isDeleted", "==", false))
  // );

  const {
    productsLoading,
    listing,
    allCount,
    hasMore,
    leastExpensiveBidIncrement,
    mostExpensiveBidIncrement,
    leastExpensiveItemStartingPrice,
    mostExpensiveItemStartingPrice,
    leastExpensiveItemEntryFee,
    mostRosaryCount,
    smallestRosarySize,
    leastRosaryCount,
    newestWatchYear,
    oldestWatchYear,
    biggestRosarySize,
    biggestDialSize,
    mostExpensiveItemEntryFee,
    smallestDialSize,
  } = useInfiniteScroll(
    res,
    numBidders,
    views,
    orderSelectedName,
    urlSortOption,
    lastDoc,
    categorySearch,
    conditionFilter,
    postTypeFilter,
    lowestPriceFilter,
    heighestPriceFilter,
    lowestEntryFeeFilter,
    heighestEntryFeeFilter,
    lowestStartingPriceFilter,
    heighestStartingPriceFilter,
    lowestBidIncrementFilter,
    heighestBidIncrementFilter,
    shippingMethodsFilter,
    companyFilter,
    watchDepartmentFilter,
    watchOutsideColorFilter,
    watchInsideColorFilter,
    watchDialShapeFilter,
    smallestDialSizeFilter,
    biggestDialSizeFilter,
    watchHandlesColorFilter,
    watchNumbersColorFilter,
    watchNumbersLanguageFilter,
    watchBandMaterialFilter,
    watchBandColorFilter,
    watchHasOriginalPackagingFilter,
    watchIsWaterResistantFilter,
    watchOldestYearMadeFilter,
    watchNewestYearMadeFilter,
    rosaryKindFilter,
    rosaryColorFilter,
    leastRosaryCountFilter,
    mostRosaryCountFilter,
    smallestRosarySizeFilter,
    biggestRosarySizeFilter,
    walletDepartmentFilter,
    walletOutsideColorFilter,
    purseMaterialFilter,
    purseOutsideColorFilter,
    purseInsideColorFilter,
    carPlateKindFilter,
    carPlateTransferFeeOnFilter,
    carPlateTransferTimeFilter,
    phoneNumberCourierFilter
  );

  // get the filters
  useEffect(() => {
    if(!productsLoading){
      const productsFound = allCount;
      setNumProducts(
        productsFound === 0
          ? "لم يتم العثور على منتجات"
          : productsFound === 1
          ? "تم العثور على منتج واحد"
          : productsFound === 2
          ? "تم العثور على منتجين"
          : productsFound > 0 && productsFound <= 10
          ? `تم العثور على ${productsFound} منتجات`
          : `تم العثور على ${productsFound} منتج`
      );
    }
    else{
      setNumProducts("")
    }
  }, [allCount, productsLoading ]);
  // #region
  // const handleScrollPosition = () => {
  //   const position = window.scrollY;
  //   setScrollPosition(position);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScrollPosition);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrollPosition);
  //   };
  // }, []);

  // Function to handle checkbox change
  const handleSortOptionChange = (option) => {
    setSelectedSortOption(option);
  };

  // rosary filter
  // Update state based on the rosary kind filter from URL
  useEffect(() => {
    if (rosaryKindFilter) {
      setRosaryKindChecked_all(false);
      setRosaryKindChecked_backlight(rosaryKindFilter.includes("بكلايت"));
      setRosaryKindChecked_kahraman(rosaryKindFilter.includes("كهرمان"));
      setRosaryKindChecked_fatoran(rosaryKindFilter.includes("فاتوران"));
      setRosaryKindChecked_ivory(rosaryKindFilter.includes("عاج"));
      setRosaryKindChecked_wood(rosaryKindFilter.includes("خشب"));
      setRosaryKindChecked_stone(rosaryKindFilter.includes("حجر"));
    } else {
      setRosaryKindChecked_all(true);
      setRosaryKindChecked_backlight(false);
      setRosaryKindChecked_kahraman(false);
      setRosaryKindChecked_fatoran(false);
      setRosaryKindChecked_ivory(false);
      setRosaryKindChecked_wood(false);
      setRosaryKindChecked_stone(false);
    }
  }, [rosaryKindFilter]);

  // useEffect to update the state based on wallet outside color filter from URL
  useEffect(() => {
    if (walletOutsideColorFilter) {
      setWalletOutsideColorChecked_all(false);
      setWalletOutsideColorChecked_black(
        walletOutsideColorFilter.includes("أسود")
      );
      setWalletOutsideColorChecked_white(
        walletOutsideColorFilter.includes("أبيض")
      );
      setWalletOutsideColorChecked_red(
        walletOutsideColorFilter.includes("أحمر")
      );
      setWalletOutsideColorChecked_blue(
        walletOutsideColorFilter.includes("أزرق")
      );
      setWalletOutsideColorChecked_green(
        walletOutsideColorFilter.includes("أخضر")
      );
      setWalletOutsideColorChecked_yellow(
        walletOutsideColorFilter.includes("أصفر")
      );
      setWalletOutsideColorChecked_orange(
        walletOutsideColorFilter.includes("برتقالي")
      );
      setWalletOutsideColorChecked_purple(
        walletOutsideColorFilter.includes("بنفسجي")
      );
      setWalletOutsideColorChecked_pink(
        walletOutsideColorFilter.includes("وردي")
      );
      setWalletOutsideColorChecked_brown(
        walletOutsideColorFilter.includes("بني")
      );
      setWalletOutsideColorChecked_gray(
        walletOutsideColorFilter.includes("رمادي")
      );
      setWalletOutsideColorChecked_olive(
        walletOutsideColorFilter.includes("زيتي")
      );
      setWalletOutsideColorChecked_cyan(
        walletOutsideColorFilter.includes("سماوي")
      );
      setWalletOutsideColorChecked_navy(
        walletOutsideColorFilter.includes("كحلي")
      );
      setWalletOutsideColorChecked_beige(
        walletOutsideColorFilter.includes("بيج")
      );
      setWalletOutsideColorChecked_other(
        walletOutsideColorFilter.includes("أخرى")
      );
    } else {
      setWalletOutsideColorChecked_all(true);
      setWalletOutsideColorChecked_black(false);
      setWalletOutsideColorChecked_white(false);
      setWalletOutsideColorChecked_red(false);
      setWalletOutsideColorChecked_blue(false);
      setWalletOutsideColorChecked_green(false);
      setWalletOutsideColorChecked_yellow(false);
      setWalletOutsideColorChecked_orange(false);
      setWalletOutsideColorChecked_purple(false);
      setWalletOutsideColorChecked_pink(false);
      setWalletOutsideColorChecked_brown(false);
      setWalletOutsideColorChecked_gray(false);
      setWalletOutsideColorChecked_olive(false);
      setWalletOutsideColorChecked_cyan(false);
      setWalletOutsideColorChecked_navy(false);
      setWalletOutsideColorChecked_beige(false);
      setWalletOutsideColorChecked_other(false);
    }
  }, [walletOutsideColorFilter]);

  // rosary color use effect
  useEffect(() => {
    if (rosaryColorFilter) {
      setRosaryColorChecked_all(false);
      setRosaryColorChecked_black(rosaryColorFilter.includes("أسود"));
      setRosaryColorChecked_white(rosaryColorFilter.includes("أبيض"));
      setRosaryColorChecked_red(rosaryColorFilter.includes("أحمر"));
      setRosaryColorChecked_blue(rosaryColorFilter.includes("أزرق"));
      setRosaryColorChecked_green(rosaryColorFilter.includes("أخضر"));
      setRosaryColorChecked_yellow(rosaryColorFilter.includes("أصفر"));
      setRosaryColorChecked_orange(rosaryColorFilter.includes("برتقالي"));
      setRosaryColorChecked_purple(rosaryColorFilter.includes("بنفسجي"));
      setRosaryColorChecked_pink(rosaryColorFilter.includes("وردي"));
      setRosaryColorChecked_brown(rosaryColorFilter.includes("بني"));
      setRosaryColorChecked_gray(rosaryColorFilter.includes("رمادي"));
      setRosaryColorChecked_olive(rosaryColorFilter.includes("زيتي"));
      setRosaryColorChecked_cyan(rosaryColorFilter.includes("سماوي"));
      setRosaryColorChecked_navy(rosaryColorFilter.includes("كحلي"));
      setRosaryColorChecked_beige(rosaryColorFilter.includes("بيج"));
      setRosaryColorChecked_other(rosaryColorFilter.includes("آخر"));
    } else {
      setRosaryColorChecked_all(true);
      setRosaryColorChecked_black(false);
      setRosaryColorChecked_white(false);
      setRosaryColorChecked_red(false);
      setRosaryColorChecked_blue(false);
      setRosaryColorChecked_green(false);
      setRosaryColorChecked_yellow(false);
      setRosaryColorChecked_orange(false);
      setRosaryColorChecked_purple(false);
      setRosaryColorChecked_pink(false);
      setRosaryColorChecked_brown(false);
      setRosaryColorChecked_gray(false);
      setRosaryColorChecked_olive(false);
      setRosaryColorChecked_cyan(false);
      setRosaryColorChecked_navy(false);
      setRosaryColorChecked_beige(false);
      setRosaryColorChecked_other(false);
    }
  }, [rosaryColorFilter]);

  // Update state based on the purse material filter from URL
  useEffect(() => {
    if (purseMaterialFilter) {
      setPurseMaterialChecked_all(false);
      setPurseMaterialChecked_leather(purseMaterialFilter.includes("جلد"));
      setPurseMaterialChecked_fabric(purseMaterialFilter.includes("مخمل"));
    } else {
      setPurseMaterialChecked_all(true);
      setPurseMaterialChecked_leather(false);
      setPurseMaterialChecked_fabric(false);
    }
  }, [purseMaterialFilter]);

  // purse outside color filter
  useEffect(() => {
    if (purseOutsideColorFilter) {
      setPurseOutsideColorChecked_all(false);
      setPurseOutsideColorChecked_black(
        purseOutsideColorFilter.includes("أسود")
      );
      setPurseOutsideColorChecked_white(
        purseOutsideColorFilter.includes("أبيض")
      );
      setPurseOutsideColorChecked_red(purseOutsideColorFilter.includes("أحمر"));
      setPurseOutsideColorChecked_blue(
        purseOutsideColorFilter.includes("أزرق")
      );
      setPurseOutsideColorChecked_green(
        purseOutsideColorFilter.includes("أخضر")
      );
      setPurseOutsideColorChecked_yellow(
        purseOutsideColorFilter.includes("أصفر")
      );
      setPurseOutsideColorChecked_orange(
        purseOutsideColorFilter.includes("برتقالي")
      );
      setPurseOutsideColorChecked_purple(
        purseOutsideColorFilter.includes("بنفسجي")
      );
      setPurseOutsideColorChecked_pink(
        purseOutsideColorFilter.includes("وردي")
      );
      setPurseOutsideColorChecked_brown(
        purseOutsideColorFilter.includes("بني")
      );
      setPurseOutsideColorChecked_gray(
        purseOutsideColorFilter.includes("رمادي")
      );
      setPurseOutsideColorChecked_olive(
        purseOutsideColorFilter.includes("زيتي")
      );
      setPurseOutsideColorChecked_cyan(
        purseOutsideColorFilter.includes("سماوي")
      );
      setPurseOutsideColorChecked_navy(
        purseOutsideColorFilter.includes("كحلي")
      );
      setPurseOutsideColorChecked_beige(
        purseOutsideColorFilter.includes("بيج")
      );
      setPurseOutsideColorChecked_other(
        purseOutsideColorFilter.includes("أخرى")
      );
    } else {
      setPurseOutsideColorChecked_all(true);
      setPurseOutsideColorChecked_black(false);
      setPurseOutsideColorChecked_white(false);
      setPurseOutsideColorChecked_red(false);
      setPurseOutsideColorChecked_blue(false);
      setPurseOutsideColorChecked_green(false);
      setPurseOutsideColorChecked_yellow(false);
      setPurseOutsideColorChecked_orange(false);
      setPurseOutsideColorChecked_purple(false);
      setPurseOutsideColorChecked_pink(false);
      setPurseOutsideColorChecked_brown(false);
      setPurseOutsideColorChecked_gray(false);
      setPurseOutsideColorChecked_olive(false);
      setPurseOutsideColorChecked_cyan(false);
      setPurseOutsideColorChecked_navy(false);
      setPurseOutsideColorChecked_beige(false);
      setPurseOutsideColorChecked_other(false);
    }
  }, [purseOutsideColorFilter]);

  // purse inside color filter
  useEffect(() => {
    if (purseInsideColorFilter) {
      setPurseInsideColorChecked_all(false);
      setPurseInsideColorChecked_black(purseInsideColorFilter.includes("أسود"));
      setPurseInsideColorChecked_white(purseInsideColorFilter.includes("أبيض"));
      setPurseInsideColorChecked_red(purseInsideColorFilter.includes("أحمر"));
      setPurseInsideColorChecked_blue(purseInsideColorFilter.includes("أزرق"));
      setPurseInsideColorChecked_green(purseInsideColorFilter.includes("أخضر"));
      setPurseInsideColorChecked_yellow(
        purseInsideColorFilter.includes("أصفر")
      );
      setPurseInsideColorChecked_orange(
        purseInsideColorFilter.includes("برتقالي")
      );
      setPurseInsideColorChecked_purple(
        purseInsideColorFilter.includes("بنفسجي")
      );
      setPurseInsideColorChecked_pink(purseInsideColorFilter.includes("وردي"));
      setPurseInsideColorChecked_brown(purseInsideColorFilter.includes("بني"));
      setPurseInsideColorChecked_gray(purseInsideColorFilter.includes("رمادي"));
      setPurseInsideColorChecked_olive(purseInsideColorFilter.includes("زيتي"));
      setPurseInsideColorChecked_cyan(purseInsideColorFilter.includes("سماوي"));
      setPurseInsideColorChecked_navy(purseInsideColorFilter.includes("كحلي"));
      setPurseInsideColorChecked_beige(purseInsideColorFilter.includes("بيج"));
      setPurseInsideColorChecked_other(purseInsideColorFilter.includes("أخرى"));
    } else {
      setPurseInsideColorChecked_all(true);
      setPurseInsideColorChecked_black(false);
      setPurseInsideColorChecked_white(false);
      setPurseInsideColorChecked_red(false);
      setPurseInsideColorChecked_blue(false);
      setPurseInsideColorChecked_green(false);
      setPurseInsideColorChecked_yellow(false);
      setPurseInsideColorChecked_orange(false);
      setPurseInsideColorChecked_purple(false);
      setPurseInsideColorChecked_pink(false);
      setPurseInsideColorChecked_brown(false);
      setPurseInsideColorChecked_gray(false);
      setPurseInsideColorChecked_olive(false);
      setPurseInsideColorChecked_cyan(false);
      setPurseInsideColorChecked_navy(false);
      setPurseInsideColorChecked_beige(false);
      setPurseInsideColorChecked_other(false);
    }
  }, [purseInsideColorFilter]);

  // car plate kind use effect
  useEffect(() => {
    if (carPlateKindFilter) {
      setCarPlateKindChecked_all(false);
      setCarPlateKindChecked_privateCar(
        carPlateKindFilter.includes("لوحة سيارة خاصة")
      );
      setCarPlateKindChecked_motorCycle(
        carPlateKindFilter.includes("لوحة دراجة نارية")
      );
      setCarPlateKindChecked_transportation(
        carPlateKindFilter.includes("لوحة سيارة نقل عام")
      );
      setCarPlateKindChecked_commerce(
        carPlateKindFilter.includes("لوحة سيارة تجارية")
      );
    } else {
      setCarPlateKindChecked_all(true);
      setCarPlateKindChecked_privateCar(false);
      setCarPlateKindChecked_motorCycle(false);
      setCarPlateKindChecked_transportation(false);
      setCarPlateKindChecked_commerce(false);
    }
  }, [carPlateKindFilter]);

  // useEffect to update state based on carPlateTransferTimeFilter
  useEffect(() => {
    if (carPlateTransferTimeFilter) {
      setCarPlateTransferTimeChecked_all(false);
      setCarPlateTransferTimeChecked_24to48Hours(
        carPlateTransferTimeFilter.includes("24 إلى 48 ساعة")
      );
      setCarPlateTransferTimeChecked_twoToFiveDays(
        carPlateTransferTimeFilter.includes("يومين إلى 5 أيام")
      );
    } else {
      setCarPlateTransferTimeChecked_all(true);
      setCarPlateTransferTimeChecked_24to48Hours(false);
      setCarPlateTransferTimeChecked_twoToFiveDays(false);
    }
  }, [carPlateTransferTimeFilter]);

  // useEffect to update state based on carPlateTransferFeeOnFilter
  useEffect(() => {
    if (carPlateTransferFeeOnFilter) {
      setCarPlateTransferFeeOnChecked_all(false);
      setCarPlateTransferFeeOnChecked_seller(
        carPlateTransferFeeOnFilter.includes("البائع")
      );
      setCarPlateTransferFeeOnChecked_buyer(
        carPlateTransferFeeOnFilter.includes("المشتري")
      );
    } else {
      setCarPlateTransferFeeOnChecked_all(true);
      setCarPlateTransferFeeOnChecked_seller(false);
      setCarPlateTransferFeeOnChecked_buyer(false);
    }
  }, [carPlateTransferFeeOnFilter]);

  // useEffect to update state based on phoneNumberCourierFilter
  useEffect(() => {
    if (phoneNumberCourierFilter) {
      setPhoneNumberCourierChecked_all(false);
      setPhoneNumberCourierChecked_stc(
        phoneNumberCourierFilter.includes("STC")
      );
      setPhoneNumberCourierChecked_mobily(
        phoneNumberCourierFilter.includes("موبايلي")
      );
      setPhoneNumberCourierChecked_zain(
        phoneNumberCourierFilter.includes("زين")
      );
    } else {
      setPhoneNumberCourierChecked_all(true);
      setPhoneNumberCourierChecked_stc(false);
      setPhoneNumberCourierChecked_mobily(false);
      setPhoneNumberCourierChecked_zain(false);
    }
  }, [phoneNumberCourierFilter]);

  // Update state based on the condition filter from URL
  useEffect(() => {
    if (conditionFilter) {
      setProductConditionChecked_all(false);
      setProductConditionChecked_new(conditionFilter.includes("جديد"));
      setProductConditionChecked_used(conditionFilter.includes("مستعمل"));
      setProductConditionChecked_excellent(conditionFilter.includes("ممتازة"));
      setProductConditionChecked_veryGood(conditionFilter.includes("جيدة جدا"));
      setProductConditionChecked_good(conditionFilter.includes("جيدة"));
      setProductConditionChecked_acceptable(conditionFilter.includes("مقبولة"));
    } else {
      setProductConditionChecked_all(true);
      setProductConditionChecked_new(false);
      setProductConditionChecked_used(false);
      setProductConditionChecked_excellent(false);
      setProductConditionChecked_veryGood(false);
      setProductConditionChecked_good(false);
      setProductConditionChecked_acceptable(false);
    }
  }, [conditionFilter]);

  // watch is water resistant filter

  // watch packaging filter
  useEffect(() => {
    // Check if watchHasOriginalPackagingFilter is defined and not empty
    if (watchIsWaterResistantFilter) {
      // If watchHasOriginalPackagingFilter is defined, update individual states based on its content
      setWatchIsWaterResistantChecked_all(false);
      setWatchIsWaterResistantChecked_yes(
        watchIsWaterResistantFilter.includes(true)
      );
      setWatchIsWaterResistantChecked_no(
        watchIsWaterResistantFilter.includes(false)
      );
    } else {
      // If watchHasOriginalPackagingFilter is not defined (null or undefined), reset all states
      setWatchIsWaterResistantChecked_all(true);
      setWatchIsWaterResistantChecked_yes(false);
      setWatchIsWaterResistantChecked_no(false);
    }
  }, [watchIsWaterResistantFilter]);

  // watch packaging filter
  useEffect(() => {
    // Check if watchHasOriginalPackagingFilter is defined and not empty
    if (watchHasOriginalPackagingFilter) {
      // If watchHasOriginalPackagingFilter is defined, update individual states based on its content
      setWatchHasOriginalPackagingChecked_all(false);
      setWatchHasOriginalPackagingChecked_yes(
        watchHasOriginalPackagingFilter.includes(true)
      );
      setWatchHasOriginalPackagingChecked_no(
        watchHasOriginalPackagingFilter.includes(false)
      );
    } else {
      // If watchHasOriginalPackagingFilter is not defined (null or undefined), reset all states
      setWatchHasOriginalPackagingChecked_all(true);
      setWatchHasOriginalPackagingChecked_yes(false);
      setWatchHasOriginalPackagingChecked_no(false);
    }
  }, [watchHasOriginalPackagingFilter]);

  // watch band color filter
  useEffect(() => {
    if (watchBandColorFilter) {
      setWatchBandColorChecked_all(false);
      setWatchBandColorChecked_stainlessSteel(
        watchBandColorFilter.includes("ستانلس ستيل")
      );
      setWatchBandColorChecked_black(watchBandColorFilter.includes("أسود"));
      setWatchBandColorChecked_white(watchBandColorFilter.includes("أبيض"));
      setWatchBandColorChecked_red(watchBandColorFilter.includes("أحمر"));
      setWatchBandColorChecked_blue(watchBandColorFilter.includes("أزرق"));
      setWatchBandColorChecked_green(watchBandColorFilter.includes("أخضر"));
      setWatchBandColorChecked_yellow(watchBandColorFilter.includes("أصفر"));
      setWatchBandColorChecked_orange(watchBandColorFilter.includes("برتقالي"));
      setWatchBandColorChecked_purple(watchBandColorFilter.includes("بنفسجي"));
      setWatchBandColorChecked_pink(watchBandColorFilter.includes("وردي"));
      setWatchBandColorChecked_brown(watchBandColorFilter.includes("بني"));
      setWatchBandColorChecked_gray(watchBandColorFilter.includes("رمادي"));
      setWatchBandColorChecked_olive(watchBandColorFilter.includes("زيتي"));
      setWatchBandColorChecked_cyan(watchBandColorFilter.includes("سماوي"));
      setWatchBandColorChecked_navy(watchBandColorFilter.includes("كحلي"));
      setWatchBandColorChecked_beige(watchBandColorFilter.includes("بيج"));
      setWatchBandColorChecked_other(watchBandColorFilter.includes("آخر"));
    } else {
      setWatchBandColorChecked_all(true);
      setWatchBandColorChecked_stainlessSteel(false);
      setWatchBandColorChecked_black(false);
      setWatchBandColorChecked_white(false);
      setWatchBandColorChecked_red(false);
      setWatchBandColorChecked_blue(false);
      setWatchBandColorChecked_green(false);
      setWatchBandColorChecked_yellow(false);
      setWatchBandColorChecked_orange(false);
      setWatchBandColorChecked_purple(false);
      setWatchBandColorChecked_pink(false);
      setWatchBandColorChecked_brown(false);
      setWatchBandColorChecked_gray(false);
      setWatchBandColorChecked_olive(false);
      setWatchBandColorChecked_cyan(false);
      setWatchBandColorChecked_navy(false);
      setWatchBandColorChecked_beige(false);
      setWatchBandColorChecked_other(false);
    }
  }, [watchBandColorFilter]);

  // watch band material filter
  useEffect(() => {
    if (watchBandMaterialFilter) {
      setWatchBandMaterialChecked_all(false);
      setWatchBandMaterialChecked_stainlessSteel(
        watchBandMaterialFilter.includes("ستانلس ستيل")
      );
      setWatchBandMaterialChecked_leather(
        watchBandMaterialFilter.includes("جلد")
      );
      setWatchBandMaterialChecked_rubber(
        watchBandMaterialFilter.includes("ربل")
      );
    } else {
      setWatchBandMaterialChecked_all(true);
      setWatchBandMaterialChecked_stainlessSteel(false);
      setWatchBandMaterialChecked_leather(false);
      setWatchBandMaterialChecked_rubber(false);
    }
  }, [watchBandMaterialFilter]);

  // Update state based on the condition filter from URL
  useEffect(() => {
    if (watchNumbersLanguageFilter) {
      setWatchNumbersLanguageChecked_all(false);
      setWatchNumbersLanguageChecked_arabic(
        watchNumbersLanguageFilter?.includes("العربية")
      );
      setWatchNumbersLanguageChecked_english(
        watchNumbersLanguageFilter?.includes("الإنجليزية")
      );
      setWatchNumbersLanguageChecked_roman(
        watchNumbersLanguageFilter?.includes("الرومانية")
      );
      setWatchNumbersLanguageChecked_none(
        watchNumbersLanguageFilter?.includes("بدون أرقام")
      );
    } else {
      setWatchNumbersLanguageChecked_all(true);
      setWatchNumbersLanguageChecked_arabic(false);
      setWatchNumbersLanguageChecked_english(false);
      setWatchNumbersLanguageChecked_roman(false);
      setWatchNumbersLanguageChecked_none(false);
    }
  }, [watchNumbersLanguageFilter]);

  // useEffect to update the state based on watch dial shape filter
  useEffect(() => {
    if (watchDialShapeFilter) {
      setWatchDialShapeChecked_all(false);
      setWatchDialShapeChecked_round(watchDialShapeFilter.includes("دائري"));
      setWatchDialShapeChecked_square(watchDialShapeFilter.includes("مربع"));
      setWatchDialShapeChecked_rectangle(
        watchDialShapeFilter.includes("مستطيل")
      );
      setWatchDialShapeChecked_oval(watchDialShapeFilter.includes("بيضاوي"));
      setWatchDialShapeChecked_pentagon(watchDialShapeFilter.includes("خماسي"));
      setWatchDialShapeChecked_hexagon(watchDialShapeFilter.includes("سداسي"));
    } else {
      setWatchDialShapeChecked_all(true);
      setWatchDialShapeChecked_round(false);
      setWatchDialShapeChecked_square(false);
      setWatchDialShapeChecked_rectangle(false);
      setWatchDialShapeChecked_oval(false);
      setWatchDialShapeChecked_pentagon(false);
      setWatchDialShapeChecked_hexagon(false);
    }
  }, [watchDialShapeFilter]);

  // useEffect to update the state based on watch inside color filter
  useEffect(() => {
    if (watchInsideColorFilter) {
      setWatchInsideColorChecked_all(false);
      setWatchInsideColorChecked_stainlessSteel(
        watchInsideColorFilter.includes("ستانلس ستيل")
      );
      setWatchInsideColorChecked_black(watchInsideColorFilter.includes("أسود"));
      setWatchInsideColorChecked_white(watchInsideColorFilter.includes("أبيض"));
      setWatchInsideColorChecked_red(watchInsideColorFilter.includes("أحمر"));
      setWatchInsideColorChecked_blue(watchInsideColorFilter.includes("أزرق"));
      setWatchInsideColorChecked_green(watchInsideColorFilter.includes("أخضر"));
      setWatchInsideColorChecked_yellow(
        watchInsideColorFilter.includes("أصفر")
      );
      setWatchInsideColorChecked_orange(
        watchInsideColorFilter.includes("برتقالي")
      );
      setWatchInsideColorChecked_purple(
        watchInsideColorFilter.includes("أرجواني")
      );
      setWatchInsideColorChecked_pink(watchInsideColorFilter.includes("وردي"));
      setWatchInsideColorChecked_brown(watchInsideColorFilter.includes("بني"));
      setWatchInsideColorChecked_gray(watchInsideColorFilter.includes("رمادي"));
      setWatchInsideColorChecked_olive(watchInsideColorFilter.includes("زيتي"));
      setWatchInsideColorChecked_cyan(watchInsideColorFilter.includes("سماوي"));
      setWatchInsideColorChecked_navy(watchInsideColorFilter.includes("كحلي"));
      setWatchInsideColorChecked_beige(watchInsideColorFilter.includes("بيج"));
      setWatchInsideColorChecked_other(watchInsideColorFilter.includes("أخرى"));
    } else {
      setWatchInsideColorChecked_all(true);
      setWatchInsideColorChecked_stainlessSteel(false);
      setWatchInsideColorChecked_black(false);
      setWatchInsideColorChecked_white(false);
      setWatchInsideColorChecked_red(false);
      setWatchInsideColorChecked_blue(false);
      setWatchInsideColorChecked_green(false);
      setWatchInsideColorChecked_yellow(false);
      setWatchInsideColorChecked_orange(false);
      setWatchInsideColorChecked_purple(false);
      setWatchInsideColorChecked_pink(false);
      setWatchInsideColorChecked_brown(false);
      setWatchInsideColorChecked_gray(false);
      setWatchInsideColorChecked_olive(false);
      setWatchInsideColorChecked_cyan(false);
      setWatchInsideColorChecked_navy(false);
      setWatchInsideColorChecked_beige(false);
      setWatchInsideColorChecked_other(false);
    }
  }, [watchInsideColorFilter]);

  // watch handles color
  useEffect(() => {
    if (watchHandlesColorFilter) {
      setWatchHandlesColorChecked_all(false);
      setWatchHandlesColorChecked_stainlessSteel(
        watchHandlesColorFilter.includes("ستانلس ستيل")
      );
      setWatchHandlesColorChecked_black(
        watchHandlesColorFilter.includes("أسود")
      );
      setWatchHandlesColorChecked_white(
        watchHandlesColorFilter.includes("أبيض")
      );
      setWatchHandlesColorChecked_red(watchHandlesColorFilter.includes("أحمر"));
      setWatchHandlesColorChecked_blue(
        watchHandlesColorFilter.includes("أزرق")
      );
      setWatchHandlesColorChecked_green(
        watchHandlesColorFilter.includes("أخضر")
      );
      setWatchHandlesColorChecked_yellow(
        watchHandlesColorFilter.includes("أصفر")
      );
      setWatchHandlesColorChecked_orange(
        watchHandlesColorFilter.includes("برتقالي")
      );
      setWatchHandlesColorChecked_purple(
        watchHandlesColorFilter.includes("بنفسجي")
      );
      setWatchHandlesColorChecked_pink(
        watchHandlesColorFilter.includes("وردي")
      );
      setWatchHandlesColorChecked_brown(
        watchHandlesColorFilter.includes("بني")
      );
      setWatchHandlesColorChecked_gray(
        watchHandlesColorFilter.includes("رمادي")
      );
      setWatchHandlesColorChecked_olive(
        watchHandlesColorFilter.includes("زيتي")
      );
      setWatchHandlesColorChecked_cyan(
        watchHandlesColorFilter.includes("سماوي")
      );
      setWatchHandlesColorChecked_navy(
        watchHandlesColorFilter.includes("كحلي")
      );
      setWatchHandlesColorChecked_beige(
        watchHandlesColorFilter.includes("بيج")
      );
      setWatchHandlesColorChecked_other(
        watchHandlesColorFilter.includes("آخر")
      );
    } else {
      setWatchHandlesColorChecked_all(true);
      setWatchHandlesColorChecked_stainlessSteel(false);
      setWatchHandlesColorChecked_black(false);
      setWatchHandlesColorChecked_white(false);
      setWatchHandlesColorChecked_red(false);
      setWatchHandlesColorChecked_blue(false);
      setWatchHandlesColorChecked_green(false);
      setWatchHandlesColorChecked_yellow(false);
      setWatchHandlesColorChecked_orange(false);
      setWatchHandlesColorChecked_purple(false);
      setWatchHandlesColorChecked_pink(false);
      setWatchHandlesColorChecked_brown(false);
      setWatchHandlesColorChecked_gray(false);
      setWatchHandlesColorChecked_olive(false);
      setWatchHandlesColorChecked_cyan(false);
      setWatchHandlesColorChecked_navy(false);
      setWatchHandlesColorChecked_beige(false);
      setWatchHandlesColorChecked_other(false);
    }
  }, [watchHandlesColorFilter]);

  // watch numbers color
  useEffect(() => {
    if (watchNumbersColorFilter) {
      setWatchNumbersColorChecked_all(false);
      setWatchNumbersColorChecked_stainlessSteel(
        watchNumbersColorFilter.includes("ستانلس ستيل")
      );
      setWatchNumbersColorChecked_black(
        watchNumbersColorFilter.includes("أسود")
      );
      setWatchNumbersColorChecked_white(
        watchNumbersColorFilter.includes("أبيض")
      );
      setWatchNumbersColorChecked_red(watchNumbersColorFilter.includes("أحمر"));
      setWatchNumbersColorChecked_blue(
        watchNumbersColorFilter.includes("أزرق")
      );
      setWatchNumbersColorChecked_green(
        watchNumbersColorFilter.includes("أخضر")
      );
      setWatchNumbersColorChecked_yellow(
        watchNumbersColorFilter.includes("أصفر")
      );
      setWatchNumbersColorChecked_orange(
        watchNumbersColorFilter.includes("برتقالي")
      );
      setWatchNumbersColorChecked_purple(
        watchNumbersColorFilter.includes("بنفسجي")
      );
      setWatchNumbersColorChecked_pink(
        watchNumbersColorFilter.includes("وردي")
      );
      setWatchNumbersColorChecked_brown(
        watchNumbersColorFilter.includes("بني")
      );
      setWatchNumbersColorChecked_gray(
        watchNumbersColorFilter.includes("رمادي")
      );
      setWatchNumbersColorChecked_olive(
        watchNumbersColorFilter.includes("زيتي")
      );
      setWatchNumbersColorChecked_cyan(
        watchNumbersColorFilter.includes("سماوي")
      );
      setWatchNumbersColorChecked_navy(
        watchNumbersColorFilter.includes("كحلي")
      );
      setWatchNumbersColorChecked_beige(
        watchNumbersColorFilter.includes("بيج")
      );
      setWatchNumbersColorChecked_other(
        watchNumbersColorFilter.includes("آخر")
      );
    } else {
      setWatchNumbersColorChecked_all(true);
      setWatchNumbersColorChecked_stainlessSteel(false);
      setWatchNumbersColorChecked_black(false);
      setWatchNumbersColorChecked_white(false);
      setWatchNumbersColorChecked_red(false);
      setWatchNumbersColorChecked_blue(false);
      setWatchNumbersColorChecked_green(false);
      setWatchNumbersColorChecked_yellow(false);
      setWatchNumbersColorChecked_orange(false);
      setWatchNumbersColorChecked_purple(false);
      setWatchNumbersColorChecked_pink(false);
      setWatchNumbersColorChecked_brown(false);
      setWatchNumbersColorChecked_gray(false);
      setWatchNumbersColorChecked_olive(false);
      setWatchNumbersColorChecked_cyan(false);
      setWatchNumbersColorChecked_navy(false);
      setWatchNumbersColorChecked_beige(false);
      setWatchNumbersColorChecked_other(false);
    }
  }, [watchNumbersColorFilter]);

  // useEffect to update the state based on watch outside color filter
  useEffect(() => {
    if (watchOutsideColorFilter) {
      setWatchOutsideColorChecked_all(false);
      setWatchOutsideColorChecked_stainlessSteel(
        watchOutsideColorFilter.includes("ستانلس ستيل")
      );
      setWatchOutsideColorChecked_black(
        watchOutsideColorFilter.includes("أسود")
      );
      setWatchOutsideColorChecked_white(
        watchOutsideColorFilter.includes("أبيض")
      );
      setWatchOutsideColorChecked_red(watchOutsideColorFilter.includes("أحمر"));
      setWatchOutsideColorChecked_blue(
        watchOutsideColorFilter.includes("أزرق")
      );
      setWatchOutsideColorChecked_green(
        watchOutsideColorFilter.includes("أخضر")
      );
      setWatchOutsideColorChecked_yellow(
        watchOutsideColorFilter.includes("أصفر")
      );
      setWatchOutsideColorChecked_orange(
        watchOutsideColorFilter.includes("برتقالي")
      );
      setWatchOutsideColorChecked_purple(
        watchOutsideColorFilter.includes("أرجواني")
      );
      setWatchOutsideColorChecked_pink(
        watchOutsideColorFilter.includes("وردي")
      );
      setWatchOutsideColorChecked_brown(
        watchOutsideColorFilter.includes("بني")
      );
      setWatchOutsideColorChecked_gray(
        watchOutsideColorFilter.includes("رمادي")
      );
      setWatchOutsideColorChecked_olive(
        watchOutsideColorFilter.includes("زيتي")
      );
      setWatchOutsideColorChecked_cyan(
        watchOutsideColorFilter.includes("سماوي")
      );
      setWatchOutsideColorChecked_navy(
        watchOutsideColorFilter.includes("كحلي")
      );
      setWatchOutsideColorChecked_beige(
        watchOutsideColorFilter.includes("بيج")
      );
      setWatchOutsideColorChecked_other(
        watchOutsideColorFilter.includes("أخرى")
      );
    } else {
      setWatchOutsideColorChecked_all(true);
      setWatchOutsideColorChecked_stainlessSteel(false);
      setWatchOutsideColorChecked_black(false);
      setWatchOutsideColorChecked_white(false);
      setWatchOutsideColorChecked_red(false);
      setWatchOutsideColorChecked_blue(false);
      setWatchOutsideColorChecked_green(false);
      setWatchOutsideColorChecked_yellow(false);
      setWatchOutsideColorChecked_orange(false);
      setWatchOutsideColorChecked_purple(false);
      setWatchOutsideColorChecked_pink(false);
      setWatchOutsideColorChecked_brown(false);
      setWatchOutsideColorChecked_gray(false);
      setWatchOutsideColorChecked_olive(false);
      setWatchOutsideColorChecked_cyan(false);
      setWatchOutsideColorChecked_navy(false);
      setWatchOutsideColorChecked_beige(false);
      setWatchOutsideColorChecked_other(false);
    }
  }, [watchOutsideColorFilter]);

  // useEffect to update the state based on wallet department filter from URL
  useEffect(() => {
    if (walletDepartmentFilter) {
      setWalletDepartmentChecked_all(false);
      setWalletDepartmentChecked_men(walletDepartmentFilter.includes("رجالي"));
      setWalletDepartmentChecked_women(
        walletDepartmentFilter.includes("نسائي")
      );
      setWalletDepartmentChecked_unisex(
        walletDepartmentFilter.includes("للجنسين")
      );
    } else {
      setWalletDepartmentChecked_all(true);
      setWalletDepartmentChecked_men(false);
      setWalletDepartmentChecked_women(false);
      setWalletDepartmentChecked_unisex(false);
    }
  }, [walletDepartmentFilter]);

  // watch departmenf filter useeffect
  useEffect(() => {
    if (watchDepartmentFilter) {
      setWatchDepartmentChecked_all(false);
      setWatchDepartmentChecked_men(watchDepartmentFilter.includes("رجالي"));
      setWatchDepartmentChecked_women(watchDepartmentFilter.includes("نسائي"));
      setWatchDepartmentChecked_unisex(
        watchDepartmentFilter.includes("للجنسين")
      );
    } else {
      setWatchDepartmentChecked_all(true);
      setWatchDepartmentChecked_men(false);
      setWatchDepartmentChecked_women(false);
      setWatchDepartmentChecked_unisex(false);
    }
  }, [watchDepartmentFilter]);

  // company filter use effect
  useEffect(() => {
    if (companyFilter) {
      setCompanyChecked_all(false);
      setCompanyChecked_canon(companyFilter.includes("Canon"));
      setCompanyChecked_sony(companyFilter.includes("Sony"));
      setCompanyChecked_nikon(companyFilter.includes("Nikon"));
      setCompanyChecked_rolex(companyFilter.includes("Rolex"));
      setCompanyChecked_omega(companyFilter.includes("Omega"));
      setCompanyChecked_cartier(companyFilter.includes("Cartier"));
      setCompanyChecked_versace(companyFilter.includes("Versace"));
      setCompanyChecked_montBlanc(companyFilter.includes("Montblanc"));
      setCompanyChecked_aigner(companyFilter.includes("Aigner"));
      setCompanyChecked_rado(companyFilter.includes("Rado"));
      setCompanyChecked_givenchy(companyFilter.includes("Givenchy"));
      setCompanyChecked_chopard(companyFilter.includes("Chopard"));
      setCompanyChecked_tissot(companyFilter.includes("Tissot"));
      setCompanyChecked_patikPhillipe(companyFilter.includes("Patek Philippe"));
      setCompanyChecked_armaniExchange(
        companyFilter.includes("Armani Exchange")
      );
      setCompanyChecked_gShock(companyFilter.includes("G-Shock"));
      setCompanyChecked_apple(companyFilter.includes("Apple"));
      setCompanyChecked_huawei(companyFilter.includes("Huawei"));
      setCompanyChecked_samsung(companyFilter.includes("Samsung"));
      setCompanyChecked_xiaomi(companyFilter.includes("Xiaomi"));
    } else {
      setCompanyChecked_all(true);
      setCompanyChecked_canon(false);
      setCompanyChecked_sony(false);
      setCompanyChecked_nikon(false);
      setCompanyChecked_rolex(false);
      setCompanyChecked_omega(false);
      setCompanyChecked_cartier(false);
      setCompanyChecked_versace(false);
      setCompanyChecked_montBlanc(false);
      setCompanyChecked_aigner(false);
      setCompanyChecked_rado(false);
      setCompanyChecked_givenchy(false);
      setCompanyChecked_chopard(false);
      setCompanyChecked_tissot(false);
      setCompanyChecked_patikPhillipe(false);
      setCompanyChecked_armaniExchange(false);
      setCompanyChecked_gShock(false);
      setCompanyChecked_apple(false);
      setCompanyChecked_huawei(false);
      setCompanyChecked_samsung(false);
      setCompanyChecked_xiaomi(false);
    }
  }, [companyFilter]);

  // Update state based on the shipping method filter from URL
  useEffect(() => {
    if (shippingMethodsFilter) {
      setShippingMethodChecked_all(false);
      setShippingMethodChecked_shipping(shippingMethodsFilter?.includes("شحن"));
      setShippingMethodChecked_delivery(
        shippingMethodsFilter?.includes("تسليم")
      );
    } else {
      setShippingMethodChecked_all(true);
      setShippingMethodChecked_shipping(false);
      setShippingMethodChecked_delivery(false);
    }
  }, [shippingMethodsFilter]);

  // Update state based on the post type filter from URL
  useEffect(() => {
    if (postTypeFilter) {
      setProductPostTypeChecked_all(false);
      setProductPostTypeChecked_buyNow(postTypeFilter.includes("شراء فوري"));
      setProductPostTypeChecked_auction(postTypeFilter.includes("مزاد"));
      setProductPostTypeChecked_negotiable(
        postTypeFilter.includes("قابل للتفاوض")
      );
    } else {
      setProductPostTypeChecked_all(true);
      setProductPostTypeChecked_buyNow(false);
      setProductPostTypeChecked_auction(false);
      setProductPostTypeChecked_negotiable(false);
    }
  }, [postTypeFilter]);

  // condition use effect
  useEffect(() => {
    if (
      !productConditionChecked_new &&
      !productConditionChecked_used &&
      !productConditionChecked_excellent &&
      !productConditionChecked_veryGood &&
      !productConditionChecked_good &&
      !productConditionChecked_acceptable
    ) {
      setProductConditionChecked_all(true);
      setProductConditionFilters(["الكل"]);
    } else {
      setProductConditionChecked_all(false);
      const newFilters = [];
      if (productConditionChecked_new) newFilters.push("جديد");
      if (productConditionChecked_used) newFilters.push("مستعمل");
      if (productConditionChecked_excellent) newFilters.push("ممتازة");
      if (productConditionChecked_veryGood) newFilters.push("جيدة جدا");
      if (productConditionChecked_good) newFilters.push("جيدة");
      if (productConditionChecked_acceptable) newFilters.push("مقبولة");
      setProductConditionFilters(newFilters);
    }
  }, [
    productConditionChecked_new,
    productConditionChecked_used,
    productConditionChecked_excellent,
    productConditionChecked_veryGood,
    productConditionChecked_good,
    productConditionChecked_acceptable,
  ]);

  // watchHasOriginalPackaging use effect
  useEffect(() => {
    if (
      !watchHasOriginalPackagingChecked_yes &&
      !watchHasOriginalPackagingChecked_no
    ) {
      setWatchHasOriginalPackagingChecked_all(true);
      setWatchHasOriginalPackagingFilters(["الكل"]);
    }
  }, [
    watchHasOriginalPackagingChecked_yes,
    watchHasOriginalPackagingChecked_no,
  ]);

  // postType use effect
  useEffect(() => {
    if (
      !productPostTypeChecked_buyNow &&
      !productPostTypeChecked_auction &&
      !productPostTypeChecked_negotiable
    ) {
      setProductPostTypeChecked_all(true);
      setProductPostTypeFilters(["الكل"]);
    }
  }, [
    productPostTypeChecked_buyNow,
    productPostTypeChecked_auction,
    productPostTypeChecked_negotiable,
  ]);

  // watchNumbersLanguage use effect
  useEffect(() => {
    if (
      !watchNumbersLanguageChecked_arabic &&
      !watchNumbersLanguageChecked_english &&
      !watchNumbersLanguageChecked_roman &&
      !watchNumbersLanguageChecked_none
    ) {
      setWatchNumbersLanguageChecked_all(true);
      setWatchDepartmentFilters(["الكل"]);
    }
  }, [
    watchNumbersLanguageChecked_arabic,
    watchNumbersLanguageChecked_english,
    watchNumbersLanguageChecked_roman,
    watchNumbersLanguageChecked_none,
  ]);

  // watchIsWaterResistant use effect
  useEffect(() => {
    if (!watchIsWaterResistantChecked_yes && !watchIsWaterResistantChecked_no) {
      setWatchIsWaterResistantChecked_all(true);
      setWatchIsWaterResistantFilters(["الكل"]);
    }
  }, [watchIsWaterResistantChecked_yes, watchIsWaterResistantChecked_no]);
  // watch band material use effect
  useEffect(() => {
    if (
      !watchBandMaterialChecked_stainlessSteel &&
      !watchBandMaterialChecked_leather &&
      !watchBandMaterialChecked_rubber
    ) {
      setWatchBandMaterialChecked_all(true);
      setWatchBandMaterialFilters(["الكل"]);
    }
  }, [
    watchBandMaterialChecked_stainlessSteel,
    watchBandMaterialChecked_leather,
    watchBandMaterialChecked_rubber,
  ]);

  // watch dial shape use effect
  useEffect(() => {
    if (
      !watchDialShapeChecked_round &&
      !watchDialShapeChecked_square &&
      !watchDialShapeChecked_rectangle &&
      !watchDialShapeChecked_oval &&
      !watchDialShapeChecked_pentagon &&
      !watchDialShapeChecked_hexagon
    ) {
      setWatchDialShapeChecked_all(true);
      setWatchDialShapeFilters(["الكل"]);
    }
  }, [
    watchDialShapeChecked_round,
    watchDialShapeChecked_square,
    watchDialShapeChecked_rectangle,
    watchDialShapeChecked_oval,
    watchDialShapeChecked_pentagon,
    watchDialShapeChecked_hexagon,
  ]);

  // watch department use effect
  useEffect(() => {
    if (
      !watchDepartmentChecked_men &&
      !watchDepartmentChecked_women &&
      !watchDepartmentChecked_unisex
    ) {
      setWatchDepartmentChecked_all(true);
      setWatchDepartmentFilters(["الكل"]);
    }
  }, [
    watchDepartmentChecked_men,
    watchDepartmentChecked_women,
    watchDepartmentChecked_unisex,
  ]);

  // walletDepartment useEffect
  useEffect(() => {
    if (
      !walletDepartmentChecked_men &&
      !walletDepartmentChecked_women &&
      !walletDepartmentChecked_unisex
    ) {
      setWalletDepartmentChecked_all(true);
      setWalletDepartmentFilters(["الكل"]);
    }
  }, [
    walletDepartmentChecked_men,
    walletDepartmentChecked_women,
    walletDepartmentChecked_unisex,
  ]);

  // company use effect
  useEffect(() => {
    if (
      !companyChecked_canon &&
      !companyChecked_sony &&
      !companyChecked_nikon &&
      !companyChecked_rolex &&
      !companyChecked_omega &&
      !companyChecked_cartier &&
      !companyChecked_versace &&
      !companyChecked_montBlanc &&
      !companyChecked_aigner &&
      !companyChecked_rado &&
      !companyChecked_givenchy &&
      !companyChecked_chopard &&
      !companyChecked_tissot &&
      !companyChecked_patikPhillipe &&
      !companyChecked_armaniExchange &&
      !companyChecked_gShock &&
      !companyChecked_apple &&
      !companyChecked_huawei &&
      !companyChecked_samsung &&
      !companyChecked_xiaomi &&
      !companyChecked_other
    ) {
      setCompanyChecked_all(true);
      setProductCompanyFilters(["الكل"]);
    }
  }, [
    companyChecked_canon,
    companyChecked_nikon,
    companyChecked_sony,
    companyChecked_rolex,
    companyChecked_omega,
    companyChecked_cartier,
    companyChecked_versace,
    companyChecked_montBlanc,
    companyChecked_aigner,
    companyChecked_rado,
    companyChecked_givenchy,
    companyChecked_chopard,
    companyChecked_tissot,
    companyChecked_patikPhillipe,
    companyChecked_armaniExchange,
    companyChecked_gShock,
    companyChecked_apple,
    companyChecked_huawei,
    companyChecked_samsung,
    companyChecked_xiaomi,
    companyChecked_other,
  ]);

  // watchOutsideColor use effect
  useEffect(() => {
    if (
      !watchOutsideColorChecked_stainlessSteel &&
      !watchOutsideColorChecked_black &&
      !watchOutsideColorChecked_white &&
      !watchOutsideColorChecked_red &&
      !watchOutsideColorChecked_blue &&
      !watchOutsideColorChecked_green &&
      !watchOutsideColorChecked_yellow &&
      !watchOutsideColorChecked_orange &&
      !watchOutsideColorChecked_purple &&
      !watchOutsideColorChecked_pink &&
      !watchOutsideColorChecked_brown &&
      !watchOutsideColorChecked_gray &&
      !watchOutsideColorChecked_olive &&
      !watchOutsideColorChecked_cyan &&
      !watchOutsideColorChecked_navy &&
      !watchOutsideColorChecked_beige &&
      !watchOutsideColorChecked_other
    ) {
      setWatchOutsideColorChecked_all(true);
      setWatchOutsideColorFilters(["الكل"]);
    }
  }, [
    watchOutsideColorChecked_stainlessSteel,
    watchOutsideColorChecked_black,
    watchOutsideColorChecked_white,
    watchOutsideColorChecked_red,
    watchOutsideColorChecked_blue,
    watchOutsideColorChecked_green,
    watchOutsideColorChecked_yellow,
    watchOutsideColorChecked_orange,
    watchOutsideColorChecked_purple,
    watchOutsideColorChecked_pink,
    watchOutsideColorChecked_brown,
    watchOutsideColorChecked_gray,
    watchOutsideColorChecked_olive,
    watchOutsideColorChecked_cyan,
    watchOutsideColorChecked_navy,
    watchOutsideColorChecked_beige,
    watchOutsideColorChecked_other,
  ]);

  // watchBandColor use effect
  useEffect(() => {
    if (
      !watchBandColorChecked_stainlessSteel &&
      !watchBandColorChecked_black &&
      !watchBandColorChecked_white &&
      !watchBandColorChecked_red &&
      !watchBandColorChecked_blue &&
      !watchBandColorChecked_green &&
      !watchBandColorChecked_yellow &&
      !watchBandColorChecked_orange &&
      !watchBandColorChecked_purple &&
      !watchBandColorChecked_pink &&
      !watchBandColorChecked_brown &&
      !watchBandColorChecked_gray &&
      !watchBandColorChecked_olive &&
      !watchBandColorChecked_cyan &&
      !watchBandColorChecked_navy &&
      !watchBandColorChecked_beige &&
      !watchBandColorChecked_other
    ) {
      setWatchBandColorChecked_all(true);
      setWatchBandColorFilters(["الكل"]);
    }
  }, [
    watchBandColorChecked_stainlessSteel,
    watchBandColorChecked_black,
    watchBandColorChecked_white,
    watchBandColorChecked_red,
    watchBandColorChecked_blue,
    watchBandColorChecked_green,
    watchBandColorChecked_yellow,
    watchBandColorChecked_orange,
    watchBandColorChecked_purple,
    watchBandColorChecked_pink,
    watchBandColorChecked_brown,
    watchBandColorChecked_gray,
    watchBandColorChecked_olive,
    watchBandColorChecked_cyan,
    watchBandColorChecked_navy,
    watchBandColorChecked_beige,
    watchBandColorChecked_other,
  ]);

  // watchNumbersColor use effect
  useEffect(() => {
    if (
      !watchNumbersColorChecked_stainlessSteel &&
      !watchNumbersColorChecked_black &&
      !watchNumbersColorChecked_white &&
      !watchNumbersColorChecked_red &&
      !watchNumbersColorChecked_blue &&
      !watchNumbersColorChecked_green &&
      !watchNumbersColorChecked_yellow &&
      !watchNumbersColorChecked_orange &&
      !watchNumbersColorChecked_purple &&
      !watchNumbersColorChecked_pink &&
      !watchNumbersColorChecked_brown &&
      !watchNumbersColorChecked_gray &&
      !watchNumbersColorChecked_olive &&
      !watchNumbersColorChecked_cyan &&
      !watchNumbersColorChecked_navy &&
      !watchNumbersColorChecked_beige &&
      !watchNumbersColorChecked_other
    ) {
      setWatchNumbersColorChecked_all(true);
      setWatchNumbersColorFilters(["الكل"]);
    }
  }, [
    watchNumbersColorChecked_stainlessSteel,
    watchNumbersColorChecked_black,
    watchNumbersColorChecked_white,
    watchNumbersColorChecked_red,
    watchNumbersColorChecked_blue,
    watchNumbersColorChecked_green,
    watchNumbersColorChecked_yellow,
    watchNumbersColorChecked_orange,
    watchNumbersColorChecked_purple,
    watchNumbersColorChecked_pink,
    watchNumbersColorChecked_brown,
    watchNumbersColorChecked_gray,
    watchNumbersColorChecked_olive,
    watchNumbersColorChecked_cyan,
    watchNumbersColorChecked_navy,
    watchNumbersColorChecked_beige,
    watchNumbersColorChecked_other,
  ]);

  // watchHandlesColor use effect
  useEffect(() => {
    if (
      !watchHandlesColorChecked_stainlessSteel &&
      !watchHandlesColorChecked_black &&
      !watchHandlesColorChecked_white &&
      !watchHandlesColorChecked_red &&
      !watchHandlesColorChecked_blue &&
      !watchHandlesColorChecked_green &&
      !watchHandlesColorChecked_yellow &&
      !watchHandlesColorChecked_orange &&
      !watchHandlesColorChecked_purple &&
      !watchHandlesColorChecked_pink &&
      !watchHandlesColorChecked_brown &&
      !watchHandlesColorChecked_gray &&
      !watchHandlesColorChecked_olive &&
      !watchHandlesColorChecked_cyan &&
      !watchHandlesColorChecked_navy &&
      !watchHandlesColorChecked_beige &&
      !watchHandlesColorChecked_other
    ) {
      setWatchHandlesColorChecked_all(true);
      setWatchHandlesColorFilters(["الكل"]);
    }
  }, [
    watchHandlesColorChecked_stainlessSteel,
    watchHandlesColorChecked_black,
    watchHandlesColorChecked_white,
    watchHandlesColorChecked_red,
    watchHandlesColorChecked_blue,
    watchHandlesColorChecked_green,
    watchHandlesColorChecked_yellow,
    watchHandlesColorChecked_orange,
    watchHandlesColorChecked_purple,
    watchHandlesColorChecked_pink,
    watchHandlesColorChecked_brown,
    watchHandlesColorChecked_gray,
    watchHandlesColorChecked_olive,
    watchHandlesColorChecked_cyan,
    watchHandlesColorChecked_navy,
    watchHandlesColorChecked_beige,
    watchHandlesColorChecked_other,
  ]);

  // watchInsideColor use effect
  useEffect(() => {
    if (
      !watchInsideColorChecked_stainlessSteel &&
      !watchInsideColorChecked_black &&
      !watchInsideColorChecked_white &&
      !watchInsideColorChecked_red &&
      !watchInsideColorChecked_blue &&
      !watchInsideColorChecked_green &&
      !watchInsideColorChecked_yellow &&
      !watchInsideColorChecked_orange &&
      !watchInsideColorChecked_purple &&
      !watchInsideColorChecked_pink &&
      !watchInsideColorChecked_brown &&
      !watchInsideColorChecked_gray &&
      !watchInsideColorChecked_olive &&
      !watchInsideColorChecked_cyan &&
      !watchInsideColorChecked_navy &&
      !watchInsideColorChecked_beige &&
      !watchInsideColorChecked_other
    ) {
      setWatchInsideColorChecked_all(true);
      setWatchInsideColorFilters(["الكل"]);
    }
  }, [
    watchInsideColorChecked_stainlessSteel,
    watchInsideColorChecked_black,
    watchInsideColorChecked_white,
    watchInsideColorChecked_red,
    watchInsideColorChecked_blue,
    watchInsideColorChecked_green,
    watchInsideColorChecked_yellow,
    watchInsideColorChecked_orange,
    watchInsideColorChecked_purple,
    watchInsideColorChecked_pink,
    watchInsideColorChecked_brown,
    watchInsideColorChecked_gray,
    watchInsideColorChecked_olive,
    watchInsideColorChecked_cyan,
    watchInsideColorChecked_navy,
    watchInsideColorChecked_beige,
    watchInsideColorChecked_other,
  ]);

  // rosaryKind use effect
  useEffect(() => {
    if (
      !rosaryKindChecked_backlight &&
      !rosaryKindChecked_kahraman &&
      !rosaryKindChecked_fatoran &&
      !rosaryKindChecked_ivory &&
      !rosaryKindChecked_wood &&
      !rosaryKindChecked_stone
    ) {
      setRosaryKindChecked_all(true);
      setRosaryKindFilters(["الكل"]);
    }
  }, [
    rosaryKindChecked_backlight,
    rosaryKindChecked_kahraman,
    rosaryKindChecked_fatoran,
    rosaryKindChecked_ivory,
    rosaryKindChecked_wood,
    rosaryKindChecked_stone,
  ]);

  // rosaryColor use effect
  useEffect(() => {
    if (
      !rosaryColorChecked_black &&
      !rosaryColorChecked_white &&
      !rosaryColorChecked_red &&
      !rosaryColorChecked_blue &&
      !rosaryColorChecked_green &&
      !rosaryColorChecked_yellow &&
      !rosaryColorChecked_orange &&
      !rosaryColorChecked_purple &&
      !rosaryColorChecked_pink &&
      !rosaryColorChecked_brown &&
      !rosaryColorChecked_gray &&
      !rosaryColorChecked_olive &&
      !rosaryColorChecked_cyan &&
      !rosaryColorChecked_navy &&
      !rosaryColorChecked_beige &&
      !rosaryColorChecked_other
    ) {
      setRosaryColorChecked_all(true);
      setRosaryColorFilters(["الكل"]);
    }
  }, [
    rosaryColorChecked_black,
    rosaryColorChecked_white,
    rosaryColorChecked_red,
    rosaryColorChecked_blue,
    rosaryColorChecked_green,
    rosaryColorChecked_yellow,
    rosaryColorChecked_orange,
    rosaryColorChecked_purple,
    rosaryColorChecked_pink,
    rosaryColorChecked_brown,
    rosaryColorChecked_gray,
    rosaryColorChecked_olive,
    rosaryColorChecked_cyan,
    rosaryColorChecked_navy,
    rosaryColorChecked_beige,
    rosaryColorChecked_other,
  ]);

  // Wallet outside color useEffect
  useEffect(() => {
    if (
      !walletOutsideColorChecked_black &&
      !walletOutsideColorChecked_white &&
      !walletOutsideColorChecked_red &&
      !walletOutsideColorChecked_blue &&
      !walletOutsideColorChecked_green &&
      !walletOutsideColorChecked_yellow &&
      !walletOutsideColorChecked_orange &&
      !walletOutsideColorChecked_purple &&
      !walletOutsideColorChecked_pink &&
      !walletOutsideColorChecked_brown &&
      !walletOutsideColorChecked_gray &&
      !walletOutsideColorChecked_olive &&
      !walletOutsideColorChecked_cyan &&
      !walletOutsideColorChecked_navy &&
      !walletOutsideColorChecked_beige &&
      !walletOutsideColorChecked_other
    ) {
      setWalletOutsideColorChecked_all(true);
      setWalletOutsideColorFilters(["الكل"]);
    }
  }, [
    walletOutsideColorChecked_black,
    walletOutsideColorChecked_white,
    walletOutsideColorChecked_red,
    walletOutsideColorChecked_blue,
    walletOutsideColorChecked_green,
    walletOutsideColorChecked_yellow,
    walletOutsideColorChecked_orange,
    walletOutsideColorChecked_purple,
    walletOutsideColorChecked_pink,
    walletOutsideColorChecked_brown,
    walletOutsideColorChecked_gray,
    walletOutsideColorChecked_olive,
    walletOutsideColorChecked_cyan,
    walletOutsideColorChecked_navy,
    walletOutsideColorChecked_beige,
    walletOutsideColorChecked_other,
  ]);

  // purse material useefect
  useEffect(() => {
    if (
      !purseMaterialChecked_leather &&
      !purseMaterialChecked_fabric
      // Add more conditions for other materials if needed
    ) {
      setPurseMaterialChecked_all(true);
      setPurseMaterialFilters(["الكل"]);
    }
  }, [
    purseMaterialChecked_leather,
    purseMaterialChecked_fabric,
    // Add more dependencies for other materials if needed
  ]);

  // purse outside color
  useEffect(() => {
    if (
      !purseOutsideColorChecked_black &&
      !purseOutsideColorChecked_white &&
      !purseOutsideColorChecked_red &&
      !purseOutsideColorChecked_blue &&
      !purseOutsideColorChecked_green &&
      !purseOutsideColorChecked_yellow &&
      !purseOutsideColorChecked_orange &&
      !purseOutsideColorChecked_purple &&
      !purseOutsideColorChecked_pink &&
      !purseOutsideColorChecked_brown &&
      !purseOutsideColorChecked_gray &&
      !purseOutsideColorChecked_olive &&
      !purseOutsideColorChecked_cyan &&
      !purseOutsideColorChecked_navy &&
      !purseOutsideColorChecked_beige &&
      !purseOutsideColorChecked_other
    ) {
      setPurseOutsideColorChecked_all(true);
      setPurseOutsideColorFilters(["الكل"]);
    } else {
      setPurseOutsideColorChecked_all(false);
    }
  }, [
    purseOutsideColorChecked_black,
    purseOutsideColorChecked_white,
    purseOutsideColorChecked_red,
    purseOutsideColorChecked_blue,
    purseOutsideColorChecked_green,
    purseOutsideColorChecked_yellow,
    purseOutsideColorChecked_orange,
    purseOutsideColorChecked_purple,
    purseOutsideColorChecked_pink,
    purseOutsideColorChecked_brown,
    purseOutsideColorChecked_gray,
    purseOutsideColorChecked_olive,
    purseOutsideColorChecked_cyan,
    purseOutsideColorChecked_navy,
    purseOutsideColorChecked_beige,
    purseOutsideColorChecked_other,
    setPurseOutsideColorChecked_all,
    setPurseOutsideColorFilters,
  ]);

  // purse inside color
  useEffect(() => {
    if (
      !purseInsideColorChecked_black &&
      !purseInsideColorChecked_white &&
      !purseInsideColorChecked_red &&
      !purseInsideColorChecked_blue &&
      !purseInsideColorChecked_green &&
      !purseInsideColorChecked_yellow &&
      !purseInsideColorChecked_orange &&
      !purseInsideColorChecked_purple &&
      !purseInsideColorChecked_pink &&
      !purseInsideColorChecked_brown &&
      !purseInsideColorChecked_gray &&
      !purseInsideColorChecked_olive &&
      !purseInsideColorChecked_cyan &&
      !purseInsideColorChecked_navy &&
      !purseInsideColorChecked_beige &&
      !purseInsideColorChecked_other
    ) {
      setPurseInsideColorChecked_all(true);
      setPurseInsideColorFilters(["الكل"]);
    } else {
      setPurseInsideColorChecked_all(false);
    }
  }, [
    purseInsideColorChecked_black,
    purseInsideColorChecked_white,
    purseInsideColorChecked_red,
    purseInsideColorChecked_blue,
    purseInsideColorChecked_green,
    purseInsideColorChecked_yellow,
    purseInsideColorChecked_orange,
    purseInsideColorChecked_purple,
    purseInsideColorChecked_pink,
    purseInsideColorChecked_brown,
    purseInsideColorChecked_gray,
    purseInsideColorChecked_olive,
    purseInsideColorChecked_cyan,
    purseInsideColorChecked_navy,
    purseInsideColorChecked_beige,
    purseInsideColorChecked_other,
    setPurseInsideColorChecked_all,
    setPurseInsideColorFilters,
  ]);

  // car plate transfer fee on useeffect
  useEffect(() => {
    if (
      !carPlateTransferFeeOnChecked_seller &&
      !carPlateTransferFeeOnChecked_buyer
    ) {
      setCarPlateTransferFeeOnChecked_all(true);
      setCarPlateTransferFeeOnFilters(["الكل"]);
    }
  }, [carPlateTransferFeeOnChecked_seller, carPlateTransferFeeOnChecked_buyer]);

  // useEffect to handle "الكل" checkbox behavior carplate
  useEffect(() => {
    if (
      !carPlateKindChecked_privateCar &&
      !carPlateKindChecked_motorCycle &&
      !carPlateKindChecked_transportation &&
      !carPlateKindChecked_commerce
    ) {
      setCarPlateKindChecked_all(true);
      setCarPlateKindFilters(["الكل"]);
    }
  }, [
    carPlateKindChecked_privateCar,
    carPlateKindChecked_motorCycle,
    carPlateKindChecked_transportation,
    carPlateKindChecked_commerce,
  ]);

  // car plate transfer time time useeffect

  useEffect(() => {
    if (
      !carPlateTransferTimeChecked_24to48Hours &&
      !carPlateTransferTimeChecked_twoToFiveDays
    ) {
      setCarPlateTransferTimeChecked_all(true);
      setCarPlateTransferTimeFilters(["الكل"]);
    }
  }, [
    carPlateTransferTimeChecked_24to48Hours,
    carPlateTransferTimeChecked_twoToFiveDays,
  ]);

  // useEffect for phoneNumberCourier filters
  useEffect(() => {
    if (
      !phoneNumberCourierChecked_stc &&
      !phoneNumberCourierChecked_mobily &&
      !phoneNumberCourierChecked_zain
    ) {
      setPhoneNumberCourierChecked_all(true);
      setPhoneNumberCourierFilters(["الكل"]);
    }
  }, [
    phoneNumberCourierChecked_stc,
    phoneNumberCourierChecked_mobily,
    phoneNumberCourierChecked_zain,
  ]);

  // useEffect for shippingMethod filters
  useEffect(() => {
    if (!shippingMethodChecked_shipping && !shippingMethodChecked_delivery) {
      setShippingMethodChecked_all(true);
      setShippingMethodFilters(["الكل"]);
    }
  }, [shippingMethodChecked_shipping, shippingMethodChecked_delivery]);

  // Handler for all shippingMethod filters
  const handleShippingMethodCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      shippingMethodFilters.length === 1 &&
      shippingMethodFilters.includes("الكل")
    ) {
      return;
    }

    setShippingMethodChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setShippingMethodFilters(["الكل"]);
      setShippingMethodChecked_shipping(false);
      setShippingMethodChecked_delivery(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (shippingMethodFilters.length === 0) {
        setShippingMethodChecked_all(true);
      }
    }
  };

  // Handler for individual shippingMethod filters
  const handleShippingMethodCheckboxChange_others = (
    method,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setShippingMethodFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          method,
        ];
        setShippingMethodChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== method
        );
        if (filteredFilters.length === 0) {
          setShippingMethodChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // Handler for all phoneNumberCourier filters
  const handlePhoneNumberCourierCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      phoneNumberCourierFilters.length === 1 &&
      phoneNumberCourierFilters.includes("الكل")
    ) {
      return;
    }

    setPhoneNumberCourierChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setPhoneNumberCourierFilters(["الكل"]);
      setPhoneNumberCourierChecked_stc(false);
      setPhoneNumberCourierChecked_mobily(false);
      setPhoneNumberCourierChecked_zain(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (phoneNumberCourierFilters.length === 0) {
        setPhoneNumberCourierChecked_all(true);
      }
    }
  };

  // Handler for individual phoneNumberCourier filters
  const handlePhoneNumberCourierCheckboxChange_others = (
    provider,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setPhoneNumberCourierFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          provider,
        ];
        setPhoneNumberCourierChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== provider
        );
        if (filteredFilters.length === 0) {
          setPhoneNumberCourierChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // car plate transfer time all
  const handleCarPlateTransferTimeCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      carPlateTransferTimeFilters.length === 1 &&
      carPlateTransferTimeFilters.includes("الكل")
    ) {
      return;
    }

    setCarPlateTransferTimeChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setCarPlateTransferTimeFilters(["الكل"]);
      setCarPlateTransferTimeChecked_24to48Hours(false);
      setCarPlateTransferTimeChecked_twoToFiveDays(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (carPlateTransferTimeFilters.length === 0) {
        setCarPlateTransferTimeChecked_all(true);
      }
    }
  };

  // car plate transfer time others
  const handleCarPlateTransferTimeCheckboxChange_others = (
    timePeriod,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setCarPlateTransferTimeFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          timePeriod,
        ];
        setCarPlateTransferTimeChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== timePeriod
        );
        if (filteredFilters.length === 0) {
          setCarPlateTransferTimeChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // car plate transfer fee on all
  const handleCarPlateTransferFeeOnCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      carPlateTransferFeeOnFilters.length === 1 &&
      carPlateTransferFeeOnFilters.includes("الكل")
    ) {
      return;
    }

    setCarPlateTransferFeeOnChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setCarPlateTransferFeeOnFilters(["الكل"]);
      setCarPlateTransferFeeOnChecked_seller(false);
      setCarPlateTransferFeeOnChecked_buyer(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (carPlateTransferFeeOnFilters.length === 0) {
        setCarPlateTransferFeeOnChecked_all(true);
      }
    }
  };

  // car plate transfer fee on others
  const handleCarPlateTransferFeeOnCheckboxChange_others = (
    role,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setCarPlateTransferFeeOnFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          role,
        ];
        setCarPlateTransferFeeOnChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter((filter) => filter !== role);
        if (filteredFilters.length === 0) {
          setCarPlateTransferFeeOnChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // Handler for carplate "الكل" checkbox
  const handleCarPlateKindCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      carPlateKindFilters.length === 1 &&
      carPlateKindFilters.includes("الكل")
    ) {
      return;
    }

    setCarPlateKindChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setCarPlateKindFilters(["الكل"]);
      setCarPlateKindChecked_privateCar(false);
      setCarPlateKindChecked_motorCycle(false);
      setCarPlateKindChecked_transportation(false);
      setCarPlateKindChecked_commerce(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (carPlateKindFilters.length === 0) {
        setCarPlateKindChecked_all(true);
      }
    }
  };

  // Handler for individual carplate checkboxes
  const handleCarPlateKindCheckboxChange_others = (
    kind,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setCarPlateKindFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          kind,
        ];
        setCarPlateKindChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter((filter) => filter !== kind);
        if (filteredFilters.length === 0) {
          setCarPlateKindChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  const handlePurseOutsideColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      purseOutsideColorFilters.length === 1 &&
      purseOutsideColorFilters.includes("الكل")
    ) {
      return;
    }

    setPurseOutsideColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setPurseOutsideColorFilters(["الكل"]);
      setPurseOutsideColorChecked_black(false);
      setPurseOutsideColorChecked_white(false);
      setPurseOutsideColorChecked_red(false);
      setPurseOutsideColorChecked_blue(false);
      setPurseOutsideColorChecked_green(false);
      setPurseOutsideColorChecked_yellow(false);
      setPurseOutsideColorChecked_orange(false);
      setPurseOutsideColorChecked_purple(false);
      setPurseOutsideColorChecked_pink(false);
      setPurseOutsideColorChecked_brown(false);
      setPurseOutsideColorChecked_gray(false);
      setPurseOutsideColorChecked_olive(false);
      setPurseOutsideColorChecked_cyan(false);
      setPurseOutsideColorChecked_navy(false);
      setPurseOutsideColorChecked_beige(false);
      setPurseOutsideColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (purseOutsideColorFilters.length === 0) {
        setPurseOutsideColorChecked_all(true);
      }
    }
  };

  // Similar handler for purseInsideColor
  const handlePurseInsideColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      purseInsideColorFilters.length === 1 &&
      purseInsideColorFilters.includes("الكل")
    ) {
      return;
    }

    setPurseInsideColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setPurseInsideColorFilters(["الكل"]);
      setPurseInsideColorChecked_black(false);
      setPurseInsideColorChecked_white(false);
      setPurseInsideColorChecked_red(false);
      setPurseInsideColorChecked_blue(false);
      setPurseInsideColorChecked_green(false);
      setPurseInsideColorChecked_yellow(false);
      setPurseInsideColorChecked_orange(false);
      setPurseInsideColorChecked_purple(false);
      setPurseInsideColorChecked_pink(false);
      setPurseInsideColorChecked_brown(false);
      setPurseInsideColorChecked_gray(false);
      setPurseInsideColorChecked_olive(false);
      setPurseInsideColorChecked_cyan(false);
      setPurseInsideColorChecked_navy(false);
      setPurseInsideColorChecked_beige(false);
      setPurseInsideColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (purseInsideColorFilters.length === 0) {
        setPurseInsideColorChecked_all(true);
      }
    }
  };

  const handlePurseOutsideColorCheckboxChange_others = (
    color,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setPurseOutsideColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          color,
        ];
        setPurseOutsideColorChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== color
        );
        if (filteredFilters.length === 0) {
          setPurseOutsideColorChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // Similar handler for purseInsideColor
  const handlePurseInsideColorCheckboxChange_others = (
    color,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setPurseInsideColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          color,
        ];
        setPurseInsideColorChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== color
        );
        if (filteredFilters.length === 0) {
          setPurseInsideColorChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // purse material all
  const handlePurseMaterialCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      purseMaterialFilters.length === 1 &&
      purseMaterialFilters.includes("الكل")
    ) {
      return;
    }

    setPurseMaterialChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setPurseMaterialFilters(["الكل"]);
      setPurseMaterialChecked_leather(false);
      setPurseMaterialChecked_fabric(false);
      // Add more set functions for other materials if needed
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (purseMaterialFilters.length === 0) {
        setPurseMaterialChecked_all(true);
      }
    }
  };
  // purse material others

  const handlePurseMaterialCheckboxChange_others = (
    material,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setPurseMaterialFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          material,
        ];
        setPurseMaterialChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== material
        );
        if (filteredFilters.length === 0) {
          setPurseMaterialChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // Wallet outside color all filter
  const handleWalletOutsideColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      walletOutsideColorFilters.length === 1 &&
      walletOutsideColorFilters.includes("الكل")
    ) {
      return;
    }

    setWalletOutsideColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      setWalletOutsideColorFilters(["الكل"]);
      setWalletOutsideColorChecked_black(false);
      setWalletOutsideColorChecked_white(false);
      setWalletOutsideColorChecked_red(false);
      setWalletOutsideColorChecked_blue(false);
      setWalletOutsideColorChecked_green(false);
      setWalletOutsideColorChecked_yellow(false);
      setWalletOutsideColorChecked_orange(false);
      setWalletOutsideColorChecked_purple(false);
      setWalletOutsideColorChecked_pink(false);
      setWalletOutsideColorChecked_brown(false);
      setWalletOutsideColorChecked_gray(false);
      setWalletOutsideColorChecked_olive(false);
      setWalletOutsideColorChecked_cyan(false);
      setWalletOutsideColorChecked_navy(false);
      setWalletOutsideColorChecked_beige(false);
      setWalletOutsideColorChecked_other(false);
    } else {
      if (
        !walletOutsideColorChecked_black &&
        !walletOutsideColorChecked_white &&
        !walletOutsideColorChecked_red &&
        !walletOutsideColorChecked_blue &&
        !walletOutsideColorChecked_green &&
        !walletOutsideColorChecked_yellow &&
        !walletOutsideColorChecked_orange &&
        !walletOutsideColorChecked_purple &&
        !walletOutsideColorChecked_pink &&
        !walletOutsideColorChecked_brown &&
        !walletOutsideColorChecked_gray &&
        !walletOutsideColorChecked_olive &&
        !walletOutsideColorChecked_cyan &&
        !walletOutsideColorChecked_navy &&
        !walletOutsideColorChecked_beige &&
        !walletOutsideColorChecked_other
      ) {
        setWalletOutsideColorChecked_all(true);
      }
    }
  };

  // Individual wallet outside color filter
  const handleWalletOutsideColorCheckboxChange_others = (
    color,
    newCheckedStatus,
    setCheckbox
  ) => {
    setCheckbox(newCheckedStatus);

    setWalletOutsideColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        const newFilters = [
          ...prevFilters.filter((filter) => filter !== "الكل"),
          color,
        ];
        setWalletOutsideColorChecked_all(false);
        return newFilters;
      } else {
        const filteredFilters = prevFilters.filter(
          (filter) => filter !== color
        );
        if (filteredFilters.length === 0) {
          setWalletOutsideColorChecked_all(true);
          return ["الكل"];
        }
        return filteredFilters;
      }
    });
  };

  // rosaryColor all
  const handleRosaryColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      rosaryColorFilters.length === 1 &&
      rosaryColorFilters.includes("الكل")
    ) {
      return;
    }

    setRosaryColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setRosaryColorFilters(["الكل"]);
      setRosaryColorChecked_black(false);
      setRosaryColorChecked_white(false);
      setRosaryColorChecked_red(false);
      setRosaryColorChecked_blue(false);
      setRosaryColorChecked_green(false);
      setRosaryColorChecked_yellow(false);
      setRosaryColorChecked_orange(false);
      setRosaryColorChecked_purple(false);
      setRosaryColorChecked_pink(false);
      setRosaryColorChecked_brown(false);
      setRosaryColorChecked_gray(false);
      setRosaryColorChecked_olive(false);
      setRosaryColorChecked_cyan(false);
      setRosaryColorChecked_navy(false);
      setRosaryColorChecked_beige(false);
      setRosaryColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (rosaryColorFilters.length === 0) {
        setRosaryColorChecked_all(true);
      }
    }
  };

  // rosaryColor others
  const handleRosaryColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setRosaryColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setRosaryColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setRosaryColorChecked_all(false);
    }
  };

  // rosaryKind all
  const handleRosaryKindCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      rosaryKindFilters.length === 1 &&
      rosaryKindFilters.includes("الكل")
    ) {
      return;
    }

    setRosaryKindChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setRosaryKindFilters(["الكل"]);
      setRosaryKindChecked_backlight(false);
      setRosaryKindChecked_kahraman(false);
      setRosaryKindChecked_fatoran(false);
      setRosaryKindChecked_ivory(false);
      setRosaryKindChecked_wood(false);
      setRosaryKindChecked_stone(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (rosaryKindFilters.length === 0) {
        setRosaryKindChecked_all(true);
      }
    }
  };

  // rosaryKind others
  const handleRosaryKindCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setRosaryKindFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setRosaryKindChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setRosaryKindChecked_all(false);
    }
  };

  const handleProductConditionCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      productConditionFilters.length === 1 &&
      productConditionFilters.includes("الكل")
    ) {
      return;
    }

    setProductConditionChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setProductConditionFilters(["الكل"]);
      setProductConditionChecked_new(false);
      setProductConditionChecked_used(false);
      setProductConditionChecked_excellent(false);
      setProductConditionChecked_veryGood(false);
      setProductConditionChecked_good(false);
      setProductConditionChecked_acceptable(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (productConditionFilters.length === 0) {
        setProductConditionChecked_all(true);
      }
    }
  };

  // watch department all filter
  const handleWatchDepartmentCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchDepartmentFilters.length === 1 &&
      watchDepartmentFilters.includes("الكل")
    ) {
      return;
    }

    setWatchDepartmentChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchDepartmentFilters(["الكل"]);
      setWatchDepartmentChecked_men(false);
      setWatchDepartmentChecked_women(false);
      setWatchDepartmentChecked_unisex(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchDepartmentFilters.length === 0) {
        setWatchDepartmentChecked_all(true);
      }
    }
  };

  // Wallet department all filter
  const handleWalletDepartmentCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      walletDepartmentFilters.length === 1 &&
      walletDepartmentFilters.includes("الكل")
    ) {
      return;
    }

    setWalletDepartmentChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWalletDepartmentFilters(["الكل"]);
      setWalletDepartmentChecked_men(false);
      setWalletDepartmentChecked_women(false);
      setWalletDepartmentChecked_unisex(false);
    } else {
      // If no other filters are active, re-check "الكل"
      if (
        !walletDepartmentChecked_men &&
        !walletDepartmentChecked_women &&
        !walletDepartmentChecked_unisex
      ) {
        setWalletDepartmentChecked_all(true);
      }
    }
  };

  // watch outside color all
  const handleWatchOutsideColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchOutsideColorFilters.length === 1 &&
      watchOutsideColorFilters.includes("الكل")
    ) {
      return;
    }

    setWatchOutsideColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchOutsideColorFilters(["الكل"]);
      setWatchOutsideColorChecked_stainlessSteel(false);
      setWatchOutsideColorChecked_black(false);
      setWatchOutsideColorChecked_white(false);
      setWatchOutsideColorChecked_red(false);
      setWatchOutsideColorChecked_blue(false);
      setWatchOutsideColorChecked_green(false);
      setWatchOutsideColorChecked_yellow(false);
      setWatchOutsideColorChecked_orange(false);
      setWatchOutsideColorChecked_purple(false);
      setWatchOutsideColorChecked_pink(false);
      setWatchOutsideColorChecked_brown(false);
      setWatchOutsideColorChecked_gray(false);
      setWatchOutsideColorChecked_olive(false);
      setWatchOutsideColorChecked_cyan(false);
      setWatchOutsideColorChecked_navy(false);
      setWatchOutsideColorChecked_beige(false);
      setWatchOutsideColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchOutsideColorFilters.length === 0) {
        setWatchOutsideColorChecked_all(true);
      }
    }
  };
  // watch outside color others
  const handleWatchOutsideColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchOutsideColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchOutsideColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchOutsideColorChecked_all(false);
    }
  };

  // watchHasOriginalPackaging all
  const handleWatchHasOriginalPackagingCheckboxChange_all = (
    newCheckedStatus
  ) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchHasOriginalPackagingFilters.length === 1 &&
      watchHasOriginalPackagingFilters.includes("الكل")
    ) {
      return;
    }

    setWatchHasOriginalPackagingChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchHasOriginalPackagingFilters(["الكل"]);
      setWatchHasOriginalPackagingChecked_yes(false);
      setWatchHasOriginalPackagingChecked_no(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchHasOriginalPackagingFilters.length === 0) {
        setWatchHasOriginalPackagingChecked_all(true);
      }
    }
  };

  // watchHasOriginalPackaging others
  const handleWatchHasOriginalPackagingCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchHasOriginalPackagingFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchHasOriginalPackagingChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchHasOriginalPackagingChecked_all(false);
    }
  };

  // watchIsWaterResistant all
  const handleWatchIsWaterResistantCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchIsWaterResistantFilters.length === 1 &&
      watchIsWaterResistantFilters.includes("الكل")
    ) {
      return;
    }

    setWatchIsWaterResistantChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchIsWaterResistantFilters(["الكل"]);
      setWatchIsWaterResistantChecked_yes(false);
      setWatchIsWaterResistantChecked_no(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchIsWaterResistantFilters.length === 0) {
        setWatchIsWaterResistantChecked_all(true);
      }
    }
  };

  // watchIsWaterResistant others
  const handleWatchIsWaterResistantCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchIsWaterResistantFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchIsWaterResistantChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchIsWaterResistantChecked_all(false);
    }
  };

  // watchBandColor all
  const handleWatchBandColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchBandColorFilters.length === 1 &&
      watchBandColorFilters.includes("الكل")
    ) {
      return;
    }

    setWatchBandColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchBandColorFilters(["الكل"]);
      setWatchBandColorChecked_stainlessSteel(false);
      setWatchBandColorChecked_black(false);
      setWatchBandColorChecked_white(false);
      setWatchBandColorChecked_red(false);
      setWatchBandColorChecked_blue(false);
      setWatchBandColorChecked_green(false);
      setWatchBandColorChecked_yellow(false);
      setWatchBandColorChecked_orange(false);
      setWatchBandColorChecked_purple(false);
      setWatchBandColorChecked_pink(false);
      setWatchBandColorChecked_brown(false);
      setWatchBandColorChecked_gray(false);
      setWatchBandColorChecked_olive(false);
      setWatchBandColorChecked_cyan(false);
      setWatchBandColorChecked_navy(false);
      setWatchBandColorChecked_beige(false);
      setWatchBandColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchBandColorFilters.length === 0) {
        setWatchBandColorChecked_all(true);
      }
    }
  };

  // watchBandColor others
  const handleWatchBandColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchBandColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchBandColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchBandColorChecked_all(false);
    }
  };

  // watch band material all
  const handleWatchBandMaterialCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchBandMaterialFilters.length === 1 &&
      watchBandMaterialFilters.includes("الكل")
    ) {
      return;
    }

    setWatchBandMaterialChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchBandMaterialFilters(["الكل"]);
      setWatchBandMaterialChecked_stainlessSteel(false);
      setWatchBandMaterialChecked_leather(false);
      setWatchBandMaterialChecked_rubber(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchBandMaterialFilters.length === 0) {
        setWatchBandMaterialChecked_all(true);
      }
    }
  };

  // watch outside color others
  const handleWatchBandMaterialCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchBandMaterialFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchBandMaterialChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchBandMaterialChecked_all(false);
    }
  };

  // watchHandlesColor all
  const handleWatchHandlesColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchHandlesColorFilters.length === 1 &&
      watchHandlesColorFilters.includes("الكل")
    ) {
      return;
    }

    setWatchHandlesColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchHandlesColorFilters(["الكل"]);
      setWatchHandlesColorChecked_stainlessSteel(false);
      setWatchHandlesColorChecked_black(false);
      setWatchHandlesColorChecked_white(false);
      setWatchHandlesColorChecked_red(false);
      setWatchHandlesColorChecked_blue(false);
      setWatchHandlesColorChecked_green(false);
      setWatchHandlesColorChecked_yellow(false);
      setWatchHandlesColorChecked_orange(false);
      setWatchHandlesColorChecked_purple(false);
      setWatchHandlesColorChecked_pink(false);
      setWatchHandlesColorChecked_brown(false);
      setWatchHandlesColorChecked_gray(false);
      setWatchHandlesColorChecked_olive(false);
      setWatchHandlesColorChecked_cyan(false);
      setWatchHandlesColorChecked_navy(false);
      setWatchHandlesColorChecked_beige(false);
      setWatchHandlesColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchHandlesColorFilters.length === 0) {
        setWatchHandlesColorChecked_all(true);
      }
    }
  };

  // watchHandlesColor others
  const handleWatchHandlesColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchHandlesColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchHandlesColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchHandlesColorChecked_all(false);
    }
  };

  // watchNumbersColor all
  const handleWatchNumbersColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchNumbersColorFilters.length === 1 &&
      watchNumbersColorFilters.includes("الكل")
    ) {
      return;
    }

    setWatchNumbersColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchNumbersColorFilters(["الكل"]);
      setWatchNumbersColorChecked_stainlessSteel(false);
      setWatchNumbersColorChecked_black(false);
      setWatchNumbersColorChecked_white(false);
      setWatchNumbersColorChecked_red(false);
      setWatchNumbersColorChecked_blue(false);
      setWatchNumbersColorChecked_green(false);
      setWatchNumbersColorChecked_yellow(false);
      setWatchNumbersColorChecked_orange(false);
      setWatchNumbersColorChecked_purple(false);
      setWatchNumbersColorChecked_pink(false);
      setWatchNumbersColorChecked_brown(false);
      setWatchNumbersColorChecked_gray(false);
      setWatchNumbersColorChecked_olive(false);
      setWatchNumbersColorChecked_cyan(false);
      setWatchNumbersColorChecked_navy(false);
      setWatchNumbersColorChecked_beige(false);
      setWatchNumbersColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchNumbersColorFilters.length === 0) {
        setWatchNumbersColorChecked_all(true);
      }
    }
  };

  // watchNumbersColor others
  const handleWatchNumbersColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchNumbersColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchNumbersColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchNumbersColorChecked_all(false);
    }
  };

  // watchNumbersLanguage all
  const handleWatchNumbersLanguageCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchNumbersLanguageFilters.length === 1 &&
      watchNumbersLanguageFilters.includes("الكل")
    ) {
      return;
    }

    setWatchNumbersLanguageChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchNumbersLanguageFilters(["الكل"]);
      setWatchNumbersLanguageChecked_arabic(false);
      setWatchNumbersLanguageChecked_english(false);
      setWatchNumbersLanguageChecked_roman(false);
      setWatchNumbersLanguageChecked_none(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchNumbersLanguageFilters.length === 0) {
        setWatchNumbersLanguageChecked_all(true);
      }
    }
  };

  // watchNumbersLanguage others
  const handleWatchNumbersLanguageCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchNumbersLanguageFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchNumbersLanguageChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchNumbersLanguageChecked_all(false);
    }
  };

  // watch dial shape all
  const handleWatchDialShapeCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchDialShapeFilters.length === 1 &&
      watchDialShapeFilters.includes("الكل")
    ) {
      return;
    }

    setWatchDialShapeChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchDialShapeFilters(["الكل"]);
      setWatchDialShapeChecked_round(false);
      setWatchDialShapeChecked_square(false);
      setWatchDialShapeChecked_rectangle(false);
      setWatchDialShapeChecked_oval(false);
      setWatchDialShapeChecked_pentagon(false);
      setWatchDialShapeChecked_hexagon(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchDialShapeFilters.length === 0) {
        setWatchDialShapeChecked_all(true);
      }
    }
  };

  // watch dial shape others
  const handleWatchDialShapeCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchDialShapeFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchDialShapeChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchDialShapeChecked_all(false);
    }
  };

  // watch inside color all
  const handleWatchInsideColorCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      watchInsideColorFilters.length === 1 &&
      watchInsideColorFilters.includes("الكل")
    ) {
      return;
    }

    setWatchInsideColorChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setWatchInsideColorFilters(["الكل"]);
      setWatchInsideColorChecked_stainlessSteel(false);
      setWatchInsideColorChecked_black(false);
      setWatchInsideColorChecked_white(false);
      setWatchInsideColorChecked_red(false);
      setWatchInsideColorChecked_blue(false);
      setWatchInsideColorChecked_green(false);
      setWatchInsideColorChecked_yellow(false);
      setWatchInsideColorChecked_orange(false);
      setWatchInsideColorChecked_purple(false);
      setWatchInsideColorChecked_pink(false);
      setWatchInsideColorChecked_brown(false);
      setWatchInsideColorChecked_gray(false);
      setWatchInsideColorChecked_olive(false);
      setWatchInsideColorChecked_cyan(false);
      setWatchInsideColorChecked_navy(false);
      setWatchInsideColorChecked_beige(false);
      setWatchInsideColorChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (watchInsideColorFilters.length === 0) {
        setWatchInsideColorChecked_all(true);
      }
    }
  };

  // watch inside color others
  const handleWatchInsideColorCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchInsideColorFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchInsideColorChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchInsideColorChecked_all(false);
    }
  };

  const handleProductPostTypeCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      productPostTypeFilters.length === 1 &&
      productPostTypeFilters.includes("الكل")
    ) {
      return;
    }

    setProductPostTypeChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setProductPostTypeFilters(["الكل"]);
      setProductPostTypeChecked_buyNow(false);
      setProductPostTypeChecked_auction(false);
      setProductPostTypeChecked_negotiable(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (productPostTypeFilters.length === 0) {
        setProductPostTypeChecked_all(true);
      }
    }
  };

  const handleProductCompanyCheckboxChange_all = (newCheckedStatus) => {
    // If trying to uncheck "الكل" but it's the only filter, ignore the action
    if (
      !newCheckedStatus &&
      productCompanyFilters.length === 1 &&
      productCompanyFilters.includes("الكل")
    ) {
      return;
    }

    setCompanyChecked_all(newCheckedStatus);

    if (newCheckedStatus) {
      // Set "الكل" as the only filter and uncheck all other checkboxes
      setProductCompanyFilters(["الكل"]);
      setCompanyChecked_rolex(false);
      setCompanyChecked_canon(false);
      setCompanyChecked_sony(false);
      setCompanyChecked_nikon(false);
      setCompanyChecked_omega(false);
      setCompanyChecked_cartier(false);
      setCompanyChecked_versace(false);
      setCompanyChecked_montBlanc(false);
      setCompanyChecked_aigner(false);
      setCompanyChecked_rado(false);
      setCompanyChecked_givenchy(false);
      setCompanyChecked_chopard(false);
      setCompanyChecked_tissot(false);
      setCompanyChecked_patikPhillipe(false);
      setCompanyChecked_armaniExchange(false);
      setCompanyChecked_gShock(false);
      setCompanyChecked_apple(false);
      setCompanyChecked_huawei(false);
      setCompanyChecked_samsung(false);
      setCompanyChecked_xiaomi(false);
      setCompanyChecked_other(false);
    } else {
      // If somehow "الكل" is unchecked and no other filters are active, re-check "الكل"
      if (productCompanyFilters.length === 0) {
        setCompanyChecked_all(true);
      }
    }
  };

  const handleProductConditionCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setProductConditionFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setProductConditionChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setProductConditionChecked_all(false);
    }
  };

  // watch department others
  const handleWatchDepartmentCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWatchDepartmentFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWatchDepartmentChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWatchDepartmentChecked_all(false);
    }
  };

  // wallet department others
  const handleWalletDepartmentCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setWalletDepartmentFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setWalletDepartmentChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setWalletDepartmentChecked_all(false);
    }
  };

  const handleProductPostTypeCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setProductPostTypeFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setProductPostTypeChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setProductPostTypeChecked_all(false);
    }
  };

  const handleProductCompanyCheckboxChange_others = (
    label,
    newCheckedStatus,
    setCheckboxState
  ) => {
    setProductCompanyFilters((prevFilters) => {
      if (newCheckedStatus) {
        return [...prevFilters.filter((filter) => filter !== "الكل"), label];
      } else {
        const newFilters = prevFilters.filter((filter) => filter !== label);
        if (newFilters.length === 0) {
          setCompanyChecked_all(true);
          return ["الكل"];
        }
        return newFilters;
      }
    });

    setCheckboxState(newCheckedStatus);

    if (newCheckedStatus) {
      setCompanyChecked_all(false);
    }
  };

  const handleSliderChange = (_, newValue) => {
    setLeastPriceSelected(newValue[0]);
    setMostPriceSelected(newValue[1]);
    setPriceFiltered(true);
  };

  const handleSliderChangeWatchYearMade = (_, newValue) => {
    setOldestWatchYearSelected(newValue[0]);
    setNewestWatchYearSelected(newValue[1]);
    setWatchYearMadeFiltered(true);
  };

  const handleSliderChangeRosaryCount = (_, newValue) => {
    setLeastRosaryCountSelected(newValue[0]);
    setMostRosaryCountSelected(newValue[1]);
    setRosaryCountFiltered(true);
  };

  const handleSliderChangeRosarySize = (_, newValue) => {
    setSmallestRosarySizeSelected(newValue[0]);
    setBiggestRosarySizeSelected(newValue[1]);
    setRosarySizeFiltered(true);
  };

  const handleSliderChangeWatchDialSize = (_, newValue) => {
    setSmallestWatchDialSizeSelected(newValue[0]);
    setBiggestWatchDialSizeSelected(newValue[1]);
    setWatchDialSizeFiltered(true);
  };

  const handleSliderChangeEntryFee = (_, newValue) => {
    setLeastExpensiveItemEntryFeeSelected(newValue[0]);
    setMostExpensiveItemEntryFeeSelected(newValue[1]);
    setEntryFeeFiltered(true);
  };

  const handleSliderChangeStartingPrice = (_, newValue) => {
    setLeastExpensiveItemStartingPriceSelected(newValue[0]);
    setMostExpensiveItemStartingPriceSelected(newValue[1]);
    setStartingPriceFiltered(true);
  };

  const handleSliderChangeBidIncrement = (_, newValue) => {
    setLeastExpensiveBidIncrementSelected(newValue[0]);
    setMostExpensiveBidIncrementSelected(newValue[1]);
    setBidIncrementFiltered(true);
  };

  const handleLeastPriceChange = (e) => {
    const value = Number(e.target.value);
    setLeastPriceSelected(value);
    handleSliderChange(null, [value, mostPriceSelected]);
    setPriceFiltered(true);
  };

  const handleMostPriceChange = (e) => {
    const value = Number(e.target.value);
    setMostPriceSelected(value);
    handleSliderChange(null, [leastPriceSelected, value]);
    setPriceFiltered(true);
  };

  const handleLeastEntryFeeChange = (e) => {
    const value = Number(e.target.value);
    setLeastExpensiveItemEntryFeeSelected(value);
    handleSliderChangeEntryFee(null, [value, mostEntryFeeSelected]);
    setEntryFeeFiltered(true);
  };

  const handleLeastBidIncrementChange = (e) => {
    const value = Number(e.target.value);
    setLeastExpensiveBidIncrementSelected(value);
    handleSliderChangeBidIncrement(null, [value, mostBidIncrementSelected]);
    setBidIncrementFiltered(true);
  };

  const handleLeastStartingPriceChange = (e) => {
    const value = Number(e.target.value);
    setLeastExpensiveItemStartingPriceSelected(value);
    handleSliderChangeStartingPrice(null, [value, mostStartingPriceSelected]);
    setStartingPriceFiltered(true);
  };

  const handleMostEntryFeeChange = (e) => {
    const value = Number(e.target.value);
    setMostExpensiveItemEntryFeeSelected(value);
    handleSliderChangeEntryFee(null, [leastEntryFeeSelected, value]);
    setEntryFeeFiltered(true);
  };

  const handleNewestWatchYearMadeChange = (e) => {
    const value = Number(e.target.value);
    setNewestWatchYearSelected(value);
    handleSliderChangeWatchYearMade(null, [oldestWatchYearSelected, value]);
    setWatchYearMadeFiltered(true);
  };

  const handleOldestWatchYearMadeChange = (e) => {
    const value = Number(e.target.value);
    setOldestWatchYearSelected(value);
    handleSliderChangeWatchYearMade(null, [value, newestWatchYearSelected]);
    setWatchYearMadeFiltered(true);
  };

  const handleMostRosaryCountChange = (e) => {
    const value = Number(e.target.value);
    setMostRosaryCountSelected(value);
    handleSliderChangeRosaryCount(null, [leastRosaryCountSelected, value]);
    setRosaryCountFiltered(true);
  };

  const handleBiggestRosarySizeChange = (e) => {
    const value = Number(e.target.value);
    setBiggestRosarySizeSelected(value);
    handleSliderChangeRosarySize(null, [smallestRosarySizeSelected, value]);
    setRosarySizeFiltered(true);
  };

  const handleSmallestRosarySizeChange = (e) => {
    const value = Number(e.target.value);
    setSmallestRosarySizeSelected(value);
    handleSliderChangeRosarySize(null, [value, biggestRosarySizeSelected]);
    setRosarySizeFiltered(true);
  };

  const handleLeastRosaryCountChange = (e) => {
    const value = Number(e.target.value);
    setLeastRosaryCountSelected(value);
    handleSliderChangeRosaryCount(null, [value, mostRosaryCountSelected]);
    setRosaryCountFiltered(true);
  };

  const handleBiggestWatchDialSizeChange = (e) => {
    const value = Number(e.target.value);
    setBiggestWatchDialSizeSelected(value);
    handleSliderChangeWatchDialSize(null, [smallestDialSizeSelected, value]);
    setWatchDialSizeFiltered(true);
  };

  const handleSmallestWatchDialSizeChange = (e) => {
    const value = Number(e.target.value);
    setSmallestWatchDialSizeSelected(value);
    handleSliderChangeWatchDialSize(null, [value, biggestDialSizeSelected]);
    setWatchDialSizeFiltered(true);
  };

  const handleMostBidIncrementChange = (e) => {
    const value = Number(e.target.value);
    setMostExpensiveBidIncrementSelected(value);
    handleSliderChangeBidIncrement(null, [leastBidIncrementSelected, value]);
    setBidIncrementFiltered(true);
  };

  const handleMostStartingPriceChange = (e) => {
    const value = Number(e.target.value);
    setMostExpensiveItemStartingPriceSelected(value);
    handleSliderChangeStartingPrice(null, [leastStartingPriceSelected, value]);
    setStartingPriceFiltered(true);
  };

  const [conditionFilterClicked, setConditionFilterClicked] =
    useState(conditionFilter);
  const [postTypeFilterClicked, setPostTypeFilterClicked] =
    useState(postTypeFilter);
  const [postPriceFilterClicked, setPriceFilterClicked] = useState(
    lowestPriceFilter ||
      heighestPriceFilter ||
      lowestEntryFeeFilter ||
      heighestEntryFeeFilter ||
      lowestStartingPriceFilter ||
      heighestEntryFeeFilter ||
      lowestBidIncrementFilter ||
      heighestBidIncrementFilter
  );
  const [watchDepartmentFilterClicked, setWatchDepartmentFilterClicked] =
    useState(watchDepartmentFilter);
  const [watchOutsideColorFilterClicked, setWatchOutsideColorFilterClicked] =
    useState(watchOutsideColorFilter);
  const [watchInsideColorFilterClicked, setWatchInsideColorFilterClicked] =
    useState(watchInsideColorFilter);
  const [watchDialShapeFilterClicked, setWatchDialShapeFilterClicked] =
    useState(watchDialShapeFilter);
  const [watchDialSizeFilterClicked, setWatchDialSizeFilterClicked] = useState(
    smallestDialSizeFilter || biggestDialSizeFilter
  );
  const [watchHandlesColorFilterClicked, setWatchHandlesColorFilterClicked] =
    useState(watchHandlesColorFilter);
  const [watchNumbersColorFilterClicked, setWatchNumbersColorFilterClicked] =
    useState(watchNumbersColorFilter);
  const [
    watchNumbersLanguageFilterClicked,
    setWatchNumbersLanguageFilterClicked,
  ] = useState(watchNumbersLanguageFilter);
  const [watchBandMaterialFilterClicked, setWatchBandMaterialFilterClicked] =
    useState(watchBandMaterialFilter);
  const [watchBandColorFilterClicked, setWatchBandColorFilterClicked] =
    useState(watchBandColorFilter);
  const [watchHasPackagingFilterClicked, setWatchHasPackagingFilterClicked] =
    useState(watchHasOriginalPackagingFilter);
  const [
    watchIsWaterResistantFilterClicked,
    setWatchIsWaterResistantFilterClicked,
  ] = useState(watchIsWaterResistantFilter);
  const [productCompanyFilterClicked, setProductCompanyFilterClicked] =
    useState(companyFilter);
  const [watchYearMadeFilterClicked, setWatchYearMadeFilterClicked] = useState(
    watchOldestYearMadeFilter || watchNewestYearMadeFilter
  );

  const [rosaryKindFilterClicked, setRosaryKindFilterClicked] =
    useState(rosaryKindFilter);
  const [rosaryColorFilterClicked, setRosaryColorFilterClicked] =
    useState(rosaryColorFilter);
  const [rosaryCountFilterClicked, setRosaryCountFilterClicked] = useState(
    leastRosaryCountFilter || mostRosaryCountFilter
  );
  const [rosarySizeFilterClicked, setRosarySizeFilterClicked] = useState(
    smallestRosarySizeFilter || biggestRosarySizeFilter
  );
  const [rosaryCountFiltered, setRosaryCountFiltered] = useState(
    leastRosaryCountFilter || mostRosaryCountFilter || false
  );
  const [rosarySizeFiltered, setRosarySizeFiltered] = useState(
    smallestRosarySizeFilter || biggestRosarySizeFilter || false
  );

  const [walletDepartmentFilterClicked, setWalletDepartmentFilterClicked] =
    useState(walletDepartmentFilter);
  const [walletOutsideColorFilterClicked, setWalletOutsideColorFilterClicked] =
    useState(walletOutsideColorFilter);

  const [purseMaterialFilterClicked, setPurseMaterialFilterClicked] =
    useState(purseMaterialFilter);
  const [purseOutsideColorFilterClicked, setPurseOutsideColorFilterClicked] =
    useState(purseOutsideColorFilter);
  const [purseInsideColorFilterClicked, setPurseInsideColorFilterClicked] =
    useState(purseInsideColorFilter);

  const [carPlateKindFilterClicked, setCarPlateKindFilterClicked] =
    useState(carPlateKindFilter);
  const [
    carPlateTransferFeeOnFilterClicked,
    setCarPlateTransferFeeOnFilterClicked,
  ] = useState(carPlateTransferFeeOnFilter);
  const [
    carPlateTransferTimeFilterClicked,
    setCarPlateTransferTimeFilterClicked,
  ] = useState(carPlateTransferTimeFilter);

  const [phoneCourierFilterClicked, setPhoneCourierFilterClicked] = useState(
    phoneNumberCourierFilter
  );

  const [shippingMethodsFilterClicked, setShippingMethodsFilterClicked] =
    useState(shippingMethodsFilter);

  const [showFilters, setShowFilters] = useState(false);
  const [hasAucionPosts, setHasAucionPosts] = useState(false);

  const [numProducts, setNumProducts] = useState("");
  const [productCategory, setProductCategory] = useState("");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Scroll behavior set to instant for instant scrolling
    });
    if (filterDivRef.current) {
      filterDivRef.current.scrollTo({
        top: 0,
        behavior: "instant", // Scroll behavior set to instant for instant scrolling
      });
    }
  };

  function checkDisabledFilters() {
    if (
      productConditionFilters.includes("الكل") &&
      productPostTypeFilters.includes("الكل") &&
      shippingMethodFilters.includes("الكل") &&
      leastPriceSelected === "" &&
      mostPriceSelected === "" &&
      leastEntryFeeSelected === "" &&
      mostEntryFeeSelected === "" &&
      leastStartingPriceSelected === "" &&
      mostStartingPriceSelected === "" &&
      leastBidIncrementSelected === "" &&
      mostBidIncrementSelected === "" &&
      // watches filters
      productCompanyFilters.includes("الكل") &&
      watchDepartmentFilters.includes("الكل") &&
      watchOutsideColorFilters.includes("الكل") &&
      watchInsideColorFilters.includes("الكل") &&
      watchDialShapeFilters.includes("الكل") &&
      smallestDialSizeSelected === "" &&
      biggestDialSizeSelected === "" &&
      watchHandlesColorFilters.includes("الكل") &&
      watchNumbersColorFilters.includes("الكل") &&
      watchNumbersLanguageFilters.includes("الكل") &&
      watchBandMaterialFilters.includes("الكل") &&
      watchBandColorFilters.includes("الكل") &&
      watchHasOriginalPackagingFilters.includes("الكل") &&
      watchIsWaterResistantFilters.includes("الكل") &&
      oldestWatchYearSelected === "" &&
      newestWatchYearSelected === "" &&
      // rosary filters
      rosaryKindFilters.includes("الكل") &&
      rosaryColorFilters.includes("الكل") &&
      leastRosaryCountSelected === "" &&
      mostRosaryCountSelected === "" &&
      smallestRosarySizeSelected === "" &&
      biggestRosarySizeSelected === "" &&
      // pens filters
      productCompanyFilters.includes("الكل") &&
      // wallet filters
      walletDepartmentFilters.includes("الكل") &&
      walletOutsideColorFilters.includes("الكل") &&
      // purse filters
      productCompanyFilters.includes("الكل") &&
      purseMaterialFilters.includes("الكل") &&
      purseOutsideColorFilters.includes("الكل") &&
      purseInsideColorFilters.includes("الكل") &&
      // car plate filters
      carPlateKindFilters.includes("الكل") &&
      carPlateTransferFeeOnFilters.includes("الكل") &&
      carPlateTransferTimeFilters.includes("الكل") &&
      // phone number filter
      phoneNumberCourierFilters.includes("الكل") &&
      // sorting
      selectedSortOption === "من الأقدم إلى الأحدث"
    ) {
      return true;
    }
    return false;
  }

  function filter() {
    if (checkDisabledFilters()) {
      resetFilters();
      return;
    }

    const keys = Array.from(searchParams.keys());
    keys.forEach((key) => {
      if (key !== "category" && key !== "search") {
        searchParams.delete(key);
      }
    });

    if (showFilters) toggleShowFilters();

    // setIsLoading(true);

    // apply filters to url
    if (
      categorySearch !== "لوح سيارات" &&
      categorySearch !== "ارقام جوالات" &&
      !productConditionFilters.includes("الكل")
    )
      searchParams.set("condition", productConditionFilters);

    if (!productPostTypeFilters.includes("الكل"))
      searchParams.set("postType", productPostTypeFilters);

    if (parseFloat(leastPriceSelected) > 0)
      searchParams.set("lowestPrice", leastPriceSelected);

    if (parseFloat(mostPriceSelected) > 0)
      searchParams.set("heighestPrice", mostPriceSelected);

    if (parseFloat(leastEntryFeeSelected) > 0)
      searchParams.set("lowestEntryFee", leastEntryFeeSelected);

    if (parseFloat(mostEntryFeeSelected) > 0)
      searchParams.set("heighestEntryFee", mostEntryFeeSelected);

    if (parseFloat(leastStartingPriceSelected) > 0)
      searchParams.set("lowestStartingPrice", leastStartingPriceSelected);

    if (parseFloat(mostStartingPriceSelected) > 0)
      searchParams.set("heighestStartingPrice", mostStartingPriceSelected);

    if (leastBidIncrementSelected !== "")
      searchParams.set("lowestBidIncrement", leastBidIncrementSelected);

    if (mostBidIncrementSelected !== "")
      searchParams.set("heighestBidIncrement", mostBidIncrementSelected);

    if (!shippingMethodFilters.includes("الكل"))
      searchParams.set("shippingMethods", shippingMethodFilters);

    if (!productCompanyFilters.includes("الكل"))
      searchParams.set("company", productCompanyFilters);

    if (!watchDepartmentFilters.includes("الكل"))
      searchParams.set("watchDepartment", watchDepartmentFilters);

    if (!watchOutsideColorFilters.includes("الكل"))
      searchParams.set("watchOutsideColor", watchOutsideColorFilters);

    if (!watchInsideColorFilters.includes("الكل"))
      searchParams.set("watchInsideColor", watchInsideColorFilters);

    if (!watchDialShapeFilters.includes("الكل"))
      searchParams.set("watchDialShape", watchDialShapeFilters);

    if (smallestDialSizeSelected !== "")
      searchParams.set("smallestWatchDialSize", smallestDialSizeSelected);

    if (biggestDialSizeSelected !== "")
      searchParams.set("biggestWatchDialSize", biggestDialSizeSelected);

    if (!watchHandlesColorFilters.includes("الكل"))
      searchParams.set("watchHandlesColor", watchHandlesColorFilters);

    if (!watchNumbersColorFilters.includes("الكل"))
      searchParams.set("watchNumbersColor", watchNumbersColorFilters);

    if (!watchNumbersLanguageFilters.includes("الكل"))
      searchParams.set("watchNumbersLanguage", watchNumbersLanguageFilters);

    if (!watchBandMaterialFilters.includes("الكل"))
      searchParams.set("watchBandMaterial", watchBandMaterialFilters);

    if (!watchBandColorFilters.includes("الكل"))
      searchParams.set("watchBandColor", watchBandColorFilters);

    if (!watchHasOriginalPackagingFilters.includes("الكل"))
      searchParams.set(
        "watchHasOriginalPackaging",
        watchHasOriginalPackagingFilters
      );

    if (!watchIsWaterResistantFilters.includes("الكل"))
      searchParams.set("watchIsWaterResistant", watchIsWaterResistantFilters);

    if (oldestWatchYearSelected !== "")
      searchParams.set("oldestWatchYearMade", oldestWatchYearSelected);

    if (newestWatchYearSelected !== "")
      searchParams.set("newestWatchYearMade", newestWatchYearSelected);

    // rosary filters
    if (!rosaryKindFilters.includes("الكل"))
      searchParams.set("rosaryKind", rosaryKindFilters);

    if (!rosaryColorFilters.includes("الكل"))
      searchParams.set("rosaryColor", rosaryColorFilters);

    if (leastRosaryCountSelected !== "")
      searchParams.set("leastRosaryCount", leastRosaryCountSelected);

    if (mostRosaryCountSelected !== "")
      searchParams.set("mostRosaryCount", mostRosaryCountSelected);

    if (smallestRosarySizeSelected !== "")
      searchParams.set("smallestRosarySize", smallestRosarySizeSelected);

    if (biggestRosarySizeSelected !== "")
      searchParams.set("biggestRosarySize", biggestRosarySizeSelected);

    if (!walletDepartmentFilters.includes("الكل"))
      searchParams.set("walletDepartment", walletDepartmentFilters);

    if (!walletOutsideColorFilters.includes("الكل"))
      searchParams.set("walletOutsideColor", walletOutsideColorFilters);

    if (!purseMaterialFilters.includes("الكل"))
      searchParams.set("purseMaterial", purseMaterialFilters);

    if (!purseOutsideColorFilters.includes("الكل"))
      searchParams.set("purseOutsideColor", purseOutsideColorFilters);

    if (!purseInsideColorFilters.includes("الكل"))
      searchParams.set("purseInsideColor", purseInsideColorFilters);

    // car plate filters
    if (!carPlateKindFilters.includes("الكل"))
      searchParams.set("carPlateKind", carPlateKindFilters);

    if (!carPlateTransferFeeOnFilters.includes("الكل"))
      searchParams.set("carPlateTransferFeeOn", carPlateTransferFeeOnFilters);

    if (!carPlateTransferTimeFilters.includes("الكل"))
      searchParams.set("carPlateTransferTime", carPlateTransferTimeFilters);

    if (!phoneNumberCourierFilters.includes("الكل"))
      searchParams.set("phoneNumberCourier", phoneNumberCourierFilters);

    // sort
    if (selectedSortOption !== "من الأقدم إلى الأحدث")
      searchParams.set("sort", selectedSortOption);

    navigate(`/products?${searchParams.toString()}`);
  }

  function resetFilters() {
    setShowFilters(false);
    // reset condition
    if (categorySearch !== "لوح سيارات" && categorySearch !== "ارقام جوالات") {
      setProductConditionFilters(["الكل"]);
      setProductConditionChecked_all(true);
      setProductConditionChecked_new(false);
      setProductConditionChecked_used(false);
      setProductConditionChecked_excellent(false);
      setProductConditionChecked_veryGood(false);
      setProductConditionChecked_good(false);
      setProductConditionChecked_acceptable(false);

      setConditionFilterClicked(false);
    }

    // reset post type
    setProductPostTypeFilters(["الكل"]);
    setProductPostTypeChecked_all(true);
    setProductPostTypeChecked_buyNow(false);
    setProductPostTypeChecked_auction(false);
    setProductPostTypeChecked_negotiable(false);

    setPostTypeFilterClicked(false);

    // reset price
    setLeastPriceSelected("");
    setMostPriceSelected("");
    setPriceFiltered(false);

    // reset entry fee
    setLeastExpensiveItemEntryFeeSelected("");
    setMostExpensiveItemEntryFeeSelected("");
    setEntryFeeFiltered(false);

    // reset starting price
    setLeastExpensiveItemStartingPriceSelected("");
    setMostExpensiveItemStartingPriceSelected("");
    setStartingPriceFiltered(false);

    // reset bid increment
    setLeastExpensiveBidIncrementSelected("");
    setMostExpensiveBidIncrementSelected("");
    setBidIncrementFiltered(false);

    setPriceFilterClicked(false);

    // reset shipping methods
    setShippingMethodFilters(["الكل"]);
    setShippingMethodChecked_all(true);
    setShippingMethodChecked_shipping(false);
    setShippingMethodChecked_delivery(false);

    setShippingMethodsFilterClicked(false);

    // reset product company
    if (
      categorySearch === "ساعات" ||
      categorySearch === "اقلام" ||
      categorySearch === "ابواك" ||
      categorySearch === "كاميرات" ||
      categorySearch === "حقائب نسائية"
    ) {
      setProductCompanyFilters(["الكل"]);
      setCompanyChecked_all(true);
      setCompanyChecked_other(false);
      if (categorySearch === "ساعات") {
        setCompanyChecked_rolex(false);
        setCompanyChecked_omega(false);
        setCompanyChecked_cartier(false);
        setCompanyChecked_versace(false);
        setCompanyChecked_montBlanc(false);
        setCompanyChecked_aigner(false);
        setCompanyChecked_rado(false);
        setCompanyChecked_givenchy(false);
        setCompanyChecked_chopard(false);
        setCompanyChecked_tissot(false);
        setCompanyChecked_patikPhillipe(false);
        setCompanyChecked_armaniExchange(false);
        setCompanyChecked_gShock(false);
        setCompanyChecked_apple(false);
        setCompanyChecked_huawei(false);
        setCompanyChecked_samsung(false);
        setCompanyChecked_xiaomi(false);
      } else if (categorySearch === "كاميرات") {
        setCompanyChecked_canon(false);
        setCompanyChecked_sony(false);
        setCompanyChecked_nikon(false);
        setCompanyChecked_other(false);
      } else if (categorySearch === "اقلام") {
        setCompanyChecked_rolex(false);
        setCompanyChecked_omega(false);
        setCompanyChecked_cartier(false);
        setCompanyChecked_versace(false);
        setCompanyChecked_montBlanc(false);
        setCompanyChecked_aigner(false);
        setCompanyChecked_rado(false);
        setCompanyChecked_givenchy(false);
        setCompanyChecked_chopard(false);
        setCompanyChecked_tissot(false);
        setCompanyChecked_patikPhillipe(false);
      } else if (categorySearch === "ابواك") {
        setCompanyChecked_chanel(false);
        setCompanyChecked_dior(false);
        setCompanyChecked_louisVuitton(false);
        setCompanyChecked_gucci(false);
        setCompanyChecked_dolceGabbana(false);
        setCompanyChecked_tomFord(false);
        setCompanyChecked_fredPerry(false);
        setCompanyChecked_rolex(false);
        setCompanyChecked_omega(false);
        setCompanyChecked_cartier(false);
        setCompanyChecked_versace(false);
        setCompanyChecked_montBlanc(false);
        setCompanyChecked_aigner(false);
        setCompanyChecked_rado(false);
        setCompanyChecked_givenchy(false);
        setCompanyChecked_chopard(false);
      } else if (categorySearch === "حقائب نسائية") {
        setCompanyChecked_chanel(false);
        setCompanyChecked_dior(false);
        setCompanyChecked_louisVuitton(false);
        setCompanyChecked_versace(false);
        setCompanyChecked_gucci(false);
        setCompanyChecked_dolceGabbana(false);
        setCompanyChecked_givenchy(false);
        setCompanyChecked_prada(false);
        setCompanyChecked_balenciaga(false);
        setCompanyChecked_saintLaurent(false);
        setCompanyChecked_toryBurch(false);
        setCompanyChecked_coach(false);
        setCompanyChecked_tedBaker(false);
        setCompanyChecked_charlesKeith(false);
        setCompanyChecked_guess(false);
        setCompanyChecked_offWhite(false);
        setCompanyChecked_michaelKors(false);
      }
      setProductCompanyFilterClicked(false);
    }

    // reset watch filters
    if (categorySearch === "ساعات") {
      // reset watch department
      setWatchDepartmentFilters(["الكل"]);
      setWatchDepartmentChecked_all(true);
      setWatchDepartmentChecked_men(false);
      setWatchDepartmentChecked_women(false);
      setWatchDepartmentChecked_unisex(false);

      setWatchDepartmentFilterClicked(false);

      // reset watch outside color
      setWatchInsideColorFilters(["الكل"]);
      setWatchOutsideColorChecked_all(true);
      setWatchOutsideColorChecked_stainlessSteel(false);
      setWatchOutsideColorChecked_black(false);
      setWatchOutsideColorChecked_white(false);
      setWatchOutsideColorChecked_red(false);
      setWatchOutsideColorChecked_blue(false);
      setWatchOutsideColorChecked_green(false);
      setWatchOutsideColorChecked_yellow(false);
      setWatchOutsideColorChecked_orange(false);
      setWatchOutsideColorChecked_purple(false);
      setWatchOutsideColorChecked_pink(false);
      setWatchOutsideColorChecked_brown(false);
      setWatchOutsideColorChecked_gray(false);
      setWatchOutsideColorChecked_olive(false);
      setWatchOutsideColorChecked_cyan(false);
      setWatchOutsideColorChecked_navy(false);
      setWatchOutsideColorChecked_beige(false);
      setWatchOutsideColorChecked_other(false);

      setWatchOutsideColorFilterClicked(false);

      // Reset watch inside color
      setWatchInsideColorFilters(["الكل"]);
      setWatchInsideColorChecked_all(true);
      setWatchInsideColorChecked_stainlessSteel(false);
      setWatchInsideColorChecked_black(false);
      setWatchInsideColorChecked_white(false);
      setWatchInsideColorChecked_red(false);
      setWatchInsideColorChecked_blue(false);
      setWatchInsideColorChecked_green(false);
      setWatchInsideColorChecked_yellow(false);
      setWatchInsideColorChecked_orange(false);
      setWatchInsideColorChecked_purple(false);
      setWatchInsideColorChecked_pink(false);
      setWatchInsideColorChecked_brown(false);
      setWatchInsideColorChecked_gray(false);
      setWatchInsideColorChecked_olive(false);
      setWatchInsideColorChecked_cyan(false);
      setWatchInsideColorChecked_navy(false);
      setWatchInsideColorChecked_beige(false);
      setWatchInsideColorChecked_other(false);

      setWatchInsideColorFilterClicked(false);

      // Reset watch dial shape
      setWatchDialShapeFilters(["الكل"]);
      setWatchDialShapeChecked_all(true);
      setWatchDialShapeChecked_round(false);
      setWatchDialShapeChecked_square(false);
      setWatchDialShapeChecked_rectangle(false);
      setWatchDialShapeChecked_oval(false);
      setWatchDialShapeChecked_pentagon(false);
      setWatchDialShapeChecked_hexagon(false);

      setWatchDialShapeFilterClicked(false);

      // reset watch dial size
      setSmallestWatchDialSizeSelected("");
      setBiggestWatchDialSizeSelected("");
      setWatchDialSizeFiltered(false);

      setWatchDialSizeFilterClicked(false);

      // reset watch handles color
      setWatchHandlesColorFilters(["الكل"]);
      setWatchHandlesColorChecked_all(true);
      setWatchHandlesColorChecked_stainlessSteel(false);
      setWatchHandlesColorChecked_black(false);
      setWatchHandlesColorChecked_white(false);
      setWatchHandlesColorChecked_red(false);
      setWatchHandlesColorChecked_blue(false);
      setWatchHandlesColorChecked_green(false);
      setWatchHandlesColorChecked_yellow(false);
      setWatchHandlesColorChecked_orange(false);
      setWatchHandlesColorChecked_purple(false);
      setWatchHandlesColorChecked_pink(false);
      setWatchHandlesColorChecked_brown(false);
      setWatchHandlesColorChecked_gray(false);
      setWatchHandlesColorChecked_olive(false);
      setWatchHandlesColorChecked_cyan(false);
      setWatchHandlesColorChecked_navy(false);
      setWatchHandlesColorChecked_beige(false);
      setWatchHandlesColorChecked_other(false);

      setWatchHandlesColorFilterClicked(false);

      // reset watch numbers color
      setWatchNumbersColorFilters(["الكل"]);
      setWatchNumbersColorChecked_all(true);
      setWatchNumbersColorChecked_stainlessSteel(false);
      setWatchNumbersColorChecked_black(false);
      setWatchNumbersColorChecked_white(false);
      setWatchNumbersColorChecked_red(false);
      setWatchNumbersColorChecked_blue(false);
      setWatchNumbersColorChecked_green(false);
      setWatchNumbersColorChecked_yellow(false);
      setWatchNumbersColorChecked_orange(false);
      setWatchNumbersColorChecked_purple(false);
      setWatchNumbersColorChecked_pink(false);
      setWatchNumbersColorChecked_brown(false);
      setWatchNumbersColorChecked_gray(false);
      setWatchNumbersColorChecked_olive(false);
      setWatchNumbersColorChecked_cyan(false);
      setWatchNumbersColorChecked_navy(false);
      setWatchNumbersColorChecked_beige(false);
      setWatchNumbersColorChecked_other(false);

      setWatchNumbersColorFilterClicked(false);

      // Reset watch numbers language and filter
      setWatchNumbersLanguageFilters(["الكل"]);
      setWatchNumbersLanguageChecked_all(true);
      setWatchNumbersLanguageChecked_arabic(false);
      setWatchNumbersLanguageChecked_english(false);
      setWatchNumbersLanguageChecked_roman(false);
      setWatchNumbersLanguageChecked_none(false);

      setWatchNumbersLanguageFilterClicked(false);

      // Reset watch band material and filter
      setWatchBandMaterialFilters(["الكل"]);
      setWatchBandMaterialChecked_all(true);
      setWatchBandMaterialChecked_stainlessSteel(false);
      setWatchBandMaterialChecked_leather(false);
      setWatchBandMaterialChecked_rubber(false);

      setWatchBandMaterialFilterClicked(false);

      // Reset watch band color and filter
      setWatchBandColorFilters(["الكل"]);
      setWatchBandColorChecked_all(true);
      setWatchBandColorChecked_stainlessSteel(false);
      setWatchBandColorChecked_black(false);
      setWatchBandColorChecked_white(false);
      setWatchBandColorChecked_red(false);
      setWatchBandColorChecked_blue(false);
      setWatchBandColorChecked_green(false);
      setWatchBandColorChecked_yellow(false);
      setWatchBandColorChecked_orange(false);
      setWatchBandColorChecked_purple(false);
      setWatchBandColorChecked_pink(false);
      setWatchBandColorChecked_brown(false);
      setWatchBandColorChecked_gray(false);
      setWatchBandColorChecked_olive(false);
      setWatchBandColorChecked_cyan(false);
      setWatchBandColorChecked_navy(false);
      setWatchBandColorChecked_beige(false);
      setWatchBandColorChecked_other(false);

      setWatchBandColorFilterClicked(false);

      // Reset watch has original packaging and filter
      setWatchHasOriginalPackagingFilters(["الكل"]);
      setWatchHasOriginalPackagingChecked_all(true);
      setWatchHasOriginalPackagingChecked_yes(false);
      setWatchHasOriginalPackagingChecked_no(false);

      setWatchHasPackagingFilterClicked(false);

      // Reset watch is water-resistant and filter
      setWatchIsWaterResistantFilters(["الكل"]);
      setWatchIsWaterResistantChecked_all(true);
      setWatchIsWaterResistantChecked_yes(false);
      setWatchIsWaterResistantChecked_no(false);

      setWatchIsWaterResistantFilterClicked(false);

      // reset watch year made
      setOldestWatchYearSelected("");
      setNewestWatchYearSelected("");
      setWatchYearMadeFiltered(false);

      setWatchYearMadeFilterClicked(false);
    } else if (categorySearch === "سبح") {
      // reset roasry kind
      setRosaryKindFilters(["الكل"]);
      setRosaryKindChecked_all(true);
      setRosaryKindChecked_backlight(false);
      setRosaryKindChecked_kahraman(false);
      setRosaryKindChecked_fatoran(false);
      setRosaryKindChecked_ivory(false);
      setRosaryKindChecked_wood(false);
      setRosaryKindChecked_stone(false);

      setRosaryKindFilterClicked(false);

      // reset rosary color
      setRosaryColorFilters(["الكل"]);
      setRosaryColorChecked_all(true);
      setRosaryColorChecked_black(false);
      setRosaryColorChecked_white(false);
      setRosaryColorChecked_red(false);
      setRosaryColorChecked_blue(false);
      setRosaryColorChecked_green(false);
      setRosaryColorChecked_yellow(false);
      setRosaryColorChecked_orange(false);
      setRosaryColorChecked_purple(false);
      setRosaryColorChecked_pink(false);
      setRosaryColorChecked_brown(false);
      setRosaryColorChecked_gray(false);
      setRosaryColorChecked_olive(false);
      setRosaryColorChecked_cyan(false);
      setRosaryColorChecked_navy(false);
      setRosaryColorChecked_beige(false);
      setRosaryColorChecked_other(false);

      setRosaryColorFilterClicked(false);

      // reset rosary count filters
      setLeastRosaryCountSelected("");
      setMostRosaryCountSelected("");
      setRosaryCountFiltered(false);

      setRosaryCountFilterClicked(false);

      // reset rosary size filters
      setSmallestRosarySizeSelected("");
      setMostRosaryCountSelected("");
      setRosarySizeFiltered(false);

      setRosarySizeFilterClicked(false);
    } else if (categorySearch === "ابواك") {
      // reset wallet department
      setWalletDepartmentFilters(["الكل"]);
      setWalletDepartmentChecked_all(true);
      setWalletDepartmentChecked_men(false);
      setWalletDepartmentChecked_women(false);
      setWalletDepartmentChecked_unisex(false);

      setWalletDepartmentFilterClicked(false);

      // reset wallet outside color
      setWalletOutsideColorFilters(["الكل"]);
      setWalletOutsideColorChecked_all(true);
      setWalletOutsideColorChecked_black(false);
      setWalletOutsideColorChecked_white(false);
      setWalletOutsideColorChecked_red(false);
      setWalletOutsideColorChecked_blue(false);
      setWalletOutsideColorChecked_green(false);
      setWalletOutsideColorChecked_yellow(false);
      setWalletOutsideColorChecked_orange(false);
      setWalletOutsideColorChecked_purple(false);
      setWalletOutsideColorChecked_pink(false);
      setWalletOutsideColorChecked_brown(false);
      setWalletOutsideColorChecked_gray(false);
      setWalletOutsideColorChecked_olive(false);
      setWalletOutsideColorChecked_cyan(false);
      setWalletOutsideColorChecked_navy(false);
      setWalletOutsideColorChecked_beige(false);
      setWalletOutsideColorChecked_other(false);

      setWalletOutsideColorFilterClicked(false);
    } else if (categorySearch === "حقائب نسائية") {
      // reset purse material
      setPurseMaterialFilters(["الكل"]);
      setPurseMaterialChecked_all(true);
      setPurseMaterialChecked_leather(false);
      setPurseMaterialChecked_fabric(false);

      setPurseMaterialFilterClicked(false);

      // reset purse outside color
      setPurseOutsideColorFilters(["الكل"]);
      setPurseOutsideColorChecked_all(true);
      setPurseOutsideColorChecked_black(false);
      setPurseOutsideColorChecked_white(false);
      setPurseOutsideColorChecked_red(false);
      setPurseOutsideColorChecked_blue(false);
      setPurseOutsideColorChecked_green(false);
      setPurseOutsideColorChecked_yellow(false);
      setPurseOutsideColorChecked_orange(false);
      setPurseOutsideColorChecked_purple(false);
      setPurseOutsideColorChecked_pink(false);
      setPurseOutsideColorChecked_brown(false);
      setPurseOutsideColorChecked_gray(false);
      setPurseOutsideColorChecked_olive(false);
      setPurseOutsideColorChecked_cyan(false);
      setPurseOutsideColorChecked_navy(false);
      setPurseOutsideColorChecked_beige(false);
      setPurseOutsideColorChecked_other(false);

      setPurseOutsideColorFilterClicked(false);

      // reset purse inside color
      setPurseInsideColorFilters(["الكل"]);
      setPurseInsideColorChecked_all(true);
      setPurseInsideColorChecked_black(false);
      setPurseInsideColorChecked_white(false);
      setPurseInsideColorChecked_red(false);
      setPurseInsideColorChecked_blue(false);
      setPurseInsideColorChecked_green(false);
      setPurseInsideColorChecked_yellow(false);
      setPurseInsideColorChecked_orange(false);
      setPurseInsideColorChecked_purple(false);
      setPurseInsideColorChecked_pink(false);
      setPurseInsideColorChecked_brown(false);
      setPurseInsideColorChecked_gray(false);
      setPurseInsideColorChecked_olive(false);
      setPurseInsideColorChecked_cyan(false);
      setPurseInsideColorChecked_navy(false);
      setPurseInsideColorChecked_beige(false);
      setPurseInsideColorChecked_other(false);

      setPurseInsideColorFilterClicked(false);
    } else if (categorySearch === "لوح سيارات") {
      setCarPlateKindFilters(["الكل"]);

      setCarPlateKindChecked_all(true);
      setCarPlateKindChecked_privateCar(false);
      setCarPlateKindChecked_motorCycle(false);
      setCarPlateKindChecked_transportation(false);
      setCarPlateKindChecked_commerce(false);

      setCarPlateKindFilterClicked(false);

      setCarPlateTransferFeeOnFilters(["الكل"]);
      setCarPlateTransferFeeOnChecked_all(true);
      setCarPlateTransferFeeOnChecked_seller(false);
      setCarPlateTransferFeeOnChecked_buyer(false);

      setCarPlateTransferFeeOnFilterClicked(false);

      setCarPlateTransferTimeFilters(["الكل"]);

      setCarPlateTransferTimeChecked_all(true);
      setCarPlateTransferTimeChecked_24to48Hours(false);
      setCarPlateTransferTimeChecked_twoToFiveDays(false);

      setCarPlateTransferTimeFilterClicked(false);
    } else if (categorySearch === "ارقام جوالات") {
      setPhoneNumberCourierFilters(["الكل"]);

      setPhoneNumberCourierChecked_all(true);
      setPhoneNumberCourierChecked_stc(false);
      setPhoneNumberCourierChecked_mobily(false);
      setPhoneNumberCourierChecked_zain(false);

      setPhoneCourierFilterClicked(false);
    }

    // reset sort
    setSelectedSortOption("من الأقدم إلى الأحدث");

    // Create a new URLSearchParams object
    const searchParams = new URLSearchParams(window.location.search);

    const keys = Array.from(searchParams.keys());
    keys.forEach((key) => {
      if (key !== "category" && key !== "search") {
        searchParams.delete(key);
      }
    });

    if(searchParams.size === 0){
      navigate("/")
      return;
    }

    // Navigate to the updated URL
    // setIsLoading(true);
    navigate(`/products?${searchParams.toString()}`);
  }

  // useEffect(() => {
  //   // Build the query based on category existence
  //   let newQuery;
  //   if (categorySearch) {
  //     newQuery = query(
  //       collection(db, "Posts"),
  //       where("isDeleted", "==", false),
  //       where("isSold", "==", false),
  //       where("category", "==", categorySearch) // Add category filter if it exists
  //     );
  //   } else {
  //     newQuery = query(
  //       collection(db, "Posts"),
  //       where("isDeleted", "==", false),
  //       where("isSold", "==", false)
  //     ); // Default query
  //   }
  //   setQu(newQuery); // Update the query state
  // }, [categorySearch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isVisible, setIsVisible] = useState(false); // Visibility state

  useEffect(() => {
    // Set the div to visible after a slight delay to ensure the transition happens
    // if(!isLoading){
      setTimeout(() => setIsVisible(true), 100);
    // }
  }, [isLoading]);

  const [productsVisible, setProductsVisible] = useState(false); // Visibility state

  useEffect(() => {
    // Set the div to visible after a slight delay to ensure the transition happens
    if(!productsLoading){
      setTimeout(() => setProductsVisible(true), 300);
    }
  }, [productsLoading]);

  // get the filters
  // useEffect(() => {
  //   const listener = onSnapshot(qu, (querySnapshot) => {
  //     if (!querySnapshot.empty) {
  //       var arr = querySnapshot.docs
  //         .map(doc => doc.data())
  //         .filter(data => {
  //           const isNotDeletedOrSold = !data.isDeleted && !data.isSold;
  //           const isAuction = data.postType.includes("مزاد");
  //           const isWithinExpiry = data.expiryDate ? (Date.now() / 1000 - data.expiryDate.seconds < 0) : true;

  //           if (!res) {
  //             if (numBidders && isAuction && isNotDeletedOrSold && isWithinExpiry && data.numBidders >= numBidders) {
  //               return true;
  //             } else if (isNotDeletedOrSold && isWithinExpiry) {
  //               return true;
  //             }
  //             return false;
  //           } else {
  //             const searchTerm = res.split(" ");
  //             const hasAllElems = searchTerm.every(term => data.keywords.includes(term));
  //             if (hasAllElems && isNotDeletedOrSold && isWithinExpiry) {
  //               return true;
  //             }
  //             return false;
  //           }
  //         });

  //         if (arr.length > 0) {

  //           // Condition filter
  //           if (conditionFilter && conditionFilter.length > 0) {
  //             arr = arr.filter(item => conditionFilter.includes(item.condition));
  //           }

  //           // Post type filter
  //           if (postTypeFilter && postTypeFilter.length > 0) {
  //             arr = arr.filter(item => {
  //               if (Array.isArray(item.postType)) {
  //                 return item.postType.some(pt => postTypeFilter.includes(pt));
  //               }
  //               return false;
  //             });
  //           }

  //           // Lowest price filter
  //           if (lowestPriceFilter && parseFloat(lowestPriceFilter) > 0) {
  //             arr = arr.filter(item => item.productPrice >= parseFloat(lowestPriceFilter));
  //           }

  //           // Highest price filter
  //           if (heighestPriceFilter && parseFloat(heighestPriceFilter) > 0) {
  //             arr = arr.filter(item => item.productPrice <= parseFloat(heighestPriceFilter));
  //           }

  //           // Lowest entry fee filter
  //           if (lowestEntryFeeFilter && parseFloat(lowestEntryFeeFilter) > 0) {
  //             arr = arr.filter(item => item.entryFee >= parseFloat(lowestEntryFeeFilter));
  //           }

  //           // Highest entry fee filter
  //           if (heighestEntryFeeFilter && parseFloat(heighestEntryFeeFilter) > 0) {
  //             arr = arr.filter(item => item.entryFee <= parseFloat(heighestEntryFeeFilter));
  //           }

  //           // Lowest starting price filter
  //           if (lowestStartingPriceFilter && parseFloat(lowestStartingPriceFilter) > 0) {
  //             arr = arr.filter(item => item.startingPrice >= parseFloat(lowestStartingPriceFilter));
  //           }

  //           // Highest starting price filter
  //           if (heighestStartingPriceFilter && parseFloat(heighestStartingPriceFilter) > 0) {
  //             arr = arr.filter(item => item.startingPrice <= parseFloat(heighestStartingPriceFilter));
  //           }

  //           // Lowest bid increment filter
  //           if (lowestBidIncrementFilter && parseFloat(lowestBidIncrementFilter) > 0) {
  //             arr = arr.filter(item => item.bidIncrements >= parseFloat(lowestBidIncrementFilter));
  //           }

  //           // Highest bid increment filter
  //           if (heighestBidIncrementFilter && parseFloat(heighestBidIncrementFilter) > 0) {
  //             arr = arr.filter(item => item.bidIncrements <= parseFloat(heighestBidIncrementFilter));
  //           }

  //           // Shipping methods filter
  //           if (shippingMethodsFilter && shippingMethodsFilter.length > 0) {
  //             arr = arr.filter(item => {
  //               if (Array.isArray(item.shippingMethods)) {
  //                 return item.shippingMethods.some(pt => shippingMethodsFilter.includes(pt));
  //               }
  //               return false;
  //             });
  //           }

  //           // Company filter
  //           if (companyFilter && companyFilter.length > 0) {
  //             arr = arr.filter(item => companyFilter.includes(item.productCompany));
  //           }

  //           // watch department filter
  //           if (watchDepartmentFilter && watchDepartmentFilter.length > 0) {
  //             arr = arr.filter(item => watchDepartmentFilter.includes(item.watchDepartment));
  //           }

  //           // watch outside color filter
  //           if (watchOutsideColorFilter && watchOutsideColorFilter.length > 0) {
  //             arr = arr.filter(item => watchOutsideColorFilter.includes(item.watchOutsideColor));
  //           }

  //           // watch outside color filter
  //           if (watchInsideColorFilter && watchInsideColorFilter.length > 0) {
  //             arr = arr.filter(item => watchInsideColorFilter.includes(item.watchInsideColor));
  //           }

  //           // rosary kind filter
  //           if (rosaryKindFilter && rosaryKindFilter.length > 0) {
  //             arr = arr.filter(item => rosaryKindFilter.includes(item.rosaryKind));
  //           }

  //           // rosary kind filter
  //           if (rosaryColorFilter && rosaryColorFilter.length > 0) {
  //             arr = arr.filter(item => rosaryColorFilter.includes(item.rosaryColor));
  //           }

  //           // rosary least count filter
  //           if (leastRosaryCountFilter && parseFloat(leastRosaryCountFilter) > 0) {
  //             arr = arr.filter(item => item.rosaryCount >= parseFloat(leastRosaryCountFilter));
  //           }

  //           // rosary most count filter
  //           if (mostRosaryCountFilter && parseFloat(mostRosaryCountFilter) > 0) {
  //             arr = arr.filter(item => item.rosaryCount <= parseFloat(mostRosaryCountFilter));
  //           }

  //           // wallet department filter
  //           if (walletDepartmentFilter && walletDepartmentFilter.length > 0) {
  //             arr = arr.filter(item => walletDepartmentFilter.includes(item.walletDepartment));
  //           }

  //           // wallet outside color filter
  //           if (walletOutsideColorFilter && walletOutsideColorFilter.length > 0) {
  //             arr = arr.filter(item => walletOutsideColorFilter.includes(item.walletOutsideColor));
  //           }

  //           // purse material filter
  //           if (purseMaterialFilter && purseMaterialFilter.length > 0) {
  //             arr = arr.filter(item => purseMaterialFilter.includes(item.purseMaterial));
  //           }

  //           // purse outside coloe filter
  //           if (purseOutsideColorFilter && purseOutsideColorFilter.length > 0) {
  //             arr = arr.filter(item => purseOutsideColorFilter.includes(item.purseOutsideColor));
  //           }

  //           // car plate kind filter
  //           if (carPlateKindFilter && carPlateKindFilter.length > 0) {
  //             arr = arr.filter(item => carPlateKindFilter.includes(item.carPlateKind));
  //           }

  //           // Car plate transfer fee on filter
  //           if (carPlateTransferFeeOnFilter && carPlateTransferFeeOnFilter.length > 0) {
  //             arr = arr.filter(item => carPlateTransferFeeOnFilter.includes(item.carPlateTransferFeeOn));
  //           }

  //           // Car plate transfer time filter
  //           if (carPlateTransferTimeFilter && carPlateTransferTimeFilter.length > 0) {
  //             arr = arr.filter(item => carPlateTransferTimeFilter.includes(item.carPlateTransferTime));
  //           }

  //           // phone number courier filter
  //           if (phoneNumberCourierFilter && phoneNumberCourierFilter.length > 0) {
  //             arr = arr.filter(item => phoneNumberCourierFilter.includes(item.phoneNumberCourier));
  //           }

  //           // filter for !== "" (optional product values)

  //           // watch dial shape filter
  //           if (watchDialShapeFilter && watchDialShapeFilter.length > 0) {
  //             const filteredWatchDialShapeArr = arr.filter(item => item.watchDialShape !== "");
  //             arr = filteredWatchDialShapeArr.filter(item => watchDialShapeFilter.includes(item.watchDialShape));
  //           }

  //           // watch smallest dial size filter
  //           if (smallestDialSizeFilter && parseFloat(smallestDialSizeFilter) > 0) {
  //             const filteredWatchSDialSizeArr = arr.filter(item => item.watchDialSize !== "");
  //             arr = filteredWatchSDialSizeArr.filter(item => item.watchDialSize >= parseFloat(smallestDialSizeFilter));
  //           }

  //           // watch biggest dial size filter
  //           if (biggestDialSizeFilter && parseFloat(biggestDialSizeFilter) > 0) {
  //             const filteredWatchSDialSizeArr = arr.filter(item => item.watchDialSize !== "");
  //             arr = filteredWatchSDialSizeArr.filter(item => item.watchDialSize <= parseFloat(biggestDialSizeFilter));
  //           }

  //           // watch handles color filter
  //           if (watchHandlesColorFilter && watchHandlesColorFilter.length > 0) {
  //             const filteredWatchHandlesColorArr = arr.filter(item => item.watchHandlesColor !== "");
  //             arr = filteredWatchHandlesColorArr.filter(item => watchHandlesColorFilter.includes(item.watchHandlesColor));
  //           }

  //           // watch numbers color filter
  //           if (watchNumbersColorFilter && watchNumbersColorFilter.length > 0) {
  //             const filteredWatchNumbersColorArr = arr.filter(item => item.watchNumbersColor !== "");
  //             arr = filteredWatchNumbersColorArr.filter(item => watchNumbersColorFilter.includes(item.watchNumbersColor));
  //           }

  //           // watch numbers language filter
  //           if (watchNumbersLanguageFilter && watchNumbersLanguageFilter.length > 0) {
  //             const filteredWatchNumbersLanguageArr = arr.filter(item => item.watchNumbersLanguage !== "");
  //             arr = filteredWatchNumbersLanguageArr.filter(item => watchNumbersLanguageFilter.includes(item.watchNumbersLanguage));
  //           }

  //           // watch band material filter
  //           if (watchBandMaterialFilter && watchBandMaterialFilter.length > 0) {
  //             const filteredWatchBandMaterialArr = arr.filter(item => item.watchBandMaterial !== "");
  //             arr = filteredWatchBandMaterialArr.filter(item => watchBandMaterialFilter.includes(item.watchBandMaterial));
  //           }

  //           // watch band color filter
  //           if (watchBandColorFilter && watchBandColorFilter.length > 0) {
  //             const filteredWatchBandColorArr = arr.filter(item => item.watchBandColor !== "");
  //             arr = filteredWatchBandColorArr.filter(item => watchBandColorFilter.includes(item.watchBandColor));
  //           }

  //           // watch has original packaging filter
  //           if (watchHasOriginalPackagingFilter && watchHasOriginalPackagingFilter.length > 0) {
  //             const filteredWatchHasOriginalPackagingArr = arr.filter(item => item.watchHasOriginalPackaging !== "");
  //             arr = filteredWatchHasOriginalPackagingArr.filter(item => watchHasOriginalPackagingFilter.includes(item.watchHasOriginalPackaging));
  //           }

  //           // watch is water resistant filter
  //           if (watchIsWaterResistantFilter && watchIsWaterResistantFilter.length > 0) {
  //             const filteredWatchIsWaterResistantArr = arr.filter(item => item.watchIsWaterResistant !== "");
  //             arr = filteredWatchIsWaterResistantArr.filter(item => watchIsWaterResistantFilter.includes(item.watchIsWaterResistant));
  //           }

  //           // watch oldest year made filter
  //           if (watchOldestYearMadeFilter && parseFloat(watchOldestYearMadeFilter) > 0) {
  //             const filteredWatchOldestYearMadeArr = arr.filter(item => item.yearMade !== "");
  //             arr = filteredWatchOldestYearMadeArr.filter(item => item.watchYearMade >= parseFloat(watchOldestYearMadeFilter));
  //           }

  //           // watch newest year made filter
  //           if (watchNewestYearMadeFilter && parseFloat(watchNewestYearMadeFilter) > 0) {
  //             const filteredWatchNewestYearMadeArr = arr.filter(item => item.yearMade !== "");
  //             arr = filteredWatchNewestYearMadeArr.filter(item => item.watchYearMade <= parseFloat(watchNewestYearMadeFilter));
  //           }

  //           // Sort and filter logic
  //           const filteredPriceArr = arr.filter(item => item.productPrice !== "");
  //           if (filteredPriceArr.length > 0) {
  //             const sortedByPrice = [...filteredPriceArr].sort((a, b) => b.productPrice - a.productPrice);
  //             setMostExpensiveItemPrice(parseFloat(sortedByPrice[0].productPrice));
  //             setLeastExpensiveItemPrice(parseFloat(sortedByPrice[sortedByPrice.length - 1].productPrice));
  //           }

  //           const filteredAuctionsArr = arr.filter(item => item.postType.includes("مزاد"));
  //           if (filteredAuctionsArr.length > 0) {
  //             setHasAucionPosts(true);
  //             const sortedByEntryFee = [...filteredAuctionsArr].sort((a, b) => b.entryFee - a.entryFee);
  //             setMostExpensiveItemEntryFee(parseFloat(sortedByEntryFee[0].entryFee));
  //             setLeastExpensiveItemEntryFee(parseFloat(sortedByEntryFee[sortedByEntryFee.length - 1].entryFee));

  //             const sortedByStartingPrice = [...filteredAuctionsArr].sort((a, b) => b.startingPrice - a.startingPrice);
  //             setMostExpensiveItemStartingPrice(parseFloat(sortedByStartingPrice[0].startingPrice));
  //             setLeastExpensiveItemStartingPrice(parseFloat(sortedByStartingPrice[sortedByStartingPrice.length - 1].startingPrice));

  //             const sortedByBidIncrement = [...filteredAuctionsArr].sort((a, b) => b.bidIncrements - a.bidIncrements);
  //             setMostExpensiveBidIncrement(parseFloat(sortedByBidIncrement[0].bidIncrements));
  //             setLeastExpensiveBidIncrement(parseFloat(sortedByBidIncrement[sortedByBidIncrement.length - 1].bidIncrements));
  //           }

  //           // Watches
  //           if (categorySearch === "ساعات") {
  //             const filteredDialSizeArr = arr.filter(item => item.watchDialSize !== "");
  //             if (filteredDialSizeArr.length > 0) {
  //               const sortedByDialSize = [...filteredDialSizeArr].sort((a, b) => b.watchDialSize - a.watchDialSize);
  //               setSmallestWatchDialSize(parseFloat(sortedByDialSize[sortedByDialSize.length - 1].watchDialSize));
  //               setBiggestWatchDialSize(parseFloat(sortedByDialSize[0].watchDialSize));
  //             }

  //             const filteredWatchYearMadeArr = arr.filter(item => item.watchYearMade !== "");
  //             if (filteredWatchYearMadeArr.length > 0) {
  //               const sortedByYearMade = [...filteredWatchYearMadeArr].sort((a, b) => b.watchYearMade - a.watchYearMade);
  //               setNewestWatchYear(parseFloat(sortedByYearMade[0].watchYearMade));
  //               setOldestWatchYear(parseFloat(sortedByYearMade[sortedByYearMade.length - 1].watchYearMade));
  //             }
  //           } else if (categorySearch === "سبح") {
  //             const filteredRosaryCountArr = arr.filter(item => item.rosaryCount !== "");

  //             if(filteredRosaryCountArr.length > 0){
  //               const sortedByRosaryCount = [...arr].sort((a, b) => b.rosaryCount - a.rosaryCount);
  //               setMostRosaryCount(parseFloat(sortedByRosaryCount[0].rosaryCount));
  //               setLeastRosaryCount(parseFloat(sortedByRosaryCount[sortedByRosaryCount.length - 1].rosaryCount));
  //               }

  //             const filteredRosarySizeArr = arr.filter(item => item.rosarySize !== "");
  //             if (filteredRosarySizeArr.length > 0) {
  //               const sortedByRosarySize = [...filteredRosarySizeArr].sort((a, b) => b.rosarySize - a.rosarySize);
  //               setBiggestRosarySize(parseFloat(sortedByRosarySize[0].rosarySize));
  //               setSmallestRosarySize(parseFloat(sortedByRosarySize[sortedByRosarySize.length - 1].rosarySize));

  //               // rosary smallest size filter
  //               if (smallestRosarySizeFilter && parseFloat(smallestRosarySizeFilter) > 0) {
  //                 arr = arr.filter(item => item.rosarySize >= parseFloat(smallestRosarySizeFilter));
  //               }

  //               // rosary biggest size filter
  //               if (biggestRosarySizeFilter && parseFloat(biggestRosarySizeFilter) > 0) {
  //                 arr = arr.filter(item => item.rosarySize <= parseFloat(biggestRosarySizeFilter));
  //               }
  //             }
  //           }
  //           else if(categorySearch === "حقائب نسائية"){
  //             const filteredPurseInsideColorArr = arr.filter(item => item.purseInsideColor !== "");
  //             if (filteredPurseInsideColorArr.length > 0) {
  //               // purse inside coloe filter
  //               if (purseInsideColorFilter && purseInsideColorFilter.length > 0) {
  //                 arr = arr.filter(item => purseInsideColorFilter.includes(item.purseInsideColor));
  //               }
  //             }
  //           }
  //         }

  //       let sortedArr;
  //       switch (selectedSortOption) {
  //         case "من الأقدم إلى الأحدث":
  //           sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
  //           break;
  //           case "من الأحدث إلى الأقدم":
  //           sortedArr = [...arr].sort((a, b) => a.createdAt - b.createdAt);
  //           break;
  //         case "سعر الشراء تنازليا":
  //           const filteredAndSortedDescHasProductPrice = [...arr]
  //           .filter(post => post.productPrice !== "")  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => b.productPrice - a.productPrice);
  //           sortedArr = filteredAndSortedDescHasProductPrice
  //           break;
  //         case "سعر الشراء تصاعديا":
  //           const filteredAndSortedAscHasProductPrice = [...arr]
  //           .filter(post => post.productPrice !== "")  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => a.productPrice - b.productPrice);
  //           sortedArr = filteredAndSortedAscHasProductPrice
  //           break;
  //         case "تاريخ انتهاء المزاد تصاعديا":
  //           const filteredAndSortedAscArr_expiryDate = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => a.expiryDate - b.expiryDate);
  //           sortedArr = filteredAndSortedAscArr_expiryDate
  //           break;
  //         case "تاريخ انتهاء المزاد تنازليا":
  //           const filteredAndSortedDescArr_expiryDate = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => b.expiryDate - a.expiryDate);
  //           sortedArr = filteredAndSortedDescArr_expiryDate
  //           break;
  //         case "سعر المزايدة الحالي تنازليا":
  //           const filteredAndSortedDescArr_currentBid = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => b.currentBid - a.currentBid);
  //           sortedArr = filteredAndSortedDescArr_currentBid
  //           break;
  //         case "سعر المزايدة الحالي تصاعديا":
  //           const filteredAndSortedAscArr_currentBid = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => a.currentBid - b.currentBid);
  //           sortedArr = filteredAndSortedAscArr_currentBid
  //           break;
  //         case "عدد المزايدين تصاعديا":
  //           const filteredAndSortedAscArr_numBidders = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => a.numBidders - b.numBidders);
  //           sortedArr = filteredAndSortedAscArr_numBidders
  //           break;
  //         case "عدد المزايدين تنازليا":
  //           const filteredAndSortedDescArr_numBidders = [...arr]
  //           .filter(post => post.postType.includes("مزاد"))  // Filter posts where postType includes "مزاد"
  //           .sort((a, b) => b.numBidders - a.numBidders);
  //           sortedArr = filteredAndSortedDescArr_numBidders
  //           break;
  //         default:
  //           sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
  //           break;
  //       }

  //       setListing(sortedArr);
  //       setInitialListing(sortedArr);

  //       const productsFound = sortedArr.length;
  //       setNumProducts(
  //         productsFound === 0
  //           ? "لم يتم العثور على منتجات"
  //           : productsFound === 1
  //             ? "تم العثور على منتج واحد"
  //             : productsFound === 2
  //               ? "تم العثور على منتجين"
  //               : productsFound > 0 && productsFound <= 10
  //                 ? `تم العثور على ${productsFound} منتجات`
  //                 : `تم العثور على ${productsFound} منتج`
  //       );

  //     } else {
  //       console.log("No documents found.");
  //       setNumProducts("لم يتم العثور على منتجات");
  //       setListing([]);
  //       setInitialListing([]);
  //     }
  //     setIsLoading(false);
  //   });

  //   return () => listener();
  // }, [
  //   qu,
  //   res,
  //   numBidders,
  //   orderSelectedName,
  //   conditionFilter,
  //   postTypeFilter,
  //   lowestPriceFilter,
  //   heighestPriceFilter,
  //   lowestEntryFeeFilter,
  //   heighestEntryFeeFilter,
  //   lowestStartingPriceFilter,
  //   heighestStartingPriceFilter,
  //   lowestBidIncrementFilter,
  //   heighestBidIncrementFilter,
  //   shippingMethodsFilter,
  //   companyFilter,
  //   watchDepartmentFilter,
  //   watchOutsideColorFilter,
  //   watchInsideColorFilter,
  //   watchDialShapeFilter,
  //   smallestDialSizeFilter,
  //   biggestDialSizeFilter,
  //   watchHandlesColorFilter,
  //   watchNumbersColorFilter,
  //   watchNumbersLanguageFilter,
  //   watchBandMaterialFilter,
  //   watchBandColorFilter,
  //   watchHasOriginalPackagingFilter,
  //   watchIsWaterResistantFilter,
  //   watchOldestYearMadeFilter,
  //   watchNewestYearMadeFilter,
  //   rosaryKindFilter,
  //   rosaryColorFilter,
  //   leastRosaryCountFilter,
  //   mostRosaryCountFilter,
  //   smallestRosarySizeFilter,
  //   biggestRosarySizeFilter,
  //   walletDepartmentFilter,
  //   walletOutsideColorFilter,
  //   purseMaterialFilter,
  //   purseOutsideColorFilter,
  //   purseInsideColorFilter,
  //   carPlateKindFilter,
  //   carPlateTransferFeeOnFilter,
  //   carPlateTransferTimeFilter,
  //   phoneNumberCourierFilter,
  //   urlSortOption
  // ]);

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
  <Helmet>
  <title>{categorySearch || res}</title>
  <meta name="description" content={categorySearch || res} />
  <meta name="keywords" content={categorySearch || res} />
  <meta property="og:type" content="product" />
  <meta property="og:title" content={categorySearch || res} />
  <meta property="og:description" content={categorySearch || res} />
  <meta property="og:image" content={logo} />
  <meta property="og:url" content={window.location.href} />
  <meta property="og:site_name" content="منصة بيع" />
  <meta name="twitter:title" content={categorySearch || res} />
  <meta name="twitter:description" content={categorySearch || res} />
  <meta name="twitter:image" content={logo} />
</Helmet>

      <InfiniteScroll
        isLoading={isLoading}
        dataLength={listing.length} //This is important field to render the next data
        next={() => {
          setLastDoc((x) => x + 1);
        }}
        hasMore={hasMore}
        loader={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100px",
            }}
          >
            <Oval
              height={"35px"}
              width={"35px"}
              color={"#00C8B8"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel="oval-loading"
              secondaryColor="#ccc"
              strokeWidth={2}
              strokeWidthSecondary={1}
            />
          </div>
        }
        endMessage={""}
      >
      <div
        id="products-screen-div"
        style={{
          minHeight:
            window.innerWidth > window.innerHeight
              ? "calc(100vh - 65px)"
              : "calc(100vh - 110px)",
          opacity: isVisible ? 1 : 0, // Fade in effect based on isVisible
          transform: isVisible ? "translateY(0)" : "translateY(20px)", // Move up effect based on isVisible
          transition: "opacity 0.3s ease, transform 0.2s ease", // Transition effects
        }}
      >
          {/* path */}
          <div
            style={{
              backgroundColor: "#F7F7F7",
              border: "1px solid #E8E9E9",
              width: "100%",
              height: "100%",
              marginTop: "18px",
              padding: "11px",
              borderRadius: "8px",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", direction: "rtl" }}>
              <GoHomeFill
                size={18}
                style={{ marginLeft: "6px", color: "#00C8B8" }}
              />
              <a href="/">
                <h6
                  style={{
                    marginBottom: "0px",
                    color: "#01635c",
                    cursor: "pointer",
                    fontSize: "14px",
        fontWeight:"bold"
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#00A49B";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#01635c";
                  }}
                >
                  الرئيسية
                </h6>
              </a>
              <h6 style={{ margin: "0px 6px", fontSize: "14px" }}>/</h6>
              <h6 style={{ marginBottom: "0px", fontSize: "14px" }}>
                {categorySearch || res || (numBidders ? "مزادات رائجة" : "منتجات رائجة")}
              </h6>
            </div>
          </div>

          <div id="numproducts-and-filters">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#01635c",
                marginBottom: "0px",
                opacity: productsLoading ? 0 : 1,
                transform: productsLoading ? "translateY(5px)" : "translateY(0px)", // Move up effect based on isVisible
                transition: "opacity 0.3s ease, transform 0.3s ease", // Transition effects
              }}
            >
              {numProducts}
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {/* <p
                style={{
                  fontSize: "15px",
                  padding: "8px 4px",
                  margin: "auto",
                  fontWeight: 500,
                  color:"#01635c"
                }}
                onClick={() => {
                  toggleShowFilters();
                }}
              >
                تصفية
              </p> */}

              {[
                conditionFilterArray,
                postTypeFilterArray,
                companyFilterArray,
                watchDepartmentFilterArray,
                watchOutsideColorFilterArray,
                watchInsideColorFilterArray,
                watchDialShapeFilterArray,
                watchHandlesColorFilterArray,
                watchNumbersColorFilterArray,
                watchNumbersLanguageFilterArray,
                watchBandMaterialFilterArray,
                watchBandColorFilterArray,
                watchHasOriginalPackagingFilterArray,
                watchIsWaterResistantFilterArray,
                rosaryKindFilterArray,
                rosaryColorFilterArray,
                walletDepartmentFilterArray,
                walletOutsideColorFilterArray,
                purseMaterialFilterArray,
                purseOutsideColorFilterArray,
                purseInsideColorFilterArray,
                carPlateKindFilterArray,
                carPlateTransferFeeOnFilterArray,
                carPlateTransferTimeFilterArray,
                phoneNumberCourierFilterArray,
                shippingMethodsFilterArray,
              ].some((array) => array.length > 0) ||
              [
                smallestDialSizeFilter,
                biggestDialSizeFilter,
                watchOldestYearMadeFilter,
                watchNewestYearMadeFilter,
                leastRosaryCountFilter,
                mostRosaryCountFilter,
                smallestRosarySizeFilter,
                biggestRosarySizeFilter,
                lowestPriceFilter,
                heighestPriceFilter,
                lowestEntryFeeFilter,
                heighestEntryFeeFilter,
                lowestStartingPriceFilter,
                heighestStartingPriceFilter,
                lowestBidIncrementFilter,
                heighestBidIncrementFilter,
              ].some((filter) => filter) ||
              urlSortOption ? (
                <div style={{ padding: "2px 0px", position:"relative",
                  opacity: productsLoading ? 0 : 1,
                  transform: productsLoading ? "translateY(5px)" : "translateY(0px)", // Move up effect based on isVisible
                  transition: "opacity 0.3s ease, transform 0.3s ease", // Transition effects
                }}>
                  <FaCircle style={{minHeight:"12px", minWidth:"12px", height:"12px", width:"12px", border:"2px solid white", position:"absolute", top: "0.1rem", right:0, borderRadius:"50%"}} color="#01635c"/>
                  <LiaSlidersHSolid
                  color="#01635c"
                  size={22}
                    style={{ marginBottom: "2px" }}
                    onClick={() => {
                      toggleShowFilters();
                    }}
                  />
                </div>
              ) : (
                <div style={{ padding: "2px 0px",
                  opacity: productsLoading ? 0 : 1,
                  transform: productsLoading ? "translateY(5px)" : "translateY(0px)", // Move up effect based on isVisible
                  transition: "opacity 0.3s ease, transform 0.3s ease", // Transition effects
                }}>
                  <LiaSlidersHSolid
                  color="#01635c"
                  size={22}
                    style={{ marginBottom: "2px" }}
                    onClick={() => {
                      toggleShowFilters();
                    }}
                  />
                </div>
              )}
            </div>

            {/* {!showFilters ? <BiChevronDown size={22} style={{ marginRight: "0px"}}/> : <BiChevronUp size={22} style={{ marginRight: "0px"}}/> } */}
          </div>

          <div style={{ display: "flex", direction: "rtl", minHeight: "100vh" }}>
            {/* <div style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
            width: "100vw",
            height: "100vh",
            overflow: "hidden", // Optional, depending on your layout needs
            display: showFilters ? "block" : "none",
            position: "fixed",
            zIndex: 31,
            top: 0,
            right: 0, // Remove left: 0 if unnecessary
            transition: `opacity ${showFilters ? '0.5s ease-in' : 'none'}`,
          }}> */}

            <Drawer
              anchor="left"
              open={showFilters}
              onClose={() => {
                setShowFilters(false);
              }}
            >
              <div
                ref={filterDivRef}
                style={{
                  direction: "rtl",
                  overflowY: "auto",
                  // maxHeight: showFilters ? '100vh' : '0vh',
                  // opacity: showFilters ? 1 : 0,
                  // transition: 'max-height 0.2s ease, opacity 0.2s ease, left 0.2s ease',
                  display: "block", // Always 'block' as per the original condition
                  position: "relative",
                  // left: showFilters ? 0 : "-100%",
                  // zIndex: 32,
                  // top: 0,
                  // minHeight:"100vh",
                  backgroundColor: "white",
                  paddingTop:
                    window.innerWidth > window.innerHeight ? "16px" : "24px",
                  paddingRight: window.innerWidth < 600 ? "16px" : "32px",
                  paddingBottom: "5vh",
                  // boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                  // marginRight: window.innerWidth < 600 ? '16px' : '0px',
                  width: window.innerWidth > window.innerHeight ? "25vw" : "80vw", // Adjusted width as requested
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: window.innerWidth < 600 ? "24px" : "16px",
                    alignItems: "center",
                    marginLeft:
                      window.innerWidth > window.innerHeight ? "8%" : "11px",
                  }}
                >
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <LiaSlidersHSolid
                      size={20}
                  color="#01635c"
                      
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        cursor: "pointer",
                      }}
                      
                    />
                    <h5
                      style={{
                  color:"#01635c",
                        marginRight: "6px",
                        marginBottom: "2px",
                        fontSize: "16px",

                      }}
                    >
                      تصفية
                    </h5>
                  </div>
                  <div style={{ marginRight: "auto" }}>
                    <div
                      style={{ padding: "4px", cursor: "pointer" }}
                      onClick={() => {
                        toggleShowFilters();
                      }}
                    >
                      <IoCloseCircleOutline
                        color="#F00057"
                        size={24}
                        style={{
                          marginTop: "auto",
                          marginBottom: "1px",
                          cursor: "pointer",
                          marginRight: "4px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                {categorySearch !== "لوح سيارات" &&
                  categorySearch !== "ارقام جوالات" && (
                    <>
                      {/* product condition */}
                      <div style={{ marginBottom: "16px" }}>
                        <div
                          style={{
                            width: "100%",
                            paddingTop: "4px",
                            paddingLeft:
                              window.innerWidth > window.innerHeight
                                ? "10%"
                                : "16px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setConditionFilterClicked(!conditionFilterClicked);
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <LuCircleDashed
                              color="#00C8B8"
                              size={18}
                              style={{ marginBottom: "0px", marginLeft: "8px" }}
                            />

                            <h6
                              style={{ fontSize: "15px", fontWeight: 500 }}
                              id="filter-headers"
                            >
                              حالة المنتج
                            </h6>
                          </div>
                          <FiChevronLeft
                            size={14}
                            style={{
                              marginBottom: "6px",
                              transform: conditionFilterClicked
                                ? "rotate(-90deg)"
                                : "none",
                              transition: "transform 0.2s ease-in-out",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: conditionFilterClicked ? "300px" : "0",
                            opacity: conditionFilterClicked ? "1" : "0",
                            overflow: "hidden",
                            transition:
                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="الكل"
                              checked={productConditionChecked_all}
                              onChange={handleProductConditionCheckboxChange_all}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="جديد"
                              checked={productConditionChecked_new}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_new(newCheckedStatus);
                                handleProductConditionCheckboxChange_others(
                                  "جديد",
                                  newCheckedStatus,
                                  setProductConditionChecked_new
                                );
                              }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="مستعمل"
                              checked={productConditionChecked_used}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_used(newCheckedStatus);
                                handleProductConditionCheckboxChange_others(
                                  "مستعمل",
                                  newCheckedStatus,
                                  setProductConditionChecked_used
                                );
                              }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="ممتازة"
                              checked={productConditionChecked_excellent}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_excellent(
                                  newCheckedStatus
                                );
                                handleProductConditionCheckboxChange_others(
                                  "ممتازة",
                                  newCheckedStatus,
                                  setProductConditionChecked_excellent
                                );
                              }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="جيدة جدا"
                              checked={productConditionChecked_veryGood}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_veryGood(
                                  newCheckedStatus
                                );
                                handleProductConditionCheckboxChange_others(
                                  "جيدة جدا",
                                  newCheckedStatus,
                                  setProductConditionChecked_veryGood
                                );
                              }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="جيدة"
                              checked={productConditionChecked_good}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_good(newCheckedStatus);
                                handleProductConditionCheckboxChange_others(
                                  "جيدة",
                                  newCheckedStatus,
                                  setProductConditionChecked_good
                                );
                              }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                              label="مقبولة"
                              checked={productConditionChecked_acceptable}
                              onChange={(newCheckedStatus) => {
                                setProductConditionChecked_acceptable(
                                  newCheckedStatus
                                );
                                handleProductConditionCheckboxChange_others(
                                  "مقبولة",
                                  newCheckedStatus,
                                  setProductConditionChecked_acceptable
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* post type */}
                <div style={{ marginBottom: "16px" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "4px",
                      paddingLeft:
                        window.innerWidth > window.innerHeight ? "10%" : "16px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setPostTypeFilterClicked(!postTypeFilterClicked);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <MdSell
                        color="#00C8B8"
                        size={18}
                        style={{ marginBottom: "0px", marginLeft: "8px" }}
                      />

                      <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                        طريقة العرض
                      </h6>
                    </div>
                    <FiChevronLeft
                      size={14}
                      style={{
                        marginBottom: "6px",
                        transform: postTypeFilterClicked
                          ? "rotate(-90deg)"
                          : "none",
                        transition: "transform 0.2s ease-in-out",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      maxHeight: postTypeFilterClicked ? "200px" : "0",
                      opacity: postTypeFilterClicked ? "1" : "0",
                      overflow: "hidden",
                      transition:
                        "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        label="الكل"
                        checked={productPostTypeChecked_all}
                        onChange={handleProductPostTypeCheckboxChange_all}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        label="شراء فوري"
                        checked={productPostTypeChecked_buyNow}
                        onChange={(newCheckedStatus) => {
                          setProductPostTypeChecked_buyNow(newCheckedStatus);
                          handleProductPostTypeCheckboxChange_others(
                            "شراء فوري",
                            newCheckedStatus,
                            setProductPostTypeChecked_buyNow
                          );
                        }}
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        label="مزاد"
                        checked={productPostTypeChecked_auction}
                        onChange={(newCheckedStatus) => {
                          setProductPostTypeChecked_auction(newCheckedStatus);
                          handleProductPostTypeCheckboxChange_others(
                            "مزاد",
                            newCheckedStatus,
                            setProductPostTypeChecked_auction
                          );
                        }}
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        label="قابل للتفاوض"
                        checked={productPostTypeChecked_negotiable}
                        onChange={(newCheckedStatus) => {
                          setProductPostTypeChecked_negotiable(newCheckedStatus);
                          handleProductPostTypeCheckboxChange_others(
                            "قابل للتفاوض",
                            newCheckedStatus,
                            setProductPostTypeChecked_negotiable
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* price  */}
                <div style={{ marginBottom: "16px" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "4px",
                      paddingLeft:
                        window.innerWidth > window.innerHeight ? "10%" : "16px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setPriceFilterClicked(!postPriceFilterClicked);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <FaMoneyBill
                        color="#00C8B8"
                        size={18}
                        style={{ marginBottom: "0px", marginLeft: "8px" }}
                      />

                      <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                        السعر
                      </h6>
                    </div>
                    <FiChevronLeft
                      size={14}
                      style={{
                        transform: postPriceFilterClicked
                          ? "rotate(-90deg)"
                          : "none",
                        transition: "transform 0.2s ease-in-out",
                        marginBottom: "3px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      maxHeight: postPriceFilterClicked ? "700px" : "0",
                      opacity: postPriceFilterClicked ? "1" : "0",
                      transition:
                        "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                      width: "92%",
                      overflow: postPriceFilterClicked ? "unset" : "hidden",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>سعر الشراء الفوري:</p>
                    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                      <Slider
                        defaultValue={0}
                        max={mostExpensiveItemPrice || 0}
                        min={leastExpensiveItemPrice || 0}
                        value={[
                          leastPriceSelected
                            ? leastPriceSelected
                            : leastExpensiveItemPrice,
                          mostPriceSelected
                            ? mostPriceSelected
                            : mostExpensiveItemPrice,
                        ]}
                        onChange={handleSliderChange}
                        style={{ color: "#00C8B8", marginTop: "0px" }}
                        valueLabelDisplay="auto"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "12px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => handleMostPriceChange(e)}
                        placeholder="إلى"
                        type="tel"
                        value={mostPriceSelected || mostExpensiveItemPrice}
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />{" "}
                      -
                      <input
                        onChange={(e) => handleLeastPriceChange(e)}
                        placeholder="من"
                        type="tel"
                        value={leastPriceSelected || leastExpensiveItemPrice}
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />
                      {priceFiltered && (
                        <IoCloseCircleOutline
                          size={22}
                          style={{
                            color: "#F00057",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPriceFiltered(false);
                            setMostPriceSelected("");
                            setLeastPriceSelected("");
                          }}
                        />
                      )}
                    </div>

                    {/* entry fee filter  */}
                    <p style={{ marginBottom: "0px" }}>عربون دخول المزاد:</p>
                    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                      <Slider
                        defaultValue={0}
                        max={mostExpensiveItemEntryFee || 0}
                        min={leastExpensiveItemEntryFee || 0}
                        value={[
                          leastEntryFeeSelected
                            ? leastEntryFeeSelected
                            : leastExpensiveItemEntryFee,
                          mostEntryFeeSelected
                            ? mostEntryFeeSelected
                            : mostExpensiveItemEntryFee,
                        ]}
                        onChange={handleSliderChangeEntryFee}
                        style={{ color: "#00C8B8", marginTop: "0px" }}
                        valueLabelDisplay="auto"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "12px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => handleMostEntryFeeChange(e)}
                        placeholder="إلى"
                        type="tel"
                        value={mostEntryFeeSelected || mostExpensiveItemEntryFee}
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />{" "}
                      -
                      <input
                        onChange={(e) => handleLeastEntryFeeChange(e)}
                        placeholder="من"
                        type="tel"
                        value={
                          leastEntryFeeSelected || leastExpensiveItemEntryFee
                        }
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />
                      {entryFeeFiltered && (
                        <IoCloseCircleOutline
                          size={22}
                          style={{
                            color: "#F00057",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEntryFeeFiltered(false);
                            setLeastExpensiveItemEntryFeeSelected("");
                            setMostExpensiveItemEntryFeeSelected("");
                          }}
                        />
                      )}
                    </div>

                    {/* starting price filter */}
                    <p style={{ marginBottom: "0px" }}>مبلغ افتتاح المزاد:</p>
                    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                      <Slider
                        defaultValue={0}
                        max={mostExpensiveItemStartingPrice || 0}
                        min={leastExpensiveItemStartingPrice || 0}
                        value={[
                          leastStartingPriceSelected
                            ? leastStartingPriceSelected
                            : leastExpensiveItemStartingPrice,
                          mostStartingPriceSelected
                            ? mostStartingPriceSelected
                            : mostExpensiveItemStartingPrice,
                        ]}
                        onChange={handleSliderChangeStartingPrice}
                        style={{ color: "#00C8B8", marginTop: "0px" }}
                        valueLabelDisplay="auto"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "12px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => handleMostStartingPriceChange(e)}
                        placeholder="إلى"
                        type="tel"
                        value={
                          mostStartingPriceSelected ||
                          mostExpensiveItemStartingPrice
                        }
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />{" "}
                      -
                      <input
                        onChange={(e) => handleLeastStartingPriceChange(e)}
                        placeholder="من"
                        type="tel"
                        value={
                          leastStartingPriceSelected ||
                          leastExpensiveItemStartingPrice
                        }
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />
                      {startingPriceFiltered && (
                        <IoCloseCircleOutline
                          size={22}
                          style={{
                            color: "#F00057",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setStartingPriceFiltered(false);
                            setLeastExpensiveItemStartingPriceSelected("");
                            setMostExpensiveItemStartingPriceSelected("");
                          }}
                        />
                      )}
                    </div>

                    {/* bid increments filter */}
                    <div style={{ paddingLeft: "16px", paddingRight: "8px" }}>
                      <p style={{ marginBottom: "0px" }}>
                        مبلغ مضاعفات المزايدة:
                      </p>
                      <Slider
                        defaultValue={0}
                        max={mostExpensiveBidIncrement || 0}
                        min={leastExpensiveBidIncrement || 0}
                        value={[
                          leastBidIncrementSelected
                            ? leastBidIncrementSelected
                            : leastExpensiveBidIncrement,
                          mostBidIncrementSelected
                            ? mostBidIncrementSelected
                            : mostExpensiveBidIncrement,
                        ]}
                        onChange={handleSliderChangeBidIncrement}
                        style={{ color: "#00C8B8", marginTop: "0px" }}
                        valueLabelDisplay="auto"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "12px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => handleMostBidIncrementChange(e)}
                        placeholder="إلى"
                        type="tel"
                        value={
                          mostBidIncrementSelected || mostExpensiveBidIncrement
                        }
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />{" "}
                      -
                      <input
                        onChange={(e) => handleLeastBidIncrementChange(e)}
                        placeholder="من"
                        type="tel"
                        value={
                          leastBidIncrementSelected || leastExpensiveBidIncrement
                        }
                        style={{
                          width: "40%",
                          border: "1px solid #00C8B8",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      />
                      {BidIncrementFiltered && (
                        <IoCloseCircleOutline
                          size={22}
                          style={{
                            color: "#F00057",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setBidIncrementFiltered(false);
                            setLeastExpensiveBidIncrementSelected("");
                            setMostExpensiveBidIncrementSelected("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* shipping method   */}
                {categorySearch !== "لوح سيارات" &&
                  categorySearch !== "ارقام جوالات" && (
                    <>
                      <div style={{ marginBottom: "16px" }}>
                        <div
                          style={{
                            width: "100%",
                            paddingTop: "4px",
                            paddingLeft:
                              window.innerWidth > window.innerHeight
                                ? "10%"
                                : "16px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setShippingMethodsFilterClicked(
                              !shippingMethodsFilterClicked
                            );
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <FaShippingFast
                              color="#00C8B8"
                              size={18}
                              style={{ marginBottom: "0px", marginLeft: "8px" }}
                            />

                            <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                              طرق الشحن و التوصيل
                            </h6>
                          </div>
                          <FiChevronLeft
                            size={14}
                            style={{
                              marginBottom: "6px",
                              transform: shippingMethodsFilterClicked
                                ? "rotate(-90deg)"
                                : "none",
                              transition: "transform 0.2s ease-in-out",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: shippingMethodsFilterClicked
                              ? "200px"
                              : "0",
                            opacity: shippingMethodsFilterClicked ? "1" : "0",
                            overflow: "hidden",
                            transition:
                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                          }}
                        >
                          <CustomCheckbox
                            checked={shippingMethodChecked_all}
                            onChange={(newCheckedStatus) =>
                              handleShippingMethodCheckboxChange_all(
                                newCheckedStatus
                              )
                            }
                            label="الكل"
                            labelStyle={{
                              fontSize: "15px",
                              marginBottom: "6px",
                              marginRight: "8px",
                            }}
                          />

                          <CustomCheckbox
                            checked={shippingMethodChecked_shipping}
                            onChange={(newCheckedStatus) =>
                              handleShippingMethodCheckboxChange_others(
                                "شحن",
                                newCheckedStatus,
                                setShippingMethodChecked_shipping
                              )
                            }
                            label="شحن"
                            labelStyle={{
                              fontSize: "15px",
                              marginBottom: "6px",
                              marginRight: "8px",
                            }}
                          />

                          <CustomCheckbox
                            checked={shippingMethodChecked_delivery}
                            onChange={(newCheckedStatus) =>
                              handleShippingMethodCheckboxChange_others(
                                "تسليم",
                                newCheckedStatus,
                                setShippingMethodChecked_delivery
                              )
                            }
                            label="تسليم"
                            labelStyle={{
                              fontSize: "15px",
                              marginBottom: "6px",
                              marginRight: "8px",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                {/* product company */}
                {(categorySearch === "ساعات" ||
                  categorySearch === "اقلام" ||
                  categorySearch === "ابواك" ||
                  categorySearch === "كاميرات" ||
                  categorySearch === "حقائب نسائية") && (
                  <>
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setProductCompanyFilterClicked(
                            !productCompanyFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <MdFactory
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            الشركة المصنعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: productCompanyFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: productCompanyFilterClicked ? "800px" : "0",
                          opacity: productCompanyFilterClicked ? "1" : "0",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                          width: "100%",
                          overflow: productCompanyFilterClicked
                            ? "unset"
                            : "hidden",
                        }}
                      >
                        <div
                          style={{
                            padding: "0px",
                            transition: "padding 0.3s ease-in-out",
                            maxHeight: "100%",
                          }}
                        >
                          {categorySearch === "كاميرات" && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="الكل"
                                  checked={companyChecked_all}
                                  onChange={
                                    handleProductCompanyCheckboxChange_all
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Sony"
                                  checked={companyChecked_sony}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_sony(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Sony",
                                      newCheckedStatus,
                                      setCompanyChecked_sony
                                    );
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Canon"
                                  checked={companyChecked_canon}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_canon(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Canon",
                                      newCheckedStatus,
                                      setCompanyChecked_canon
                                    );
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Nikon"
                                  checked={companyChecked_nikon}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_nikon(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Nikon",
                                      newCheckedStatus,
                                      setCompanyChecked_nikon
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="أخرى"
                                  checked={companyChecked_other}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_other(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "أخرى",
                                      newCheckedStatus,
                                      setCompanyChecked_other
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}

                          {categorySearch === "ساعات" && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="الكل"
                                  checked={companyChecked_all}
                                  onChange={
                                    handleProductCompanyCheckboxChange_all
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Rolex"
                                  checked={companyChecked_rolex}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_rolex(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Rolex",
                                      newCheckedStatus,
                                      setCompanyChecked_rolex
                                    );
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Omega"
                                  checked={companyChecked_omega}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_omega(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Omega",
                                      newCheckedStatus,
                                      setCompanyChecked_omega
                                    );
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Cartier"
                                  checked={companyChecked_cartier}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_cartier(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Cartier",
                                      newCheckedStatus,
                                      setCompanyChecked_cartier
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Versace"
                                  checked={companyChecked_versace}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_versace(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Versace",
                                      newCheckedStatus,
                                      setCompanyChecked_versace
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Montblanc"
                                  checked={companyChecked_montBlanc}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_montBlanc(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Montblanc",
                                      newCheckedStatus,
                                      setCompanyChecked_montBlanc
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Aigner"
                                  checked={companyChecked_aigner}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_aigner(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Aigner",
                                      newCheckedStatus,
                                      setCompanyChecked_aigner
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Rado"
                                  checked={companyChecked_rado}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_rado(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Rado",
                                      newCheckedStatus,
                                      setCompanyChecked_rado
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Givenchy"
                                  checked={companyChecked_givenchy}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_givenchy(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Givenchy",
                                      newCheckedStatus,
                                      setCompanyChecked_givenchy
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Chopard"
                                  checked={companyChecked_chopard}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_chopard(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Chopard",
                                      newCheckedStatus,
                                      setCompanyChecked_chopard
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Tissot"
                                  checked={companyChecked_tissot}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_tissot(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Tissot",
                                      newCheckedStatus,
                                      setCompanyChecked_tissot
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Patek Philippe"
                                  checked={companyChecked_patikPhillipe}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_patikPhillipe(
                                      newCheckedStatus
                                    );
                                    handleProductCompanyCheckboxChange_others(
                                      "Patek Philippe",
                                      newCheckedStatus,
                                      setCompanyChecked_patikPhillipe
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Armani Exchange"
                                  checked={companyChecked_armaniExchange}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_armaniExchange(
                                      newCheckedStatus
                                    );
                                    handleProductCompanyCheckboxChange_others(
                                      "Armani Exchange",
                                      newCheckedStatus,
                                      setCompanyChecked_armaniExchange
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="G-Shock"
                                  checked={companyChecked_gShock}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_gShock(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "G-Shock",
                                      newCheckedStatus,
                                      setCompanyChecked_gShock
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Apple"
                                  checked={companyChecked_apple}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_apple(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Apple",
                                      newCheckedStatus,
                                      setCompanyChecked_apple
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Huawei"
                                  checked={companyChecked_huawei}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_huawei(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Huawei",
                                      newCheckedStatus,
                                      setCompanyChecked_huawei
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Samsung"
                                  checked={companyChecked_samsung}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_samsung(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Samsung",
                                      newCheckedStatus,
                                      setCompanyChecked_samsung
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="Xiaomi"
                                  checked={companyChecked_xiaomi}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_xiaomi(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "Xiaomi",
                                      newCheckedStatus,
                                      setCompanyChecked_xiaomi
                                    );
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomCheckbox
                                  label="أخرى"
                                  checked={companyChecked_other}
                                  onChange={(newCheckedStatus) => {
                                    setCompanyChecked_other(newCheckedStatus);
                                    handleProductCompanyCheckboxChange_others(
                                      "أخرى",
                                      newCheckedStatus,
                                      setCompanyChecked_other
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}

                          {categorySearch === "اقلام" && (
                            <>
                              <CustomCheckbox
                                label="الكل"
                                checked={companyChecked_all}
                                onChange={handleProductCompanyCheckboxChange_all}
                              />

                              <CustomCheckbox
                                label="Rolex"
                                checked={companyChecked_rolex}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_rolex(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Rolex",
                                    newCheckedStatus,
                                    setCompanyChecked_rolex
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Omega"
                                checked={companyChecked_omega}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_omega(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Omega",
                                    newCheckedStatus,
                                    setCompanyChecked_omega
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Cartier"
                                checked={companyChecked_cartier}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_cartier(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Cartier",
                                    newCheckedStatus,
                                    setCompanyChecked_cartier
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Versace"
                                checked={companyChecked_versace}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_versace(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Versace",
                                    newCheckedStatus,
                                    setCompanyChecked_versace
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Montblanc"
                                checked={companyChecked_montBlanc}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_montBlanc(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Montblanc",
                                    newCheckedStatus,
                                    setCompanyChecked_montBlanc
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Aigner"
                                checked={companyChecked_aigner}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_aigner(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Aigner",
                                    newCheckedStatus,
                                    setCompanyChecked_aigner
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Rado"
                                checked={companyChecked_rado}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_rado(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Rado",
                                    newCheckedStatus,
                                    setCompanyChecked_rado
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Givenchy"
                                checked={companyChecked_givenchy}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_givenchy(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Givenchy",
                                    newCheckedStatus,
                                    setCompanyChecked_givenchy
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Chopard"
                                checked={companyChecked_chopard}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_chopard(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Chopard",
                                    newCheckedStatus,
                                    setCompanyChecked_chopard
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Tissot"
                                checked={companyChecked_tissot}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_tissot(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Tissot",
                                    newCheckedStatus,
                                    setCompanyChecked_tissot
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Patek Philippe"
                                checked={companyChecked_patikPhillipe}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_patikPhillipe(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Patek Philippe",
                                    newCheckedStatus,
                                    setCompanyChecked_patikPhillipe
                                  );
                                }}
                              />
                            </>
                          )}

                          {categorySearch === "ابواك" && (
                            <>
                              <CustomCheckbox
                                label="الكل"
                                checked={companyChecked_all}
                                onChange={handleProductCompanyCheckboxChange_all}
                              />

                              <CustomCheckbox
                                label="Chanel"
                                checked={companyChecked_chanel}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_chanel(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Chanel",
                                    newCheckedStatus,
                                    setCompanyChecked_chanel
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Dior"
                                checked={companyChecked_dior}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_dior(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Dior",
                                    newCheckedStatus,
                                    setCompanyChecked_dior
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Louis Vuitton"
                                checked={companyChecked_louisVuitton}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_louisVuitton(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Louis Vuitton",
                                    newCheckedStatus,
                                    setCompanyChecked_louisVuitton
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Versace"
                                checked={companyChecked_versace}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_versace(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Versace",
                                    newCheckedStatus,
                                    setCompanyChecked_versace
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Gucci"
                                checked={companyChecked_gucci}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_gucci(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Gucci",
                                    newCheckedStatus,
                                    setCompanyChecked_gucci
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Dolce & Gabbana"
                                checked={companyChecked_dolceGabbana}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_dolceGabbana(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Dolce & Gabbana",
                                    newCheckedStatus,
                                    setCompanyChecked_dolceGabbana
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Givenchy"
                                checked={companyChecked_givenchy}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_givenchy(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Givenchy",
                                    newCheckedStatus,
                                    setCompanyChecked_givenchy
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Tom Ford"
                                checked={companyChecked_tomFord}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_tomFord(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Tom Ford",
                                    newCheckedStatus,
                                    setCompanyChecked_tomFord
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Fred Perry"
                                checked={companyChecked_fredPerry}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_fredPerry(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Fred Perry",
                                    newCheckedStatus,
                                    setCompanyChecked_fredPerry
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Rolex"
                                checked={companyChecked_rolex}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_rolex(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Rolex",
                                    newCheckedStatus,
                                    setCompanyChecked_rolex
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Omega"
                                checked={companyChecked_omega}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_omega(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Omega",
                                    newCheckedStatus,
                                    setCompanyChecked_omega
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Cartier"
                                checked={companyChecked_cartier}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_cartier(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Cartier",
                                    newCheckedStatus,
                                    setCompanyChecked_cartier
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Montblanc"
                                checked={companyChecked_montBlanc}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_montBlanc(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Montblanc",
                                    newCheckedStatus,
                                    setCompanyChecked_montBlanc
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Aigner"
                                checked={companyChecked_aigner}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_aigner(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Aigner",
                                    newCheckedStatus,
                                    setCompanyChecked_aigner
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Chopard"
                                checked={companyChecked_chopard}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_chopard(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Chopard",
                                    newCheckedStatus,
                                    setCompanyChecked_chopard
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="أخرى"
                                checked={companyChecked_other}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_other(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "أخرى",
                                    newCheckedStatus,
                                    setCompanyChecked_other
                                  );
                                }}
                              />
                            </>
                          )}

                          {categorySearch === "حقائب نسائية" && (
                            <>
                              <CustomCheckbox
                                label="الكل"
                                checked={companyChecked_all}
                                onChange={handleProductCompanyCheckboxChange_all}
                              />

                              <CustomCheckbox
                                label="Chanel"
                                checked={companyChecked_chanel}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_chanel(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Chanel",
                                    newCheckedStatus,
                                    setCompanyChecked_chanel
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Dior"
                                checked={companyChecked_dior}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_dior(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Dior",
                                    newCheckedStatus,
                                    setCompanyChecked_dior
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Louis Vuitton"
                                checked={companyChecked_louisVuitton}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_louisVuitton(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Louis Vuitton",
                                    newCheckedStatus,
                                    setCompanyChecked_louisVuitton
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Versace"
                                checked={companyChecked_versace}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_versace(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Versace",
                                    newCheckedStatus,
                                    setCompanyChecked_versace
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Gucci"
                                checked={companyChecked_gucci}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_gucci(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Gucci",
                                    newCheckedStatus,
                                    setCompanyChecked_gucci
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Dolce & Gabbana"
                                checked={companyChecked_dolceGabbana}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_dolceGabbana(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Dolce & Gabbana",
                                    newCheckedStatus,
                                    setCompanyChecked_dolceGabbana
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Givenchy"
                                checked={companyChecked_givenchy}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_givenchy(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Givenchy",
                                    newCheckedStatus,
                                    setCompanyChecked_givenchy
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Prada"
                                checked={companyChecked_prada}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_prada(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Prada",
                                    newCheckedStatus,
                                    setCompanyChecked_prada
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Balenciaga"
                                checked={companyChecked_balenciaga}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_balenciaga(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Balenciaga",
                                    newCheckedStatus,
                                    setCompanyChecked_balenciaga
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Saint Laurent"
                                checked={companyChecked_saintLaurent}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_saintLaurent(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Saint Laurent",
                                    newCheckedStatus,
                                    setCompanyChecked_saintLaurent
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Tory Burch"
                                checked={companyChecked_toryBurch}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_toryBurch(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Tory Burch",
                                    newCheckedStatus,
                                    setCompanyChecked_toryBurch
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Coach"
                                checked={companyChecked_coach}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_coach(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Coach",
                                    newCheckedStatus,
                                    setCompanyChecked_coach
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Ted Baker"
                                checked={companyChecked_tedBaker}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_tedBaker(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Ted Baker",
                                    newCheckedStatus,
                                    setCompanyChecked_tedBaker
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Charles & Keith"
                                checked={companyChecked_charlesKeith}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_charlesKeith(
                                    newCheckedStatus
                                  );
                                  handleProductCompanyCheckboxChange_others(
                                    "Charles & Keith",
                                    newCheckedStatus,
                                    setCompanyChecked_charlesKeith
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Guess"
                                checked={companyChecked_guess}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_guess(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Guess",
                                    newCheckedStatus,
                                    setCompanyChecked_guess
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Off-White"
                                checked={companyChecked_offWhite}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_offWhite(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Off-White",
                                    newCheckedStatus,
                                    setCompanyChecked_offWhite
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="Michael Kors"
                                checked={companyChecked_michaelKors}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_michaelKors(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "Michael Kors",
                                    newCheckedStatus,
                                    setCompanyChecked_michaelKors
                                  );
                                }}
                              />

                              <CustomCheckbox
                                label="أخرى"
                                checked={companyChecked_other}
                                onChange={(newCheckedStatus) => {
                                  setCompanyChecked_other(newCheckedStatus);
                                  handleProductCompanyCheckboxChange_others(
                                    "أخرى",
                                    newCheckedStatus,
                                    setCompanyChecked_other
                                  );
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* watch department */}
                {categorySearch === "ساعات" && (
                  <>
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchDepartmentFilterClicked(
                            !watchDepartmentFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FaTransgender
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            قسم الساعات
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchDepartmentFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchDepartmentFilterClicked ? "200px" : "0",
                          opacity: watchDepartmentFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchDepartmentChecked_all}
                            onChange={handleWatchDepartmentCheckboxChange_all}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رجالي"
                            checked={watchDepartmentChecked_men}
                            onChange={(newCheckedStatus) => {
                              setWatchDepartmentChecked_men(newCheckedStatus);
                              handleWatchDepartmentCheckboxChange_others(
                                "رجالي",
                                newCheckedStatus,
                                setWatchDepartmentChecked_men
                              );
                            }}
                          />
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="نسائي"
                            checked={watchDepartmentChecked_women}
                            onChange={(newCheckedStatus) => {
                              setWatchDepartmentChecked_women(newCheckedStatus);
                              handleWatchDepartmentCheckboxChange_others(
                                "نسائي",
                                newCheckedStatus,
                                setWatchDepartmentChecked_women
                              );
                            }}
                          />
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="للجنسين"
                            checked={watchDepartmentChecked_unisex}
                            onChange={(newCheckedStatus) => {
                              setWatchDepartmentChecked_unisex(newCheckedStatus);
                              handleWatchDepartmentCheckboxChange_others(
                                "للجنسين",
                                newCheckedStatus,
                                setWatchDepartmentChecked_unisex
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {categorySearch === "ساعات" && (
                  <>
                    {/* watch outside color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchOutsideColorFilterClicked(
                            !watchOutsideColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون قرص الساعة الخارجي
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchOutsideColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchOutsideColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchOutsideColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchOutsideColorChecked_all}
                            onChange={handleWatchOutsideColorCheckboxChange_all}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchOutsideColorChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_stainlessSteel(
                                newCheckedStatus
                              );
                              handleWatchOutsideColorCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أسود"
                            checked={watchOutsideColorChecked_black}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_black(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "أسود",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_black
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أبيض"
                            checked={watchOutsideColorChecked_white}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_white(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "أبيض",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_white
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أحمر"
                            checked={watchOutsideColorChecked_red}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_red(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "أحمر",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_red
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أزرق"
                            checked={watchOutsideColorChecked_blue}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_blue(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "أزرق",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_blue
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أخضر"
                            checked={watchOutsideColorChecked_green}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_green(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "أخضر",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_green
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أصفر"
                            checked={watchOutsideColorChecked_yellow}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_yellow(
                                newCheckedStatus
                              );
                              handleWatchOutsideColorCheckboxChange_others(
                                "أصفر",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_yellow
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="برتقالي"
                            checked={watchOutsideColorChecked_orange}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_orange(
                                newCheckedStatus
                              );
                              handleWatchOutsideColorCheckboxChange_others(
                                "برتقالي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_orange
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بنفسجي"
                            checked={watchOutsideColorChecked_purple}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_purple(
                                newCheckedStatus
                              );
                              handleWatchOutsideColorCheckboxChange_others(
                                "بنفسجي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_purple
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="وردي"
                            checked={watchOutsideColorChecked_pink}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_pink(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "وردي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_pink
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بني"
                            checked={watchOutsideColorChecked_brown}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_brown(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "بني",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_brown
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رمادي"
                            checked={watchOutsideColorChecked_gray}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_gray(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "رمادي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_gray
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="زيتي"
                            checked={watchOutsideColorChecked_olive}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_olive(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "زيتي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_olive
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سماوي"
                            checked={watchOutsideColorChecked_cyan}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_cyan(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "سماوي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_cyan
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="كحلي"
                            checked={watchOutsideColorChecked_navy}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_navy(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "كحلي",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_navy
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيج"
                            checked={watchOutsideColorChecked_beige}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_beige(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "بيج",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_beige
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="آخر"
                            checked={watchOutsideColorChecked_other}
                            onChange={(newCheckedStatus) => {
                              setWatchOutsideColorChecked_other(newCheckedStatus);
                              handleWatchOutsideColorCheckboxChange_others(
                                "آخر",
                                newCheckedStatus,
                                setWatchOutsideColorChecked_other
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch inside color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchInsideColorFilterClicked(
                            !watchInsideColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون قرص الساعة الداخلي
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchInsideColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchInsideColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchInsideColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchInsideColorChecked_all}
                            onChange={handleWatchInsideColorCheckboxChange_all}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchInsideColorChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_stainlessSteel(
                                newCheckedStatus
                              );
                              handleWatchInsideColorCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchInsideColorChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أسود"
                            checked={watchInsideColorChecked_black}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_black(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أسود",
                                newCheckedStatus,
                                setWatchInsideColorChecked_black
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أبيض"
                            checked={watchInsideColorChecked_white}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_white(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أبيض",
                                newCheckedStatus,
                                setWatchInsideColorChecked_white
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أحمر"
                            checked={watchInsideColorChecked_red}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_red(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أحمر",
                                newCheckedStatus,
                                setWatchInsideColorChecked_red
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أزرق"
                            checked={watchInsideColorChecked_blue}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_blue(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أزرق",
                                newCheckedStatus,
                                setWatchInsideColorChecked_blue
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أخضر"
                            checked={watchInsideColorChecked_green}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_green(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أخضر",
                                newCheckedStatus,
                                setWatchInsideColorChecked_green
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أصفر"
                            checked={watchInsideColorChecked_yellow}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_yellow(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "أصفر",
                                newCheckedStatus,
                                setWatchInsideColorChecked_yellow
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="برتقالي"
                            checked={watchInsideColorChecked_orange}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_orange(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "برتقالي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_orange
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بنفسجي"
                            checked={watchInsideColorChecked_purple}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_purple(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "بنفسجي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_purple
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="وردي"
                            checked={watchInsideColorChecked_pink}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_pink(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "وردي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_pink
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بني"
                            checked={watchInsideColorChecked_brown}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_brown(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "بني",
                                newCheckedStatus,
                                setWatchInsideColorChecked_brown
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رمادي"
                            checked={watchInsideColorChecked_gray}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_gray(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "رمادي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_gray
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="زيتي"
                            checked={watchInsideColorChecked_olive}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_olive(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "زيتي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_olive
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سماوي"
                            checked={watchInsideColorChecked_cyan}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_cyan(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "سماوي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_cyan
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="كحلي"
                            checked={watchInsideColorChecked_navy}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_navy(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "كحلي",
                                newCheckedStatus,
                                setWatchInsideColorChecked_navy
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيج"
                            checked={watchInsideColorChecked_beige}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_beige(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "بيج",
                                newCheckedStatus,
                                setWatchInsideColorChecked_beige
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="آخر"
                            checked={watchInsideColorChecked_other}
                            onChange={(newCheckedStatus) => {
                              setWatchInsideColorChecked_other(newCheckedStatus);
                              handleWatchInsideColorCheckboxChange_others(
                                "آخر",
                                newCheckedStatus,
                                setWatchInsideColorChecked_other
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch dial shape */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchDialShapeFilterClicked(
                            !watchDialShapeFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FaShapes
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            شكل قرص الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchDialShapeFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchDialShapeFilterClicked ? "700px" : "0",
                          opacity: watchDialShapeFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchDialShapeChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="دائري"
                            checked={watchDialShapeChecked_round}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "دائري",
                                newCheckedStatus,
                                setWatchDialShapeChecked_round
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="مربع"
                            checked={watchDialShapeChecked_square}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "مربع",
                                newCheckedStatus,
                                setWatchDialShapeChecked_square
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="مستطيل"
                            checked={watchDialShapeChecked_rectangle}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "مستطيل",
                                newCheckedStatus,
                                setWatchDialShapeChecked_rectangle
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيضاوي"
                            checked={watchDialShapeChecked_oval}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "بيضاوي",
                                newCheckedStatus,
                                setWatchDialShapeChecked_oval
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="خماسي"
                            checked={watchDialShapeChecked_pentagon}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "خماسي",
                                newCheckedStatus,
                                setWatchDialShapeChecked_pentagon
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سداسي"
                            checked={watchDialShapeChecked_hexagon}
                            onChange={(newCheckedStatus) => {
                              handleWatchDialShapeCheckboxChange_others(
                                "سداسي",
                                newCheckedStatus,
                                setWatchDialShapeChecked_hexagon
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch dial size */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchDialSizeFilterClicked(
                            !watchDialSizeFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <IoResize
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            حجم قرص الساعة (ملم)
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchDialSizeFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchDialSizeFilterClicked ? "200px" : "0",
                          opacity: watchDialSizeFilterClicked ? "1" : "0",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                          width: "92%",
                          overflow: watchDialSizeFilterClicked
                            ? "unset"
                            : "hidden",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "16px", paddingRight: "16px" }} 
                        >
                          <Slider
                            defaultValue={0}
                            max={50}
                            min={32}
                            value={[
                              (smallestDialSizeSelected
                                ? parseFloat(smallestDialSizeSelected)
                                : smallestDialSize) || 0,
                              (biggestDialSizeSelected
                                ? parseFloat(biggestDialSizeSelected)
                                : biggestDialSize) ||0,
                            ]}
                            onChange={handleSliderChangeWatchDialSize}
                            style={{ color: "#00C8B8", marginTop: "0px" }}
                            valueLabelDisplay="auto"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            paddingBottom: "12px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => handleBiggestWatchDialSizeChange(e)}
                            placeholder="إلى"
                            type="tel"
                            value={biggestDialSizeSelected || biggestDialSize}
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />{" "}
                          -
                          <input
                            onChange={(e) => handleSmallestWatchDialSizeChange(e)}
                            placeholder="من"
                            type="tel"
                            value={smallestDialSizeSelected || smallestDialSize}
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />
                          {watchDialSizeFiltered && (
                            <IoCloseCircleOutline
                              size={22}
                              style={{
                                color: "#F00057",
                                marginRight: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setWatchDialSizeFiltered(false);
                                setSmallestWatchDialSizeSelected("");
                                setBiggestWatchDialSizeSelected("");
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* watch handles color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchHandlesColorFilterClicked(
                            !watchHandlesColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون عقارب الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchHandlesColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchHandlesColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchHandlesColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchHandlesColorChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchHandlesColorChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أسود"
                            checked={watchHandlesColorChecked_black}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أسود",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_black
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أبيض"
                            checked={watchHandlesColorChecked_white}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أبيض",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_white
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أحمر"
                            checked={watchHandlesColorChecked_red}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أحمر",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_red
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أزرق"
                            checked={watchHandlesColorChecked_blue}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أزرق",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_blue
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أخضر"
                            checked={watchHandlesColorChecked_green}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أخضر",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_green
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أصفر"
                            checked={watchHandlesColorChecked_yellow}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "أصفر",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_yellow
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="برتقالي"
                            checked={watchHandlesColorChecked_orange}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "برتقالي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_orange
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بنفسجي"
                            checked={watchHandlesColorChecked_purple}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "بنفسجي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_purple
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="وردي"
                            checked={watchHandlesColorChecked_pink}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "وردي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_pink
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بني"
                            checked={watchHandlesColorChecked_brown}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "بني",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_brown
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رمادي"
                            checked={watchHandlesColorChecked_gray}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "رمادي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_gray
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="زيتي"
                            checked={watchHandlesColorChecked_olive}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "زيتي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_olive
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سماوي"
                            checked={watchHandlesColorChecked_cyan}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "سماوي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_cyan
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="كحلي"
                            checked={watchHandlesColorChecked_navy}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "كحلي",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_navy
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيج"
                            checked={watchHandlesColorChecked_beige}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "بيج",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_beige
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="آخر"
                            checked={watchHandlesColorChecked_other}
                            onChange={(newCheckedStatus) => {
                              handleWatchHandlesColorCheckboxChange_others(
                                "آخر",
                                newCheckedStatus,
                                setWatchHandlesColorChecked_other
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch numbers color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchNumbersColorFilterClicked(
                            !watchNumbersColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون أرقام الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchNumbersColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchNumbersColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchNumbersColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchNumbersColorChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchNumbersColorChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أسود"
                            checked={watchNumbersColorChecked_black}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أسود",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_black
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أبيض"
                            checked={watchNumbersColorChecked_white}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أبيض",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_white
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أحمر"
                            checked={watchNumbersColorChecked_red}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أحمر",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_red
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أزرق"
                            checked={watchNumbersColorChecked_blue}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أزرق",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_blue
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أخضر"
                            checked={watchNumbersColorChecked_green}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أخضر",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_green
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أصفر"
                            checked={watchNumbersColorChecked_yellow}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "أصفر",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_yellow
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="برتقالي"
                            checked={watchNumbersColorChecked_orange}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "برتقالي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_orange
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بنفسجي"
                            checked={watchNumbersColorChecked_purple}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "بنفسجي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_purple
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="وردي"
                            checked={watchNumbersColorChecked_pink}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "وردي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_pink
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بني"
                            checked={watchNumbersColorChecked_brown}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "بني",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_brown
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رمادي"
                            checked={watchNumbersColorChecked_gray}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "رمادي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_gray
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="زيتي"
                            checked={watchNumbersColorChecked_olive}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "زيتي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_olive
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سماوي"
                            checked={watchNumbersColorChecked_cyan}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "سماوي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_cyan
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="كحلي"
                            checked={watchNumbersColorChecked_navy}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "كحلي",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_navy
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيج"
                            checked={watchNumbersColorChecked_beige}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "بيج",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_beige
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="آخر"
                            checked={watchNumbersColorChecked_other}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersColorCheckboxChange_others(
                                "آخر",
                                newCheckedStatus,
                                setWatchNumbersColorChecked_other
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch numbers language  */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchNumbersLanguageFilterClicked(
                            !watchNumbersLanguageFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <IoLanguage
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لغة أرقام الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchNumbersLanguageFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchNumbersLanguageFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchNumbersLanguageFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchNumbersLanguageChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersLanguageCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="العربية"
                            checked={watchNumbersLanguageChecked_arabic}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersLanguageCheckboxChange_others(
                                "العربية",
                                newCheckedStatus,
                                setWatchNumbersLanguageChecked_arabic
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الإنجليزية"
                            checked={watchNumbersLanguageChecked_english}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersLanguageCheckboxChange_others(
                                "الإنجليزية",
                                newCheckedStatus,
                                setWatchNumbersLanguageChecked_english
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الرومانية"
                            checked={watchNumbersLanguageChecked_roman}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersLanguageCheckboxChange_others(
                                "الرومانية",
                                newCheckedStatus,
                                setWatchNumbersLanguageChecked_roman
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بدون أرقام"
                            checked={watchNumbersLanguageChecked_none}
                            onChange={(newCheckedStatus) => {
                              handleWatchNumbersLanguageCheckboxChange_others(
                                "بدون أرقام",
                                newCheckedStatus,
                                setWatchNumbersLanguageChecked_none
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch band material */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchBandMaterialFilterClicked(
                            !watchBandMaterialFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <IoIosWatch
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6
                            id="filter-headers"
                            style={{
                              fontSize: "15px",
                              wordBreak: "break-word",
                            }}
                          >
                            المادة المصنوع منها السوار
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchBandMaterialFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchBandMaterialFilterClicked
                            ? "200px"
                            : "0",
                          opacity: watchBandMaterialFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchBandMaterialChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandMaterialCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchBandMaterialChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandMaterialCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchBandMaterialChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="جلد"
                            checked={watchBandMaterialChecked_leather}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandMaterialCheckboxChange_others(
                                "جلد",
                                newCheckedStatus,
                                setWatchBandMaterialChecked_leather
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ربل"
                            checked={watchBandMaterialChecked_rubber}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandMaterialCheckboxChange_others(
                                "ربل",
                                newCheckedStatus,
                                setWatchBandMaterialChecked_rubber
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch band color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchBandColorFilterClicked(
                            !watchBandColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6
                            id="filter-headers"
                            style={{ marginBottom: "8px", fontSize: "15px" }}
                          >
                            لون سوار الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchBandColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchBandColorFilterClicked ? "700px" : "0",
                          opacity: watchBandColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="الكل"
                            checked={watchBandColorChecked_all}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_all(
                                newCheckedStatus
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="ستانلس ستيل"
                            checked={watchBandColorChecked_stainlessSteel}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "ستانلس ستيل",
                                newCheckedStatus,
                                setWatchBandColorChecked_stainlessSteel
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أسود"
                            checked={watchBandColorChecked_black}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أسود",
                                newCheckedStatus,
                                setWatchBandColorChecked_black
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أبيض"
                            checked={watchBandColorChecked_white}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أبيض",
                                newCheckedStatus,
                                setWatchBandColorChecked_white
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أحمر"
                            checked={watchBandColorChecked_red}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أحمر",
                                newCheckedStatus,
                                setWatchBandColorChecked_red
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أزرق"
                            checked={watchBandColorChecked_blue}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أزرق",
                                newCheckedStatus,
                                setWatchBandColorChecked_blue
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أخضر"
                            checked={watchBandColorChecked_green}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أخضر",
                                newCheckedStatus,
                                setWatchBandColorChecked_green
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="أصفر"
                            checked={watchBandColorChecked_yellow}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "أصفر",
                                newCheckedStatus,
                                setWatchBandColorChecked_yellow
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="برتقالي"
                            checked={watchBandColorChecked_orange}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "برتقالي",
                                newCheckedStatus,
                                setWatchBandColorChecked_orange
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بنفسجي"
                            checked={watchBandColorChecked_purple}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "بنفسجي",
                                newCheckedStatus,
                                setWatchBandColorChecked_purple
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="وردي"
                            checked={watchBandColorChecked_pink}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "وردي",
                                newCheckedStatus,
                                setWatchBandColorChecked_pink
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بني"
                            checked={watchBandColorChecked_brown}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "بني",
                                newCheckedStatus,
                                setWatchBandColorChecked_brown
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="رمادي"
                            checked={watchBandColorChecked_gray}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "رمادي",
                                newCheckedStatus,
                                setWatchBandColorChecked_gray
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="زيتي"
                            checked={watchBandColorChecked_olive}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "زيتي",
                                newCheckedStatus,
                                setWatchBandColorChecked_olive
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="سماوي"
                            checked={watchBandColorChecked_cyan}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "سماوي",
                                newCheckedStatus,
                                setWatchBandColorChecked_cyan
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="كحلي"
                            checked={watchBandColorChecked_navy}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "كحلي",
                                newCheckedStatus,
                                setWatchBandColorChecked_navy
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="بيج"
                            checked={watchBandColorChecked_beige}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "بيج",
                                newCheckedStatus,
                                setWatchBandColorChecked_beige
                              );
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomCheckbox
                            label="آخر"
                            checked={watchBandColorChecked_other}
                            onChange={(newCheckedStatus) => {
                              handleWatchBandColorCheckboxChange_others(
                                "آخر",
                                newCheckedStatus,
                                setWatchBandColorChecked_other
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* watch has packaging */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchHasPackagingFilterClicked(
                            !watchHasPackagingFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <BiSolidPackage
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            يوجد صندوق الساعة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchHasPackagingFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchHasPackagingFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchHasPackagingFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={watchHasOriginalPackagingChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleWatchHasOriginalPackagingCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={watchHasOriginalPackagingChecked_yes}
                          onChange={(newCheckedStatus) =>
                            handleWatchHasOriginalPackagingCheckboxChange_others(
                              true,
                              newCheckedStatus,
                              setWatchHasOriginalPackagingChecked_yes
                            )
                          }
                          label="نعم"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={watchHasOriginalPackagingChecked_no}
                          onChange={(newCheckedStatus) =>
                            handleWatchHasOriginalPackagingCheckboxChange_others(
                              false,
                              newCheckedStatus,
                              setWatchHasOriginalPackagingChecked_no
                            )
                          }
                          label="لا"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* watch is water resistant */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWatchIsWaterResistantFilterClicked(
                            !watchIsWaterResistantFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <IoIosWater
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            الساعة مقاومة للماء
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: watchIsWaterResistantFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: watchIsWaterResistantFilterClicked
                            ? "700px"
                            : "0",
                          opacity: watchIsWaterResistantFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={watchIsWaterResistantChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleWatchIsWaterResistantCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={watchIsWaterResistantChecked_yes}
                          onChange={(newCheckedStatus) =>
                            handleWatchIsWaterResistantCheckboxChange_others(
                              true,
                              newCheckedStatus,
                              setWatchIsWaterResistantChecked_yes
                            )
                          }
                          label="نعم"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={watchIsWaterResistantChecked_no}
                          onChange={(newCheckedStatus) =>
                            handleWatchIsWaterResistantCheckboxChange_others(
                              false,
                              newCheckedStatus,
                              setWatchIsWaterResistantChecked_no
                            )
                          }
                          label="لا"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* watch year made */}
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft:
                          window.innerWidth > window.innerHeight ? "10%" : "16px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setWatchYearMadeFilterClicked(
                          !watchYearMadeFilterClicked
                        );
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <FaCalendarAlt
                          color="#00C8B8"
                          size={18}
                          style={{ marginBottom: "0px", marginLeft: "8px" }}
                        />

                        <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                          سنة الصنع
                        </h6>
                      </div>
                      <FiChevronLeft
                        size={14}
                        style={{
                          transform: watchYearMadeFilterClicked
                            ? "rotate(-90deg)"
                            : "none",
                          transition: "transform 0.2s ease-in-out",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: watchYearMadeFilterClicked ? "200px" : "0",
                        opacity: watchYearMadeFilterClicked ? "1" : "0",
                        transition:
                          "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        width: "92%",
                        overflow: watchYearMadeFilterClicked ? "unset" : "hidden",
                      }}
                    >
                      <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                        <Slider
                          defaultValue={0}
                          max={2024}
                          min={1980}
                          value={[
                            oldestWatchYearSelected
                              ? parseFloat(oldestWatchYearSelected)
                              : oldestWatchYear,
                            newestWatchYearSelected
                              ? parseFloat(newestWatchYearSelected)
                              : newestWatchYear,
                          ]}
                          onChange={handleSliderChangeWatchYearMade}
                          style={{ color: "#00C8B8", marginTop: "0px" }}
                          valueLabelDisplay="auto"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          paddingBottom: "12px",
                          alignItems: "center",
                        }}
                      >
                        <input
                          onChange={(e) => handleNewestWatchYearMadeChange(e)}
                          placeholder="إلى"
                          type="tel"
                          value={newestWatchYearSelected || newestWatchYear || ""}
                          style={{
                            width: "40%",
                            border: "1px solid #00C8B8",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          }}
                        />{" "}
                        -
                        <input
                          onChange={(e) => handleOldestWatchYearMadeChange(e)}
                          placeholder="من"
                          type="tel"
                          value={oldestWatchYearSelected || oldestWatchYear || ""}
                          style={{
                            width: "40%",
                            border: "1px solid #00C8B8",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          }}
                        />
                        {watchYearMadeFiltered && (
                          <IoCloseCircleOutline
                            size={22}
                            style={{
                              color: "#F00057",
                              marginRight: "auto",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setWatchYearMadeFiltered(false);
                              setNewestWatchYearSelected("");
                              setOldestWatchYearSelected("");
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {categorySearch === "سبح" && (
                  <>
                    {/* rosary kind */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setRosaryKindFilterClicked(!rosaryKindFilterClicked);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={SebhahIcon}
                            alt=""
                            width="18"
                            height="18"
                            style={{ marginLeft: "8px" }}
                          />

                          <h6
                            style={{ fontSize: "15px", fontWeight: 500 }}
                            id="filter-headers"
                          >
                            نوع الخرز
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: rosaryKindFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: rosaryKindFilterClicked ? "300px" : "0",
                          opacity: rosaryKindFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={rosaryKindChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_all(newCheckedStatus)
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_backlight}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "بكلايت",
                              newCheckedStatus,
                              setRosaryKindChecked_backlight
                            )
                          }
                          label="بكلايت"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_kahraman}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "كهرمان",
                              newCheckedStatus,
                              setRosaryKindChecked_kahraman
                            )
                          }
                          label="كهرمان"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_fatoran}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "فاتوران",
                              newCheckedStatus,
                              setRosaryKindChecked_fatoran
                            )
                          }
                          label="فاتوران"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_ivory}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "عاج",
                              newCheckedStatus,
                              setRosaryKindChecked_ivory
                            )
                          }
                          label="عاج"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_wood}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "خشب",
                              newCheckedStatus,
                              setRosaryKindChecked_wood
                            )
                          }
                          label="خشب"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryKindChecked_stone}
                          onChange={(newCheckedStatus) =>
                            handleRosaryKindCheckboxChange_others(
                              "حجر",
                              newCheckedStatus,
                              setRosaryKindChecked_stone
                            )
                          }
                          label="حجر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* rosary color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setRosaryColorFilterClicked(!rosaryColorFilterClicked);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون الخرز
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: rosaryColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: rosaryColorFilterClicked ? "700px" : "0",
                          opacity: rosaryColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={rosaryColorChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_all(newCheckedStatus)
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_black}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أسود",
                              newCheckedStatus,
                              setRosaryColorChecked_black
                            )
                          }
                          label="أسود"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_white}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أبيض",
                              newCheckedStatus,
                              setRosaryColorChecked_white
                            )
                          }
                          label="أبيض"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_red}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أحمر",
                              newCheckedStatus,
                              setRosaryColorChecked_red
                            )
                          }
                          label="أحمر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_blue}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أزرق",
                              newCheckedStatus,
                              setRosaryColorChecked_blue
                            )
                          }
                          label="أزرق"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_green}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أخضر",
                              newCheckedStatus,
                              setRosaryColorChecked_green
                            )
                          }
                          label="أخضر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_yellow}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أصفر",
                              newCheckedStatus,
                              setRosaryColorChecked_yellow
                            )
                          }
                          label="أصفر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_orange}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "برتقالي",
                              newCheckedStatus,
                              setRosaryColorChecked_orange
                            )
                          }
                          label="برتقالي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_purple}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "أرجواني",
                              newCheckedStatus,
                              setRosaryColorChecked_purple
                            )
                          }
                          label="أرجواني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_pink}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "وردي",
                              newCheckedStatus,
                              setRosaryColorChecked_pink
                            )
                          }
                          label="وردي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_brown}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "بني",
                              newCheckedStatus,
                              setRosaryColorChecked_brown
                            )
                          }
                          label="بني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_gray}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "رمادي",
                              newCheckedStatus,
                              setRosaryColorChecked_gray
                            )
                          }
                          label="رمادي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_olive}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "زيتي",
                              newCheckedStatus,
                              setRosaryColorChecked_olive
                            )
                          }
                          label="زيتي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_cyan}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "سماوي",
                              newCheckedStatus,
                              setRosaryColorChecked_cyan
                            )
                          }
                          label="سماوي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_navy}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "كحلي",
                              newCheckedStatus,
                              setRosaryColorChecked_navy
                            )
                          }
                          label="كحلي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_beige}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "بيج",
                              newCheckedStatus,
                              setRosaryColorChecked_beige
                            )
                          }
                          label="بيج"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={rosaryColorChecked_other}
                          onChange={(newCheckedStatus) =>
                            handleRosaryColorCheckboxChange_others(
                              "آخر",
                              newCheckedStatus,
                              setRosaryColorChecked_other
                            )
                          }
                          label="آخر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* rosary count */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setRosaryCountFilterClicked(!rosaryCountFilterClicked);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <GoNumber
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            عدد الخرز
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: rosaryCountFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                            marginBottom: "3px",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: rosaryCountFilterClicked ? "700px" : "0",
                          opacity: rosaryCountFilterClicked ? "1" : "0",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                          width: "92%",
                          overflow: rosaryCountFilterClicked ? "unset" : "hidden",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "16px", paddingRight: "16px" }}
                        >
                          {/* <Slider
                            defaultValue={0}
                            max={mostRosaryCount || 0}
                            min={leastRosaryCount || 0}
                            value={[
                              leastRosaryCountSelected
                                ? parseFloat(leastRosaryCountSelected)
                                : leastRosaryCount,
                              mostRosaryCountSelected
                                ? parseFloat(mostRosaryCountSelected)
                                : mostRosaryCount,
                            ]}
                            onChange={handleSliderChangeRosaryCount}
                            style={{ color: "#00C8B8", marginTop: "0px" }}
                            valueLabelDisplay="auto"
                          /> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            paddingBottom: "12px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => handleMostRosaryCountChange(e)}
                            placeholder="إلى"
                            type="tel"
                            value={mostRosaryCountSelected || mostRosaryCount}
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />{" "}
                          -
                          <input
                            onChange={(e) => handleLeastRosaryCountChange(e)}
                            placeholder="من"
                            type="tel"
                            value={leastRosaryCountSelected || leastRosaryCount}
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />
                          {rosaryCountFiltered && (
                            <IoCloseCircleOutline
                              size={22}
                              style={{
                                color: "#F00057",
                                marginRight: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setRosaryCountFiltered(false);
                                setMostRosaryCountSelected("");
                                setLeastRosaryCountSelected("");
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* rosary size */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setRosarySizeFilterClicked(!rosarySizeFilterClicked);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <IoResize
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            حجم الخرز (ملم)
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: rosarySizeFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                            marginBottom: "3px",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: rosarySizeFilterClicked ? "700px" : "0",
                          opacity: rosarySizeFilterClicked ? "1" : "0",
                          transition:
                            "max-height 0.2s ease-in-out, opacity 0.2s ease-in-out",
                          width: "92%",
                          overflow: rosarySizeFilterClicked ? "unset" : "hidden",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "16px", paddingRight: "16px" }}
                        >
                          {/* <Slider
                            defaultValue={0}
                            max={biggestRosarySize || 0}
                            min={smallestRosarySize || 0}
                            value={[
                              smallestRosarySizeSelected
                                ? smallestRosarySizeSelected
                                : smallestRosarySize,
                              biggestRosarySizeSelected
                                ? biggestRosarySizeSelected
                                : biggestRosarySize,
                            ]}
                            onChange={handleSliderChangeRosarySize}
                            style={{ color: "#00C8B8", marginTop: "0px" }}
                            valueLabelDisplay="auto"
                          /> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            paddingBottom: "12px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => handleBiggestRosarySizeChange(e)}
                            placeholder="إلى"
                            type="tel"
                            value={biggestRosarySizeSelected || biggestRosarySize}
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />{" "}
                          -
                          <input
                            onChange={(e) => handleSmallestRosarySizeChange(e)}
                            placeholder="من"
                            type="tel"
                            value={
                              smallestRosarySizeSelected || smallestRosarySize
                            }
                            style={{
                              width: "40%",
                              border: "1px solid #00C8B8",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            }}
                          />
                          {rosarySizeFiltered && (
                            <IoCloseCircleOutline
                              size={22}
                              style={{
                                color: "#F00057",
                                marginRight: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setRosarySizeFiltered(false);
                                setBiggestRosarySizeSelected("");
                                setSmallestRosarySizeSelected("");
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {categorySearch === "ابواك" && (
                  <>
                    {/* wallet department */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWalletDepartmentFilterClicked(
                            !walletDepartmentFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FaTransgender
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            قسم البوك
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: walletDepartmentFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: walletDepartmentFilterClicked
                            ? "200px"
                            : "0",
                          opacity: walletDepartmentFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <>
                          <CustomCheckbox
                            label="الكل"
                            checked={walletDepartmentChecked_all}
                            onChange={handleWalletDepartmentCheckboxChange_all}
                          />

                          <CustomCheckbox
                            label="رجالي"
                            checked={walletDepartmentChecked_men}
                            onChange={(newCheckedStatus) => {
                              setWalletDepartmentChecked_men(newCheckedStatus);
                              handleWalletDepartmentCheckboxChange_others(
                                "رجالي",
                                newCheckedStatus,
                                setWalletDepartmentChecked_men
                              );
                            }}
                          />

                          <CustomCheckbox
                            label="نسائي"
                            checked={walletDepartmentChecked_women}
                            onChange={(newCheckedStatus) => {
                              setWalletDepartmentChecked_women(newCheckedStatus);
                              handleWalletDepartmentCheckboxChange_others(
                                "نسائي",
                                newCheckedStatus,
                                setWalletDepartmentChecked_women
                              );
                            }}
                          />

                          <CustomCheckbox
                            label="للجنسين"
                            checked={walletDepartmentChecked_unisex}
                            onChange={(newCheckedStatus) => {
                              setWalletDepartmentChecked_unisex(newCheckedStatus);
                              handleWalletDepartmentCheckboxChange_others(
                                "للجنسين",
                                newCheckedStatus,
                                setWalletDepartmentChecked_unisex
                              );
                            }}
                          />
                        </>
                      </div>
                    </div>

                    {/* wallet outside color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setWalletOutsideColorFilterClicked(
                            !walletOutsideColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون البوك الخارجي
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: walletOutsideColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: walletOutsideColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: walletOutsideColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={walletOutsideColorChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_black}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أسود",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_black
                            )
                          }
                          label="أسود"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_white}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أبيض",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_white
                            )
                          }
                          label="أبيض"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_red}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أحمر",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_red
                            )
                          }
                          label="أحمر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_blue}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أزرق",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_blue
                            )
                          }
                          label="أزرق"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_green}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أخضر",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_green
                            )
                          }
                          label="أخضر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_yellow}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أصفر",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_yellow
                            )
                          }
                          label="أصفر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_orange}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "برتقالي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_orange
                            )
                          }
                          label="برتقالي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_purple}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "أرجواني",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_purple
                            )
                          }
                          label="أرجواني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_pink}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "وردي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_pink
                            )
                          }
                          label="وردي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_brown}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "بني",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_brown
                            )
                          }
                          label="بني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_gray}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "رمادي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_gray
                            )
                          }
                          label="رمادي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_olive}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "زيتي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_olive
                            )
                          }
                          label="زيتي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_cyan}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "سماوي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_cyan
                            )
                          }
                          label="سماوي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_navy}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "كحلي",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_navy
                            )
                          }
                          label="كحلي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_beige}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "بيج",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_beige
                            )
                          }
                          label="بيج"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={walletOutsideColorChecked_other}
                          onChange={(newCheckedStatus) =>
                            handleWalletOutsideColorCheckboxChange_others(
                              "آخر",
                              newCheckedStatus,
                              setWalletOutsideColorChecked_other
                            )
                          }
                          label="آخر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {categorySearch === "حقائب نسائية" && (
                  <>
                    {/* purse material */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPurseMaterialFilterClicked(
                            !purseMaterialFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <GiSewingMachine
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            المادة المصنوعة منها الحقيبة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: purseMaterialFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: purseMaterialFilterClicked ? "200px" : "0",
                          opacity: purseMaterialFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={purseMaterialChecked_all}
                          onChange={(newCheckedStatus) =>
                            handlePurseMaterialCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseMaterialChecked_leather}
                          onChange={(newCheckedStatus) =>
                            handlePurseMaterialCheckboxChange_others(
                              "جلد",
                              newCheckedStatus,
                              setPurseMaterialChecked_leather
                            )
                          }
                          label="جلد"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseMaterialChecked_fabric}
                          onChange={(newCheckedStatus) =>
                            handlePurseMaterialCheckboxChange_others(
                              "مخمل",
                              newCheckedStatus,
                              setPurseMaterialChecked_fabric
                            )
                          }
                          label="مخمل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* purse outside color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPurseOutsideColorFilterClicked(
                            !purseOutsideColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون الحقيبة الخارجي
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: purseOutsideColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: purseOutsideColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: purseOutsideColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_all}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_black}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أسود",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_black
                            )
                          }
                          label="أسود"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_white}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أبيض",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_white
                            )
                          }
                          label="أبيض"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_red}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أحمر",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_red
                            )
                          }
                          label="أحمر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_blue}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أزرق",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_blue
                            )
                          }
                          label="أزرق"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_green}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أخضر",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_green
                            )
                          }
                          label="أخضر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_yellow}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أصفر",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_yellow
                            )
                          }
                          label="أصفر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_orange}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "برتقالي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_orange
                            )
                          }
                          label="برتقالي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_purple}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "أرجواني",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_purple
                            )
                          }
                          label="أرجواني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_pink}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "وردي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_pink
                            )
                          }
                          label="وردي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_brown}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "بني",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_brown
                            )
                          }
                          label="بني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_gray}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "رمادي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_gray
                            )
                          }
                          label="رمادي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_olive}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "زيتي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_olive
                            )
                          }
                          label="زيتي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_cyan}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "سماوي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_cyan
                            )
                          }
                          label="سماوي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_navy}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "كحلي",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_navy
                            )
                          }
                          label="كحلي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_beige}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "بيج",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_beige
                            )
                          }
                          label="بيج"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseOutsideColorChecked_other}
                          onChange={(newCheckedStatus) =>
                            handlePurseOutsideColorCheckboxChange_others(
                              "آخر",
                              newCheckedStatus,
                              setPurseOutsideColorChecked_other
                            )
                          }
                          label="آخر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* purse inside color */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPurseInsideColorFilterClicked(
                            !purseInsideColorFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <HiMiniPaintBrush
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            لون الحقيبة الداخلي
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            transform: purseInsideColorFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: purseInsideColorFilterClicked
                            ? "700px"
                            : "0",
                          opacity: purseInsideColorFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={purseInsideColorChecked_all}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_black}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أسود",
                              newCheckedStatus,
                              setPurseInsideColorChecked_black
                            )
                          }
                          label="أسود"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_white}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أبيض",
                              newCheckedStatus,
                              setPurseInsideColorChecked_white
                            )
                          }
                          label="أبيض"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_red}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أحمر",
                              newCheckedStatus,
                              setPurseInsideColorChecked_red
                            )
                          }
                          label="أحمر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_blue}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أزرق",
                              newCheckedStatus,
                              setPurseInsideColorChecked_blue
                            )
                          }
                          label="أزرق"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_green}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أخضر",
                              newCheckedStatus,
                              setPurseInsideColorChecked_green
                            )
                          }
                          label="أخضر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_yellow}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أصفر",
                              newCheckedStatus,
                              setPurseInsideColorChecked_yellow
                            )
                          }
                          label="أصفر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_orange}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "برتقالي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_orange
                            )
                          }
                          label="برتقالي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_purple}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "أرجواني",
                              newCheckedStatus,
                              setPurseInsideColorChecked_purple
                            )
                          }
                          label="أرجواني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_pink}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "وردي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_pink
                            )
                          }
                          label="وردي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_brown}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "بني",
                              newCheckedStatus,
                              setPurseInsideColorChecked_brown
                            )
                          }
                          label="بني"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_gray}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "رمادي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_gray
                            )
                          }
                          label="رمادي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_olive}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "زيتي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_olive
                            )
                          }
                          label="زيتي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_cyan}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "سماوي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_cyan
                            )
                          }
                          label="سماوي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_navy}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "كحلي",
                              newCheckedStatus,
                              setPurseInsideColorChecked_navy
                            )
                          }
                          label="كحلي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_beige}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "بيج",
                              newCheckedStatus,
                              setPurseInsideColorChecked_beige
                            )
                          }
                          label="بيج"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                        <CustomCheckbox
                          checked={purseInsideColorChecked_other}
                          onChange={(newCheckedStatus) =>
                            handlePurseInsideColorCheckboxChange_others(
                              "آخر",
                              newCheckedStatus,
                              setPurseInsideColorChecked_other
                            )
                          }
                          label="آخر"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {categorySearch === "لوح سيارات" && (
                  <>
                    {/* car plate kind */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setCarPlateKindFilterClicked(
                            !carPlateKindFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={carPlateIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            نوع اللوحة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: carPlateKindFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: carPlateKindFilterClicked ? "200px" : "0",
                          opacity: carPlateKindFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={carPlateKindChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateKindCheckboxChange_all(newCheckedStatus)
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateKindChecked_privateCar}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateKindCheckboxChange_others(
                              "لوحة سيارة خاصة",
                              newCheckedStatus,
                              setCarPlateKindChecked_privateCar
                            )
                          }
                          label="لوحة سيارة خاصة"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateKindChecked_motorCycle}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateKindCheckboxChange_others(
                              "لوحة دراجة نارية",
                              newCheckedStatus,
                              setCarPlateKindChecked_motorCycle
                            )
                          }
                          label="لوحة دراجة نارية"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateKindChecked_transportation}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateKindCheckboxChange_others(
                              "لوحة سيارة نقل عام",
                              newCheckedStatus,
                              setCarPlateKindChecked_transportation
                            )
                          }
                          label="لوحة سيارة نقل عام"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateKindChecked_commerce}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateKindCheckboxChange_others(
                              "لوحة سيارة تجارية",
                              newCheckedStatus,
                              setCarPlateKindChecked_commerce
                            )
                          }
                          label="لوحة سيارة تجارية"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* car plate fee on */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setCarPlateTransferFeeOnFilterClicked(
                            !carPlateTransferFeeOnFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={carPlateIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            رسوم نقل الملكية على
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: carPlateTransferFeeOnFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: carPlateTransferFeeOnFilterClicked
                            ? "200px"
                            : "0",
                          opacity: carPlateTransferFeeOnFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={carPlateTransferFeeOnChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferFeeOnCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateTransferFeeOnChecked_seller}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferFeeOnCheckboxChange_others(
                              "البائع",
                              newCheckedStatus,
                              setCarPlateTransferFeeOnChecked_seller
                            )
                          }
                          label="البائع"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateTransferFeeOnChecked_buyer}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferFeeOnCheckboxChange_others(
                              "المشتري",
                              newCheckedStatus,
                              setCarPlateTransferFeeOnChecked_buyer
                            )
                          }
                          label="المشتري"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>

                    {/* car plate transfer time */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setCarPlateTransferTimeFilterClicked(
                            !carPlateTransferTimeFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={carPlateIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            فترة نقل الملكية
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: carPlateTransferTimeFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: carPlateTransferTimeFilterClicked
                            ? "200px"
                            : "0",
                          opacity: carPlateTransferTimeFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={carPlateTransferTimeChecked_all}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferTimeCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateTransferTimeChecked_24to48Hours}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferTimeCheckboxChange_others(
                              "24 إلى 48 ساعة",
                              newCheckedStatus,
                              setCarPlateTransferTimeChecked_24to48Hours
                            )
                          }
                          label="24 إلى 48 ساعة"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={carPlateTransferTimeChecked_twoToFiveDays}
                          onChange={(newCheckedStatus) =>
                            handleCarPlateTransferTimeCheckboxChange_others(
                              "خلال يومين عمل",
                              newCheckedStatus,
                              setCarPlateTransferTimeChecked_twoToFiveDays
                            )
                          }
                          label="خلال 5ايام عمل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {categorySearch === "ارقام جوالات" && (
                  <>
                    {/* phone courier */}
                    <div style={{ marginBottom: "16px" }}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "4px",
                          paddingLeft:
                            window.innerWidth > window.innerHeight
                              ? "10%"
                              : "16px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPhoneCourierFilterClicked(
                            !phoneCourierFilterClicked
                          );
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FaTowerCell
                            color="#00C8B8"
                            size={18}
                            style={{ marginBottom: "0px", marginLeft: "8px" }}
                          />

                          <h6 id="filter-headers" style={{ fontSize: "15px" }}>
                            مشغل الخدمة
                          </h6>
                        </div>
                        <FiChevronLeft
                          size={14}
                          style={{
                            marginBottom: "6px",
                            transform: phoneCourierFilterClicked
                              ? "rotate(-90deg)"
                              : "none",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: phoneCourierFilterClicked ? "200px" : "0",
                          opacity: phoneCourierFilterClicked ? "1" : "0",
                          overflow: "hidden",
                          transition:
                            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                      >
                        <CustomCheckbox
                          checked={phoneNumberCourierChecked_all}
                          onChange={(newCheckedStatus) =>
                            handlePhoneNumberCourierCheckboxChange_all(
                              newCheckedStatus
                            )
                          }
                          label="الكل"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={phoneNumberCourierChecked_stc}
                          onChange={(newCheckedStatus) =>
                            handlePhoneNumberCourierCheckboxChange_others(
                              "الاتصالات السعودية STC",
                              newCheckedStatus,
                              setPhoneNumberCourierChecked_stc
                            )
                          }
                          label="الاتصالات السعودية STC"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={phoneNumberCourierChecked_mobily}
                          onChange={(newCheckedStatus) =>
                            handlePhoneNumberCourierCheckboxChange_others(
                              "موبايلي",
                              newCheckedStatus,
                              setPhoneNumberCourierChecked_mobily
                            )
                          }
                          label="موبايلي"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />

                        <CustomCheckbox
                          checked={phoneNumberCourierChecked_zain}
                          onChange={(newCheckedStatus) =>
                            handlePhoneNumberCourierCheckboxChange_others(
                              "زين",
                              newCheckedStatus,
                              setPhoneNumberCourierChecked_zain
                            )
                          }
                          label="زين"
                          labelStyle={{
                            fontSize: "15px",
                            marginBottom: "6px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    marginBottom: "24px",
                    marginTop: "24px",
                  }}
                >
                  <GoSortDesc
                    size={20}
                    color="black"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      cursor: "pointer",
                    }}
                  />
                  <h5
                    style={{
                      color: "black",
                      marginRight: "6px",
                      marginBottom: "2px",
                      fontSize: "16px",
                    }}
                  >
                    ترتيب
                  </h5>
                </div>

                <CustomCheckbox
                  checked={selectedSortOption === "من الأقدم إلى الأحدث"}
                  onChange={() => handleSortOptionChange("من الأقدم إلى الأحدث")}
                  label="من الأقدم إلى الأحدث"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "من الأحدث إلى الأقدم"}
                  onChange={() => handleSortOptionChange("من الأحدث إلى الأقدم")}
                  label="من الأحدث إلى الأقدم"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "سعر الشراء تنازليا"}
                  onChange={() => handleSortOptionChange("سعر الشراء تنازليا")}
                  label="سعر الشراء تنازليا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "سعر الشراء تصاعديا"}
                  onChange={() => handleSortOptionChange("سعر الشراء تصاعديا")}
                  label="سعر الشراء تصاعديا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "سعر المزايدة الحالي تنازليا"}
                  onChange={() =>
                    handleSortOptionChange("سعر المزايدة الحالي تنازليا")
                  }
                  label="سعر المزايدة الحالي تنازليا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "سعر المزايدة الحالي تصاعديا"}
                  onChange={() =>
                    handleSortOptionChange("سعر المزايدة الحالي تصاعديا")
                  }
                  label="سعر المزايدة الحالي تصاعديا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "عدد المزايدين تصاعديا"}
                  onChange={() => handleSortOptionChange("عدد المزايدين تصاعديا")}
                  label="عدد المزايدين تصاعديا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />
                <CustomCheckbox
                  checked={selectedSortOption === "عدد المزايدين تنازليا"}
                  onChange={() => handleSortOptionChange("عدد المزايدين تنازليا")}
                  label="عدد المزايدين تنازليا"
                  labelStyle={{
                    fontSize: "15px",
                    marginBottom: "6px",
                    marginRight: "8px",
                  }}
                />

                {/* filter buttons  */}
                <div
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft:
                      window.innerWidth > window.innerHeight ? "10%" : "16px",
                  }}
                >
                  <button
                    id="my-button"
                    style={{ flex: 2 }}
                    onClick={() => {
                      filter();
                      scrollToTop();
                    }}
                  >
                    عرض النتائج
                  </button>
                  <button
                    id="my-button-red"
                    style={{
                      flex: 1.3,
                      marginRight: "8px",
                      padding: "4px 8px",
                    }}
                    onClick={() => {
                      resetFilters();
                      scrollToTop();
                    }}
                  >
                    إعادة تعيين
                  </button>
                </div>
              </div>
            </Drawer>

            {/* </div> */}

            {productsLoading && listing.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100px",
                }}
              >
                <Oval
                  height={"35px"}
                  width={"35px"}
                  color={"#00C8B8"}
                  wrapperClass=""
                  visible={true}
                  wrapperStyle={{ display: "block" }}
                  ariaLabel="oval-loading"
                  secondaryColor="#ccc"
                  strokeWidth={2}
                  strokeWidthSecondary={1}
                />
              </div>
            ) : listing.length > 0 ? (
  <>
  <div
        style={{
          display: "grid",
          width: "100%",
          height: "fit-content",
          direction: "rtl",
          rowGap: "16px",
          columnGap: "16px",
          gridTemplateColumns:
            window.innerWidth > window.innerHeight
              ? `repeat(auto-fill, minmax(200px, 1fr))`
              : window.innerWidth > 600
              ? `repeat(auto-fill, minmax(200px, 1fr))`
              : `repeat(auto-fill, minmax(40vw, 1fr))`,
          justifyContent: "center",
          justifyItems: "stretch",
          alignItems: "stretch",
          opacity: productsVisible ? 1 : 0, // Fade in effect based on isVisible
          transform: productsVisible ? "translateY(0)" : "translateY(20px)", // Move up effect based on isVisible
          transition: "opacity 0.3s ease, transform 0.3s ease", // Transition effects
        }}
      >
      {listing.map((item, index) => (
        <div key={`product-${item.id}-${index}`}>
          <Product
            postId={item.id}
            carPlateKind={item.carPlate.kind}
            expiryDate={item.expiryDate}
            link={`/product/${item.productName.replace(/ /g, "-")}-${item.id}`}
            numBidders={item.numBidders}
            currentBid={item.currentBid}
            imgs={item.imgs[0]}
            views={item.views}
            auctionStartDate={item.postType.includes("مزاد") && item.auctionStartDate.toDate()}
            condition={item.condition}
            isAuction={item.postType.includes("مزاد")}
            acceptsOffers={item.postType.includes("عروض")}
            productPrice={item.productPrice}
            productDescription={item.productDescription}
            sellerId={item.sellerId}
            startingPrice={item.startingPrice}
            sellerCity={item.seller}
            category={item.category}
            productName={item.productName}
          />
        </div>
      ))}
    </div>
    {loadingMoreProducts && (
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100px",
            height: "100px",
            margin: "auto",
          }}
        >
          <Oval
            height={"50px"}
            width={"50px"}
            color={"#00C8B8"}
            wrapperClass=""
            visible={true}
            wrapperStyle={{ display: "block" }}
            ariaLabel="oval-loading"
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
        </div>
      </div>
    )}
  </>

            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Lottie
                  style={{ width: "330px", height: "330px" }}
                  animationData={NoProductsFoundAnim}
                />
                <h6 style={{color:"#01635c"}}>لم يتم العثور على أي منتج</h6>
              </div>
            )}
          </div>

          {/* <div 
          style={{
            display: "grid",
            gap: "8px",
            width: "100%",
            height: "fit-content",
            direction:"rtl",
            gridTemplateColumns: window.innerWidth > window.innerHeight 
            ? `repeat(3, 1fr)`
            : `repeat(2, 1fr)`,
          }}
          
          >
    {Listing.map((item) => (
      <div key={item.id}
      >
        <Product
          postId={item.id}
          expiryDate={item.expiryDate}
          link={`/product/${parseFloat(item.id)}`}
          numBidders={item.numBidders}
          currentBid={item.currentBid}
          imgs={item.imgs}
          carPlateKind={item.carPlateKind}

          condition={item.condition}
          isAuction={item.postType.includes("مزاد")}
          acceptsOffers={item.postType.includes("عروض")}
          productPrice={item.productPrice}
          productDescription={item.productDescription}
          sellerId={item.sellerId}
          startingPrice={item.startingPrice}
          sellerCity={item.seller}
          category={item.category}
          productName={item.productName}

        />
      </div>
    ))}
        </div> */}
        </div>
      </InfiniteScroll>
    </>
  
);
}

export default ProductsScreen;
