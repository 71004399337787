import React, { Component } from 'react';
import { Link } from 'react-router-dom';
function Category({img, category, link}) {
        return (

            
            <div id='category-div'>

                    <Link to={link}>
                <div id='category'>

                    <img
                    style={{
                        objectFit: 'cover',
                        borderRadius: "10px",
                        height:"100%",
                        width: "55%"}}
                        src={img}/>
<div style={{
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: "100%", height: "100%"}}>

                    <h2 style={{textAlign: 'center', textDecoration: 'none', color:'black'}}>{category}</h2>
</div>
                </div>
        </Link>
            </div>
        );
}
 
export default Category;