import React, {useEffect, useRef} from 'react'
import CustomNavbar from '../components/CustomNavbar';
import Product from '../components/Product';
import { useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storagePath, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { db, auth, storage } from '../config/firebase'
import { useReadOTP } from "react-read-otp";
import LoadingScreen from './LoadingScreen';
import { getFunctions, httpsCallable } from "firebase/functions";
import Lottie from 'lottie-react';
import SignedInAnim from '../assets/animations/SignedInAnim.json';
import ReactGA from "react-ga4";
import logo from '../assets/logo.png'
import { Helmet } from 'react-helmet';



function SignInOrSignUpScreen ({prompt, redirectUrl, setSignUpPhoneNumber}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [blobUrl, setBlobUrl] = useState("");
    const countryCode = "+966"
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [hasSelectedProfilePicture, setHasSelectedProfilePicture] = useState(false);
    const [profilePictureName, setProfilePictureName] = useState("");
    const [otpSent , setOtpSent] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [userExists, setUserExists] = useState(false);

    const [sendOTPLoading, setSendOTPLoading] = useState(false)
    const [timer, setTimer] = useState(0);

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const otpRef = useRef();

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };
    

    useEffect(() => {
      let interval = null;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(timer - 1);
        }, 1000);
      } else if (timer === 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [timer]);



    useEffect(()=>{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }, [])

    useEffect(() => {
      auth.onAuthStateChanged(async function (user) {
        if(user.isAnonymous){
        setLoading(false);

            // setTimeout(() => {
            //   navigate(`${redirectUrl || '/my_account/my_info'}`); // Redirect to the desired location upon successful verification
            // }, 3000) 
          }
          else{
            if(fromPage)
              navigate(fromPage)
            if(redirectUrl)
              navigate(redirectUrl)
          }
      });
    }, [auth.currentUser]);

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const fromPage = location.state?.currentUrl;

    // let navigateURL = fromPage && fromPage.includes("my_account") ? "my_account/my_info" : (fromPage || "/my_account/my_info");
    // console.log(fromPage)




    useEffect(() => {
      if (OTP.length === 6) {
        setLoading(true)
        verifyOTP();
      }
    }, [OTP]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (otpRef && otpRef.current) {
          otpRef.current.scrollIntoView({ behavior: 'smooth' });
          otpRef.current.focus();
        }
      }, 500);
    
      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeout);
    }, [otpRef]);
    

    const [isVisible, setIsVisible] = useState(false); // Visibility state

    useEffect(() => {
      // Set the div to visible after a slight delay to ensure the transition happens
      if(!isLoading){
        setTimeout(() => setIsVisible(true), 100);
      }
    }, [isLoading]);

    const phoneNumberRef = useRef();

    const generateRecaptcha = () => {
      if (window.recaptchaVerifier) {
        // RecaptchaVerifier already exists, no need to recreate
        return;
      }
  
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      });
    };

    const requestOTP = async () => {
      setErrorMsg("");
    
      // Validate phone number input
      if (isNaN(parseInt(phoneNumber))) {
        setErrorMsg("يرجى ادخال قيمة صحيحة. يسمح بالأرقام الانجليزية فقط 0-9");
        return;
      }
    
      // Check if the timer is active
      if (timer > 0) {
        setErrorMsg(`يرجى الانتظار ${formatTime(timer)} قبل طلب رمز جديد`);
        return;
      }
    
      // Format phone number if it starts with "05"
      let formattedPhoneNumber = phoneNumber;
      if (phoneNumber.startsWith("05")) {
        formattedPhoneNumber = phoneNumber.substring(1);
      }
    
      try {
        // Proceed with OTP request if phone number is valid
        if (formattedPhoneNumber.length === 9 && !isNaN(parseInt(formattedPhoneNumber))) {
          setOtpSent(true);
          setSendOTPLoading(true);
          // Query Firestore to check if the user exists
          const userQuery = query(collection(db, 'Users'), where('phoneNumber', '==', `${countryCode}${formattedPhoneNumber}`));
          const querySnapshot = await getDocs(userQuery);
    
          setTimer(120); // Set the timer to 120 seconds (2 minutes) after OTP request

          // if (otpRef && otpRef.current) {
          //   otpRef.current.scrollIntoView({ behavior: 'smooth' });
          //   otpRef.current.focus();
          // }
          generateRecaptcha();
          setSendOTPLoading(false);
          const appVerifier = window.recaptchaVerifier;
          
          // Ensure reCAPTCHA has been resolved
          if (!appVerifier) {
            setErrorMsg('Please complete the reCAPTCHA first.');
            return;
          }
    
          const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${formattedPhoneNumber}`, appVerifier);
          window.confirmationResult = confirmationResult;
    
          // Set user existence based on Firestore query result
          setUserExists(!querySnapshot.empty);
          if(!querySnapshot.empty){
            ReactGA.event({
              category: "User",
              action: "Logged in",
              label: "User Logged in",
            });
          }
        } else {
          setErrorMsg('الرجاء إدخال رقم هاتف صحيح');
        }
      } catch (error) {
        console.error('Error requesting OTP:', error);
        // Convert error to a string before setting it in state
        setErrorMsg('حدث خطأ أثناء إرسال الرمز: ' + error.message || error.toString());
      }
    };
    

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'instant' // Scroll behavior set to instant for instant scrolling
      });
    };
  
    const[verifyOTPLoading, setVerifyOTPLoading] = useState(false)

    const verifyOTP = async () => {
      try {
        scrollToTop()
        const confirmationResult = window.confirmationResult;

        confirmationResult
          .confirm(OTP)
          .then(async (result) => {
            // Handle successful OTP verification
            if (!userExists) {
              const userDetails = {
                phoneNumber: `+966${phoneNumber}`,
                uid: auth.currentUser.uid,
              };
            
              console.log("Creating user document with details:", userDetails);
            
              try {
                const functions = getFunctions();
                const createUserDoc = httpsCallable(functions, 'createUserDoc');
                const response = await createUserDoc(userDetails);
            
                console.log("createUserDoc function response:", response);
            
                if (response.data.success) {
                      // Track registration event
                      ReactGA.event({
                        category: "User",
                        action: "Registration",
                        label: "User Registered",
                      });
                  console.log("User document created with ID:", response.data.userId);
                  // navigate(`${redirectUrl || 'my_account/my_info'}`); // Redirect to the desired location upon successful verification
                } else {
                  console.error("Error creating user document:", response.data.error);
                }
              } catch (error) {
                console.error("Error calling createUserDoc function:", error);
              } finally {
                setLoading(false);
              }
            }
            
          })
          .catch((error) => {
            // Handle OTP verification error
            console.error('Error confirming OTP:', error);
            setErrorMsg('خطأ في تأكيد الرمز');
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error('Error during OTP verification:', error);
        setErrorMsg('حدث خطأ أثناء تأكيد الرمز');
      } finally {
        setVerifyOTPLoading(false)
      }
    };

const handleOTPChange = (e) => {
  const otpValue = e.target.value;
  if (otpValue.length <= 6) {
    setOTP(otpValue); // Update OTP state but do not verify yet
  }
};


    if(isLoading){
      return <LoadingScreen/>
    }

        return (
          <>
                <Helmet>
              <title>التسجيل أو تسجيل الدخول</title>
              <meta name="description" content="التسجيل أو تسجيل الدخول" />
              <meta property="og:type" content="page" />
              <meta property="og:title" content="التسجيل أو تسجيل الدخول"/>
              <meta property="og:description" content="التسجيل أو تسجيل الدخول" />
              <meta property="og:image" content={logo} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:site_name" content="التسجيل أو تسجيل الدخول" />
              <meta name="twitter:title" content="التسجيل أو تسجيل الدخول" />
              <meta name="twitter:description" content="التسجيل أو تسجيل الدخول" />
              <meta name="twitter:image" content={logo} />
          </Helmet>




                      { verifyOTPLoading && (
  <>
    <div style={{width:"100vw", height:"100vh", backgroundColor: 'rgba(0, 0, 0, 0.2)',  position:"fixed", zIndex:"1010", display:"flex", justifyContent:"center", alignItems:"center", top:0}}>
      <div style={{backgroundColor:"white",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", borderRadius:"12px"}}>
      <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              padding:"32px"
            }}
          >
                    <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>

      </div>
    </div>
  </>
)}
          { auth.currentUser && auth.currentUser.isAnonymous ? (
    <div
    style={{
      backgroundColor: "#F7F7F7",
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      opacity: isVisible ? 1 : 0, // Fade in effect based on isVisible
      transform: isVisible ? "translateY(0)" : "translateY(20px)", // Move up effect based on isVisible
      transition: "opacity 0.3s ease, transform 0.2s ease", // Transition effects
    }}
  >
              <div style={{
                  backgroundColor : "white",
                  width: window.innerWidth > window.innerHeight ? "50%" : window.innerWidth < 600 ? "90%" : "60%",
                  borderRadius: "20px",
                  marginTop: "-20%",
                  boxShadow: "0px 0.5px 0px 0px #ccc",
                  padding: "32px 16px"
              }}>
                
                  {!prompt ? <p>الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة </p> : <p>{prompt}</p>}

                  <div style={{
                  display: "flex",
                  margin: "8px",
                  marginTop:"16px",
                  }}>
                  <div style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                  }}>

                  <p style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "4px"
                      }}>+966</p>
                  </div>
                      <input type='tel' ref={phoneNumberRef} onChange={(value)=>{
                        if(setSignUpPhoneNumber)
                        setSignUpPhoneNumber(value.target.value); 

                        setPhoneNumber(value.target.value)}} style={{display: "block", width: "100%",borderRadius: "4px", border: "none", height: "40px", direction: "ltr", padding: "4px 8px", border: "1px solid #f0f0f0", backgroundColor: "#F0F0F0", fontSize:"1rem", textAlign:"left"}} placeholder='541234567'/>
                  </div>
                      <p style={{color:"red", display: errorMsg != "" ? "block" : "none", fontSize:"14px"}}>{errorMsg}</p>
                      <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"16px"}}>
                      
                      <button id='my-button' 
              disabled={sendOTPLoading || timer > 0}
              style={{fontSize: "14px", width: "60%"}} onClick={requestOTP}>
{   
   sendOTPLoading ?    
   <div style={{
  alignItems: 'center',
  width: "100%",
  height: "100%",
  justifyContent: 'center',
  display:"flex"
    }}>
      <Oval
            height={"20px"}
            width={"20px"}
            color={"white"}
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
            />
            </div>
             : <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", padding:"0px 16px"}}>
              {timer > 0 && <p style={{marginBottom:"0px", color:"white", marginRight:"8px"}}>{formatTime(timer)}</p>}
              <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>
              إرسال رمز التحقق
              </p>

             </div>
            }
                </button>
                      </div>
                  <div
                  style={{
                  justifyContent: "center",
                  display: otpSent ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  }}
                  >
                      <input
                      type='text'
                      inputMode='numeric'
                      pattern='[0-9]*'
                      autoComplete='one-time-code'
                      autoFocus // Set autoFocus based on the OTP value
                      style={{width: "40%",borderRadius: "8px", border: "none", textAlign:"center" , height: "40px", marginTop: "32px" , direction: "rtl", paddingRight: "4px", paddingLeft:"4px" , border: "1px solid #f0f0f0", fontSize:"1rem", backgroundColor: "#F0F0F0",}}
                      onChange={handleOTPChange}
                      value={OTP}
                      placeholder='ادخل الرمز'
                      ref={otpRef}
                      />
                      <p style={{color: "gray", marginTop:"8px", fontSize:"14px"}}>قد يستغرق وصول الرمز دقيقة واحدة</p>
                      {/* <button id='my-button' style={{width:"50%"}} onClick={verifyOTP} >

                      {   
   sendOTPLoading ?    
   <div style={{
  alignItems: 'center',
  width: "100%",
  height: "100%",
  justifyContent: 'center',
  display:"flex"
    }}>
      <Oval
            height={"20px"}
            width={"20px"}
            color={"white"}
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
            />
            </div>
             : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}> </p>
            }
                      </button> */}
                  </div>
                  <p style={{marginBottom:"0px", marginTop:"22px", fontSize: "14px"}}>إذا كنت مستخدمًا جديدًا وأنشأت حسابًا، فإنك توافق على <Link to="/user_policy">سياسة الاستخدام و الخصوصية</Link></p>
              </div>
              <div style={{display: "none", visibility:"hidden" , justifyContent: "center", alignItems: "center", marginBottom: "50px"}} id='recaptcha-container'></div>
          </div>
          ) : (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"  }}>
            <Lottie
              style={{ width: "30%", }}
              animationData={SignedInAnim}
              loop={true}
            />
            <p style={{ marginTop: "16px", color: "#00A49B", fontWeight:500 }}>أنت مسجل دخول بالفعل</p>
          </div>
          )
        }
          </>
        );
}
 
export default SignInOrSignUpScreen;