import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import { ChatProvider } from '../src/hooks/ChatContext'; // The context provider
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import { hydrate, render } from 'react-dom'



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(  <Router>
    <ChatProvider>
      <App />
    </ChatProvider>
  </Router>, rootElement);
} else {
  render(  <Router>
    <ChatProvider>
      <App />
    </ChatProvider>
  </Router>, rootElement);
}


// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <Router>
//     <ChatProvider>
//       <App />
//     </ChatProvider>
//   </Router>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
