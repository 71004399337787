import React, { useState, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { FaTrashCan } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { Divider } from '@mui/material';
import galleryPlaceholder from '../assets/galley-placeholder.jpg';
import { BiImageAdd } from "react-icons/bi";
import { Oval } from 'react-loader-spinner'; // Ensure you have imported the Oval component from react-loader-spinner


function ImageCropper({ imagess, setImages: updateImages, activeIndex, setActiveIndex, onCancelRecrop, onDiscard, onCrop, onRecrop}) {
    const initialImageState = {
        src: null,
        croppedImage: null,
        isCropped: false,
    };

    // Initialize images state with placeholder data
    const [images, setImages] = useState(Array(10).fill(null).map((_, idx) => ({ ...initialImageState, id: idx })));
    // const [activeIndex, setActiveIndex] = useState(null);
    const cropperRef = useRef(null);
    const fileInputRefs = useRef(new Array(10).fill(null));

    const [cropperLoading, setCropperLoading] = useState(false);

    const [loading, setLoading] = useState(true);

    const onSelectFile = (index) => (event) => {
      if (event.target.files && event.target.files.length > 0) {
        let selectedFiles = Array.from(event.target.files);
    
        // Limit the selection to the first 10 files
        if (selectedFiles.length > 10) {
          selectedFiles = selectedFiles.slice(0, 10);
        }
    
        Promise.all(
          selectedFiles.map((file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result); // Resolve with the base64 URL
              reader.readAsDataURL(file);
            })
          )
        )
          .then((results) => {
            const updatedImages = [...images];
    
            // Handle multiple selected files
            results.forEach((result, fileIndex) => {
              // Find the first empty slot or create a new slot if all are full
              let emptySlotIndex = updatedImages.findIndex((image) => image.src === null);
              if (emptySlotIndex === -1) {
                emptySlotIndex = updatedImages.length;
                updatedImages.push({}); // Create a new slot
              }
    
              updatedImages[emptySlotIndex] = { src: result, isCropped: false };
            });
    
            setImages(updatedImages);
            updateImages(updatedImages.filter((image) => image && image.src));
            setImagesUpdated(true);
            event.target.value = ''; // Optionally, clear the file input after selecting files
          })
          .catch((error) => console.error('Error reading selected file:', error));
      }
    };
    
    
    function moveImageToFirst(selectedIndex, images) {
      // Early return to avoid unnecessary operations if the image is already at index 0
      if (selectedIndex === 0) return;
    
      // Extract the image to move
      const [imageToMove] = images.splice(selectedIndex, 1);
    
      // Insert the image at the beginning (index 0)
      images.unshift(imageToMove);
    
      // Update the parent component's state with the correctly ordered images
      setImages(images); // No additional order manipulation needed
      updateImages(images)
    }
    
    

    // const onCrop = () => {
    //     if (cropperRef.current) {
    //       const cropper = cropperRef.current.cropper;
    //       if (cropper) {
    //         cropper.getCroppedCanvas().toBlob((blob) => {
    //           if (blob) {
    //             const croppedUrl = URL.createObjectURL(blob);
    //             const updatedImages = images.map((image, index) =>
    //               index === activeIndex ? { ...image, src: croppedUrl } : image
    //             );
    //             setImages(updatedImages);
    //             setImagesUpdated(true);
    //             setActiveIndex(null);
    //           }
    //         });
    //       }
    //     }
    //   };
    
    const [imagesUpdated, setImagesUpdated] = useState(false)

      useEffect(() => {

            const updatedImages = imagess.map((image, index) => ({
                ...initialImageState,
                id: index,
                src: typeof image === 'string' ? image : image.src,
                isCropped: false,
                isRecropping: typeof image === 'object' ? image.isRecropping : false,
            }));
            setLoading(false);
    
            const emptySlots = 10 - updatedImages.length;
            for (let i = 0; i < emptySlots; i++) {
                updatedImages.push({
                    ...initialImageState,
                    id: updatedImages.length,
                });
            }
    
            setImages(updatedImages);
    }, [imagess]); // Consider removing this dependency or managing it carefully
    


    const onDelete = (index) => {
        if (window.confirm("حذف الصورة؟")) {
            const newImages = images.filter((_, idx) => idx !== index);
            newImages.forEach((image, idx) => {
                image.id = idx; // Update the id to maintain uniqueness
            });
    
            setImages(newImages);
            setImagesUpdated(true)
            updateImages(newImages.filter((image) => image.src !== null));
    
            if (fileInputRefs.current[index]) {
                fileInputRefs.current[index].value = '';
            }
        }
    };

    // const onRecrop = (index) => {
    //   console.log("onRecrop called for index:", index);
    //   setCropperLoading(true); // Show loading spinner
    //   setActiveIndex(index);   // Set the active image index
    //   setImages((prevImages) => {
    //     const updatedImages = prevImages.map((img, idx) =>
    //       idx === index ? { ...img, isCropped: false, isRecropping: true } : img
    //     );
    //     console.log("Updated Images:", updatedImages);
    //     return updatedImages;
    //   });
    // };
    

    // const onCancelRecrop = (index) => {
    //     const newImages = [...images];
    //     newImages[index] = { ...newImages[index], isRecropping: false };
    //     setImages(newImages);
    //     setActiveIndex(null); // Optionally reset the active index
    // };


    // useEffect(() => {
    //   if (activeIndex) {
    //     // Disable scrolling when the component is mounted
    //     document.body.style.overflow = 'hidden';
    //   } else {
    //     // Enable scrolling if the condition is not met
    //     document.body.style.overflow = 'auto';
    //   }
    
    //   // Clean up when the component is unmounted or when the openSearchShouldRender value changes
    //   return () => {
    //     document.body.style.overflow = 'auto';  // Always restore default scrolling when the component is unmounted
    //   };
    // }, [activeIndex]);  // Dependency array includes openSearchShouldRender
    

    return (
      <>

        <div style={{ 
          width: '100%', 
          height:"fit-content",
          direction:"rtl", 
          justifyContent:"center", 
          backgroundColor:"#f0f0f0",
          padding:"8px", 
          borderRadius:"8px",
          boxSizing:"border-box"

          }}>
          
          
            <div 
            style={{ 
              padding: window.innerWidth > window.innerHeight ? "8px 0px" :"8px 16px",
              height:"fit-content"
            }}
            >

<div style={{ 
  display: images.filter(image => image.src !== null).length === 0 ? "block" : 'grid', 
  textAlign:"center", 
  justifyContent:"center" ,
  gridTemplateColumns: window.innerWidth > window.innerHeight ? "repeat(auto-fill, minmax(200px, 1fr))" : "repeat(auto-fill, minmax(40%, 1fr))", 
  columnGap:"1rem", 
  rowGap:"2rem", 
  height:"fit-content",
  
   }}>
{images.map((image, index) => {
  const key = image.id !== undefined ? image.id : `image_${index}`;
  const checked = index === 0;
  // Conditional rendering for image slot
  return (index === 0 || images[index - 1]?.src) && (
    <div 
    key={key} 
    style={{ 
      width: "fit-content", 
      height: 'fit-content', 
      position: 'relative', 
      cursor:"pointer",
      }}>
      {!image.src && (
        <div style={{ width: '100%', height: '100%', position: 'relative', justifyContent: "flex-start", textAlign:"center",display:"flex", cursor:"pointer"}}>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={onSelectFile(index)}
            max={10 - images.length}
            style={{ width: '100%', height: '100%', opacity: 0, position: 'absolute', zIndex: 1, cursor:"pointer" }}
            ref={el => (fileInputRefs.current[index] = el)}
          />
          <div onClick={() => fileInputRefs.current[index].click()}
          
            style={{ 
              
              width: '10rem', 
              height: '10rem' ,
              display: 'flex',
               backgroundColor:"#f7f7f7", justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius:"8px", border:"1.5px SOLid #00C8B8", minHeight:"10rem", minWidth:"10rem", maxWidth:"10rem", maxHeight:"10rem" }}>
            <BiImageAdd style={{width:"45%", height:"45%", color:"#00C6B7", cursor:"pointer"}} />
          </div>
        </div>
      )}

      {image.src && (
        <>
          <img
            src={image.isCropped ? image.croppedImage : image.src}
            alt={`Image ${index}`}
            style={{ width: window.innerWidth > window.innerHeight ? "200px" : "100%", objectFit: 'cover', borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          />
          <div style={{ justifyContent: "start", direction: "rtl", padding: "8px", backgroundColor: "#f7f7f7", borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px", alignItems: "center", width: "100%", marginTop: "-1px", borderBottom: "0.5px solid #ccc" }}>
            
            <div style={{display:"flex"}}>

            <button style={{ backgroundColor: "#F00057", border: "none", borderRadius: "50%", marginLeft: "6px", width: "27px", height: "27px", minHeight:"27px", minWidth:"27px" }} onClick={(e) => { e.stopPropagation(); onDelete(index); }}>
              <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%", height:"100%"}}>
              <FaTrashCan size={12} style={{ color: "white", marginRight:"1px"}} />
              </div>
            </button>
            <button style={{ backgroundColor: "#00C6B7", border: "none", borderRadius: "50%", marginLeft: "4px", width: "27px", height: "27px", minHeight:"27px", minWidth:"27px" }} onClick={(e) => { 
              e.stopPropagation(); 
              onRecrop(index); }}>
              <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center"}}>
              <IoIosCrop size={17} style={{ color: "white", minHeight:"14px", minWidth:"14px" }} />
              </div>
            </button>
            </div>
            <div style={{ 
              display: "flex", 
              justifyContent: "flex-start", 
              alignItems: "center", 
              position:"relative", 
              marginTop:"8px", 
            }}
              onClick={()=>{
              // Handle checkbox change to move image data
              const newImages = [...images];
              moveImageToFirst(index, newImages); // Call the redefined function
              }}
            >
              <label style={{ marginLeft: "4px", color: "#00C8B8", display: "flex", alignItems: "center" }}>
              <input
              type="checkbox"
              checked={checked} // Set checked state based on index
              style={{
                appearance: "none", // Remove default checkbox styles
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                border: "1px solid #00C8B8",
                backgroundColor: checked ? "#00C8B8" : "transparent",
                marginRight: "4px",
                cursor: "pointer",
                lineHeight: "16px", // Center content vertically
                textAlign: "center", // Center content horizontally
                position: "relative", // Make checkbox a positioning context
              }}
              />
              <FaCheck
                style={{
                  color: checked ? "white" : "transparent ",
                  fontSize: "9px",
                  padding: 0,
                  margin: 0,
                  position: "absolute", // Make FaCheck absolutely positioned
                  top: "50%", // Position vertically centered within checkbox
                  right: "0.31rem", // Position horizontally centered within checkbox
                  transform: "translate(-50%, -50%)", // Adjust position for centering
                }}
              />
            </label>
            <p style={{whiteSpace:"nowrap", fontSize:"0.8rem", marginBottom:"0px"}}>الصورة الأساسية</p>
          </div>
          </div>
        </>
      )}
    </div>
  );
})}



            </div>

            </div>
        </div>
      </>

    );
}
export default ImageCropper;