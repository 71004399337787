import React, {useEffect, useRef, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import { IoLogoWhatsapp } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { IoSend } from "react-icons/io5"; 
import { FaInstagram } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { db, auth, storage } from '../config/firebase'
import { Oval } from 'react-loader-spinner'
import { GoHomeFill, GoReport } from "react-icons/go";
import { getFunctions, httpsCallable } from 'firebase/functions';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import logo from '../assets/logo.png'



function ContactUs ({myId}) {

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [])


  useEffect(() => {
    document.title = `بيع - تواصل معنا`; // Dynamically set the page title
}, []); // Empty dependency array ensures this runs once on mount


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set the div to visible after a slight delay to ensure the transition happens
      setTimeout(() => setIsVisible(true), 100);
  }, []);

  const location = useLocation();


const [message, setMessage] = useState("");
const [messageHasError, setMessageHasError] = useState("");
const [messageIsSending, setMessageIsSending] = useState(false);
const messageRef = useRef();
const messageTypeRef = useRef();

const messageTypeLoc = location.state?.messageTypeLoc;
const [messageType, setMessageType] = useState(messageTypeLoc || "");


  const whatsAppLink = (phoneNumber) => {
    return `https://wa.me/${phoneNumber}`;
};

const sendSupportMessage = async () => {


  if(!myId){
    alert("يجب تسجيل الدخول لخدمتك بشكل أفضل");
    return
  }

  if(!messageType){
    alert("يجب تحديد نوع الرسالة");
    return;
  }

  
  if(!message ||  message.trim() === ''){
    alert("يجب ادخال نص الرسالة");
    setMessageHasError(true)
    return
  }


  setMessageIsSending(true)
  const functions = getFunctions();
  const sendMessage = httpsCallable(functions, 'sendSupportMessage');
  
  try {
    const result = await sendMessage({
      userId: myId, // Pass the user ID here
      message,
      messageType
    });

    if (result.data.success) {
          // Track registration event
    ReactGA.event({
      category: "Message",
      action: messageType,
      label: "User Contacted Support",
    });
      setMessageType("");
      setMessage("");
      if(messageTypeRef.current) messageTypeRef.current.value = "";
      messageRef.current.value = "";
      alert("شكرا لتواصلك معنا! سنرد عليك بأسرع وقت")
    } else {
      console.error('Error sending message:', result.data.error);
    }
  } catch (error) {
    console.error('Error calling sendSupportMessage function:', error);
  }
  setMessageIsSending(false)
};

        return (
          <>

          <Helmet>
              <title>تواصل معنا</title>
              <meta name="description" content="صفحة تواصل معنا على منصة بيع. هنا يمكنك ارسال رسالة للدعم الفني و سيتم الرد عليك بأسرع وقت أو التواصل على رقم الواتساب" />
              <meta property="og:type" content="page" />
              <meta property="og:title" content="تواصل معنا" />
              <meta property="og:description" content="صفحة تواصل معنا على منصة بيع. هنا يمكنك ارسال رسالة للدعم الفني و سيتم الرد عليك بأسرع وقت أو التواصل على رقم الواتساب" />
              <meta property="og:image" content={logo} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:site_name" content="منصة بيع" />
              <meta name="twitter:title" content="تواصل معنا" />
              <meta name="twitter:description" content="صفحة تواصل معنا على منصة بيع. هنا يمكنك ارسال رسالة للدعم الفني و سيتم الرد عليك بأسرع وقت أو التواصل على رقم الواتساب" />
              <meta name="twitter:image" content={logo} />
          </Helmet>
          
<div
      style={{
        direction: "rtl",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px",
        height: "fit-content",
        minHeight: "calc(100vh - 65px)",
        marginTop: "32px",
        opacity: isVisible ? 1 : 0, // Change opacity based on isVisible
        transform: isVisible ? "translateY(0)" : "translateY(20px)", // Reset translateY based on isVisible
        transition: "opacity 0.3s ease, transform 0.2s ease",
      }}
      onClick={() => {
        setMessageHasError(false);
      }}
    >

                          {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>تواصل معنا</h6>
        </div>
      </div>
                <h4 style={{marginTop:"16px"}}>تواصل معنا</h4>

<p style={{fontSize:"18px", fontWeight:"500"}}> نحن هنا لخدمتكم على مدار الساعة اذا كانت لديكم أي استفسارات يمكنكم ارسالها لنا عن طريق المنصة و سنقوم بالرد بأسرع وقت أو مراسلتنا عبر البريد الإلكتروني أو عن طريق الواتساب: </p>
            
            {/* <div style={{display:"flex", alignItems:"center"}}>
            <div style={{
    backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
    justifyContent: "center", 
    alignItems: "center", 
    padding: "4px", 
  borderRadius: "30px",
  marginLeft:"8px",
  width:"fit-content",
  height:"fit-content",
  marginBottom:"4px"
}}>
    <div style={{
        backgroundColor: "#00C8B8", 
        width: "30px", 
        height: "30px", 
        justifyContent: "center", 
        alignItems: "center", 
        borderRadius: "30px",
        display:"flex"
    }}>
        <CiMail color='white'/>
    </div>
</div>
            <a href="mailto:support@men-ela.com" style={{fontSize:"18px", color:"black", textDecoration:"none", fontWeight:"500"}}>support@men-ela.com</a>

            </div> */}


            <div style={{display:"flex", alignItems:"center", marginBottom:"64px"}}>
            <div style={{
    backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
    justifyContent: "center", 
    alignItems: "center", 
    padding: "4px", 
  borderRadius: "30px",
  marginLeft:"8px",
  width:"fit-content",
  height:"fit-content"
}}>
    <div style={{
        backgroundColor: "#00C8B8", 
        width: "30px", 
        height: "30px", 
        justifyContent: "center", 
        alignItems: "center", 
        borderRadius: "30px",
        display:"flex"
    }}>
        <IoLogoWhatsapp color='white'/>
    </div>
</div>
            <a href={whatsAppLink("966541236990")} target='_blank' style={{fontSize:"18px", color:"black", textDecoration:"none", fontWeight:"500", direction:"ltr"}}>+966541236990</a>

            </div>


            <h5>التواصل مع الدعم</h5>

            <div style={{direction:"rtl", display:"flex", flexDirection:"column", position:"relative", height:"fit-content", width: window.innerWidth > window.innerHeight ? "50%" : "100%", paddingTop:"8px"}}>
            <div style={{display:"flex"}}>
            <p style={{color:"red"}}>*</p>
            <select
            defaultValue={messageType}
            ref={messageTypeRef}
              id="input-field"
              onChange={(event) => {
                const selectedValue = event.target.value;
                setMessageType(selectedValue);
              }}
              style={{ color: "black", borderRadius: "4px", marginBottom:"8px", cursor:"pointer" }}
              >
              <option value="">نوع الرسالة</option>  {/* Default option with descriptive text */}
              <option value="استفسار" style={{ backgroundColor: "white", textAlign: "right" }}>
                استفسار
              </option>
              <option value="مشكلة" style={{ backgroundColor: "white", textAlign: "right" }}>
                ابلاغ عن مشكلة
              </option>
              <option value="اضافة منتج" style={{ backgroundColor: "white", textAlign: "right" }}>
                لا أرى تصنيف منتجي
              </option>
            </select>
              </div>
            <textarea ref={messageRef} onChange={(e)=>{setMessageHasError(false); setMessage(e.target.value)}} placeholder='اكتب هنا' style={{backgroundColor:"#F0F0F0", border: messageHasError ? "1px solid #F00057" : "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px"}}/>
            <button disabled={messageIsSending} id='my-button' onClick={()=> sendSupportMessage(message)} style={{
    height: "35px", 
    width: window.innerWidth > window.innerHeight  ? "150px" : "100px", 
    position: "absolute", 
    bottom: 8, 
    left: 8,
    display: "flex", // Use flexbox for alignment
    alignItems: "center", // Center vertically
    justifyContent: "center", // Align content to the left
    backgroundColor:"#F00057"
}}>
{
  messageIsSending ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", paddingTop:"4px"}}>
  <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            /> 
  </div> : <p style={{marginBottom:"0px"}}>ارسال</p>
}
    <IoSend style={{
        position:"absolute",
        left:8,
        top:"50%",
        bottom:"50%",
        transform: "translateY(-50%) scaleX(-1)" // Flip horizontally and adjust vertical position
    }} />
</button>



            </div>

            <div style={{marginTop:"32px", textAlign:"center", direction:"ltr"}}>
              <h5>تابعنا على</h5>


            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
             

 {/* tiktok */}

 <a href="https://www.tiktok.com/@SellAppSA" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

<div style={{
  backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
  justifyContent: "center", 
  alignItems: "center", 
  padding: "4px", 
  borderRadius: "30px",
  marginLeft:"8px",
  width:"fit-content",
  height:"fit-content"
}}>
  <div style={{
      backgroundColor: "#00C8B8", 
      width: "30px", 
      height: "30px", 
      justifyContent: "center", 
      alignItems: "center", 
      borderRadius: "30px",
      display:"flex"
  }}>
      <FaTiktok color='white'/>
  </div>
</div>
</a>
            {/* snapchat */}
            <a href="https://snapchat.com/t/ai7kUIuT" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

              <div style={{
                backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                justifyContent: "center", 
                alignItems: "center", 
                padding: "4px", 
                borderRadius: "30px",
                marginLeft:"8px",
                width:"fit-content",
                height:"fit-content"
              }}>
                <div style={{
                    backgroundColor: "#00C8B8", 
                    width: "30px", 
                    height: "30px", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    borderRadius: "30px",
                    display:"flex"
                }}>
                    <FaSnapchatGhost color='white'/>
                </div>
            </div>
</a>

 {/* instagram */}

 <a href="https://www.instagram.com/SellAppSA" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <div style={{
        backgroundColor: "rgba(0, 200, 184, 0.21)",
        justifyContent: "center", 
        alignItems: "center", 
        padding: "4px", 
        borderRadius: "30px",
        marginLeft:"8px",
        width:"fit-content",
        height:"fit-content"
    }}>
        <div style={{
            backgroundColor: "#00C8B8", 
            width: "30px", 
            height: "30px", 
            justifyContent: "center", 
            alignItems: "center", 
            borderRadius: "30px",
            display:"flex"
        }}>
            <FaInstagram color='white' size={19}/>
        </div>
    </div>
</a>
           
            {/* x */}

            <a href="https://x.com/SellAppSA" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

              <div style={{
                backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                justifyContent: "center", 
                alignItems: "center", 
                padding: "4px", 
                borderRadius: "30px",
                marginLeft:"8px",
                width:"fit-content",
                height:"fit-content"
              }}>
                <div style={{
                    backgroundColor: "#00C8B8", 
                    width: "30px", 
                    height: "30px", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    borderRadius: "30px",
                    display:"flex"
                }}>
                    <RiTwitterXFill color='white'/>
                </div>
            </div>
</a>
            </div>
            </div>

            </div>
          </>
        );
}
 
export default ContactUs;