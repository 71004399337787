import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, auth } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Oval } from 'react-loader-spinner';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { SlCheck } from "react-icons/sl";
import { getDoc } from 'firebase/firestore';
import LoadingScreen from './LoadingScreen';
import Lottie from 'lottie-react';
import PaymentSuccess from '../assets/animations/paymentSuccess.json'
import PaymentFailed from '../assets/animations/paymentFailed.json'
import ReactGA from "react-ga4";
import logo from '../assets/logo.png'
import { Helmet } from 'react-helmet';



function PaymentStatus({myId}) {
  const [isLoading, setIsLoading] = useState(true);
  const [buyerId, setBuyerId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [mode, setMode] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const orderNumber = urlParams.get('orderId');

  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderAmount, setOrderAmount] = useState(0);


  useEffect(() => {
    let unsubscribeOrderData;

    const fetchData = async () => {
        setIsLoading(true); // Start loading before fetching data

        try {
            const orderDocRef = doc(db, "Orders", `${orderNumber.toString()}`);
            unsubscribeOrderData = onSnapshot(orderDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const orderData = docSnapshot.data();
                    setBuyerId(orderData.buyerId);
                    setBuyerId(myId);
                    // Only navigate if buyerId and myId have been set
                    if(myId){
                      if (orderData.buyerId !== myId) {
                        console.log("Not my order");
                        navigate("/");
                        return;
                      }
                    }

                    setPaymentStatus(orderData.payment_status);
                    setOrderAmount(orderData.amount)
                    setProductId(orderData.productId);
                    setMode(orderData.type);
                } else {
                    // console.log("Order document does not exist for order number:", orderNumber);
                    setPaymentStatus("error");
                    setBuyerId(null);
                    setProductId(null);
                    setMode(null);
                }
            }, (error) => {
                console.error("Error fetching order document:", error);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // setError(error); // Set error state if there's an issue
        } finally {
            // Ensure loading state is set to false after data fetching is complete
            setIsLoading(false);
            setUserDataLoaded(true);
        }
    };

    fetchData();

    return () => {
        if (unsubscribeOrderData) unsubscribeOrderData();
    };
}, [orderNumber, myId]); // Depend on orderNumber and myId for data fetching


  useEffect(() => {
    window.scrollTo(0, 0);
}, []);



  useEffect(() => {
    if (paymentStatus === "success") {
      if(mode === "Purchase"){
      // Track registration event
      ReactGA.event({
        category: "Purchase",
        action: "User purchased an item",
        label: "User Purchase",
        amount: orderAmount
      });
        setTimeout(() => {
          navigate(`/my_orders/${orderNumber}`, {
            state: {
              mode: mode,
              productId: productId
            }
          });
        }, 3000); // 2 seconds delay
      } else {
        setTimeout(() => {
          navigate(`/product/${productId}`, {state: {mode: mode}});  
        }, 3000); // 2 seconds delay
      }
    } else if (paymentStatus === "failed") {
      setTimeout(() => {
        navigate(`/checkout/${productId}`, { state: { mode: mode, offerAmount: orderAmount } });
      }, 3000); // 2 seconds delay
    }
  }, [paymentStatus]);


  if (isLoading || paymentStatus === null) {
    return <LoadingScreen text={"جاري معالجة عملية الدفع"} />
  }

  if(buyerId !== null && myId !== null){
    if (buyerId != myId) {
      navigate(`/`);
      return <h1 style={{ marginTop: "60px" }}>not my order</h1>;
    }
  }

  return (

    <>

<Helmet>
              <title>تنفيذ عملية دفع للطلب رقم ${orderNumber}</title>
          </Helmet>
    
    <div style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      {
        paymentStatus !== null && (
          paymentStatus === "success" ? (
            <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <Lottie animationData={PaymentSuccess} loop={false}/>
              <p style={{marginTop:"0px", marginBottom:"4px"}}>تمت عملية الدفع بنجاح!</p>
              <p>جاري إعادة توجيهك للصفحة المطلوبة...</p>
            </div>
          ) : paymentStatus === "failed" ? (
            <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <Lottie animationData={PaymentFailed} loop={false}/>

            <p style={{marginTop:"0px", marginBottom:"4px"}}>لقد فشلت عملية الدفع</p>
            <p>جاري إعادة توجيهك للصفحة المطلوبة...</p>
          </div>
          // ) : ""
          ) : <h1>here</h1>

        )
      }
    </div>
    </>
  );
}

export default PaymentStatus;